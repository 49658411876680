import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Breed, Bull, Segment } from 'types';

type AllSelectedSegment = {
  type: 'segment'
  key: Omit<Segment, ''>
}

type AllSelectedBreed = {
  type: 'breed'
  key: string
}

type AllSelectedNone = {
  type: 'none',
  key?: undefined
}

export type AllSelected = AllSelectedSegment | AllSelectedBreed

type BullsState = {
  bulls: Bull[]
  breeds: Breed[]
  selectedBulls: number[],
  filteredBulls: number[],
  highlightBreeds: number[],
  scopedBulls: number[],
  loading: boolean,
  mainLoading: boolean,
  allSelected: AllSelected[]
}

const initialState: BullsState = {
  bulls: [],
  breeds: [],
  selectedBulls: [],
  filteredBulls: [],
  scopedBulls: [],
  highlightBreeds: [],
  loading: false,
  mainLoading: false,
  allSelected: []
};

function getIndexOfAllSelected(arr: AllSelected[], p: AllSelected) {
  return arr.findIndex(e => e.key === p.key && e.type === p.type);
}

const items = createSlice({
  name: 'items',
  initialState,
  reducers: {
    pushBull: (state, action: PayloadAction<Bull>) => {
      state.bulls.push(action.payload);
    },
    setBulls: (state, action: PayloadAction<Bull[]>) => {
      state.bulls = action.payload;
    },
    selectBull: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      if (!state.selectedBulls.includes(payload)) {
        state.selectedBulls.push(payload);
      }
    },
    unselectBull: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      if (state.selectedBulls.includes(payload)) {
        state.selectedBulls = state.selectedBulls.filter(n => n !== payload);
      }
    },
    silentChangeSelectedBulls: (state, action: PayloadAction<number[]>) => {
      state.selectedBulls = action.payload;
    },
    changeFilteredList: (state, action: PayloadAction<number[]>) => {
      state.filteredBulls = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setMainLoading: (state, action: PayloadAction<boolean>) => {
      state.mainLoading = action.payload;
    },
    setBreeds: (state, action: PayloadAction<Breed[]>) => {
      state.breeds = action.payload;
    },
    setHighlighBreeds: (state, action: PayloadAction<number[]>) => {
      state.highlightBreeds = action.payload;
    },
    changeScopedBulls: (state, action: PayloadAction<number[]>) => {
      state.scopedBulls = action.payload;
    },
    pushAllSelected: (state, action: PayloadAction<AllSelected>) => {
      if (getIndexOfAllSelected(state.allSelected, action.payload) === -1) {
        state.allSelected.push(action.payload);
      } else {
        console.warn('Trying to repeat all-selected item');
      }
    },
    removeAllSelected: (state, action: PayloadAction<AllSelected>) => {
      const index = getIndexOfAllSelected(state.allSelected, action.payload);
      state.allSelected.splice(index, 1);
    }
  }
});

export const itemsActions = items.actions;
export default items.reducer;