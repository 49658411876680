import { FormControl, Stack, Select, InputLabel, MenuItem, Autocomplete, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import utils from 'utils';
import styles from './Form.styles';
import citiesByUF from './citiesByUF';

type Props = {
  onChange?: (uf: string, city: string) => void
}

const states = Object.keys(citiesByUF);

const cityToOption = (city: {city: string, code: number}) => ({ label: city.city, id: `${city.code}` });
const emptyCity = { label: '', id: '-1' };

const Form: FC<Props> = ({ onChange }) => {

  const [ state, setState ] = useState('');
  const [ city, setCity ] = useState(emptyCity);
  const cityDisabled = state === '';

  useEffect(() => {
    onChange && onChange(state, city.id);
  }, [ city, state ]);

  return (
    <Stack
      { ...utils.stack.flex.row('center', 'center') }
      spacing={ 1 }
      sx={ styles.container }
    >
      <FormControl sx={ styles.stateInputSx }>
        <InputLabel id='state-select-label'> Estado </InputLabel>
        <Select
          labelId='state-select-label'
          label='Estado'
          value={ state }
          onChange={(x) => {
            setState(x.target.value),
            setCity(emptyCity);
          }}
        >
          {
            states.map(state => (
              <MenuItem key={ state } value={ state }>{ state }</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <Autocomplete
        sx={{ width: '100%', '& .MuiOutlinedInput-root': { paddingY: '1px' } }}
        disablePortal
        disabled={ cityDisabled }
        options={
          [
            ...(citiesByUF[state as keyof typeof citiesByUF] ?? []).map(cityToOption),
            emptyCity
          ]
        }
        getOptionDisabled={(option) => option.id === '-1'}
        value={city}
        onChange={(_, val) => setCity(val ?? emptyCity)}
        renderInput={
          (params) => (
            <TextField {...params}/>
          )
        }
      />
    </Stack>
  );
};

export default Form;