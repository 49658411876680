import { Checkbox, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import utils from 'utils';
import styles from './SelectableButton.styles';

type Props = {
  selected?: boolean,
  onClick?: () => void
}

const SelectableButton: FC<Props> = ({
  selected = false,
  onClick
}) => {
  return (
    <Stack
      { ...utils.stack.flex.row('center', 'center') }
      spacing={ 2 }
      sx={{
        ...styles.container, '&:hover': {
          background: '#4881B5'
        },
        background: selected ? '#154775' : '#2F679C'
      }}
      onClick={ onClick }
    >
      <Checkbox checked={ selected } color='default' sx={{ padding: '0px' }} />
      <Typography sx={{ color: 'white' }}>{ selected ? 'Selecionado' : 'Selecionar' }</Typography>
    </Stack>
  );
};

export default SelectableButton;