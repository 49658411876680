import { Theme, ThemeOptions } from '@mui/material';

export const getTextFieldOverrides = (theme: Theme): ThemeOptions['components'] => {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '8px'
        },
        root: {
          background: '#EAF2FA'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#7F746F',
          top: '-8px',
        },
        shrink: {
          top: '0px',
          transition: '.15s ease'
        }
      }
    }
  };
};