import { SxProps, Theme } from '@mui/material';
import { AlignItems, JustifyContent } from 'types';

const col = (horizontalAlignment: AlignItems = 'center', verticalAlginment: JustifyContent = 'center'): any => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: verticalAlginment,
  alignItems: horizontalAlignment
});

const row = (horizontalAlignment: JustifyContent = 'center', verticalAlginment: AlignItems = 'center'): any => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: horizontalAlignment,
  alignItems: verticalAlginment
});

const flex = {
  col,
  row
};

const fw: SxProps = {
  width: '100%',
  maxWidth: 'none'
};

const container = (theme: Theme): SxProps => ({
  width: '100%',
  maxWidth: theme.breakpoints.values.lg,
  paddingX: '16px',
  [theme.breakpoints.up('lg')]: {
    paddingX: '8px'
  }
});

const fixedSize = (minWidth: number): SxProps => ({
  minWidth: `${minWidth}px`
});

const sx = {
  flex,
  fw,
  container,
  fixedSize
};

export default sx;