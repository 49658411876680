import { Badge, Stack, Typography } from '@mui/material';
import { Store } from 'rdx';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import utils from 'utils';
import styles from './CatalogButton.styles';


const CatalogButton: FC = () => {

  const navigate = useNavigate();
  const items = useSelector((store: Store) => store.items);
  const clickable = items.selectedBulls.length > 0;

  return (
    <Badge badgeContent={items.selectedBulls.length} sx={ styles.badge }>
      <Stack
        { ...utils.stack.flex.row('space-between', 'center') }
        spacing={ 2 }
        sx={ styles.getContainer(clickable) }
        onClick={() => clickable && navigate('/catalog')}
      >
        <img
          style={ styles.img as any }
          src={
            clickable ? '/icons/catalog2.svg' : '/icons/catalog.svg'
          }
        />      
        <Typography sx={ styles.getText(clickable) }>
        Gerar catálogo
        </Typography>
      </Stack>
    </Badge>
  );
};

export default CatalogButton;