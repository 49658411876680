import { Stack, Typography } from '@mui/material';
import { objectIs } from 'object-fn-ts/lib';
import { FC } from 'react';
import utils from 'utils';
import IndexElement from './IndexElement';

const { safeHas } = utils;

type Props = {
  selectedProofData: any
}

const LEIndexes: FC<Props> = ({ selectedProofData }) => {
  return (
    <>
      {
        (safeHas('ÍNDICES', selectedProofData) || safeHas('ÍNDICE', selectedProofData)) &&
      <Stack
        { ...utils.stack.flex.col('flex-start', 'center') }
        spacing={ 2 }
        sx={{
          width: '100%',
          maxWidth: '450px',
          minWidth: '340px',
          marginY: '24px'
        }}
      >
        <Typography variant='h3'> {safeHas('ÍNDICES', selectedProofData) ? 'ÍNDICES' : 'ÍNDICE'} </Typography>
        <Stack
          { ...utils.stack.flex.row('flex-start', 'center') }
          sx={{ width: '100%', flexWrap: 'wrap' }}
        >
          {
            Object.entries(
              selectedProofData?.['ÍNDICES'] || selectedProofData?.['ÍNDICE'] || {}
            ).map(([ key, val ]: [string, any], i) => (
              <IndexElement key={key+i} name={key} val={`${val.value}`} />
            ))
          }
        </Stack>
      </Stack>
      }
      {
        safeHas('PRODUÇÃO', selectedProofData) &&
        <Stack
          { ...utils.stack.flex.col('flex-start', 'center') }
          spacing={ 2 }
          sx={{
            width: '100%',
            maxWidth: '450px',
            minWidth: '340px',
            marginY: '24px'
          }}
        >
          <Typography variant='h3'> PRODUÇÃO </Typography>
          <Stack
            { ...utils.stack.flex.row('flex-start', 'center') }
            sx={{ width: '100%', flexWrap: 'wrap' }}
          >
            {
              Object.entries(selectedProofData?.['PRODUÇÃO'] || {})
                .map(([ key, val ]: [string, any], i) => (
                  <IndexElement key={key+i} name={key} val={`${val.value}`} />
                ))
            }
          </Stack>
        </Stack>
      }
    </>
  );
};

export default LEIndexes;