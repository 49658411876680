const tsToBrDate = (ts: string) => {
  const dateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{2}|\d{4})$/;
  
  if (!ts) return '';

  if (dateRegex.test(ts)) return ts;
  
  const preDate = ts.substring(0, ts.indexOf('T'));
  const components = preDate.split('-');
  return `${components[2]}/${components[1]}/${components[0]}`;
};

export default tsToBrDate;