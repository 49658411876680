import { withTheme } from 'theme';
import utils from 'utils';

const container = withTheme((theme) => ({
  ...utils.sx.flex.col('center', 'center'),
  width: '100%',
  height: '110px',
  boxShadow: '0px 3px 21px rgba(0, 0, 0, .15)',
  position: 'fixed',
  top: '0px',
  background: '#fff',
  zIndex: 2,
  [theme.breakpoints.up('md')]: {
    height: '110px'
  }
}));

const contentContainer = withTheme((theme) => ({
  ...utils.sx.flex.row('space-between', 'center'),
  ...utils.sx.container(theme)
}));

const styles = {
  container,
  contentContainer
};

export default styles;