import { Box, Stack, Typography } from '@mui/material';
import { Img } from 'components/common';
import { FC } from 'react';
import { Consultant } from 'types';
import utils from 'utils';
import styles from './ConsultantCard.styles';

type Props = {
  consultant: Consultant
}

const phoneMask = (s: string): string =>
  s.replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4,5})(\d{4})$/, '$1-$2');

const ConsultantCard: FC<Props> = ({ consultant }) => {
  return (
    <Stack
      { ...utils.stack.flex.row('center', 'center') }
      spacing={ 2 }
      sx={ styles.container }
    >
      <Box
        sx={{
          width: '33%',
          maxWidth: '130px',
          aspectRatio: '4/3',
          overflow: 'hidden',
          borderRadius: '4px',
          border: '1px solid #99BDDE',
          ...utils.sx.flex.col('center', 'flex-start')
        }}
      >
        <Img
          url={consultant.picture}
          fallbackImg='/imgs/default.png'
          sx={{ width: '100%', borderRadius: '4px' }}
        /> 
      </Box>   
      <Stack { ...utils.stack.flex.col('flex-start', 'center') } spacing={ .25 } sx={ styles.infoWrapper }>
        <Typography variant='h3'>{ consultant.name }</Typography>        
        {
          consultant.cellphone &&
          consultant.cellphone !== '-' &&
          <Typography>
            {`Contato: ${phoneMask(consultant.cellphone)}`}
          </Typography>
        }
        {
          consultant.phone &&
          consultant.phone !== '-' &&
          <Typography>
            {`Contato: ${phoneMask(consultant.phone)}`}
          </Typography>
        }
        {
          consultant.mail &&
          consultant.mail !== '-' &&
          <Typography>{ `Email: ${consultant.mail}` }</Typography>
        }
      </Stack>
    </Stack>
  );
};

export default ConsultantCard;