import { Box, CircularProgress, Typography } from '@mui/material';
import { FC } from 'react';
import styles from './MainLoading.styles';

const MainLoading: FC = () => {
  return (
    <Box sx={ styles.container }>
      <Typography variant='h2' sx={t => ({ color: t.palette.primary.main, paddingBottom: '12px' })}>
        Fazendo download dos dados
      </Typography>
      <CircularProgress size={ 64 } color='primary' />
    </Box>
  );
};

export default MainLoading;