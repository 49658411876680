const citiesByUF = {
  AC: [
    { city: 'Acrelândia', code: 1200013 },
    { city: 'Assis Brasil', code: 1200054 },
    { city: 'Brasiléia', code: 1200104 },
    { city: 'Bujari', code: 1200138 },
    { city: 'Capixaba', code: 1200179 },
    { city: 'Cruzeiro do Sul', code: 1200203 },
    { city: 'Epitaciolândia', code: 1200252 },
    { city: 'Feijó', code: 1200302 },
    { city: 'Jordão', code: 1200328 },
    { city: 'Mâncio Lima', code: 1200336 },
    { city: 'Manoel Urbano', code: 1200344 },
    { city: 'Marechal Thaumaturgo', code: 1200351 },
    { city: 'Plácido de Castro', code: 1200385 },
    { city: 'Porto Acre', code: 1200807 },
    { city: 'Porto Walter', code: 1200393 },
    { city: 'Rio Branco', code: 1200401 },
    { city: 'Rodrigues Alves', code: 1200427 },
    { city: 'Santa Rosa do Purus', code: 1200435 },
    { city: 'Sena Madureira', code: 1200500 },
    { city: 'Senador Guiomard', code: 1200450 },
    { city: 'Tarauacá', code: 1200609 },
    { city: 'Xapuri', code: 1200708 },
  ],
  AL: [
    { city: 'Água Branca', code: 2700102 },
    { city: 'Anadia', code: 2700201 },
    { city: 'Arapiraca', code: 2700300 },
    { city: 'Atalaia', code: 2700409 },
    { city: 'Barra de Santo Antônio', code: 2700508 },
    { city: 'Barra de São Miguel', code: 2700607 },
    { city: 'Batalha', code: 2700706 },
    { city: 'Belém', code: 2700805 },
    { city: 'Belo Monte', code: 2700904 },
    { city: 'Boca da Mata', code: 2701001 },
    { city: 'Branquinha', code: 2701100 },
    { city: 'Cacimbinhas', code: 2701209 },
    { city: 'Cajueiro', code: 2701308 },
    { city: 'Campestre', code: 2701357 },
    { city: 'Campo Alegre', code: 2701407 },
    { city: 'Campo Grande', code: 2701506 },
    { city: 'Canapi', code: 2701605 },
    { city: 'Capela', code: 2701704 },
    { city: 'Carneiros', code: 2701803 },
    { city: 'Chã Preta', code: 2701902 },
    { city: 'Coité do Nóia', code: 2702009 },
    { city: 'Colônia Leopoldina', code: 2702108 },
    { city: 'Coqueiro Seco', code: 2702207 },
    { city: 'Coruripe', code: 2702306 },
    { city: 'Craíbas', code: 2702355 },
    { city: 'Delmiro Gouveia', code: 2702405 },
    { city: 'Dois Riachos', code: 2702504 },
    { city: 'Estrela de Alagoas', code: 2702553 },
    { city: 'Feira Grande', code: 2702603 },
    { city: 'Feliz Deserto', code: 2702702 },
    { city: 'Flexeiras', code: 2702801 },
    { city: 'Girau do Ponciano', code: 2702900 },
    { city: 'Ibateguara', code: 2703007 },
    { city: 'Igaci', code: 2703106 },
    { city: 'Igreja Nova', code: 2703205 },
    { city: 'Inhapi', code: 2703304 },
    { city: 'Jacaré dos Homens', code: 2703403 },
    { city: 'Jacuípe', code: 2703502 },
    { city: 'Japaratinga', code: 2703601 },
    { city: 'Jaramataia', code: 2703700 },
    { city: 'Jequiá da Praia', code: 2703759 },
    { city: 'Joaquim Gomes', code: 2703809 },
    { city: 'Jundiá', code: 2703908 },
    { city: 'Junqueiro', code: 2704005 },
    { city: 'Lagoa da Canoa', code: 2704104 },
    { city: 'Limoeiro de Anadia', code: 2704203 },
    { city: 'Maceió', code: 2704302 },
    { city: 'Major Isidoro', code: 2704401 },
    { city: 'Mar Vermelho', code: 2704906 },
    { city: 'Maragogi', code: 2704500 },
    { city: 'Maravilha', code: 2704609 },
    { city: 'Marechal Deodoro', code: 2704708 },
    { city: 'Maribondo', code: 2704807 },
    { city: 'Mata Grande', code: 2705002 },
    { city: 'Matriz de Camaragibe', code: 2705101 },
    { city: 'Messias', code: 2705200 },
    { city: 'Minador do Negrão', code: 2705309 },
    { city: 'Monteirópolis', code: 2705408 },
    { city: 'Murici', code: 2705507 },
    { city: 'Novo Lino', code: 2705606 },
    { city: 'Olho d\'Água das Flores', code: 2705705 },
    { city: 'Olho d\'Água do Casado', code: 2705804 },
    { city: 'Olho d\'Água Grande', code: 2705903 },
    { city: 'Olivença', code: 2706000 },
    { city: 'Ouro Branco', code: 2706109 },
    { city: 'Palestina', code: 2706208 },
    { city: 'Palmeira dos Índios', code: 2706307 },
    { city: 'Pão de Açúcar', code: 2706406 },
    { city: 'Pariconha', code: 2706422 },
    { city: 'Paripueira', code: 2706448 },
    { city: 'Passo de Camaragibe', code: 2706505 },
    { city: 'Paulo Jacinto', code: 2706604 },
    { city: 'Penedo', code: 2706703 },
    { city: 'Piaçabuçu', code: 2706802 },
    { city: 'Pilar', code: 2706901 },
    { city: 'Pindoba', code: 2707008 },
    { city: 'Piranhas', code: 2707107 },
    { city: 'Poço das Trincheiras', code: 2707206 },
    { city: 'Porto Calvo', code: 2707305 },
    { city: 'Porto de Pedras', code: 2707404 },
    { city: 'Porto Real do Colégio', code: 2707503 },
    { city: 'Quebrangulo', code: 2707602 },
    { city: 'Rio Largo', code: 2707701 },
    { city: 'Roteiro', code: 2707800 },
    { city: 'Santa Luzia do Norte', code: 2707909 },
    { city: 'Santana do Ipanema', code: 2708006 },
    { city: 'Santana do Mundaú', code: 2708105 },
    { city: 'São Brás', code: 2708204 },
    { city: 'São José da Laje', code: 2708303 },
    { city: 'São José da Tapera', code: 2708402 },
    { city: 'São Luís do Quitunde', code: 2708501 },
    { city: 'São Miguel dos Campos', code: 2708600 },
    { city: 'São Miguel dos Milagres', code: 2708709 },
    { city: 'São Sebastião', code: 2708808 },
    { city: 'Satuba', code: 2708907 },
    { city: 'Senador Rui Palmeira', code: 2708956 },
    { city: 'Tanque d\'Arca', code: 2709004 },
    { city: 'Taquarana', code: 2709103 },
    { city: 'Teotônio Vilela', code: 2709152 },
    { city: 'Traipu', code: 2709202 },
    { city: 'União dos Palmares', code: 2709301 },
    { city: 'Viçosa', code: 2709400 },
  ],
  AM: [
    { city: 'Alvarães', code: 1300029 },
    { city: 'Amaturá', code: 1300060 },
    { city: 'Anamã', code: 1300086 },
    { city: 'Anori', code: 1300102 },
    { city: 'Apuí', code: 1300144 },
    { city: 'Atalaia do Norte', code: 1300201 },
    { city: 'Autazes', code: 1300300 },
    { city: 'Barcelos', code: 1300409 },
    { city: 'Barreirinha', code: 1300508 },
    { city: 'Benjamin Constant', code: 1300607 },
    { city: 'Beruri', code: 1300631 },
    { city: 'Boa Vista do Ramos', code: 1300680 },
    { city: 'Boca do Acre', code: 1300706 },
    { city: 'Borba', code: 1300805 },
    { city: 'Caapiranga', code: 1300839 },
    { city: 'Canutama', code: 1300904 },
    { city: 'Carauari', code: 1301001 },
    { city: 'Careiro', code: 1301100 },
    { city: 'Careiro da Várzea', code: 1301159 },
    { city: 'Coari', code: 1301209 },
    { city: 'Codajás', code: 1301308 },
    { city: 'Eirunepé', code: 1301407 },
    { city: 'Envira', code: 1301506 },
    { city: 'Fonte Boa', code: 1301605 },
    { city: 'Guajará', code: 1301654 },
    { city: 'Humaitá', code: 1301704 },
    { city: 'Ipixuna', code: 1301803 },
    { city: 'Iranduba', code: 1301852 },
    { city: 'Itacoatiara', code: 1301902 },
    { city: 'Itamarati', code: 1301951 },
    { city: 'Itapiranga', code: 1302009 },
    { city: 'Japurá', code: 1302108 },
    { city: 'Juruá', code: 1302207 },
    { city: 'Jutaí', code: 1302306 },
    { city: 'Lábrea', code: 1302405 },
    { city: 'Manacapuru', code: 1302504 },
    { city: 'Manaquiri', code: 1302553 },
    { city: 'Manaus', code: 1302603 },
    { city: 'Manicoré', code: 1302702 },
    { city: 'Maraã', code: 1302801 },
    { city: 'Maués', code: 1302900 },
    { city: 'Nhamundá', code: 1303007 },
    { city: 'Nova Olinda do Norte', code: 1303106 },
    { city: 'Novo Airão', code: 1303205 },
    { city: 'Novo Aripuanã', code: 1303304 },
    { city: 'Parintins', code: 1303403 },
    { city: 'Pauini', code: 1303502 },
    { city: 'Presidente Figueiredo', code: 1303536 },
    { city: 'Rio Preto da Eva', code: 1303569 },
    { city: 'Santa Isabel do Rio Negro', code: 1303601 },
    { city: 'Santo Antônio do Içá', code: 1303700 },
    { city: 'São Gabriel da Cachoeira', code: 1303809 },
    { city: 'São Paulo de Olivença', code: 1303908 },
    { city: 'São Sebastião do Uatumã', code: 1303957 },
    { city: 'Silves', code: 1304005 },
    { city: 'Tabatinga', code: 1304062 },
    { city: 'Tapauá', code: 1304104 },
    { city: 'Tefé', code: 1304203 },
    { city: 'Tonantins', code: 1304237 },
    { city: 'Uarini', code: 1304260 },
    { city: 'Urucará', code: 1304302 },
    { city: 'Urucurituba', code: 1304401 },
  ],
  AP: [
    { city: 'Amapá', code: 1600105 },
    { city: 'Calçoene', code: 1600204 },
    { city: 'Cutias', code: 1600212 },
    { city: 'Ferreira Gomes', code: 1600238 },
    { city: 'Itaubal', code: 1600253 },
    { city: 'Laranjal do Jari', code: 1600279 },
    { city: 'Macapá', code: 1600303 },
    { city: 'Mazagão', code: 1600402 },
    { city: 'Oiapoque', code: 1600501 },
    { city: 'Pedra Branca do Amapari', code: 1600154 },
    { city: 'Porto Grande', code: 1600535 },
    { city: 'Pracuúba', code: 1600550 },
    { city: 'Santana', code: 1600600 },
    { city: 'Serra do Navio', code: 1600055 },
    { city: 'Tartarugalzinho', code: 1600709 },
    { city: 'Vitória do Jari', code: 1600808 },
  ],
  BA: [
    { city: 'Abaíra', code: 2900108 },
    { city: 'Abaré', code: 2900207 },
    { city: 'Acajutiba', code: 2900306 },
    { city: 'Adustina', code: 2900355 },
    { city: 'Água Fria', code: 2900405 },
    { city: 'Aiquara', code: 2900603 },
    { city: 'Alagoinhas', code: 2900702 },
    { city: 'Alcobaça', code: 2900801 },
    { city: 'Almadina', code: 2900900 },
    { city: 'Amargosa', code: 2901007 },
    { city: 'Amélia Rodrigues', code: 2901106 },
    { city: 'América Dourada', code: 2901155 },
    { city: 'Anagé', code: 2901205 },
    { city: 'Andaraí', code: 2901304 },
    { city: 'Andorinha', code: 2901353 },
    { city: 'Angical', code: 2901403 },
    { city: 'Anguera', code: 2901502 },
    { city: 'Antas', code: 2901601 },
    { city: 'Antônio Cardoso', code: 2901700 },
    { city: 'Antônio Gonçalves', code: 2901809 },
    { city: 'Aporá', code: 2901908 },
    { city: 'Apuarema', code: 2901957 },
    { city: 'Araçás', code: 2902054 },
    { city: 'Aracatu', code: 2902005 },
    { city: 'Araci', code: 2902104 },
    { city: 'Aramari', code: 2902203 },
    { city: 'Arataca', code: 2902252 },
    { city: 'Aratuípe', code: 2902302 },
    { city: 'Aurelino Leal', code: 2902401 },
    { city: 'Baianópolis', code: 2902500 },
    { city: 'Baixa Grande', code: 2902609 },
    { city: 'Banzaê', code: 2902658 },
    { city: 'Barra', code: 2902708 },
    { city: 'Barra da Estiva', code: 2902807 },
    { city: 'Barra do Choça', code: 2902906 },
    { city: 'Barra do Mendes', code: 2903003 },
    { city: 'Barra do Rocha', code: 2903102 },
    { city: 'Barreiras', code: 2903201 },
    { city: 'Barro Alto', code: 2903235 },
    { city: 'Barro Preto', code: 2903300 },
    { city: 'Barrocas', code: 2903276 },
    { city: 'Belmonte', code: 2903409 },
    { city: 'Belo Campo', code: 2903508 },
    { city: 'Biritinga', code: 2903607 },
    { city: 'Boa Nova', code: 2903706 },
    { city: 'Boa Vista do Tupim', code: 2903805 },
    { city: 'Bom Jesus da Lapa', code: 2903904 },
    { city: 'Bom Jesus da Serra', code: 2903953 },
    { city: 'Boninal', code: 2904001 },
    { city: 'Bonito', code: 2904050 },
    { city: 'Boquira', code: 2904100 },
    { city: 'Botuporã', code: 2904209 },
    { city: 'Brejões', code: 2904308 },
    { city: 'Brejolândia', code: 2904407 },
    { city: 'Brotas de Macaúbas', code: 2904506 },
    { city: 'Brumado', code: 2904605 },
    { city: 'Buerarema', code: 2904704 },
    { city: 'Buritirama', code: 2904753 },
    { city: 'Caatiba', code: 2904803 },
    { city: 'Cabaceiras do Paraguaçu', code: 2904852 },
    { city: 'Cachoeira', code: 2904902 },
    { city: 'Caculé', code: 2905008 },
    { city: 'Caém', code: 2905107 },
    { city: 'Caetanos', code: 2905156 },
    { city: 'Caetité', code: 2905206 },
    { city: 'Cafarnaum', code: 2905305 },
    { city: 'Cairu', code: 2905404 },
    { city: 'Caldeirão Grande', code: 2905503 },
    { city: 'Camacan', code: 2905602 },
    { city: 'Camaçari', code: 2905701 },
    { city: 'Camamu', code: 2905800 },
    { city: 'Campo Alegre de Lourdes', code: 2905909 },
    { city: 'Campo Formoso', code: 2906006 },
    { city: 'Canápolis', code: 2906105 },
    { city: 'Canarana', code: 2906204 },
    { city: 'Canavieiras', code: 2906303 },
    { city: 'Candeal', code: 2906402 },
    { city: 'Candeias', code: 2906501 },
    { city: 'Candiba', code: 2906600 },
    { city: 'Cândido Sales', code: 2906709 },
    { city: 'Cansanção', code: 2906808 },
    { city: 'Canudos', code: 2906824 },
    { city: 'Capela do Alto Alegre', code: 2906857 },
    { city: 'Capim Grosso', code: 2906873 },
    { city: 'Caraíbas', code: 2906899 },
    { city: 'Caravelas', code: 2906907 },
    { city: 'Cardeal da Silva', code: 2907004 },
    { city: 'Carinhanha', code: 2907103 },
    { city: 'Casa Nova', code: 2907202 },
    { city: 'Castro Alves', code: 2907301 },
    { city: 'Catolândia', code: 2907400 },
    { city: 'Catu', code: 2907509 },
    { city: 'Caturama', code: 2907558 },
    { city: 'Central', code: 2907608 },
    { city: 'Chorrochó', code: 2907707 },
    { city: 'Cícero Dantas', code: 2907806 },
    { city: 'Cipó', code: 2907905 },
    { city: 'Coaraci', code: 2908002 },
    { city: 'Cocos', code: 2908101 },
    { city: 'Conceição da Feira', code: 2908200 },
    { city: 'Conceição do Almeida', code: 2908309 },
    { city: 'Conceição do Coité', code: 2908408 },
    { city: 'Conceição do Jacuípe', code: 2908507 },
    { city: 'Conde', code: 2908606 },
    { city: 'Condeúba', code: 2908705 },
    { city: 'Contendas do Sincorá', code: 2908804 },
    { city: 'Coração de Maria', code: 2908903 },
    { city: 'Cordeiros', code: 2909000 },
    { city: 'Coribe', code: 2909109 },
    { city: 'Coronel João Sá', code: 2909208 },
    { city: 'Correntina', code: 2909307 },
    { city: 'Cotegipe', code: 2909406 },
    { city: 'Cravolândia', code: 2909505 },
    { city: 'Crisópolis', code: 2909604 },
    { city: 'Cristópolis', code: 2909703 },
    { city: 'Cruz das Almas', code: 2909802 },
    { city: 'Curaçá', code: 2909901 },
    { city: 'Dário Meira', code: 2910008 },
    { city: 'Dias d\'Ávila', code: 2910057 },
    { city: 'Dom Basílio', code: 2910107 },
    { city: 'Dom Macedo Costa', code: 2910206 },
    { city: 'Elísio Medrado', code: 2910305 },
    { city: 'Encruzilhada', code: 2910404 },
    { city: 'Entre Rios', code: 2910503 },
    { city: 'Érico Cardoso', code: 2900504 },
    { city: 'Esplanada', code: 2910602 },
    { city: 'Euclides da Cunha', code: 2910701 },
    { city: 'Eunápolis', code: 2910727 },
    { city: 'Fátima', code: 2910750 },
    { city: 'Feira da Mata', code: 2910776 },
    { city: 'Feira de Santana', code: 2910800 },
    { city: 'Filadélfia', code: 2910859 },
    { city: 'Firmino Alves', code: 2910909 },
    { city: 'Floresta Azul', code: 2911006 },
    { city: 'Formosa do Rio Preto', code: 2911105 },
    { city: 'Gandu', code: 2911204 },
    { city: 'Gavião', code: 2911253 },
    { city: 'Gentio do Ouro', code: 2911303 },
    { city: 'Glória', code: 2911402 },
    { city: 'Gongogi', code: 2911501 },
    { city: 'Governador Mangabeira', code: 2911600 },
    { city: 'Guajeru', code: 2911659 },
    { city: 'Guanambi', code: 2911709 },
    { city: 'Guaratinga', code: 2911808 },
    { city: 'Heliópolis', code: 2911857 },
    { city: 'Iaçu', code: 2911907 },
    { city: 'Ibiassucê', code: 2912004 },
    { city: 'Ibicaraí', code: 2912103 },
    { city: 'Ibicoara', code: 2912202 },
    { city: 'Ibicuí', code: 2912301 },
    { city: 'Ibipeba', code: 2912400 },
    { city: 'Ibipitanga', code: 2912509 },
    { city: 'Ibiquera', code: 2912608 },
    { city: 'Ibirapitanga', code: 2912707 },
    { city: 'Ibirapuã', code: 2912806 },
    { city: 'Ibirataia', code: 2912905 },
    { city: 'Ibitiara', code: 2913002 },
    { city: 'Ibititá', code: 2913101 },
    { city: 'Ibotirama', code: 2913200 },
    { city: 'Ichu', code: 2913309 },
    { city: 'Igaporã', code: 2913408 },
    { city: 'Igrapiúna', code: 2913457 },
    { city: 'Iguaí', code: 2913507 },
    { city: 'Ilhéus', code: 2913606 },
    { city: 'Inhambupe', code: 2913705 },
    { city: 'Ipecaetá', code: 2913804 },
    { city: 'Ipiaú', code: 2913903 },
    { city: 'Ipirá', code: 2914000 },
    { city: 'Ipupiara', code: 2914109 },
    { city: 'Irajuba', code: 2914208 },
    { city: 'Iramaia', code: 2914307 },
    { city: 'Iraquara', code: 2914406 },
    { city: 'Irará', code: 2914505 },
    { city: 'Irecê', code: 2914604 },
    { city: 'Itabela', code: 2914653 },
    { city: 'Itaberaba', code: 2914703 },
    { city: 'Itabuna', code: 2914802 },
    { city: 'Itacaré', code: 2914901 },
    { city: 'Itaeté', code: 2915007 },
    { city: 'Itagi', code: 2915106 },
    { city: 'Itagibá', code: 2915205 },
    { city: 'Itagimirim', code: 2915304 },
    { city: 'Itaguaçu da Bahia', code: 2915353 },
    { city: 'Itaju do Colônia', code: 2915403 },
    { city: 'Itajuípe', code: 2915502 },
    { city: 'Itamaraju', code: 2915601 },
    { city: 'Itamari', code: 2915700 },
    { city: 'Itambé', code: 2915809 },
    { city: 'Itanagra', code: 2915908 },
    { city: 'Itanhém', code: 2916005 },
    { city: 'Itaparica', code: 2916104 },
    { city: 'Itapé', code: 2916203 },
    { city: 'Itapebi', code: 2916302 },
    { city: 'Itapetinga', code: 2916401 },
    { city: 'Itapicuru', code: 2916500 },
    { city: 'Itapitanga', code: 2916609 },
    { city: 'Itaquara', code: 2916708 },
    { city: 'Itarantim', code: 2916807 },
    { city: 'Itatim', code: 2916856 },
    { city: 'Itiruçu', code: 2916906 },
    { city: 'Itiúba', code: 2917003 },
    { city: 'Itororó', code: 2917102 },
    { city: 'Ituaçu', code: 2917201 },
    { city: 'Ituberá', code: 2917300 },
    { city: 'Iuiu', code: 2917334 },
    { city: 'Jaborandi', code: 2917359 },
    { city: 'Jacaraci', code: 2917409 },
    { city: 'Jacobina', code: 2917508 },
    { city: 'Jaguaquara', code: 2917607 },
    { city: 'Jaguarari', code: 2917706 },
    { city: 'Jaguaripe', code: 2917805 },
    { city: 'Jandaíra', code: 2917904 },
    { city: 'Jequié', code: 2918001 },
    { city: 'Jeremoabo', code: 2918100 },
    { city: 'Jiquiriçá', code: 2918209 },
    { city: 'Jitaúna', code: 2918308 },
    { city: 'João Dourado', code: 2918357 },
    { city: 'Juazeiro', code: 2918407 },
    { city: 'Jucuruçu', code: 2918456 },
    { city: 'Jussara', code: 2918506 },
    { city: 'Jussari', code: 2918555 },
    { city: 'Jussiape', code: 2918605 },
    { city: 'Lafaiete Coutinho', code: 2918704 },
    { city: 'Lagoa Real', code: 2918753 },
    { city: 'Laje', code: 2918803 },
    { city: 'Lajedão', code: 2918902 },
    { city: 'Lajedinho', code: 2919009 },
    { city: 'Lajedo do Tabocal', code: 2919058 },
    { city: 'Lamarão', code: 2919108 },
    { city: 'Lapão', code: 2919157 },
    { city: 'Lauro de Freitas', code: 2919207 },
    { city: 'Lençóis', code: 2919306 },
    { city: 'Licínio de Almeida', code: 2919405 },
    { city: 'Livramento de Nossa Senhora', code: 2919504 },
    { city: 'Luís Eduardo Magalhães', code: 2919553 },
    { city: 'Macajuba', code: 2919603 },
    { city: 'Macarani', code: 2919702 },
    { city: 'Macaúbas', code: 2919801 },
    { city: 'Macururé', code: 2919900 },
    { city: 'Madre de Deus', code: 2919926 },
    { city: 'Maetinga', code: 2919959 },
    { city: 'Maiquinique', code: 2920007 },
    { city: 'Mairi', code: 2920106 },
    { city: 'Malhada', code: 2920205 },
    { city: 'Malhada de Pedras', code: 2920304 },
    { city: 'Manoel Vitorino', code: 2920403 },
    { city: 'Mansidão', code: 2920452 },
    { city: 'Maracás', code: 2920502 },
    { city: 'Maragogipe', code: 2920601 },
    { city: 'Maraú', code: 2920700 },
    { city: 'Marcionílio Souza', code: 2920809 },
    { city: 'Mascote', code: 2920908 },
    { city: 'Mata de São João', code: 2921005 },
    { city: 'Matina', code: 2921054 },
    { city: 'Medeiros Neto', code: 2921104 },
    { city: 'Miguel Calmon', code: 2921203 },
    { city: 'Milagres', code: 2921302 },
    { city: 'Mirangaba', code: 2921401 },
    { city: 'Mirante', code: 2921450 },
    { city: 'Monte Santo', code: 2921500 },
    { city: 'Morpará', code: 2921609 },
    { city: 'Morro do Chapéu', code: 2921708 },
    { city: 'Mortugaba', code: 2921807 },
    { city: 'Mucugê', code: 2921906 },
    { city: 'Mucuri', code: 2922003 },
    { city: 'Mulungu do Morro', code: 2922052 },
    { city: 'Mundo Novo', code: 2922102 },
    { city: 'Muniz Ferreira', code: 2922201 },
    { city: 'Muquém do São Francisco', code: 2922250 },
    { city: 'Muritiba', code: 2922300 },
    { city: 'Mutuípe', code: 2922409 },
    { city: 'Nazaré', code: 2922508 },
    { city: 'Nilo Peçanha', code: 2922607 },
    { city: 'Nordestina', code: 2922656 },
    { city: 'Nova Canaã', code: 2922706 },
    { city: 'Nova Fátima', code: 2922730 },
    { city: 'Nova Ibiá', code: 2922755 },
    { city: 'Nova Itarana', code: 2922805 },
    { city: 'Nova Redenção', code: 2922854 },
    { city: 'Nova Soure', code: 2922904 },
    { city: 'Nova Viçosa', code: 2923001 },
    { city: 'Novo Horizonte', code: 2923035 },
    { city: 'Novo Triunfo', code: 2923050 },
    { city: 'Olindina', code: 2923100 },
    { city: 'Oliveira dos Brejinhos', code: 2923209 },
    { city: 'Ouriçangas', code: 2923308 },
    { city: 'Ourolândia', code: 2923357 },
    { city: 'Palmas de Monte Alto', code: 2923407 },
    { city: 'Palmeiras', code: 2923506 },
    { city: 'Paramirim', code: 2923605 },
    { city: 'Paratinga', code: 2923704 },
    { city: 'Paripiranga', code: 2923803 },
    { city: 'Pau Brasil', code: 2923902 },
    { city: 'Paulo Afonso', code: 2924009 },
    { city: 'Pé de Serra', code: 2924058 },
    { city: 'Pedrão', code: 2924108 },
    { city: 'Pedro Alexandre', code: 2924207 },
    { city: 'Piatã', code: 2924306 },
    { city: 'Pilão Arcado', code: 2924405 },
    { city: 'Pindaí', code: 2924504 },
    { city: 'Pindobaçu', code: 2924603 },
    { city: 'Pintadas', code: 2924652 },
    { city: 'Piraí do Norte', code: 2924678 },
    { city: 'Piripá', code: 2924702 },
    { city: 'Piritiba', code: 2924801 },
    { city: 'Planaltino', code: 2924900 },
    { city: 'Planalto', code: 2925006 },
    { city: 'Poções', code: 2925105 },
    { city: 'Pojuca', code: 2925204 },
    { city: 'Ponto Novo', code: 2925253 },
    { city: 'Porto Seguro', code: 2925303 },
    { city: 'Potiraguá', code: 2925402 },
    { city: 'Prado', code: 2925501 },
    { city: 'Presidente Dutra', code: 2925600 },
    { city: 'Presidente Jânio Quadros', code: 2925709 },
    { city: 'Presidente Tancredo Neves', code: 2925758 },
    { city: 'Queimadas', code: 2925808 },
    { city: 'Quijingue', code: 2925907 },
    { city: 'Quixabeira', code: 2925931 },
    { city: 'Rafael Jambeiro', code: 2925956 },
    { city: 'Remanso', code: 2926004 },
    { city: 'Retirolândia', code: 2926103 },
    { city: 'Riachão das Neves', code: 2926202 },
    { city: 'Riachão do Jacuípe', code: 2926301 },
    { city: 'Riacho de Santana', code: 2926400 },
    { city: 'Ribeira do Amparo', code: 2926509 },
    { city: 'Ribeira do Pombal', code: 2926608 },
    { city: 'Ribeirão do Largo', code: 2926657 },
    { city: 'Rio de Contas', code: 2926707 },
    { city: 'Rio do Antônio', code: 2926806 },
    { city: 'Rio do Pires', code: 2926905 },
    { city: 'Rio Real', code: 2927002 },
    { city: 'Rodelas', code: 2927101 },
    { city: 'Ruy Barbosa', code: 2927200 },
    { city: 'Salinas da Margarida', code: 2927309 },
    { city: 'Salvador', code: 2927408 },
    { city: 'Santa Bárbara', code: 2927507 },
    { city: 'Santa Brígida', code: 2927606 },
    { city: 'Santa Cruz Cabrália', code: 2927705 },
    { city: 'Santa Cruz da Vitória', code: 2927804 },
    { city: 'Santa Inês', code: 2927903 },
    { city: 'Santa Luzia', code: 2928059 },
    { city: 'Santa Maria da Vitória', code: 2928109 },
    { city: 'Santa Rita de Cássia', code: 2928406 },
    { city: 'Santa Terezinha', code: 2928505 },
    { city: 'Santaluz', code: 2928000 },
    { city: 'Santana', code: 2928208 },
    { city: 'Santanópolis', code: 2928307 },
    { city: 'Santo Amaro', code: 2928604 },
    { city: 'Santo Antônio de Jesus', code: 2928703 },
    { city: 'Santo Estêvão', code: 2928802 },
    { city: 'São Desidério', code: 2928901 },
    { city: 'São Domingos', code: 2928950 },
    { city: 'São Felipe', code: 2929107 },
    { city: 'São Félix', code: 2929008 },
    { city: 'São Félix do Coribe', code: 2929057 },
    { city: 'São Francisco do Conde', code: 2929206 },
    { city: 'São Gabriel', code: 2929255 },
    { city: 'São Gonçalo dos Campos', code: 2929305 },
    { city: 'São José da Vitória', code: 2929354 },
    { city: 'São José do Jacuípe', code: 2929370 },
    { city: 'São Miguel das Matas', code: 2929404 },
    { city: 'São Sebastião do Passé', code: 2929503 },
    { city: 'Sapeaçu', code: 2929602 },
    { city: 'Sátiro Dias', code: 2929701 },
    { city: 'Saubara', code: 2929750 },
    { city: 'Saúde', code: 2929800 },
    { city: 'Seabra', code: 2929909 },
    { city: 'Sebastião Laranjeiras', code: 2930006 },
    { city: 'Senhor do Bonfim', code: 2930105 },
    { city: 'Sento Sé', code: 2930204 },
    { city: 'Serra do Ramalho', code: 2930154 },
    { city: 'Serra Dourada', code: 2930303 },
    { city: 'Serra Preta', code: 2930402 },
    { city: 'Serrinha', code: 2930501 },
    { city: 'Serrolândia', code: 2930600 },
    { city: 'Simões Filho', code: 2930709 },
    { city: 'Sítio do Mato', code: 2930758 },
    { city: 'Sítio do Quinto', code: 2930766 },
    { city: 'Sobradinho', code: 2930774 },
    { city: 'Souto Soares', code: 2930808 },
    { city: 'Tabocas do Brejo Velho', code: 2930907 },
    { city: 'Tanhaçu', code: 2931004 },
    { city: 'Tanque Novo', code: 2931053 },
    { city: 'Tanquinho', code: 2931103 },
    { city: 'Taperoá', code: 2931202 },
    { city: 'Tapiramutá', code: 2931301 },
    { city: 'Teixeira de Freitas', code: 2931350 },
    { city: 'Teodoro Sampaio', code: 2931400 },
    { city: 'Teofilândia', code: 2931509 },
    { city: 'Teolândia', code: 2931608 },
    { city: 'Terra Nova', code: 2931707 },
    { city: 'Tremedal', code: 2931806 },
    { city: 'Tucano', code: 2931905 },
    { city: 'Uauá', code: 2932002 },
    { city: 'Ubaíra', code: 2932101 },
    { city: 'Ubaitaba', code: 2932200 },
    { city: 'Ubatã', code: 2932309 },
    { city: 'Uibaí', code: 2932408 },
    { city: 'Umburanas', code: 2932457 },
    { city: 'Una', code: 2932507 },
    { city: 'Urandi', code: 2932606 },
    { city: 'Uruçuca', code: 2932705 },
    { city: 'Utinga', code: 2932804 },
    { city: 'Valença', code: 2932903 },
    { city: 'Valente', code: 2933000 },
    { city: 'Várzea da Roça', code: 2933059 },
    { city: 'Várzea do Poço', code: 2933109 },
    { city: 'Várzea Nova', code: 2933158 },
    { city: 'Varzedo', code: 2933174 },
    { city: 'Vera Cruz', code: 2933208 },
    { city: 'Vereda', code: 2933257 },
    { city: 'Vitória da Conquista', code: 2933307 },
    { city: 'Wagner', code: 2933406 },
    { city: 'Wanderley', code: 2933455 },
    { city: 'Wenceslau Guimarães', code: 2933505 },
    { city: 'Xique-Xique', code: 2933604 },
  ],
  CE: [
    { city: 'Abaiara', code: 2300101 },
    { city: 'Acarape', code: 2300150 },
    { city: 'Acaraú', code: 2300200 },
    { city: 'Acopiara', code: 2300309 },
    { city: 'Aiuaba', code: 2300408 },
    { city: 'Alcântaras', code: 2300507 },
    { city: 'Altaneira', code: 2300606 },
    { city: 'Alto Santo', code: 2300705 },
    { city: 'Amontada', code: 2300754 },
    { city: 'Antonina do Norte', code: 2300804 },
    { city: 'Apuiarés', code: 2300903 },
    { city: 'Aquiraz', code: 2301000 },
    { city: 'Aracati', code: 2301109 },
    { city: 'Aracoiaba', code: 2301208 },
    { city: 'Ararendá', code: 2301257 },
    { city: 'Araripe', code: 2301307 },
    { city: 'Aratuba', code: 2301406 },
    { city: 'Arneiroz', code: 2301505 },
    { city: 'Assaré', code: 2301604 },
    { city: 'Aurora', code: 2301703 },
    { city: 'Baixio', code: 2301802 },
    { city: 'Banabuiú', code: 2301851 },
    { city: 'Barbalha', code: 2301901 },
    { city: 'Barreira', code: 2301950 },
    { city: 'Barro', code: 2302008 },
    { city: 'Barroquinha', code: 2302057 },
    { city: 'Baturité', code: 2302107 },
    { city: 'Beberibe', code: 2302206 },
    { city: 'Bela Cruz', code: 2302305 },
    { city: 'Boa Viagem', code: 2302404 },
    { city: 'Brejo Santo', code: 2302503 },
    { city: 'Camocim', code: 2302602 },
    { city: 'Campos Sales', code: 2302701 },
    { city: 'Canindé', code: 2302800 },
    { city: 'Capistrano', code: 2302909 },
    { city: 'Caridade', code: 2303006 },
    { city: 'Cariré', code: 2303105 },
    { city: 'Caririaçu', code: 2303204 },
    { city: 'Cariús', code: 2303303 },
    { city: 'Carnaubal', code: 2303402 },
    { city: 'Cascavel', code: 2303501 },
    { city: 'Catarina', code: 2303600 },
    { city: 'Catunda', code: 2303659 },
    { city: 'Caucaia', code: 2303709 },
    { city: 'Cedro', code: 2303808 },
    { city: 'Chaval', code: 2303907 },
    { city: 'Choró', code: 2303931 },
    { city: 'Chorozinho', code: 2303956 },
    { city: 'Coreaú', code: 2304004 },
    { city: 'Crateús', code: 2304103 },
    { city: 'Crato', code: 2304202 },
    { city: 'Croatá', code: 2304236 },
    { city: 'Cruz', code: 2304251 },
    { city: 'Deputado Irapuan Pinheiro', code: 2304269 },
    { city: 'Ererê', code: 2304277 },
    { city: 'Eusébio', code: 2304285 },
    { city: 'Farias Brito', code: 2304301 },
    { city: 'Forquilha', code: 2304350 },
    { city: 'Fortaleza', code: 2304400 },
    { city: 'Fortim', code: 2304459 },
    { city: 'Frecheirinha', code: 2304509 },
    { city: 'General Sampaio', code: 2304608 },
    { city: 'Graça', code: 2304657 },
    { city: 'Granja', code: 2304707 },
    { city: 'Granjeiro', code: 2304806 },
    { city: 'Groaíras', code: 2304905 },
    { city: 'Guaiúba', code: 2304954 },
    { city: 'Guaraciaba do Norte', code: 2305001 },
    { city: 'Guaramiranga', code: 2305100 },
    { city: 'Hidrolândia', code: 2305209 },
    { city: 'Horizonte', code: 2305233 },
    { city: 'Ibaretama', code: 2305266 },
    { city: 'Ibiapina', code: 2305308 },
    { city: 'Ibicuitinga', code: 2305332 },
    { city: 'Icapuí', code: 2305357 },
    { city: 'Icó', code: 2305407 },
    { city: 'Iguatu', code: 2305506 },
    { city: 'Independência', code: 2305605 },
    { city: 'Ipaporanga', code: 2305654 },
    { city: 'Ipaumirim', code: 2305704 },
    { city: 'Ipu', code: 2305803 },
    { city: 'Ipueiras', code: 2305902 },
    { city: 'Iracema', code: 2306009 },
    { city: 'Irauçuba', code: 2306108 },
    { city: 'Itaiçaba', code: 2306207 },
    { city: 'Itaitinga', code: 2306256 },
    { city: 'Itapajé', code: 2306306 },
    { city: 'Itapipoca', code: 2306405 },
    { city: 'Itapiúna', code: 2306504 },
    { city: 'Itarema', code: 2306553 },
    { city: 'Itatira', code: 2306603 },
    { city: 'Jaguaretama', code: 2306702 },
    { city: 'Jaguaribara', code: 2306801 },
    { city: 'Jaguaribe', code: 2306900 },
    { city: 'Jaguaruana', code: 2307007 },
    { city: 'Jardim', code: 2307106 },
    { city: 'Jati', code: 2307205 },
    { city: 'Jijoca de Jericoacoara', code: 2307254 },
    { city: 'Juazeiro do Norte', code: 2307304 },
    { city: 'Jucás', code: 2307403 },
    { city: 'Lavras da Mangabeira', code: 2307502 },
    { city: 'Limoeiro do Norte', code: 2307601 },
    { city: 'Madalena', code: 2307635 },
    { city: 'Maracanaú', code: 2307650 },
    { city: 'Maranguape', code: 2307700 },
    { city: 'Marco', code: 2307809 },
    { city: 'Martinópole', code: 2307908 },
    { city: 'Massapê', code: 2308005 },
    { city: 'Mauriti', code: 2308104 },
    { city: 'Meruoca', code: 2308203 },
    { city: 'Milagres', code: 2308302 },
    { city: 'Milhã', code: 2308351 },
    { city: 'Miraíma', code: 2308377 },
    { city: 'Missão Velha', code: 2308401 },
    { city: 'Mombaça', code: 2308500 },
    { city: 'Monsenhor Tabosa', code: 2308609 },
    { city: 'Morada Nova', code: 2308708 },
    { city: 'Moraújo', code: 2308807 },
    { city: 'Morrinhos', code: 2308906 },
    { city: 'Mucambo', code: 2309003 },
    { city: 'Mulungu', code: 2309102 },
    { city: 'Nova Olinda', code: 2309201 },
    { city: 'Nova Russas', code: 2309300 },
    { city: 'Novo Oriente', code: 2309409 },
    { city: 'Ocara', code: 2309458 },
    { city: 'Orós', code: 2309508 },
    { city: 'Pacajus', code: 2309607 },
    { city: 'Pacatuba', code: 2309706 },
    { city: 'Pacoti', code: 2309805 },
    { city: 'Pacujá', code: 2309904 },
    { city: 'Palhano', code: 2310001 },
    { city: 'Palmácia', code: 2310100 },
    { city: 'Paracuru', code: 2310209 },
    { city: 'Paraipaba', code: 2310258 },
    { city: 'Parambu', code: 2310308 },
    { city: 'Paramoti', code: 2310407 },
    { city: 'Pedra Branca', code: 2310506 },
    { city: 'Penaforte', code: 2310605 },
    { city: 'Pentecoste', code: 2310704 },
    { city: 'Pereiro', code: 2310803 },
    { city: 'Pindoretama', code: 2310852 },
    { city: 'Piquet Carneiro', code: 2310902 },
    { city: 'Pires Ferreira', code: 2310951 },
    { city: 'Poranga', code: 2311009 },
    { city: 'Porteiras', code: 2311108 },
    { city: 'Potengi', code: 2311207 },
    { city: 'Potiretama', code: 2311231 },
    { city: 'Quiterianópolis', code: 2311264 },
    { city: 'Quixadá', code: 2311306 },
    { city: 'Quixelô', code: 2311355 },
    { city: 'Quixeramobim', code: 2311405 },
    { city: 'Quixeré', code: 2311504 },
    { city: 'Redenção', code: 2311603 },
    { city: 'Reriutaba', code: 2311702 },
    { city: 'Russas', code: 2311801 },
    { city: 'Saboeiro', code: 2311900 },
    { city: 'Salitre', code: 2311959 },
    { city: 'Santa Quitéria', code: 2312205 },
    { city: 'Santana do Acaraú', code: 2312007 },
    { city: 'Santana do Cariri', code: 2312106 },
    { city: 'São Benedito', code: 2312304 },
    { city: 'São Gonçalo do Amarante', code: 2312403 },
    { city: 'São João do Jaguaribe', code: 2312502 },
    { city: 'São Luís do Curu', code: 2312601 },
    { city: 'Senador Pompeu', code: 2312700 },
    { city: 'Senador Sá', code: 2312809 },
    { city: 'Sobral', code: 2312908 },
    { city: 'Solonópole', code: 2313005 },
    { city: 'Tabuleiro do Norte', code: 2313104 },
    { city: 'Tamboril', code: 2313203 },
    { city: 'Tarrafas', code: 2313252 },
    { city: 'Tauá', code: 2313302 },
    { city: 'Tejuçuoca', code: 2313351 },
    { city: 'Tianguá', code: 2313401 },
    { city: 'Trairi', code: 2313500 },
    { city: 'Tururu', code: 2313559 },
    { city: 'Ubajara', code: 2313609 },
    { city: 'Umari', code: 2313708 },
    { city: 'Umirim', code: 2313757 },
    { city: 'Uruburetama', code: 2313807 },
    { city: 'Uruoca', code: 2313906 },
    { city: 'Varjota', code: 2313955 },
    { city: 'Várzea Alegre', code: 2314003 },
    { city: 'Viçosa do Ceará', code: 2314102 },
  ],
  DF: [
    { city: 'Brasília', code: 5300108 }
  ],
  ES: [
    { city: 'Afonso Cláudio', code: 3200102 },
    { city: 'Água Doce do Norte', code: 3200169 },
    { city: 'Águia Branca', code: 3200136 },
    { city: 'Alegre', code: 3200201 },
    { city: 'Alfredo Chaves', code: 3200300 },
    { city: 'Alto Rio Novo', code: 3200359 },
    { city: 'Anchieta', code: 3200409 },
    { city: 'Apiacá', code: 3200508 },
    { city: 'Aracruz', code: 3200607 },
    { city: 'Atílio Vivacqua', code: 3200706 },
    { city: 'Baixo Guandu', code: 3200805 },
    { city: 'Barra de São Francisco', code: 3200904 },
    { city: 'Boa Esperança', code: 3201001 },
    { city: 'Bom Jesus do Norte', code: 3201100 },
    { city: 'Brejetuba', code: 3201159 },
    { city: 'Cachoeiro de Itapemirim', code: 3201209 },
    { city: 'Cariacica', code: 3201308 },
    { city: 'Castelo', code: 3201407 },
    { city: 'Colatina', code: 3201506 },
    { city: 'Conceição da Barra', code: 3201605 },
    { city: 'Conceição do Castelo', code: 3201704 },
    { city: 'Divino de São Lourenço', code: 3201803 },
    { city: 'Domingos Martins', code: 3201902 },
    { city: 'Dores do Rio Preto', code: 3202009 },
    { city: 'Ecoporanga', code: 3202108 },
    { city: 'Fundão', code: 3202207 },
    { city: 'Governador Lindenberg', code: 3202256 },
    { city: 'Guaçuí', code: 3202306 },
    { city: 'Guarapari', code: 3202405 },
    { city: 'Ibatiba', code: 3202454 },
    { city: 'Ibiraçu', code: 3202504 },
    { city: 'Ibitirama', code: 3202553 },
    { city: 'Iconha', code: 3202603 },
    { city: 'Irupi', code: 3202652 },
    { city: 'Itaguaçu', code: 3202702 },
    { city: 'Itapemirim', code: 3202801 },
    { city: 'Itarana', code: 3202900 },
    { city: 'Iúna', code: 3203007 },
    { city: 'Jaguaré', code: 3203056 },
    { city: 'Jerônimo Monteiro', code: 3203106 },
    { city: 'João Neiva', code: 3203130 },
    { city: 'Laranja da Terra', code: 3203163 },
    { city: 'Linhares', code: 3203205 },
    { city: 'Mantenópolis', code: 3203304 },
    { city: 'Marataízes', code: 3203320 },
    { city: 'Marechal Floriano', code: 3203346 },
    { city: 'Marilândia', code: 3203353 },
    { city: 'Mimoso do Sul', code: 3203403 },
    { city: 'Montanha', code: 3203502 },
    { city: 'Mucurici', code: 3203601 },
    { city: 'Muniz Freire', code: 3203700 },
    { city: 'Muqui', code: 3203809 },
    { city: 'Nova Venécia', code: 3203908 },
    { city: 'Pancas', code: 3204005 },
    { city: 'Pedro Canário', code: 3204054 },
    { city: 'Pinheiros', code: 3204104 },
    { city: 'Piúma', code: 3204203 },
    { city: 'Ponto Belo', code: 3204252 },
    { city: 'Presidente Kennedy', code: 3204302 },
    { city: 'Rio Bananal', code: 3204351 },
    { city: 'Rio Novo do Sul', code: 3204401 },
    { city: 'Santa Leopoldina', code: 3204500 },
    { city: 'Santa Maria de Jetibá', code: 3204559 },
    { city: 'Santa Teresa', code: 3204609 },
    { city: 'São Domingos do Norte', code: 3204658 },
    { city: 'São Gabriel da Palha', code: 3204708 },
    { city: 'São José do Calçado', code: 3204807 },
    { city: 'São Mateus', code: 3204906 },
    { city: 'São Roque do Canaã', code: 3204955 },
    { city: 'Serra', code: 3205002 },
    { city: 'Sooretama', code: 3205010 },
    { city: 'Vargem Alta', code: 3205036 },
    { city: 'Venda Nova do Imigrante', code: 3205069 },
    { city: 'Viana', code: 3205101 },
    { city: 'Vila Pavão', code: 3205150 },
    { city: 'Vila Valério', code: 3205176 },
    { city: 'Vila Velha', code: 3205200 },
    { city: 'Vitória', code: 3205309 },
  ],
  GO: [
    { city: 'Abadia de Goiás', code: 5200050 },
    { city: 'Abadiânia', code: 5200100 },
    { city: 'Acreúna', code: 5200134 },
    { city: 'Adelândia', code: 5200159 },
    { city: 'Água Fria de Goiás', code: 5200175 },
    { city: 'Água Limpa', code: 5200209 },
    { city: 'Águas Lindas de Goiás', code: 5200258 },
    { city: 'Alexânia', code: 5200308 },
    { city: 'Aloândia', code: 5200506 },
    { city: 'Alto Horizonte', code: 5200555 },
    { city: 'Alto Paraíso de Goiás', code: 5200605 },
    { city: 'Alvorada do Norte', code: 5200803 },
    { city: 'Amaralina', code: 5200829 },
    { city: 'Americano do Brasil', code: 5200852 },
    { city: 'Amorinópolis', code: 5200902 },
    { city: 'Anápolis', code: 5201108 },
    { city: 'Anhanguera', code: 5201207 },
    { city: 'Anicuns', code: 5201306 },
    { city: 'Aparecida de Goiânia', code: 5201405 },
    { city: 'Aparecida do Rio Doce', code: 5201454 },
    { city: 'Aporé', code: 5201504 },
    { city: 'Araçu', code: 5201603 },
    { city: 'Aragarças', code: 5201702 },
    { city: 'Aragoiânia', code: 5201801 },
    { city: 'Araguapaz', code: 5202155 },
    { city: 'Arenópolis', code: 5202353 },
    { city: 'Aruanã', code: 5202502 },
    { city: 'Aurilândia', code: 5202601 },
    { city: 'Avelinópolis', code: 5202809 },
    { city: 'Baliza', code: 5203104 },
    { city: 'Barro Alto', code: 5203203 },
    { city: 'Bela Vista de Goiás', code: 5203302 },
    { city: 'Bom Jardim de Goiás', code: 5203401 },
    { city: 'Bom Jesus de Goiás', code: 5203500 },
    { city: 'Bonfinópolis', code: 5203559 },
    { city: 'Bonópolis', code: 5203575 },
    { city: 'Brazabrantes', code: 5203609 },
    { city: 'Britânia', code: 5203807 },
    { city: 'Buriti Alegre', code: 5203906 },
    { city: 'Buriti de Goiás', code: 5203939 },
    { city: 'Buritinópolis', code: 5203962 },
    { city: 'Cabeceiras', code: 5204003 },
    { city: 'Cachoeira Alta', code: 5204102 },
    { city: 'Cachoeira de Goiás', code: 5204201 },
    { city: 'Cachoeira Dourada', code: 5204250 },
    { city: 'Caçu', code: 5204300 },
    { city: 'Caiapônia', code: 5204409 },
    { city: 'Caldas Novas', code: 5204508 },
    { city: 'Caldazinha', code: 5204557 },
    { city: 'Campestre de Goiás', code: 5204607 },
    { city: 'Campinaçu', code: 5204656 },
    { city: 'Campinorte', code: 5204706 },
    { city: 'Campo Alegre de Goiás', code: 5204805 },
    { city: 'Campo Limpo de Goiás', code: 5204854 },
    { city: 'Campos Belos', code: 5204904 },
    { city: 'Campos Verdes', code: 5204953 },
    { city: 'Carmo do Rio Verde', code: 5205000 },
    { city: 'Castelândia', code: 5205059 },
    { city: 'Catalão', code: 5205109 },
    { city: 'Caturaí', code: 5205208 },
    { city: 'Cavalcante', code: 5205307 },
    { city: 'Ceres', code: 5205406 },
    { city: 'Cezarina', code: 5205455 },
    { city: 'Chapadão do Céu', code: 5205471 },
    { city: 'Cidade Ocidental', code: 5205497 },
    { city: 'Cocalzinho de Goiás', code: 5205513 },
    { city: 'Colinas do Sul', code: 5205521 },
    { city: 'Córrego do Ouro', code: 5205703 },
    { city: 'Corumbá de Goiás', code: 5205802 },
    { city: 'Corumbaíba', code: 5205901 },
    { city: 'Cristalina', code: 5206206 },
    { city: 'Cristianópolis', code: 5206305 },
    { city: 'Crixás', code: 5206404 },
    { city: 'Cromínia', code: 5206503 },
    { city: 'Cumari', code: 5206602 },
    { city: 'Damianópolis', code: 5206701 },
    { city: 'Damolândia', code: 5206800 },
    { city: 'Davinópolis', code: 5206909 },
    { city: 'Diorama', code: 5207105 },
    { city: 'Divinópolis de Goiás', code: 5208301 },
    { city: 'Doverlândia', code: 5207253 },
    { city: 'Edealina', code: 5207352 },
    { city: 'Edéia', code: 5207402 },
    { city: 'Estrela do Norte', code: 5207501 },
    { city: 'Faina', code: 5207535 },
    { city: 'Fazenda Nova', code: 5207600 },
    { city: 'Firminópolis', code: 5207808 },
    { city: 'Flores de Goiás', code: 5207907 },
    { city: 'Formosa', code: 5208004 },
    { city: 'Formoso', code: 5208103 },
    { city: 'Gameleira de Goiás', code: 5208152 },
    { city: 'Goianápolis', code: 5208400 },
    { city: 'Goiandira', code: 5208509 },
    { city: 'Goianésia', code: 5208608 },
    { city: 'Goiânia', code: 5208707 },
    { city: 'Goianira', code: 5208806 },
    { city: 'Goiás', code: 5208905 },
    { city: 'Goiatuba', code: 5209101 },
    { city: 'Gouvelândia', code: 5209150 },
    { city: 'Guapó', code: 5209200 },
    { city: 'Guaraíta', code: 5209291 },
    { city: 'Guarani de Goiás', code: 5209408 },
    { city: 'Guarinos', code: 5209457 },
    { city: 'Heitoraí', code: 5209606 },
    { city: 'Hidrolândia', code: 5209705 },
    { city: 'Hidrolina', code: 5209804 },
    { city: 'Iaciara', code: 5209903 },
    { city: 'Inaciolândia', code: 5209937 },
    { city: 'Indiara', code: 5209952 },
    { city: 'Inhumas', code: 5210000 },
    { city: 'Ipameri', code: 5210109 },
    { city: 'Ipiranga de Goiás', code: 5210158 },
    { city: 'Iporá', code: 5210208 },
    { city: 'Israelândia', code: 5210307 },
    { city: 'Itaberaí', code: 5210406 },
    { city: 'Itaguari', code: 5210562 },
    { city: 'Itaguaru', code: 5210604 },
    { city: 'Itajá', code: 5210802 },
    { city: 'Itapaci', code: 5210901 },
    { city: 'Itapirapuã', code: 5211008 },
    { city: 'Itapuranga', code: 5211206 },
    { city: 'Itarumã', code: 5211305 },
    { city: 'Itauçu', code: 5211404 },
    { city: 'Itumbiara', code: 5211503 },
    { city: 'Ivolândia', code: 5211602 },
    { city: 'Jandaia', code: 5211701 },
    { city: 'Jaraguá', code: 5211800 },
    { city: 'Jataí', code: 5211909 },
    { city: 'Jaupaci', code: 5212006 },
    { city: 'Jesúpolis', code: 5212055 },
    { city: 'Joviânia', code: 5212105 },
    { city: 'Jussara', code: 5212204 },
    { city: 'Lagoa Santa', code: 5212253 },
    { city: 'Leopoldo de Bulhões', code: 5212303 },
    { city: 'Luziânia', code: 5212501 },
    { city: 'Mairipotaba', code: 5212600 },
    { city: 'Mambaí', code: 5212709 },
    { city: 'Mara Rosa', code: 5212808 },
    { city: 'Marzagão', code: 5212907 },
    { city: 'Matrinchã', code: 5212956 },
    { city: 'Maurilândia', code: 5213004 },
    { city: 'Mimoso de Goiás', code: 5213053 },
    { city: 'Minaçu', code: 5213087 },
    { city: 'Mineiros', code: 5213103 },
    { city: 'Moiporá', code: 5213400 },
    { city: 'Monte Alegre de Goiás', code: 5213509 },
    { city: 'Montes Claros de Goiás', code: 5213707 },
    { city: 'Montividiu', code: 5213756 },
    { city: 'Montividiu do Norte', code: 5213772 },
    { city: 'Morrinhos', code: 5213806 },
    { city: 'Morro Agudo de Goiás', code: 5213855 },
    { city: 'Mossâmedes', code: 5213905 },
    { city: 'Mozarlândia', code: 5214002 },
    { city: 'Mundo Novo', code: 5214051 },
    { city: 'Mutunópolis', code: 5214101 },
    { city: 'Nazário', code: 5214408 },
    { city: 'Nerópolis', code: 5214507 },
    { city: 'Niquelândia', code: 5214606 },
    { city: 'Nova América', code: 5214705 },
    { city: 'Nova Aurora', code: 5214804 },
    { city: 'Nova Crixás', code: 5214838 },
    { city: 'Nova Glória', code: 5214861 },
    { city: 'Nova Iguaçu de Goiás', code: 5214879 },
    { city: 'Nova Roma', code: 5214903 },
    { city: 'Nova Veneza', code: 5215009 },
    { city: 'Novo Brasil', code: 5215207 },
    { city: 'Novo Gama', code: 5215231 },
    { city: 'Novo Planalto', code: 5215256 },
    { city: 'Orizona', code: 5215306 },
    { city: 'Ouro Verde de Goiás', code: 5215405 },
    { city: 'Ouvidor', code: 5215504 },
    { city: 'Padre Bernardo', code: 5215603 },
    { city: 'Palestina de Goiás', code: 5215652 },
    { city: 'Palmeiras de Goiás', code: 5215702 },
    { city: 'Palmelo', code: 5215801 },
    { city: 'Palminópolis', code: 5215900 },
    { city: 'Panamá', code: 5216007 },
    { city: 'Paranaiguara', code: 5216304 },
    { city: 'Paraúna', code: 5216403 },
    { city: 'Perolândia', code: 5216452 },
    { city: 'Petrolina de Goiás', code: 5216809 },
    { city: 'Pilar de Goiás', code: 5216908 },
    { city: 'Piracanjuba', code: 5217104 },
    { city: 'Piranhas', code: 5217203 },
    { city: 'Pirenópolis', code: 5217302 },
    { city: 'Pires do Rio', code: 5217401 },
    { city: 'Planaltina', code: 5217609 },
    { city: 'Pontalina', code: 5217708 },
    { city: 'Porangatu', code: 5218003 },
    { city: 'Porteirão', code: 5218052 },
    { city: 'Portelândia', code: 5218102 },
    { city: 'Posse', code: 5218300 },
    { city: 'Professor Jamil', code: 5218391 },
    { city: 'Quirinópolis', code: 5218508 },
    { city: 'Rialma', code: 5218607 },
    { city: 'Rianápolis', code: 5218706 },
    { city: 'Rio Quente', code: 5218789 },
    { city: 'Rio Verde', code: 5218805 },
    { city: 'Rubiataba', code: 5218904 },
    { city: 'Sanclerlândia', code: 5219001 },
    { city: 'Santa Bárbara de Goiás', code: 5219100 },
    { city: 'Santa Cruz de Goiás', code: 5219209 },
    { city: 'Santa Fé de Goiás', code: 5219258 },
    { city: 'Santa Helena de Goiás', code: 5219308 },
    { city: 'Santa Isabel', code: 5219357 },
    { city: 'Santa Rita do Araguaia', code: 5219407 },
    { city: 'Santa Rita do Novo Destino', code: 5219456 },
    { city: 'Santa Rosa de Goiás', code: 5219506 },
    { city: 'Santa Tereza de Goiás', code: 5219605 },
    { city: 'Santa Terezinha de Goiás', code: 5219704 },
    { city: 'Santo Antônio da Barra', code: 5219712 },
    { city: 'Santo Antônio de Goiás', code: 5219738 },
    { city: 'Santo Antônio do Descoberto', code: 5219753 },
    { city: 'São Domingos', code: 5219803 },
    { city: 'São Francisco de Goiás', code: 5219902 },
    { city: 'São João da Paraúna', code: 5220058 },
    { city: 'São João d\'Aliança', code: 5220009 },
    { city: 'São Luís de Montes Belos', code: 5220108 },
    { city: 'São Luiz do Norte', code: 5220157 },
    { city: 'São Miguel do Araguaia', code: 5220207 },
    { city: 'São Miguel do Passa Quatro', code: 5220264 },
    { city: 'São Patrício', code: 5220280 },
    { city: 'São Simão', code: 5220405 },
    { city: 'Senador Canedo', code: 5220454 },
    { city: 'Serranópolis', code: 5220504 },
    { city: 'Silvânia', code: 5220603 },
    { city: 'Simolândia', code: 5220686 },
    { city: 'Sítio d\'Abadia', code: 5220702 },
    { city: 'Taquaral de Goiás', code: 5221007 },
    { city: 'Teresina de Goiás', code: 5221080 },
    { city: 'Terezópolis de Goiás', code: 5221197 },
    { city: 'Três Ranchos', code: 5221304 },
    { city: 'Trindade', code: 5221403 },
    { city: 'Trombas', code: 5221452 },
    { city: 'Turvânia', code: 5221502 },
    { city: 'Turvelândia', code: 5221551 },
    { city: 'Uirapuru', code: 5221577 },
    { city: 'Uruaçu', code: 5221601 },
    { city: 'Uruana', code: 5221700 },
    { city: 'Urutaí', code: 5221809 },
    { city: 'Valparaíso de Goiás', code: 5221858 },
    { city: 'Varjão', code: 5221908 },
    { city: 'Vianópolis', code: 5222005 },
    { city: 'Vicentinópolis', code: 5222054 },
    { city: 'Vila Boa', code: 5222203 },
    { city: 'Vila Propício', code: 5222302 },
  ],
  MA: [
    { city: 'Açailândia', code: 2100055 },
    { city: 'Afonso Cunha', code: 2100105 },
    { city: 'Água Doce do Maranhão', code: 2100154 },
    { city: 'Alcântara', code: 2100204 },
    { city: 'Aldeias Altas', code: 2100303 },
    { city: 'Altamira do Maranhão', code: 2100402 },
    { city: 'Alto Alegre do Maranhão', code: 2100436 },
    { city: 'Alto Alegre do Pindaré', code: 2100477 },
    { city: 'Alto Parnaíba', code: 2100501 },
    { city: 'Amapá do Maranhão', code: 2100550 },
    { city: 'Amarante do Maranhão', code: 2100600 },
    { city: 'Anajatuba', code: 2100709 },
    { city: 'Anapurus', code: 2100808 },
    { city: 'Apicum-Açu', code: 2100832 },
    { city: 'Araguanã', code: 2100873 },
    { city: 'Araioses', code: 2100907 },
    { city: 'Arame', code: 2100956 },
    { city: 'Arari', code: 2101004 },
    { city: 'Axixá', code: 2101103 },
    { city: 'Bacabal', code: 2101202 },
    { city: 'Bacabeira', code: 2101251 },
    { city: 'Bacuri', code: 2101301 },
    { city: 'Bacurituba', code: 2101350 },
    { city: 'Balsas', code: 2101400 },
    { city: 'Barão de Grajaú', code: 2101509 },
    { city: 'Barra do Corda', code: 2101608 },
    { city: 'Barreirinhas', code: 2101707 },
    { city: 'Bela Vista do Maranhão', code: 2101772 },
    { city: 'Belágua', code: 2101731 },
    { city: 'Benedito Leite', code: 2101806 },
    { city: 'Bequimão', code: 2101905 },
    { city: 'Bernardo do Mearim', code: 2101939 },
    { city: 'Boa Vista do Gurupi', code: 2101970 },
    { city: 'Bom Jardim', code: 2102002 },
    { city: 'Bom Jesus das Selvas', code: 2102036 },
    { city: 'Bom Lugar', code: 2102077 },
    { city: 'Brejo', code: 2102101 },
    { city: 'Brejo de Areia', code: 2102150 },
    { city: 'Buriti', code: 2102200 },
    { city: 'Buriti Bravo', code: 2102309 },
    { city: 'Buriticupu', code: 2102325 },
    { city: 'Buritirana', code: 2102358 },
    { city: 'Cachoeira Grande', code: 2102374 },
    { city: 'Cajapió', code: 2102408 },
    { city: 'Cajari', code: 2102507 },
    { city: 'Campestre do Maranhão', code: 2102556 },
    { city: 'Cândido Mendes', code: 2102606 },
    { city: 'Cantanhede', code: 2102705 },
    { city: 'Capinzal do Norte', code: 2102754 },
    { city: 'Carolina', code: 2102804 },
    { city: 'Carutapera', code: 2102903 },
    { city: 'Caxias', code: 2103000 },
    { city: 'Cedral', code: 2103109 },
    { city: 'Central do Maranhão', code: 2103125 },
    { city: 'Centro do Guilherme', code: 2103158 },
    { city: 'Centro Novo do Maranhão', code: 2103174 },
    { city: 'Chapadinha', code: 2103208 },
    { city: 'Cidelândia', code: 2103257 },
    { city: 'Codó', code: 2103307 },
    { city: 'Coelho Neto', code: 2103406 },
    { city: 'Colinas', code: 2103505 },
    { city: 'Conceição do Lago-Açu', code: 2103554 },
    { city: 'Coroatá', code: 2103604 },
    { city: 'Cururupu', code: 2103703 },
    { city: 'Davinópolis', code: 2103752 },
    { city: 'Dom Pedro', code: 2103802 },
    { city: 'Duque Bacelar', code: 2103901 },
    { city: 'Esperantinópolis', code: 2104008 },
    { city: 'Estreito', code: 2104057 },
    { city: 'Feira Nova do Maranhão', code: 2104073 },
    { city: 'Fernando Falcão', code: 2104081 },
    { city: 'Formosa da Serra Negra', code: 2104099 },
    { city: 'Fortaleza dos Nogueiras', code: 2104107 },
    { city: 'Fortuna', code: 2104206 },
    { city: 'Godofredo Viana', code: 2104305 },
    { city: 'Gonçalves Dias', code: 2104404 },
    { city: 'Governador Archer', code: 2104503 },
    { city: 'Governador Edison Lobão', code: 2104552 },
    { city: 'Governador Eugênio Barros', code: 2104602 },
    { city: 'Governador Luiz Rocha', code: 2104628 },
    { city: 'Governador Newton Bello', code: 2104651 },
    { city: 'Governador Nunes Freire', code: 2104677 },
    { city: 'Graça Aranha', code: 2104701 },
    { city: 'Grajaú', code: 2104800 },
    { city: 'Guimarães', code: 2104909 },
    { city: 'Humberto de Campos', code: 2105005 },
    { city: 'Icatu', code: 2105104 },
    { city: 'Igarapé do Meio', code: 2105153 },
    { city: 'Igarapé Grande', code: 2105203 },
    { city: 'Imperatriz', code: 2105302 },
    { city: 'Itaipava do Grajaú', code: 2105351 },
    { city: 'Itapecuru Mirim', code: 2105401 },
    { city: 'Itinga do Maranhão', code: 2105427 },
    { city: 'Jatobá', code: 2105450 },
    { city: 'Jenipapo dos Vieiras', code: 2105476 },
    { city: 'João Lisboa', code: 2105500 },
    { city: 'Joselândia', code: 2105609 },
    { city: 'Junco do Maranhão', code: 2105658 },
    { city: 'Lago da Pedra', code: 2105708 },
    { city: 'Lago do Junco', code: 2105807 },
    { city: 'Lago dos Rodrigues', code: 2105948 },
    { city: 'Lago Verde', code: 2105906 },
    { city: 'Lagoa do Mato', code: 2105922 },
    { city: 'Lagoa Grande do Maranhão', code: 2105963 },
    { city: 'Lajeado Novo', code: 2105989 },
    { city: 'Lima Campos', code: 2106003 },
    { city: 'Loreto', code: 2106102 },
    { city: 'Luís Domingues', code: 2106201 },
    { city: 'Magalhães de Almeida', code: 2106300 },
    { city: 'Maracaçumé', code: 2106326 },
    { city: 'Marajá do Sena', code: 2106359 },
    { city: 'Maranhãozinho', code: 2106375 },
    { city: 'Mata Roma', code: 2106409 },
    { city: 'Matinha', code: 2106508 },
    { city: 'Matões', code: 2106607 },
    { city: 'Matões do Norte', code: 2106631 },
    { city: 'Milagres do Maranhão', code: 2106672 },
    { city: 'Mirador', code: 2106706 },
    { city: 'Miranda do Norte', code: 2106755 },
    { city: 'Mirinzal', code: 2106805 },
    { city: 'Monção', code: 2106904 },
    { city: 'Montes Altos', code: 2107001 },
    { city: 'Morros', code: 2107100 },
    { city: 'Nina Rodrigues', code: 2107209 },
    { city: 'Nova Colinas', code: 2107258 },
    { city: 'Nova Iorque', code: 2107308 },
    { city: 'Nova Olinda do Maranhão', code: 2107357 },
    { city: 'Olho d\'Água das Cunhãs', code: 2107407 },
    { city: 'Olinda Nova do Maranhão', code: 2107456 },
    { city: 'Paço do Lumiar', code: 2107506 },
    { city: 'Palmeirândia', code: 2107605 },
    { city: 'Paraibano', code: 2107704 },
    { city: 'Parnarama', code: 2107803 },
    { city: 'Passagem Franca', code: 2107902 },
    { city: 'Pastos Bons', code: 2108009 },
    { city: 'Paulino Neves', code: 2108058 },
    { city: 'Paulo Ramos', code: 2108108 },
    { city: 'Pedreiras', code: 2108207 },
    { city: 'Pedro do Rosário', code: 2108256 },
    { city: 'Penalva', code: 2108306 },
    { city: 'Peri Mirim', code: 2108405 },
    { city: 'Peritoró', code: 2108454 },
    { city: 'Pindaré-Mirim', code: 2108504 },
    { city: 'Pinheiro', code: 2108603 },
    { city: 'Pio XII', code: 2108702 },
    { city: 'Pirapemas', code: 2108801 },
    { city: 'Poção de Pedras', code: 2108900 },
    { city: 'Porto Franco', code: 2109007 },
    { city: 'Porto Rico do Maranhão', code: 2109056 },
    { city: 'Presidente Dutra', code: 2109106 },
    { city: 'Presidente Juscelino', code: 2109205 },
    { city: 'Presidente Médici', code: 2109239 },
    { city: 'Presidente Sarney', code: 2109270 },
    { city: 'Presidente Vargas', code: 2109304 },
    { city: 'Primeira Cruz', code: 2109403 },
    { city: 'Raposa', code: 2109452 },
    { city: 'Riachão', code: 2109502 },
    { city: 'Ribamar Fiquene', code: 2109551 },
    { city: 'Rosário', code: 2109601 },
    { city: 'Sambaíba', code: 2109700 },
    { city: 'Santa Filomena do Maranhão', code: 2109759 },
    { city: 'Santa Helena', code: 2109809 },
    { city: 'Santa Inês', code: 2109908 },
    { city: 'Santa Luzia', code: 2110005 },
    { city: 'Santa Luzia do Paruá', code: 2110039 },
    { city: 'Santa Quitéria do Maranhão', code: 2110104 },
    { city: 'Santa Rita', code: 2110203 },
    { city: 'Santana do Maranhão', code: 2110237 },
    { city: 'Santo Amaro do Maranhão', code: 2110278 },
    { city: 'Santo Antônio dos Lopes', code: 2110302 },
    { city: 'São Benedito do Rio Preto', code: 2110401 },
    { city: 'São Bento', code: 2110500 },
    { city: 'São Bernardo', code: 2110609 },
    { city: 'São Domingos do Azeitão', code: 2110658 },
    { city: 'São Domingos do Maranhão', code: 2110708 },
    { city: 'São Félix de Balsas', code: 2110807 },
    { city: 'São Francisco do Brejão', code: 2110856 },
    { city: 'São Francisco do Maranhão', code: 2110906 },
    { city: 'São João Batista', code: 2111003 },
    { city: 'São João do Carú', code: 2111029 },
    { city: 'São João do Paraíso', code: 2111052 },
    { city: 'São João do Soter', code: 2111078 },
    { city: 'São João dos Patos', code: 2111102 },
    { city: 'São José de Ribamar', code: 2111201 },
    { city: 'São José dos Basílios', code: 2111250 },
    { city: 'São Luís', code: 2111300 },
    { city: 'São Luís Gonzaga do Maranhão', code: 2111409 },
    { city: 'São Mateus do Maranhão', code: 2111508 },
    { city: 'São Pedro da Água Branca', code: 2111532 },
    { city: 'São Pedro dos Crentes', code: 2111573 },
    { city: 'São Raimundo das Mangabeiras', code: 2111607 },
    { city: 'São Raimundo do Doca Bezerra', code: 2111631 },
    { city: 'São Roberto', code: 2111672 },
    { city: 'São Vicente Ferrer', code: 2111706 },
    { city: 'Satubinha', code: 2111722 },
    { city: 'Senador Alexandre Costa', code: 2111748 },
    { city: 'Senador La Rocque', code: 2111763 },
    { city: 'Serrano do Maranhão', code: 2111789 },
    { city: 'Sítio Novo', code: 2111805 },
    { city: 'Sucupira do Norte', code: 2111904 },
    { city: 'Sucupira do Riachão', code: 2111953 },
    { city: 'Tasso Fragoso', code: 2112001 },
    { city: 'Timbiras', code: 2112100 },
    { city: 'Timon', code: 2112209 },
    { city: 'Trizidela do Vale', code: 2112233 },
    { city: 'Tufilândia', code: 2112274 },
    { city: 'Tuntum', code: 2112308 },
    { city: 'Turiaçu', code: 2112407 },
    { city: 'Turilândia', code: 2112456 },
    { city: 'Tutóia', code: 2112506 },
    { city: 'Urbano Santos', code: 2112605 },
    { city: 'Vargem Grande', code: 2112704 },
    { city: 'Viana', code: 2112803 },
    { city: 'Vila Nova dos Martírios', code: 2112852 },
    { city: 'Vitória do Mearim', code: 2112902 },
    { city: 'Vitorino Freire', code: 2113009 },
    { city: 'Zé Doca', code: 2114007 },
  ],
  MG: [
    { city: 'Abadia dos Dourados', code: 3100104 },
    { city: 'Abaeté', code: 3100203 },
    { city: 'Abre Campo', code: 3100302 },
    { city: 'Acaiaca', code: 3100401 },
    { city: 'Açucena', code: 3100500 },
    { city: 'Água Boa', code: 3100609 },
    { city: 'Água Comprida', code: 3100708 },
    { city: 'Aguanil', code: 3100807 },
    { city: 'Águas Formosas', code: 3100906 },
    { city: 'Águas Vermelhas', code: 3101003 },
    { city: 'Aimorés', code: 3101102 },
    { city: 'Aiuruoca', code: 3101201 },
    { city: 'Alagoa', code: 3101300 },
    { city: 'Albertina', code: 3101409 },
    { city: 'Além Paraíba', code: 3101508 },
    { city: 'Alfenas', code: 3101607 },
    { city: 'Alfredo Vasconcelos', code: 3101631 },
    { city: 'Almenara', code: 3101706 },
    { city: 'Alpercata', code: 3101805 },
    { city: 'Alpinópolis', code: 3101904 },
    { city: 'Alterosa', code: 3102001 },
    { city: 'Alto Caparaó', code: 3102050 },
    { city: 'Alto Jequitibá', code: 3153509 },
    { city: 'Alto Rio Doce', code: 3102100 },
    { city: 'Alvarenga', code: 3102209 },
    { city: 'Alvinópolis', code: 3102308 },
    { city: 'Alvorada de Minas', code: 3102407 },
    { city: 'Amparo do Serra', code: 3102506 },
    { city: 'Andradas', code: 3102605 },
    { city: 'Andrelândia', code: 3102803 },
    { city: 'Angelândia', code: 3102852 },
    { city: 'Antônio Carlos', code: 3102902 },
    { city: 'Antônio Dias', code: 3103009 },
    { city: 'Antônio Prado de Minas', code: 3103108 },
    { city: 'Araçaí', code: 3103207 },
    { city: 'Aracitaba', code: 3103306 },
    { city: 'Araçuaí', code: 3103405 },
    { city: 'Araguari', code: 3103504 },
    { city: 'Arantina', code: 3103603 },
    { city: 'Araponga', code: 3103702 },
    { city: 'Araporã', code: 3103751 },
    { city: 'Arapuá', code: 3103801 },
    { city: 'Araújos', code: 3103900 },
    { city: 'Araxá', code: 3104007 },
    { city: 'Arceburgo', code: 3104106 },
    { city: 'Arcos', code: 3104205 },
    { city: 'Areado', code: 3104304 },
    { city: 'Argirita', code: 3104403 },
    { city: 'Aricanduva', code: 3104452 },
    { city: 'Arinos', code: 3104502 },
    { city: 'Astolfo Dutra', code: 3104601 },
    { city: 'Ataléia', code: 3104700 },
    { city: 'Augusto de Lima', code: 3104809 },
    { city: 'Baependi', code: 3104908 },
    { city: 'Baldim', code: 3105004 },
    { city: 'Bambuí', code: 3105103 },
    { city: 'Bandeira', code: 3105202 },
    { city: 'Bandeira do Sul', code: 3105301 },
    { city: 'Barão de Cocais', code: 3105400 },
    { city: 'Barão de Monte Alto', code: 3105509 },
    { city: 'Barbacena', code: 3105608 },
    { city: 'Barra Longa', code: 3105707 },
    { city: 'Barroso', code: 3105905 },
    { city: 'Bela Vista de Minas', code: 3106002 },
    { city: 'Belmiro Braga', code: 3106101 },
    { city: 'Belo Horizonte', code: 3106200 },
    { city: 'Belo Oriente', code: 3106309 },
    { city: 'Belo Vale', code: 3106408 },
    { city: 'Berilo', code: 3106507 },
    { city: 'Berizal', code: 3106655 },
    { city: 'Bertópolis', code: 3106606 },
    { city: 'Betim', code: 3106705 },
    { city: 'Bias Fortes', code: 3106804 },
    { city: 'Bicas', code: 3106903 },
    { city: 'Biquinhas', code: 3107000 },
    { city: 'Boa Esperança', code: 3107109 },
    { city: 'Bocaina de Minas', code: 3107208 },
    { city: 'Bocaiúva', code: 3107307 },
    { city: 'Bom Despacho', code: 3107406 },
    { city: 'Bom Jardim de Minas', code: 3107505 },
    { city: 'Bom Jesus da Penha', code: 3107604 },
    { city: 'Bom Jesus do Amparo', code: 3107703 },
    { city: 'Bom Jesus do Galho', code: 3107802 },
    { city: 'Bom Repouso', code: 3107901 },
    { city: 'Bom Sucesso', code: 3108008 },
    { city: 'Bonfim', code: 3108107 },
    { city: 'Bonfinópolis de Minas', code: 3108206 },
    { city: 'Bonito de Minas', code: 3108255 },
    { city: 'Borda da Mata', code: 3108305 },
    { city: 'Botelhos', code: 3108404 },
    { city: 'Botumirim', code: 3108503 },
    { city: 'Brás Pires', code: 3108701 },
    { city: 'Brasilândia de Minas', code: 3108552 },
    { city: 'Brasília de Minas', code: 3108602 },
    { city: 'Braúnas', code: 3108800 },
    { city: 'Brazópolis', code: 3108909 },
    { city: 'Brumadinho', code: 3109006 },
    { city: 'Bueno Brandão', code: 3109105 },
    { city: 'Buenópolis', code: 3109204 },
    { city: 'Bugre', code: 3109253 },
    { city: 'Buritis', code: 3109303 },
    { city: 'Buritizeiro', code: 3109402 },
    { city: 'Cabeceira Grande', code: 3109451 },
    { city: 'Cabo Verde', code: 3109501 },
    { city: 'Cachoeira da Prata', code: 3109600 },
    { city: 'Cachoeira de Minas', code: 3109709 },
    { city: 'Cachoeira de Pajeú', code: 3102704 },
    { city: 'Cachoeira Dourada', code: 3109808 },
    { city: 'Caetanópolis', code: 3109907 },
    { city: 'Caeté', code: 3110004 },
    { city: 'Caiana', code: 3110103 },
    { city: 'Cajuri', code: 3110202 },
    { city: 'Caldas', code: 3110301 },
    { city: 'Camacho', code: 3110400 },
    { city: 'Camanducaia', code: 3110509 },
    { city: 'Cambuí', code: 3110608 },
    { city: 'Cambuquira', code: 3110707 },
    { city: 'Campanário', code: 3110806 },
    { city: 'Campanha', code: 3110905 },
    { city: 'Campestre', code: 3111002 },
    { city: 'Campina Verde', code: 3111101 },
    { city: 'Campo Azul', code: 3111150 },
    { city: 'Campo Belo', code: 3111200 },
    { city: 'Campo do Meio', code: 3111309 },
    { city: 'Campo Florido', code: 3111408 },
    { city: 'Campos Altos', code: 3111507 },
    { city: 'Campos Gerais', code: 3111606 },
    { city: 'Cana Verde', code: 3111903 },
    { city: 'Canaã', code: 3111705 },
    { city: 'Canápolis', code: 3111804 },
    { city: 'Candeias', code: 3112000 },
    { city: 'Cantagalo', code: 3112059 },
    { city: 'Caparaó', code: 3112109 },
    { city: 'Capela Nova', code: 3112208 },
    { city: 'Capelinha', code: 3112307 },
    { city: 'Capetinga', code: 3112406 },
    { city: 'Capim Branco', code: 3112505 },
    { city: 'Capinópolis', code: 3112604 },
    { city: 'Capitão Andrade', code: 3112653 },
    { city: 'Capitão Enéas', code: 3112703 },
    { city: 'Capitólio', code: 3112802 },
    { city: 'Caputira', code: 3112901 },
    { city: 'Caraí', code: 3113008 },
    { city: 'Caranaíba', code: 3113107 },
    { city: 'Carandaí', code: 3113206 },
    { city: 'Carangola', code: 3113305 },
    { city: 'Caratinga', code: 3113404 },
    { city: 'Carbonita', code: 3113503 },
    { city: 'Careaçu', code: 3113602 },
    { city: 'Carlos Chagas', code: 3113701 },
    { city: 'Carmésia', code: 3113800 },
    { city: 'Carmo da Cachoeira', code: 3113909 },
    { city: 'Carmo da Mata', code: 3114006 },
    { city: 'Carmo de Minas', code: 3114105 },
    { city: 'Carmo do Cajuru', code: 3114204 },
    { city: 'Carmo do Paranaíba', code: 3114303 },
    { city: 'Carmo do Rio Claro', code: 3114402 },
    { city: 'Carmópolis de Minas', code: 3114501 },
    { city: 'Carneirinho', code: 3114550 },
    { city: 'Carrancas', code: 3114600 },
    { city: 'Carvalhópolis', code: 3114709 },
    { city: 'Carvalhos', code: 3114808 },
    { city: 'Casa Grande', code: 3114907 },
    { city: 'Cascalho Rico', code: 3115003 },
    { city: 'Cássia', code: 3115102 },
    { city: 'Cataguases', code: 3115300 },
    { city: 'Catas Altas', code: 3115359 },
    { city: 'Catas Altas da Noruega', code: 3115409 },
    { city: 'Catuji', code: 3115458 },
    { city: 'Catuti', code: 3115474 },
    { city: 'Caxambu', code: 3115508 },
    { city: 'Cedro do Abaeté', code: 3115607 },
    { city: 'Central de Minas', code: 3115706 },
    { city: 'Centralina', code: 3115805 },
    { city: 'Chácara', code: 3115904 },
    { city: 'Chalé', code: 3116001 },
    { city: 'Chapada do Norte', code: 3116100 },
    { city: 'Chapada Gaúcha', code: 3116159 },
    { city: 'Chiador', code: 3116209 },
    { city: 'Cipotânea', code: 3116308 },
    { city: 'Claraval', code: 3116407 },
    { city: 'Claro dos Poções', code: 3116506 },
    { city: 'Cláudio', code: 3116605 },
    { city: 'Coimbra', code: 3116704 },
    { city: 'Coluna', code: 3116803 },
    { city: 'Comendador Gomes', code: 3116902 },
    { city: 'Comercinho', code: 3117009 },
    { city: 'Conceição da Aparecida', code: 3117108 },
    { city: 'Conceição da Barra de Minas', code: 3115201 },
    { city: 'Conceição das Alagoas', code: 3117306 },
    { city: 'Conceição das Pedras', code: 3117207 },
    { city: 'Conceição de Ipanema', code: 3117405 },
    { city: 'Conceição do Mato Dentro', code: 3117504 },
    { city: 'Conceição do Pará', code: 3117603 },
    { city: 'Conceição do Rio Verde', code: 3117702 },
    { city: 'Conceição dos Ouros', code: 3117801 },
    { city: 'Cônego Marinho', code: 3117836 },
    { city: 'Confins', code: 3117876 },
    { city: 'Congonhal', code: 3117900 },
    { city: 'Congonhas', code: 3118007 },
    { city: 'Congonhas do Norte', code: 3118106 },
    { city: 'Conquista', code: 3118205 },
    { city: 'Conselheiro Lafaiete', code: 3118304 },
    { city: 'Conselheiro Pena', code: 3118403 },
    { city: 'Consolação', code: 3118502 },
    { city: 'Contagem', code: 3118601 },
    { city: 'Coqueiral', code: 3118700 },
    { city: 'Coração de Jesus', code: 3118809 },
    { city: 'Cordisburgo', code: 3118908 },
    { city: 'Cordislândia', code: 3119005 },
    { city: 'Corinto', code: 3119104 },
    { city: 'Coroaci', code: 3119203 },
    { city: 'Coromandel', code: 3119302 },
    { city: 'Coronel Fabriciano', code: 3119401 },
    { city: 'Coronel Murta', code: 3119500 },
    { city: 'Coronel Pacheco', code: 3119609 },
    { city: 'Coronel Xavier Chaves', code: 3119708 },
    { city: 'Córrego Danta', code: 3119807 },
    { city: 'Córrego do Bom Jesus', code: 3119906 },
    { city: 'Córrego Fundo', code: 3119955 },
    { city: 'Córrego Novo', code: 3120003 },
    { city: 'Couto de Magalhães de Minas', code: 3120102 },
    { city: 'Crisólita', code: 3120151 },
    { city: 'Cristais', code: 3120201 },
    { city: 'Cristália', code: 3120300 },
    { city: 'Cristiano Otoni', code: 3120409 },
    { city: 'Cristina', code: 3120508 },
    { city: 'Crucilândia', code: 3120607 },
    { city: 'Cruzeiro da Fortaleza', code: 3120706 },
    { city: 'Cruzília', code: 3120805 },
    { city: 'Cuparaque', code: 3120839 },
    { city: 'Curral de Dentro', code: 3120870 },
    { city: 'Curvelo', code: 3120904 },
    { city: 'Datas', code: 3121001 },
    { city: 'Delfim Moreira', code: 3121100 },
    { city: 'Delfinópolis', code: 3121209 },
    { city: 'Delta', code: 3121258 },
    { city: 'Descoberto', code: 3121308 },
    { city: 'Desterro de Entre Rios', code: 3121407 },
    { city: 'Desterro do Melo', code: 3121506 },
    { city: 'Diamantina', code: 3121605 },
    { city: 'Diogo de Vasconcelos', code: 3121704 },
    { city: 'Dionísio', code: 3121803 },
    { city: 'Divinésia', code: 3121902 },
    { city: 'Divino', code: 3122009 },
    { city: 'Divino das Laranjeiras', code: 3122108 },
    { city: 'Divinolândia de Minas', code: 3122207 },
    { city: 'Divinópolis', code: 3122306 },
    { city: 'Divisa Alegre', code: 3122355 },
    { city: 'Divisa Nova', code: 3122405 },
    { city: 'Divisópolis', code: 3122454 },
    { city: 'Dom Bosco', code: 3122470 },
    { city: 'Dom Cavati', code: 3122504 },
    { city: 'Dom Joaquim', code: 3122603 },
    { city: 'Dom Silvério', code: 3122702 },
    { city: 'Dom Viçoso', code: 3122801 },
    { city: 'Dona Eusébia', code: 3122900 },
    { city: 'Dores de Campos', code: 3123007 },
    { city: 'Dores de Guanhães', code: 3123106 },
    { city: 'Dores do Indaiá', code: 3123205 },
    { city: 'Dores do Turvo', code: 3123304 },
    { city: 'Doresópolis', code: 3123403 },
    { city: 'Douradoquara', code: 3123502 },
    { city: 'Durandé', code: 3123528 },
    { city: 'Elói Mendes', code: 3123601 },
    { city: 'Engenheiro Caldas', code: 3123700 },
    { city: 'Engenheiro Navarro', code: 3123809 },
    { city: 'Entre Folhas', code: 3123858 },
    { city: 'Entre Rios de Minas', code: 3123908 },
    { city: 'Ervália', code: 3124005 },
    { city: 'Esmeraldas', code: 3124104 },
    { city: 'Espera Feliz', code: 3124203 },
    { city: 'Espinosa', code: 3124302 },
    { city: 'Espírito Santo do Dourado', code: 3124401 },
    { city: 'Estiva', code: 3124500 },
    { city: 'Estrela Dalva', code: 3124609 },
    { city: 'Estrela do Indaiá', code: 3124708 },
    { city: 'Estrela do Sul', code: 3124807 },
    { city: 'Eugenópolis', code: 3124906 },
    { city: 'Ewbank da Câmara', code: 3125002 },
    { city: 'Extrema', code: 3125101 },
    { city: 'Fama', code: 3125200 },
    { city: 'Faria Lemos', code: 3125309 },
    { city: 'Felício dos Santos', code: 3125408 },
    { city: 'Felisburgo', code: 3125606 },
    { city: 'Felixlândia', code: 3125705 },
    { city: 'Fernandes Tourinho', code: 3125804 },
    { city: 'Ferros', code: 3125903 },
    { city: 'Fervedouro', code: 3125952 },
    { city: 'Florestal', code: 3126000 },
    { city: 'Formiga', code: 3126109 },
    { city: 'Formoso', code: 3126208 },
    { city: 'Fortaleza de Minas', code: 3126307 },
    { city: 'Fortuna de Minas', code: 3126406 },
    { city: 'Francisco Badaró', code: 3126505 },
    { city: 'Francisco Dumont', code: 3126604 },
    { city: 'Francisco Sá', code: 3126703 },
    { city: 'Franciscópolis', code: 3126752 },
    { city: 'Frei Gaspar', code: 3126802 },
    { city: 'Frei Inocêncio', code: 3126901 },
    { city: 'Frei Lagonegro', code: 3126950 },
    { city: 'Fronteira', code: 3127008 },
    { city: 'Fronteira dos Vales', code: 3127057 },
    { city: 'Fruta de Leite', code: 3127073 },
    { city: 'Frutal', code: 3127107 },
    { city: 'Funilândia', code: 3127206 },
    { city: 'Galiléia', code: 3127305 },
    { city: 'Gameleiras', code: 3127339 },
    { city: 'Glaucilândia', code: 3127354 },
    { city: 'Goiabeira', code: 3127370 },
    { city: 'Goianá', code: 3127388 },
    { city: 'Gonçalves', code: 3127404 },
    { city: 'Gonzaga', code: 3127503 },
    { city: 'Gouveia', code: 3127602 },
    { city: 'Governador Valadares', code: 3127701 },
    { city: 'Grão Mogol', code: 3127800 },
    { city: 'Grupiara', code: 3127909 },
    { city: 'Guanhães', code: 3128006 },
    { city: 'Guapé', code: 3128105 },
    { city: 'Guaraciaba', code: 3128204 },
    { city: 'Guaraciama', code: 3128253 },
    { city: 'Guaranésia', code: 3128303 },
    { city: 'Guarani', code: 3128402 },
    { city: 'Guarará', code: 3128501 },
    { city: 'Guarda-Mor', code: 3128600 },
    { city: 'Guaxupé', code: 3128709 },
    { city: 'Guidoval', code: 3128808 },
    { city: 'Guimarânia', code: 3128907 },
    { city: 'Guiricema', code: 3129004 },
    { city: 'Gurinhatã', code: 3129103 },
    { city: 'Heliodora', code: 3129202 },
    { city: 'Iapu', code: 3129301 },
    { city: 'Ibertioga', code: 3129400 },
    { city: 'Ibiá', code: 3129509 },
    { city: 'Ibiaí', code: 3129608 },
    { city: 'Ibiracatu', code: 3129657 },
    { city: 'Ibiraci', code: 3129707 },
    { city: 'Ibirité', code: 3129806 },
    { city: 'Ibitiúra de Minas', code: 3129905 },
    { city: 'Ibituruna', code: 3130002 },
    { city: 'Icaraí de Minas', code: 3130051 },
    { city: 'Igarapé', code: 3130101 },
    { city: 'Igaratinga', code: 3130200 },
    { city: 'Iguatama', code: 3130309 },
    { city: 'Ijaci', code: 3130408 },
    { city: 'Ilicínea', code: 3130507 },
    { city: 'Imbé de Minas', code: 3130556 },
    { city: 'Inconfidentes', code: 3130606 },
    { city: 'Indaiabira', code: 3130655 },
    { city: 'Indianópolis', code: 3130705 },
    { city: 'Ingaí', code: 3130804 },
    { city: 'Inhapim', code: 3130903 },
    { city: 'Inhaúma', code: 3131000 },
    { city: 'Inimutaba', code: 3131109 },
    { city: 'Ipaba', code: 3131158 },
    { city: 'Ipanema', code: 3131208 },
    { city: 'Ipatinga', code: 3131307 },
    { city: 'Ipiaçu', code: 3131406 },
    { city: 'Ipuiúna', code: 3131505 },
    { city: 'Iraí de Minas', code: 3131604 },
    { city: 'Itabira', code: 3131703 },
    { city: 'Itabirinha', code: 3131802 },
    { city: 'Itabirito', code: 3131901 },
    { city: 'Itacambira', code: 3132008 },
    { city: 'Itacarambi', code: 3132107 },
    { city: 'Itaguara', code: 3132206 },
    { city: 'Itaipé', code: 3132305 },
    { city: 'Itajubá', code: 3132404 },
    { city: 'Itamarandiba', code: 3132503 },
    { city: 'Itamarati de Minas', code: 3132602 },
    { city: 'Itambacuri', code: 3132701 },
    { city: 'Itambé do Mato Dentro', code: 3132800 },
    { city: 'Itamogi', code: 3132909 },
    { city: 'Itamonte', code: 3133006 },
    { city: 'Itanhandu', code: 3133105 },
    { city: 'Itanhomi', code: 3133204 },
    { city: 'Itaobim', code: 3133303 },
    { city: 'Itapagipe', code: 3133402 },
    { city: 'Itapecerica', code: 3133501 },
    { city: 'Itapeva', code: 3133600 },
    { city: 'Itatiaiuçu', code: 3133709 },
    { city: 'Itaú de Minas', code: 3133758 },
    { city: 'Itaúna', code: 3133808 },
    { city: 'Itaverava', code: 3133907 },
    { city: 'Itinga', code: 3134004 },
    { city: 'Itueta', code: 3134103 },
    { city: 'Ituiutaba', code: 3134202 },
    { city: 'Itumirim', code: 3134301 },
    { city: 'Iturama', code: 3134400 },
    { city: 'Itutinga', code: 3134509 },
    { city: 'Jaboticatubas', code: 3134608 },
    { city: 'Jacinto', code: 3134707 },
    { city: 'Jacuí', code: 3134806 },
    { city: 'Jacutinga', code: 3134905 },
    { city: 'Jaguaraçu', code: 3135001 },
    { city: 'Jaíba', code: 3135050 },
    { city: 'Jampruca', code: 3135076 },
    { city: 'Janaúba', code: 3135100 },
    { city: 'Januária', code: 3135209 },
    { city: 'Japaraíba', code: 3135308 },
    { city: 'Japonvar', code: 3135357 },
    { city: 'Jeceaba', code: 3135407 },
    { city: 'Jenipapo de Minas', code: 3135456 },
    { city: 'Jequeri', code: 3135506 },
    { city: 'Jequitaí', code: 3135605 },
    { city: 'Jequitibá', code: 3135704 },
    { city: 'Jequitinhonha', code: 3135803 },
    { city: 'Jesuânia', code: 3135902 },
    { city: 'Joaíma', code: 3136009 },
    { city: 'Joanésia', code: 3136108 },
    { city: 'João Monlevade', code: 3136207 },
    { city: 'João Pinheiro', code: 3136306 },
    { city: 'Joaquim Felício', code: 3136405 },
    { city: 'Jordânia', code: 3136504 },
    { city: 'José Gonçalves de Minas', code: 3136520 },
    { city: 'José Raydan', code: 3136553 },
    { city: 'Josenópolis', code: 3136579 },
    { city: 'Juatuba', code: 3136652 },
    { city: 'Juiz de Fora', code: 3136702 },
    { city: 'Juramento', code: 3136801 },
    { city: 'Juruaia', code: 3136900 },
    { city: 'Juvenília', code: 3136959 },
    { city: 'Ladainha', code: 3137007 },
    { city: 'Lagamar', code: 3137106 },
    { city: 'Lagoa da Prata', code: 3137205 },
    { city: 'Lagoa dos Patos', code: 3137304 },
    { city: 'Lagoa Dourada', code: 3137403 },
    { city: 'Lagoa Formosa', code: 3137502 },
    { city: 'Lagoa Grande', code: 3137536 },
    { city: 'Lagoa Santa', code: 3137601 },
    { city: 'Lajinha', code: 3137700 },
    { city: 'Lambari', code: 3137809 },
    { city: 'Lamim', code: 3137908 },
    { city: 'Laranjal', code: 3138005 },
    { city: 'Lassance', code: 3138104 },
    { city: 'Lavras', code: 3138203 },
    { city: 'Leandro Ferreira', code: 3138302 },
    { city: 'Leme do Prado', code: 3138351 },
    { city: 'Leopoldina', code: 3138401 },
    { city: 'Liberdade', code: 3138500 },
    { city: 'Lima Duarte', code: 3138609 },
    { city: 'Limeira do Oeste', code: 3138625 },
    { city: 'Lontra', code: 3138658 },
    { city: 'Luisburgo', code: 3138674 },
    { city: 'Luislândia', code: 3138682 },
    { city: 'Luminárias', code: 3138708 },
    { city: 'Luz', code: 3138807 },
    { city: 'Machacalis', code: 3138906 },
    { city: 'Machado', code: 3139003 },
    { city: 'Madre de Deus de Minas', code: 3139102 },
    { city: 'Malacacheta', code: 3139201 },
    { city: 'Mamonas', code: 3139250 },
    { city: 'Manga', code: 3139300 },
    { city: 'Manhuaçu', code: 3139409 },
    { city: 'Manhumirim', code: 3139508 },
    { city: 'Mantena', code: 3139607 },
    { city: 'Mar de Espanha', code: 3139805 },
    { city: 'Maravilhas', code: 3139706 },
    { city: 'Maria da Fé', code: 3139904 },
    { city: 'Mariana', code: 3140001 },
    { city: 'Marilac', code: 3140100 },
    { city: 'Mário Campos', code: 3140159 },
    { city: 'Maripá de Minas', code: 3140209 },
    { city: 'Marliéria', code: 3140308 },
    { city: 'Marmelópolis', code: 3140407 },
    { city: 'Martinho Campos', code: 3140506 },
    { city: 'Martins Soares', code: 3140530 },
    { city: 'Mata Verde', code: 3140555 },
    { city: 'Materlândia', code: 3140605 },
    { city: 'Mateus Leme', code: 3140704 },
    { city: 'Mathias Lobato', code: 3171501 },
    { city: 'Matias Barbosa', code: 3140803 },
    { city: 'Matias Cardoso', code: 3140852 },
    { city: 'Matipó', code: 3140902 },
    { city: 'Mato Verde', code: 3141009 },
    { city: 'Matozinhos', code: 3141108 },
    { city: 'Matutina', code: 3141207 },
    { city: 'Medeiros', code: 3141306 },
    { city: 'Medina', code: 3141405 },
    { city: 'Mendes Pimentel', code: 3141504 },
    { city: 'Mercês', code: 3141603 },
    { city: 'Mesquita', code: 3141702 },
    { city: 'Minas Novas', code: 3141801 },
    { city: 'Minduri', code: 3141900 },
    { city: 'Mirabela', code: 3142007 },
    { city: 'Miradouro', code: 3142106 },
    { city: 'Miraí', code: 3142205 },
    { city: 'Miravânia', code: 3142254 },
    { city: 'Moeda', code: 3142304 },
    { city: 'Moema', code: 3142403 },
    { city: 'Monjolos', code: 3142502 },
    { city: 'Monsenhor Paulo', code: 3142601 },
    { city: 'Montalvânia', code: 3142700 },
    { city: 'Monte Alegre de Minas', code: 3142809 },
    { city: 'Monte Azul', code: 3142908 },
    { city: 'Monte Belo', code: 3143005 },
    { city: 'Monte Carmelo', code: 3143104 },
    { city: 'Monte Formoso', code: 3143153 },
    { city: 'Monte Santo de Minas', code: 3143203 },
    { city: 'Monte Sião', code: 3143401 },
    { city: 'Montes Claros', code: 3143302 },
    { city: 'Montezuma', code: 3143450 },
    { city: 'Morada Nova de Minas', code: 3143500 },
    { city: 'Morro da Garça', code: 3143609 },
    { city: 'Morro do Pilar', code: 3143708 },
    { city: 'Munhoz', code: 3143807 },
    { city: 'Muriaé', code: 3143906 },
    { city: 'Mutum', code: 3144003 },
    { city: 'Muzambinho', code: 3144102 },
    { city: 'Nacip Raydan', code: 3144201 },
    { city: 'Nanuque', code: 3144300 },
    { city: 'Naque', code: 3144359 },
    { city: 'Natalândia', code: 3144375 },
    { city: 'Natércia', code: 3144409 },
    { city: 'Nazareno', code: 3144508 },
    { city: 'Nepomuceno', code: 3144607 },
    { city: 'Ninheira', code: 3144656 },
    { city: 'Nova Belém', code: 3144672 },
    { city: 'Nova Era', code: 3144706 },
    { city: 'Nova Lima', code: 3144805 },
    { city: 'Nova Módica', code: 3144904 },
    { city: 'Nova Ponte', code: 3145000 },
    { city: 'Nova Porteirinha', code: 3145059 },
    { city: 'Nova Resende', code: 3145109 },
    { city: 'Nova Serrana', code: 3145208 },
    { city: 'Nova União', code: 3136603 },
    { city: 'Novo Cruzeiro', code: 3145307 },
    { city: 'Novo Oriente de Minas', code: 3145356 },
    { city: 'Novorizonte', code: 3145372 },
    { city: 'Olaria', code: 3145406 },
    { city: 'Olhos-d\'Água', code: 3145455 },
    { city: 'Olímpio Noronha', code: 3145505 },
    { city: 'Oliveira', code: 3145604 },
    { city: 'Oliveira Fortes', code: 3145703 },
    { city: 'Onça de Pitangui', code: 3145802 },
    { city: 'Oratórios', code: 3145851 },
    { city: 'Orizânia', code: 3145877 },
    { city: 'Ouro Branco', code: 3145901 },
    { city: 'Ouro Fino', code: 3146008 },
    { city: 'Ouro Preto', code: 3146107 },
    { city: 'Ouro Verde de Minas', code: 3146206 },
    { city: 'Padre Carvalho', code: 3146255 },
    { city: 'Padre Paraíso', code: 3146305 },
    { city: 'Pai Pedro', code: 3146552 },
    { city: 'Paineiras', code: 3146404 },
    { city: 'Pains', code: 3146503 },
    { city: 'Paiva', code: 3146602 },
    { city: 'Palma', code: 3146701 },
    { city: 'Palmópolis', code: 3146750 },
    { city: 'Papagaios', code: 3146909 },
    { city: 'Pará de Minas', code: 3147105 },
    { city: 'Paracatu', code: 3147006 },
    { city: 'Paraguaçu', code: 3147204 },
    { city: 'Paraisópolis', code: 3147303 },
    { city: 'Paraopeba', code: 3147402 },
    { city: 'Passa Quatro', code: 3147600 },
    { city: 'Passa Tempo', code: 3147709 },
    { city: 'Passa Vinte', code: 3147808 },
    { city: 'Passabém', code: 3147501 },
    { city: 'Passos', code: 3147907 },
    { city: 'Patis', code: 3147956 },
    { city: 'Patos de Minas', code: 3148004 },
    { city: 'Patrocínio', code: 3148103 },
    { city: 'Patrocínio do Muriaé', code: 3148202 },
    { city: 'Paula Cândido', code: 3148301 },
    { city: 'Paulistas', code: 3148400 },
    { city: 'Pavão', code: 3148509 },
    { city: 'Peçanha', code: 3148608 },
    { city: 'Pedra Azul', code: 3148707 },
    { city: 'Pedra Bonita', code: 3148756 },
    { city: 'Pedra do Anta', code: 3148806 },
    { city: 'Pedra do Indaiá', code: 3148905 },
    { city: 'Pedra Dourada', code: 3149002 },
    { city: 'Pedralva', code: 3149101 },
    { city: 'Pedras de Maria da Cruz', code: 3149150 },
    { city: 'Pedrinópolis', code: 3149200 },
    { city: 'Pedro Leopoldo', code: 3149309 },
    { city: 'Pedro Teixeira', code: 3149408 },
    { city: 'Pequeri', code: 3149507 },
    { city: 'Pequi', code: 3149606 },
    { city: 'Perdigão', code: 3149705 },
    { city: 'Perdizes', code: 3149804 },
    { city: 'Perdões', code: 3149903 },
    { city: 'Periquito', code: 3149952 },
    { city: 'Pescador', code: 3150000 },
    { city: 'Piau', code: 3150109 },
    { city: 'Piedade de Caratinga', code: 3150158 },
    { city: 'Piedade de Ponte Nova', code: 3150208 },
    { city: 'Piedade do Rio Grande', code: 3150307 },
    { city: 'Piedade dos Gerais', code: 3150406 },
    { city: 'Pimenta', code: 3150505 },
    { city: 'Pingo d\'Água', code: 3150539 },
    { city: 'Pintópolis', code: 3150570 },
    { city: 'Piracema', code: 3150604 },
    { city: 'Pirajuba', code: 3150703 },
    { city: 'Piranga', code: 3150802 },
    { city: 'Piranguçu', code: 3150901 },
    { city: 'Piranguinho', code: 3151008 },
    { city: 'Pirapetinga', code: 3151107 },
    { city: 'Pirapora', code: 3151206 },
    { city: 'Piraúba', code: 3151305 },
    { city: 'Pitangui', code: 3151404 },
    { city: 'Piumhi', code: 3151503 },
    { city: 'Planura', code: 3151602 },
    { city: 'Poço Fundo', code: 3151701 },
    { city: 'Poços de Caldas', code: 3151800 },
    { city: 'Pocrane', code: 3151909 },
    { city: 'Pompéu', code: 3152006 },
    { city: 'Ponte Nova', code: 3152105 },
    { city: 'Ponto Chique', code: 3152131 },
    { city: 'Ponto dos Volantes', code: 3152170 },
    { city: 'Porteirinha', code: 3152204 },
    { city: 'Porto Firme', code: 3152303 },
    { city: 'Poté', code: 3152402 },
    { city: 'Pouso Alegre', code: 3152501 },
    { city: 'Pouso Alto', code: 3152600 },
    { city: 'Prados', code: 3152709 },
    { city: 'Prata', code: 3152808 },
    { city: 'Pratápolis', code: 3152907 },
    { city: 'Pratinha', code: 3153004 },
    { city: 'Presidente Bernardes', code: 3153103 },
    { city: 'Presidente Juscelino', code: 3153202 },
    { city: 'Presidente Kubitschek', code: 3153301 },
    { city: 'Presidente Olegário', code: 3153400 },
    { city: 'Prudente de Morais', code: 3153608 },
    { city: 'Quartel Geral', code: 3153707 },
    { city: 'Queluzito', code: 3153806 },
    { city: 'Raposos', code: 3153905 },
    { city: 'Raul Soares', code: 3154002 },
    { city: 'Recreio', code: 3154101 },
    { city: 'Reduto', code: 3154150 },
    { city: 'Resende Costa', code: 3154200 },
    { city: 'Resplendor', code: 3154309 },
    { city: 'Ressaquinha', code: 3154408 },
    { city: 'Riachinho', code: 3154457 },
    { city: 'Riacho dos Machados', code: 3154507 },
    { city: 'Ribeirão das Neves', code: 3154606 },
    { city: 'Ribeirão Vermelho', code: 3154705 },
    { city: 'Rio Acima', code: 3154804 },
    { city: 'Rio Casca', code: 3154903 },
    { city: 'Rio do Prado', code: 3155108 },
    { city: 'Rio Doce', code: 3155009 },
    { city: 'Rio Espera', code: 3155207 },
    { city: 'Rio Manso', code: 3155306 },
    { city: 'Rio Novo', code: 3155405 },
    { city: 'Rio Paranaíba', code: 3155504 },
    { city: 'Rio Pardo de Minas', code: 3155603 },
    { city: 'Rio Piracicaba', code: 3155702 },
    { city: 'Rio Pomba', code: 3155801 },
    { city: 'Rio Preto', code: 3155900 },
    { city: 'Rio Vermelho', code: 3156007 },
    { city: 'Ritápolis', code: 3156106 },
    { city: 'Rochedo de Minas', code: 3156205 },
    { city: 'Rodeiro', code: 3156304 },
    { city: 'Romaria', code: 3156403 },
    { city: 'Rosário da Limeira', code: 3156452 },
    { city: 'Rubelita', code: 3156502 },
    { city: 'Rubim', code: 3156601 },
    { city: 'Sabará', code: 3156700 },
    { city: 'Sabinópolis', code: 3156809 },
    { city: 'Sacramento', code: 3156908 },
    { city: 'Salinas', code: 3157005 },
    { city: 'Salto da Divisa', code: 3157104 },
    { city: 'Santa Bárbara', code: 3157203 },
    { city: 'Santa Bárbara do Leste', code: 3157252 },
    { city: 'Santa Bárbara do Monte Verde', code: 3157278 },
    { city: 'Santa Bárbara do Tugúrio', code: 3157302 },
    { city: 'Santa Cruz de Minas', code: 3157336 },
    { city: 'Santa Cruz de Salinas', code: 3157377 },
    { city: 'Santa Cruz do Escalvado', code: 3157401 },
    { city: 'Santa Efigênia de Minas', code: 3157500 },
    { city: 'Santa Fé de Minas', code: 3157609 },
    { city: 'Santa Helena de Minas', code: 3157658 },
    { city: 'Santa Juliana', code: 3157708 },
    { city: 'Santa Luzia', code: 3157807 },
    { city: 'Santa Margarida', code: 3157906 },
    { city: 'Santa Maria de Itabira', code: 3158003 },
    { city: 'Santa Maria do Salto', code: 3158102 },
    { city: 'Santa Maria do Suaçuí', code: 3158201 },
    { city: 'Santa Rita de Caldas', code: 3159209 },
    { city: 'Santa Rita de Ibitipoca', code: 3159407 },
    { city: 'Santa Rita de Jacutinga', code: 3159308 },
    { city: 'Santa Rita de Minas', code: 3159357 },
    { city: 'Santa Rita do Itueto', code: 3159506 },
    { city: 'Santa Rita do Sapucaí', code: 3159605 },
    { city: 'Santa Rosa da Serra', code: 3159704 },
    { city: 'Santa Vitória', code: 3159803 },
    { city: 'Santana da Vargem', code: 3158300 },
    { city: 'Santana de Cataguases', code: 3158409 },
    { city: 'Santana de Pirapama', code: 3158508 },
    { city: 'Santana do Deserto', code: 3158607 },
    { city: 'Santana do Garambéu', code: 3158706 },
    { city: 'Santana do Jacaré', code: 3158805 },
    { city: 'Santana do Manhuaçu', code: 3158904 },
    { city: 'Santana do Paraíso', code: 3158953 },
    { city: 'Santana do Riacho', code: 3159001 },
    { city: 'Santana dos Montes', code: 3159100 },
    { city: 'Santo Antônio do Amparo', code: 3159902 },
    { city: 'Santo Antônio do Aventureiro', code: 3160009 },
    { city: 'Santo Antônio do Grama', code: 3160108 },
    { city: 'Santo Antônio do Itambé', code: 3160207 },
    { city: 'Santo Antônio do Jacinto', code: 3160306 },
    { city: 'Santo Antônio do Monte', code: 3160405 },
    { city: 'Santo Antônio do Retiro', code: 3160454 },
    { city: 'Santo Antônio do Rio Abaixo', code: 3160504 },
    { city: 'Santo Hipólito', code: 3160603 },
    { city: 'Santos Dumont', code: 3160702 },
    { city: 'São Bento Abade', code: 3160801 },
    { city: 'São Brás do Suaçuí', code: 3160900 },
    { city: 'São Domingos das Dores', code: 3160959 },
    { city: 'São Domingos do Prata', code: 3161007 },
    { city: 'São Félix de Minas', code: 3161056 },
    { city: 'São Francisco', code: 3161106 },
    { city: 'São Francisco de Paula', code: 3161205 },
    { city: 'São Francisco de Sales', code: 3161304 },
    { city: 'São Francisco do Glória', code: 3161403 },
    { city: 'São Geraldo', code: 3161502 },
    { city: 'São Geraldo da Piedade', code: 3161601 },
    { city: 'São Geraldo do Baixio', code: 3161650 },
    { city: 'São Gonçalo do Abaeté', code: 3161700 },
    { city: 'São Gonçalo do Pará', code: 3161809 },
    { city: 'São Gonçalo do Rio Abaixo', code: 3161908 },
    { city: 'São Gonçalo do Rio Preto', code: 3125507 },
    { city: 'São Gonçalo do Sapucaí', code: 3162005 },
    { city: 'São Gotardo', code: 3162104 },
    { city: 'São João Batista do Glória', code: 3162203 },
    { city: 'São João da Lagoa', code: 3162252 },
    { city: 'São João da Mata', code: 3162302 },
    { city: 'São João da Ponte', code: 3162401 },
    { city: 'São João das Missões', code: 3162450 },
    { city: 'São João del Rei', code: 3162500 },
    { city: 'São João do Manhuaçu', code: 3162559 },
    { city: 'São João do Manteninha', code: 3162575 },
    { city: 'São João do Oriente', code: 3162609 },
    { city: 'São João do Pacuí', code: 3162658 },
    { city: 'São João do Paraíso', code: 3162708 },
    { city: 'São João Evangelista', code: 3162807 },
    { city: 'São João Nepomuceno', code: 3162906 },
    { city: 'São Joaquim de Bicas', code: 3162922 },
    { city: 'São José da Barra', code: 3162948 },
    { city: 'São José da Lapa', code: 3162955 },
    { city: 'São José da Safira', code: 3163003 },
    { city: 'São José da Varginha', code: 3163102 },
    { city: 'São José do Alegre', code: 3163201 },
    { city: 'São José do Divino', code: 3163300 },
    { city: 'São José do Goiabal', code: 3163409 },
    { city: 'São José do Jacuri', code: 3163508 },
    { city: 'São José do Mantimento', code: 3163607 },
    { city: 'São Lourenço', code: 3163706 },
    { city: 'São Miguel do Anta', code: 3163805 },
    { city: 'São Pedro da União', code: 3163904 },
    { city: 'São Pedro do Suaçuí', code: 3164100 },
    { city: 'São Pedro dos Ferros', code: 3164001 },
    { city: 'São Romão', code: 3164209 },
    { city: 'São Roque de Minas', code: 3164308 },
    { city: 'São Sebastião da Bela Vista', code: 3164407 },
    { city: 'São Sebastião da Vargem Alegre', code: 3164431 },
    { city: 'São Sebastião do Anta', code: 3164472 },
    { city: 'São Sebastião do Maranhão', code: 3164506 },
    { city: 'São Sebastião do Oeste', code: 3164605 },
    { city: 'São Sebastião do Paraíso', code: 3164704 },
    { city: 'São Sebastião do Rio Preto', code: 3164803 },
    { city: 'São Sebastião do Rio Verde', code: 3164902 },
    { city: 'São Thomé das Letras', code: 3165206 },
    { city: 'São Tiago', code: 3165008 },
    { city: 'São Tomás de Aquino', code: 3165107 },
    { city: 'São Vicente de Minas', code: 3165305 },
    { city: 'Sapucaí-Mirim', code: 3165404 },
    { city: 'Sardoá', code: 3165503 },
    { city: 'Sarzedo', code: 3165537 },
    { city: 'Sem-Peixe', code: 3165560 },
    { city: 'Senador Amaral', code: 3165578 },
    { city: 'Senador Cortes', code: 3165602 },
    { city: 'Senador Firmino', code: 3165701 },
    { city: 'Senador José Bento', code: 3165800 },
    { city: 'Senador Modestino Gonçalves', code: 3165909 },
    { city: 'Senhora de Oliveira', code: 3166006 },
    { city: 'Senhora do Porto', code: 3166105 },
    { city: 'Senhora dos Remédios', code: 3166204 },
    { city: 'Sericita', code: 3166303 },
    { city: 'Seritinga', code: 3166402 },
    { city: 'Serra Azul de Minas', code: 3166501 },
    { city: 'Serra da Saudade', code: 3166600 },
    { city: 'Serra do Salitre', code: 3166808 },
    { city: 'Serra dos Aimorés', code: 3166709 },
    { city: 'Serrania', code: 3166907 },
    { city: 'Serranópolis de Minas', code: 3166956 },
    { city: 'Serranos', code: 3167004 },
    { city: 'Serro', code: 3167103 },
    { city: 'Sete Lagoas', code: 3167202 },
    { city: 'Setubinha', code: 3165552 },
    { city: 'Silveirânia', code: 3167301 },
    { city: 'Silvianópolis', code: 3167400 },
    { city: 'Simão Pereira', code: 3167509 },
    { city: 'Simonésia', code: 3167608 },
    { city: 'Sobrália', code: 3167707 },
    { city: 'Soledade de Minas', code: 3167806 },
    { city: 'Tabuleiro', code: 3167905 },
    { city: 'Taiobeiras', code: 3168002 },
    { city: 'Taparuba', code: 3168051 },
    { city: 'Tapira', code: 3168101 },
    { city: 'Tapiraí', code: 3168200 },
    { city: 'Taquaraçu de Minas', code: 3168309 },
    { city: 'Tarumirim', code: 3168408 },
    { city: 'Teixeiras', code: 3168507 },
    { city: 'Teófilo Otoni', code: 3168606 },
    { city: 'Timóteo', code: 3168705 },
    { city: 'Tiradentes', code: 3168804 },
    { city: 'Tiros', code: 3168903 },
    { city: 'Tocantins', code: 3169000 },
    { city: 'Tocos do Moji', code: 3169059 },
    { city: 'Toledo', code: 3169109 },
    { city: 'Tombos', code: 3169208 },
    { city: 'Três Corações', code: 3169307 },
    { city: 'Três Marias', code: 3169356 },
    { city: 'Três Pontas', code: 3169406 },
    { city: 'Tumiritinga', code: 3169505 },
    { city: 'Tupaciguara', code: 3169604 },
    { city: 'Turmalina', code: 3169703 },
    { city: 'Turvolândia', code: 3169802 },
    { city: 'Ubá', code: 3169901 },
    { city: 'Ubaí', code: 3170008 },
    { city: 'Ubaporanga', code: 3170057 },
    { city: 'Uberaba', code: 3170107 },
    { city: 'Uberlândia', code: 3170206 },
    { city: 'Umburatiba', code: 3170305 },
    { city: 'Unaí', code: 3170404 },
    { city: 'União de Minas', code: 3170438 },
    { city: 'Uruana de Minas', code: 3170479 },
    { city: 'Urucânia', code: 3170503 },
    { city: 'Urucuia', code: 3170529 },
    { city: 'Vargem Alegre', code: 3170578 },
    { city: 'Vargem Bonita', code: 3170602 },
    { city: 'Vargem Grande do Rio Pardo', code: 3170651 },
    { city: 'Varginha', code: 3170701 },
    { city: 'Varjão de Minas', code: 3170750 },
    { city: 'Várzea da Palma', code: 3170800 },
    { city: 'Varzelândia', code: 3170909 },
    { city: 'Vazante', code: 3171006 },
    { city: 'Verdelândia', code: 3171030 },
    { city: 'Veredinha', code: 3171071 },
    { city: 'Veríssimo', code: 3171105 },
    { city: 'Vermelho Novo', code: 3171154 },
    { city: 'Vespasiano', code: 3171204 },
    { city: 'Viçosa', code: 3171303 },
    { city: 'Vieiras', code: 3171402 },
    { city: 'Virgem da Lapa', code: 3171600 },
    { city: 'Virgínia', code: 3171709 },
    { city: 'Virginópolis', code: 3171808 },
    { city: 'Virgolândia', code: 3171907 },
    { city: 'Visconde do Rio Branco', code: 3172004 },
    { city: 'Volta Grande', code: 3172103 },
    { city: 'Wenceslau Braz', code: 3172202 },
  ],
  MS: [
    { city: 'Água Clara', code: 5000203 },
    { city: 'Alcinópolis', code: 5000252 },
    { city: 'Amambai', code: 5000609 },
    { city: 'Anastácio', code: 5000708 },
    { city: 'Anaurilândia', code: 5000807 },
    { city: 'Angélica', code: 5000856 },
    { city: 'Antônio João', code: 5000906 },
    { city: 'Aparecida do Taboado', code: 5001003 },
    { city: 'Aquidauana', code: 5001102 },
    { city: 'Aral Moreira', code: 5001243 },
    { city: 'Bandeirantes', code: 5001508 },
    { city: 'Bataguassu', code: 5001904 },
    { city: 'Batayporã', code: 5002001 },
    { city: 'Bela Vista', code: 5002100 },
    { city: 'Bodoquena', code: 5002159 },
    { city: 'Bonito', code: 5002209 },
    { city: 'Brasilândia', code: 5002308 },
    { city: 'Caarapó', code: 5002407 },
    { city: 'Camapuã', code: 5002605 },
    { city: 'Campo Grande', code: 5002704 },
    { city: 'Caracol', code: 5002803 },
    { city: 'Cassilândia', code: 5002902 },
    { city: 'Chapadão do Sul', code: 5002951 },
    { city: 'Corguinho', code: 5003108 },
    { city: 'Coronel Sapucaia', code: 5003157 },
    { city: 'Corumbá', code: 5003207 },
    { city: 'Costa Rica', code: 5003256 },
    { city: 'Coxim', code: 5003306 },
    { city: 'Deodápolis', code: 5003454 },
    { city: 'Dois Irmãos do Buriti', code: 5003488 },
    { city: 'Douradina', code: 5003504 },
    { city: 'Dourados', code: 5003702 },
    { city: 'Eldorado', code: 5003751 },
    { city: 'Fátima do Sul', code: 5003801 },
    { city: 'Figueirão', code: 5003900 },
    { city: 'Glória de Dourados', code: 5004007 },
    { city: 'Guia Lopes da Laguna', code: 5004106 },
    { city: 'Iguatemi', code: 5004304 },
    { city: 'Inocência', code: 5004403 },
    { city: 'Itaporã', code: 5004502 },
    { city: 'Itaquiraí', code: 5004601 },
    { city: 'Ivinhema', code: 5004700 },
    { city: 'Japorã', code: 5004809 },
    { city: 'Jaraguari', code: 5004908 },
    { city: 'Jardim', code: 5005004 },
    { city: 'Jateí', code: 5005103 },
    { city: 'Juti', code: 5005152 },
    { city: 'Ladário', code: 5005202 },
    { city: 'Laguna Carapã', code: 5005251 },
    { city: 'Maracaju', code: 5005400 },
    { city: 'Miranda', code: 5005608 },
    { city: 'Mundo Novo', code: 5005681 },
    { city: 'Naviraí', code: 5005707 },
    { city: 'Nioaque', code: 5005806 },
    { city: 'Nova Alvorada do Sul', code: 5006002 },
    { city: 'Nova Andradina', code: 5006200 },
    { city: 'Novo Horizonte do Sul', code: 5006259 },
    { city: 'Paraíso das Águas', code: 5006275 },
    { city: 'Paranaíba', code: 5006309 },
    { city: 'Paranhos', code: 5006358 },
    { city: 'Pedro Gomes', code: 5006408 },
    { city: 'Ponta Porã', code: 5006606 },
    { city: 'Porto Murtinho', code: 5006903 },
    { city: 'Ribas do Rio Pardo', code: 5007109 },
    { city: 'Rio Brilhante', code: 5007208 },
    { city: 'Rio Negro', code: 5007307 },
    { city: 'Rio Verde de Mato Grosso', code: 5007406 },
    { city: 'Rochedo', code: 5007505 },
    { city: 'Santa Rita do Pardo', code: 5007554 },
    { city: 'São Gabriel do Oeste', code: 5007695 },
    { city: 'Selvíria', code: 5007802 },
    { city: 'Sete Quedas', code: 5007703 },
    { city: 'Sidrolândia', code: 5007901 },
    { city: 'Sonora', code: 5007935 },
    { city: 'Tacuru', code: 5007950 },
    { city: 'Taquarussu', code: 5007976 },
    { city: 'Terenos', code: 5008008 },
    { city: 'Três Lagoas', code: 5008305 },
    { city: 'Vicentina', code: 5008404 },
  ],
  MT: [
    { city: 'Acorizal', code: 5100102 },
    { city: 'Água Boa', code: 5100201 },
    { city: 'Alta Floresta', code: 5100250 },
    { city: 'Alto Araguaia', code: 5100300 },
    { city: 'Alto Boa Vista', code: 5100359 },
    { city: 'Alto Garças', code: 5100409 },
    { city: 'Alto Paraguai', code: 5100508 },
    { city: 'Alto Taquari', code: 5100607 },
    { city: 'Apiacás', code: 5100805 },
    { city: 'Araguaiana', code: 5101001 },
    { city: 'Araguainha', code: 5101209 },
    { city: 'Araputanga', code: 5101258 },
    { city: 'Arenápolis', code: 5101308 },
    { city: 'Aripuanã', code: 5101407 },
    { city: 'Barão de Melgaço', code: 5101605 },
    { city: 'Barra do Bugres', code: 5101704 },
    { city: 'Barra do Garças', code: 5101803 },
    { city: 'Bom Jesus do Araguaia', code: 5101852 },
    { city: 'Brasnorte', code: 5101902 },
    { city: 'Cáceres', code: 5102504 },
    { city: 'Campinápolis', code: 5102603 },
    { city: 'Campo Novo do Parecis', code: 5102637 },
    { city: 'Campo Verde', code: 5102678 },
    { city: 'Campos de Júlio', code: 5102686 },
    { city: 'Canabrava do Norte', code: 5102694 },
    { city: 'Canarana', code: 5102702 },
    { city: 'Carlinda', code: 5102793 },
    { city: 'Castanheira', code: 5102850 },
    { city: 'Chapada dos Guimarães', code: 5103007 },
    { city: 'Cláudia', code: 5103056 },
    { city: 'Cocalinho', code: 5103106 },
    { city: 'Colíder', code: 5103205 },
    { city: 'Colniza', code: 5103254 },
    { city: 'Comodoro', code: 5103304 },
    { city: 'Confresa', code: 5103353 },
    { city: 'Conquista D\'Oeste', code: 5103361 },
    { city: 'Cotriguaçu', code: 5103379 },
    { city: 'Cuiabá', code: 5103403 },
    { city: 'Curvelândia', code: 5103437 },
    { city: 'Denise', code: 5103452 },
    { city: 'Diamantino', code: 5103502 },
    { city: 'Dom Aquino', code: 5103601 },
    { city: 'Feliz Natal', code: 5103700 },
    { city: 'Figueirópolis D\'Oeste', code: 5103809 },
    { city: 'Gaúcha do Norte', code: 5103858 },
    { city: 'General Carneiro', code: 5103908 },
    { city: 'Glória D\'Oeste', code: 5103957 },
    { city: 'Guarantã do Norte', code: 5104104 },
    { city: 'Guiratinga', code: 5104203 },
    { city: 'Indiavaí', code: 5104500 },
    { city: 'Ipiranga do Norte', code: 5104526 },
    { city: 'Itanhangá', code: 5104542 },
    { city: 'Itaúba', code: 5104559 },
    { city: 'Itiquira', code: 5104609 },
    { city: 'Jaciara', code: 5104807 },
    { city: 'Jangada', code: 5104906 },
    { city: 'Jauru', code: 5105002 },
    { city: 'Juara', code: 5105101 },
    { city: 'Juína', code: 5105150 },
    { city: 'Juruena', code: 5105176 },
    { city: 'Juscimeira', code: 5105200 },
    { city: 'Lambari D\'Oeste', code: 5105234 },
    { city: 'Lucas do Rio Verde', code: 5105259 },
    { city: 'Luciara', code: 5105309 },
    { city: 'Marcelândia', code: 5105580 },
    { city: 'Matupá', code: 5105606 },
    { city: 'Mirassol d\'Oeste', code: 5105622 },
    { city: 'Nobres', code: 5105903 },
    { city: 'Nortelandia', code: 5106000 },
    { city: 'Nossa Senhora do Livramento', code: 5106109 },
    { city: 'Nova Bandeirantes', code: 5106158 },
    { city: 'Nova Brasilândia', code: 5106208 },
    { city: 'Nova Canaã do Norte', code: 5106216 },
    { city: 'Nova Guarita', code: 5108808 },
    { city: 'Nova Lacerda', code: 5106182 },
    { city: 'Nova Marilândia', code: 5108857 },
    { city: 'Nova Maringá', code: 5108907 },
    { city: 'Nova Monte Verde', code: 5108956 },
    { city: 'Nova Mutum', code: 5106224 },
    { city: 'Nova Nazaré', code: 5106174 },
    { city: 'Nova Olímpia', code: 5106232 },
    { city: 'Nova Santa Helena', code: 5106190 },
    { city: 'Nova Ubiratã', code: 5106240 },
    { city: 'Nova Xavantina', code: 5106257 },
    { city: 'Novo Horizonte do Norte', code: 5106273 },
    { city: 'Novo Mundo', code: 5106265 },
    { city: 'Novo Santo Antônio', code: 5106315 },
    { city: 'Novo São Joaquim', code: 5106281 },
    { city: 'Paranaíta', code: 5106299 },
    { city: 'Paranatinga', code: 5106307 },
    { city: 'Pedra Preta', code: 5106372 },
    { city: 'Peixoto de Azevedo', code: 5106422 },
    { city: 'Planalto da Serra', code: 5106455 },
    { city: 'Poconé', code: 5106505 },
    { city: 'Pontal do Araguaia', code: 5106653 },
    { city: 'Ponte Branca', code: 5106703 },
    { city: 'Pontes e Lacerda', code: 5106752 },
    { city: 'Porto Alegre do Norte', code: 5106778 },
    { city: 'Porto dos Gaúchos', code: 5106802 },
    { city: 'Porto Esperidião', code: 5106828 },
    { city: 'Porto Estrela', code: 5106851 },
    { city: 'Poxoréu', code: 5107008 },
    { city: 'Primavera do Leste', code: 5107040 },
    { city: 'Querência', code: 5107065 },
    { city: 'Reserva do Cabaçal', code: 5107156 },
    { city: 'Ribeirão Cascalheira', code: 5107180 },
    { city: 'Ribeirãozinho', code: 5107198 },
    { city: 'Rio Branco', code: 5107206 },
    { city: 'Rondolândia', code: 5107578 },
    { city: 'Rondonópolis', code: 5107602 },
    { city: 'Rosário Oeste', code: 5107701 },
    { city: 'Salto do Céu', code: 5107750 },
    { city: 'Santa Carmem', code: 5107248 },
    { city: 'Santa Cruz do Xingu', code: 5107743 },
    { city: 'Santa Rita do Trivelato', code: 5107768 },
    { city: 'Santa Terezinha', code: 5107776 },
    { city: 'Santo Afonso', code: 5107263 },
    { city: 'Santo Antônio do Leste', code: 5107792 },
    { city: 'Santo Antônio do Leverger', code: 5107800 },
    { city: 'São Félix do Araguaia', code: 5107859 },
    { city: 'São José do Povo', code: 5107297 },
    { city: 'São José do Rio Claro', code: 5107305 },
    { city: 'São José do Xingu', code: 5107354 },
    { city: 'São José dos Quatro Marcos', code: 5107107 },
    { city: 'São Pedro da Cipa', code: 5107404 },
    { city: 'Sapezal', code: 5107875 },
    { city: 'Serra Nova Dourada', code: 5107883 },
    { city: 'Sinop', code: 5107909 },
    { city: 'Sorriso', code: 5107925 },
    { city: 'Tabaporã', code: 5107941 },
    { city: 'Tangará da Serra', code: 5107958 },
    { city: 'Tapurah', code: 5108006 },
    { city: 'Terra Nova do Norte', code: 5108055 },
    { city: 'Tesouro', code: 5108105 },
    { city: 'Torixoréu', code: 5108204 },
    { city: 'União do Sul', code: 5108303 },
    { city: 'Vale de São Domingos', code: 5108352 },
    { city: 'Várzea Grande', code: 5108402 },
    { city: 'Vera', code: 5108501 },
    { city: 'Vila Bela da Santíssima Trindade', code: 5105507 },
    { city: 'Vila Rica', code: 5108600 },
  ],
  PA: [
    { city: 'Abaetetuba', code: 1500107 },
    { city: 'Abel Figueiredo', code: 1500131 },
    { city: 'Acará', code: 1500206 },
    { city: 'Afuá', code: 1500305 },
    { city: 'Água Azul do Norte', code: 1500347 },
    { city: 'Alenquer', code: 1500404 },
    { city: 'Almeirim', code: 1500503 },
    { city: 'Altamira', code: 1500602 },
    { city: 'Anajás', code: 1500701 },
    { city: 'Ananindeua', code: 1500800 },
    { city: 'Anapu', code: 1500859 },
    { city: 'Augusto Corrêa', code: 1500909 },
    { city: 'Aurora do Pará', code: 1500958 },
    { city: 'Aveiro', code: 1501006 },
    { city: 'Bagre', code: 1501105 },
    { city: 'Baião', code: 1501204 },
    { city: 'Bannach', code: 1501253 },
    { city: 'Barcarena', code: 1501303 },
    { city: 'Belém', code: 1501402 },
    { city: 'Belterra', code: 1501451 },
    { city: 'Benevides', code: 1501501 },
    { city: 'Bom Jesus do Tocantins', code: 1501576 },
    { city: 'Bonito', code: 1501600 },
    { city: 'Bragança', code: 1501709 },
    { city: 'Brasil Novo', code: 1501725 },
    { city: 'Brejo Grande do Araguaia', code: 1501758 },
    { city: 'Breu Branco', code: 1501782 },
    { city: 'Breves', code: 1501808 },
    { city: 'Bujaru', code: 1501907 },
    { city: 'Cachoeira do Arari', code: 1502004 },
    { city: 'Cachoeira do Piriá', code: 1501956 },
    { city: 'Cametá', code: 1502103 },
    { city: 'Canaã dos Carajás', code: 1502152 },
    { city: 'Capanema', code: 1502202 },
    { city: 'Capitão Poço', code: 1502301 },
    { city: 'Castanhal', code: 1502400 },
    { city: 'Chaves', code: 1502509 },
    { city: 'Colares', code: 1502608 },
    { city: 'Conceição do Araguaia', code: 1502707 },
    { city: 'Concórdia do Pará', code: 1502756 },
    { city: 'Cumaru do Norte', code: 1502764 },
    { city: 'Curionópolis', code: 1502772 },
    { city: 'Curralinho', code: 1502806 },
    { city: 'Curuá', code: 1502855 },
    { city: 'Curuçá', code: 1502905 },
    { city: 'Dom Eliseu', code: 1502939 },
    { city: 'Eldorado do Carajás', code: 1502954 },
    { city: 'Faro', code: 1503002 },
    { city: 'Floresta do Araguaia', code: 1503044 },
    { city: 'Garrafão do Norte', code: 1503077 },
    { city: 'Goianésia do Pará', code: 1503093 },
    { city: 'Gurupá', code: 1503101 },
    { city: 'Igarapé-Açu', code: 1503200 },
    { city: 'Igarapé-Miri', code: 1503309 },
    { city: 'Inhangapi', code: 1503408 },
    { city: 'Ipixuna do Pará', code: 1503457 },
    { city: 'Irituia', code: 1503507 },
    { city: 'Itaituba', code: 1503606 },
    { city: 'Itupiranga', code: 1503705 },
    { city: 'Jacareacanga', code: 1503754 },
    { city: 'Jacundá', code: 1503804 },
    { city: 'Juruti', code: 1503903 },
    { city: 'Limoeiro do Ajuru', code: 1504000 },
    { city: 'Mãe do Rio', code: 1504059 },
    { city: 'Magalhães Barata', code: 1504109 },
    { city: 'Marabá', code: 1504208 },
    { city: 'Maracanã', code: 1504307 },
    { city: 'Marapanim', code: 1504406 },
    { city: 'Marituba', code: 1504422 },
    { city: 'Medicilândia', code: 1504455 },
    { city: 'Melgaço', code: 1504505 },
    { city: 'Mocajuba', code: 1504604 },
    { city: 'Moju', code: 1504703 },
    { city: 'Mojuí dos Campos', code: 1504752 },
    { city: 'Monte Alegre', code: 1504802 },
    { city: 'Muaná', code: 1504901 },
    { city: 'Nova Esperança do Piriá', code: 1504950 },
    { city: 'Nova Ipixuna', code: 1504976 },
    { city: 'Nova Timboteua', code: 1505007 },
    { city: 'Novo Progresso', code: 1505031 },
    { city: 'Novo Repartimento', code: 1505064 },
    { city: 'Óbidos', code: 1505106 },
    { city: 'Oeiras do Pará', code: 1505205 },
    { city: 'Oriximiná', code: 1505304 },
    { city: 'Ourém', code: 1505403 },
    { city: 'Ourilândia do Norte', code: 1505437 },
    { city: 'Pacajá', code: 1505486 },
    { city: 'Palestina do Pará', code: 1505494 },
    { city: 'Paragominas', code: 1505502 },
    { city: 'Parauapebas', code: 1505536 },
    { city: 'Pau D\'Arco', code: 1505551 },
    { city: 'Peixe-Boi', code: 1505601 },
    { city: 'Piçarra', code: 1505635 },
    { city: 'Placas', code: 1505650 },
    { city: 'Ponta de Pedras', code: 1505700 },
    { city: 'Portel', code: 1505809 },
    { city: 'Porto de Moz', code: 1505908 },
    { city: 'Prainha', code: 1506005 },
    { city: 'Primavera', code: 1506104 },
    { city: 'Quatipuru', code: 1506112 },
    { city: 'Redenção', code: 1506138 },
    { city: 'Rio Maria', code: 1506161 },
    { city: 'Rondon do Pará', code: 1506187 },
    { city: 'Rurópolis', code: 1506195 },
    { city: 'Salinópolis', code: 1506203 },
    { city: 'Salvaterra', code: 1506302 },
    { city: 'Santa Bárbara do Pará', code: 1506351 },
    { city: 'Santa Cruz do Arari', code: 1506401 },
    { city: 'Santa Izabel do Pará', code: 1506500 },
    { city: 'Santa Luzia do Pará', code: 1506559 },
    { city: 'Santa Maria das Barreiras', code: 1506583 },
    { city: 'Santa Maria do Pará', code: 1506609 },
    { city: 'Santana do Araguaia', code: 1506708 },
    { city: 'Santarém', code: 1506807 },
    { city: 'Santarém Novo', code: 1506906 },
    { city: 'Santo Antônio do Tauá', code: 1507003 },
    { city: 'São Caetano de Odivelas', code: 1507102 },
    { city: 'São Domingos do Araguaia', code: 1507151 },
    { city: 'São Domingos do Capim', code: 1507201 },
    { city: 'São Félix do Xingu', code: 1507300 },
    { city: 'São Francisco do Pará', code: 1507409 },
    { city: 'São Geraldo do Araguaia', code: 1507458 },
    { city: 'São João da Ponta', code: 1507466 },
    { city: 'São João de Pirabas', code: 1507474 },
    { city: 'São João do Araguaia', code: 1507508 },
    { city: 'São Miguel do Guamá', code: 1507607 },
    { city: 'São Sebastião da Boa Vista', code: 1507706 },
    { city: 'Sapucaia', code: 1507755 },
    { city: 'Senador José Porfírio', code: 1507805 },
    { city: 'Soure', code: 1507904 },
    { city: 'Tailândia', code: 1507953 },
    { city: 'Terra Alta', code: 1507961 },
    { city: 'Terra Santa', code: 1507979 },
    { city: 'Tomé-Açu', code: 1508001 },
    { city: 'Tracuateua', code: 1508035 },
    { city: 'Trairão', code: 1508050 },
    { city: 'Tucumã', code: 1508084 },
    { city: 'Tucuruí', code: 1508100 },
    { city: 'Ulianópolis', code: 1508126 },
    { city: 'Uruará', code: 1508159 },
    { city: 'Vigia', code: 1508209 },
    { city: 'Viseu', code: 1508308 },
    { city: 'Vitória do Xingu', code: 1508357 },
    { city: 'Xinguara', code: 1508407 },
  ],
  PB: [
    { city: 'Água Branca', code: 2500106 },
    { city: 'Aguiar', code: 2500205 },
    { city: 'Alagoa Grande', code: 2500304 },
    { city: 'Alagoa Nova', code: 2500403 },
    { city: 'Alagoinha', code: 2500502 },
    { city: 'Alcantil', code: 2500536 },
    { city: 'Algodão de Jandaíra', code: 2500577 },
    { city: 'Alhandra', code: 2500601 },
    { city: 'Amparo', code: 2500734 },
    { city: 'Aparecida', code: 2500775 },
    { city: 'Araçagi', code: 2500809 },
    { city: 'Arara', code: 2500908 },
    { city: 'Araruna', code: 2501005 },
    { city: 'Areia', code: 2501104 },
    { city: 'Areia de Baraúnas', code: 2501153 },
    { city: 'Areial', code: 2501203 },
    { city: 'Aroeiras', code: 2501302 },
    { city: 'Assunção', code: 2501351 },
    { city: 'Baía da Traição', code: 2501401 },
    { city: 'Bananeiras', code: 2501500 },
    { city: 'Baraúna', code: 2501534 },
    { city: 'Barra de Santa Rosa', code: 2501609 },
    { city: 'Barra de Santana', code: 2501575 },
    { city: 'Barra de São Miguel', code: 2501708 },
    { city: 'Bayeux', code: 2501807 },
    { city: 'Belém', code: 2501906 },
    { city: 'Belém do Brejo do Cruz', code: 2502003 },
    { city: 'Bernardino Batista', code: 2502052 },
    { city: 'Boa Ventura', code: 2502102 },
    { city: 'Boa Vista', code: 2502151 },
    { city: 'Bom Jesus', code: 2502201 },
    { city: 'Bom Sucesso', code: 2502300 },
    { city: 'Bonito de Santa Fé', code: 2502409 },
    { city: 'Boqueirão', code: 2502508 },
    { city: 'Borborema', code: 2502706 },
    { city: 'Brejo do Cruz', code: 2502805 },
    { city: 'Brejo dos Santos', code: 2502904 },
    { city: 'Caaporã', code: 2503001 },
    { city: 'Cabaceiras', code: 2503100 },
    { city: 'Cabedelo', code: 2503209 },
    { city: 'Cachoeira dos Índios', code: 2503308 },
    { city: 'Cacimba de Areia', code: 2503407 },
    { city: 'Cacimba de Dentro', code: 2503506 },
    { city: 'Cacimbas', code: 2503555 },
    { city: 'Caiçara', code: 2503605 },
    { city: 'Cajazeiras', code: 2503704 },
    { city: 'Cajazeirinhas', code: 2503753 },
    { city: 'Caldas Brandão', code: 2503803 },
    { city: 'Camalaú', code: 2503902 },
    { city: 'Campina Grande', code: 2504009 },
    { city: 'Capim', code: 2504033 },
    { city: 'Caraúbas', code: 2504074 },
    { city: 'Carrapateira', code: 2504108 },
    { city: 'Casserengue', code: 2504157 },
    { city: 'Catingueira', code: 2504207 },
    { city: 'Catolé do Rocha', code: 2504306 },
    { city: 'Caturité', code: 2504355 },
    { city: 'Conceição', code: 2504405 },
    { city: 'Condado', code: 2504504 },
    { city: 'Conde', code: 2504603 },
    { city: 'Congo', code: 2504702 },
    { city: 'Coremas', code: 2504801 },
    { city: 'Coxixola', code: 2504850 },
    { city: 'Cruz do Espírito Santo', code: 2504900 },
    { city: 'Cubati', code: 2505006 },
    { city: 'Cuité', code: 2505105 },
    { city: 'Cuité de Mamanguape', code: 2505238 },
    { city: 'Cuitegi', code: 2505204 },
    { city: 'Curral de Cima', code: 2505279 },
    { city: 'Curral Velho', code: 2505303 },
    { city: 'Damião', code: 2505352 },
    { city: 'Desterro', code: 2505402 },
    { city: 'Diamante', code: 2505600 },
    { city: 'Dona Inês', code: 2505709 },
    { city: 'Duas Estradas', code: 2505808 },
    { city: 'Emas', code: 2505907 },
    { city: 'Esperança', code: 2506004 },
    { city: 'Fagundes', code: 2506103 },
    { city: 'Frei Martinho', code: 2506202 },
    { city: 'Gado Bravo', code: 2506251 },
    { city: 'Guarabira', code: 2506301 },
    { city: 'Gurinhém', code: 2506400 },
    { city: 'Gurjão', code: 2506509 },
    { city: 'Ibiara', code: 2506608 },
    { city: 'Igaracy', code: 2502607 },
    { city: 'Imaculada', code: 2506707 },
    { city: 'Ingá', code: 2506806 },
    { city: 'Itabaiana', code: 2506905 },
    { city: 'Itaporanga', code: 2507002 },
    { city: 'Itapororoca', code: 2507101 },
    { city: 'Itatuba', code: 2507200 },
    { city: 'Jacaraú', code: 2507309 },
    { city: 'Jericó', code: 2507408 },
    { city: 'João Pessoa', code: 2507507 },
    { city: 'Joca Claudino', code: 2513653 },
    { city: 'Juarez Távora', code: 2507606 },
    { city: 'Juazeirinho', code: 2507705 },
    { city: 'Junco do Seridó', code: 2507804 },
    { city: 'Juripiranga', code: 2507903 },
    { city: 'Juru', code: 2508000 },
    { city: 'Lagoa', code: 2508109 },
    { city: 'Lagoa de Dentro', code: 2508208 },
    { city: 'Lagoa Seca', code: 2508307 },
    { city: 'Lastro', code: 2508406 },
    { city: 'Livramento', code: 2508505 },
    { city: 'Logradouro', code: 2508554 },
    { city: 'Lucena', code: 2508604 },
    { city: 'Mãe d\'Água', code: 2508703 },
    { city: 'Malta', code: 2508802 },
    { city: 'Mamanguape', code: 2508901 },
    { city: 'Manaíra', code: 2509008 },
    { city: 'Marcação', code: 2509057 },
    { city: 'Mari', code: 2509107 },
    { city: 'Marizópolis', code: 2509156 },
    { city: 'Massaranduba', code: 2509206 },
    { city: 'Mataraca', code: 2509305 },
    { city: 'Matinhas', code: 2509339 },
    { city: 'Mato Grosso', code: 2509370 },
    { city: 'Maturéia', code: 2509396 },
    { city: 'Mogeiro', code: 2509404 },
    { city: 'Montadas', code: 2509503 },
    { city: 'Monte Horebe', code: 2509602 },
    { city: 'Monteiro', code: 2509701 },
    { city: 'Mulungu', code: 2509800 },
    { city: 'Natuba', code: 2509909 },
    { city: 'Nazarezinho', code: 2510006 },
    { city: 'Nova Floresta', code: 2510105 },
    { city: 'Nova Olinda', code: 2510204 },
    { city: 'Nova Palmeira', code: 2510303 },
    { city: 'Olho d\'Água', code: 2510402 },
    { city: 'Olivedos', code: 2510501 },
    { city: 'Ouro Velho', code: 2510600 },
    { city: 'Parari', code: 2510659 },
    { city: 'Passagem', code: 2510709 },
    { city: 'Patos', code: 2510808 },
    { city: 'Paulista', code: 2510907 },
    { city: 'Pedra Branca', code: 2511004 },
    { city: 'Pedra Lavrada', code: 2511103 },
    { city: 'Pedras de Fogo', code: 2511202 },
    { city: 'Pedro Régis', code: 2512721 },
    { city: 'Piancó', code: 2511301 },
    { city: 'Picuí', code: 2511400 },
    { city: 'Pilar', code: 2511509 },
    { city: 'Pilões', code: 2511608 },
    { city: 'Pilõezinhos', code: 2511707 },
    { city: 'Pirpirituba', code: 2511806 },
    { city: 'Pitimbu', code: 2511905 },
    { city: 'Pocinhos', code: 2512002 },
    { city: 'Poço Dantas', code: 2512036 },
    { city: 'Poço de José de Moura', code: 2512077 },
    { city: 'Pombal', code: 2512101 },
    { city: 'Prata', code: 2512200 },
    { city: 'Princesa Isabel', code: 2512309 },
    { city: 'Puxinanã', code: 2512408 },
    { city: 'Queimadas', code: 2512507 },
    { city: 'Quixaba', code: 2512606 },
    { city: 'Remígio', code: 2512705 },
    { city: 'Riachão', code: 2512747 },
    { city: 'Riachão do Bacamarte', code: 2512754 },
    { city: 'Riachão do Poço', code: 2512762 },
    { city: 'Riacho de Santo Antônio', code: 2512788 },
    { city: 'Riacho dos Cavalos', code: 2512804 },
    { city: 'Rio Tinto', code: 2512903 },
    { city: 'Salgadinho', code: 2513000 },
    { city: 'Salgado de São Félix', code: 2513109 },
    { city: 'Santa Cecília', code: 2513158 },
    { city: 'Santa Cruz', code: 2513208 },
    { city: 'Santa Helena', code: 2513307 },
    { city: 'Santa Inês', code: 2513356 },
    { city: 'Santa Luzia', code: 2513406 },
    { city: 'Santa Rita', code: 2513703 },
    { city: 'Santa Teresinha', code: 2513802 },
    { city: 'Santana de Mangueira', code: 2513505 },
    { city: 'Santana dos Garrotes', code: 2513604 },
    { city: 'Santo André', code: 2513851 },
    { city: 'São Bentinho', code: 2513927 },
    { city: 'São Bento', code: 2513901 },
    { city: 'São Domingos', code: 2513968 },
    { city: 'São Domingos do Cariri', code: 2513943 },
    { city: 'São Francisco', code: 2513984 },
    { city: 'São João do Cariri', code: 2514008 },
    { city: 'São João do Rio do Peixe', code: 2500700 },
    { city: 'São João do Tigre', code: 2514107 },
    { city: 'São José da Lagoa Tapada', code: 2514206 },
    { city: 'São José de Caiana', code: 2514305 },
    { city: 'São José de Espinharas', code: 2514404 },
    { city: 'São José de Piranhas', code: 2514503 },
    { city: 'São José de Princesa', code: 2514552 },
    { city: 'São José do Bonfim', code: 2514602 },
    { city: 'São José do Brejo do Cruz', code: 2514651 },
    { city: 'São José do Sabugi', code: 2514701 },
    { city: 'São José dos Cordeiros', code: 2514800 },
    { city: 'São José dos Ramos', code: 2514453 },
    { city: 'São Mamede', code: 2514909 },
    { city: 'São Miguel de Taipu', code: 2515005 },
    { city: 'São Sebastião de Lagoa de Roça', code: 2515104 },
    { city: 'São Sebastião do Umbuzeiro', code: 2515203 },
    { city: 'São Vicente do Seridó', code: 2515401 },
    { city: 'Sapé', code: 2515302 },
    { city: 'Serra Branca', code: 2515500 },
    { city: 'Serra da Raiz', code: 2515609 },
    { city: 'Serra Grande', code: 2515708 },
    { city: 'Serra Redonda', code: 2515807 },
    { city: 'Serraria', code: 2515906 },
    { city: 'Sertãozinho', code: 2515930 },
    { city: 'Sobrado', code: 2515971 },
    { city: 'Solânea', code: 2516003 },
    { city: 'Soledade', code: 2516102 },
    { city: 'Sossêgo', code: 2516151 },
    { city: 'Sousa', code: 2516201 },
    { city: 'Sumé', code: 2516300 },
    { city: 'Tacima', code: 2516409 },
    { city: 'Taperoá', code: 2516508 },
    { city: 'Tavares', code: 2516607 },
    { city: 'Teixeira', code: 2516706 },
    { city: 'Tenório', code: 2516755 },
    { city: 'Triunfo', code: 2516805 },
    { city: 'Uiraúna', code: 2516904 },
    { city: 'Umbuzeiro', code: 2517001 },
    { city: 'Várzea', code: 2517100 },
    { city: 'Vieirópolis', code: 2517209 },
    { city: 'Vista Serrana', code: 2505501 },
    { city: 'Zabelê', code: 2517407 },
  ],
  PE: [
    { city: 'Abreu e Lima', code: 2600054 },
    { city: 'Afogados da Ingazeira', code: 2600104 },
    { city: 'Afrânio', code: 2600203 },
    { city: 'Agrestina', code: 2600302 },
    { city: 'Água Preta', code: 2600401 },
    { city: 'Águas Belas', code: 2600500 },
    { city: 'Alagoinha', code: 2600609 },
    { city: 'Aliança', code: 2600708 },
    { city: 'Altinho', code: 2600807 },
    { city: 'Amaraji', code: 2600906 },
    { city: 'Angelim', code: 2601003 },
    { city: 'Araçoiaba', code: 2601052 },
    { city: 'Araripina', code: 2601102 },
    { city: 'Arcoverde', code: 2601201 },
    { city: 'Barra de Guabiraba', code: 2601300 },
    { city: 'Barreiros', code: 2601409 },
    { city: 'Belém de Maria', code: 2601508 },
    { city: 'Belém do São Francisco', code: 2601607 },
    { city: 'Belo Jardim', code: 2601706 },
    { city: 'Betânia', code: 2601805 },
    { city: 'Bezerros', code: 2601904 },
    { city: 'Bodocó', code: 2602001 },
    { city: 'Bom Conselho', code: 2602100 },
    { city: 'Bom Jardim', code: 2602209 },
    { city: 'Bonito', code: 2602308 },
    { city: 'Brejão', code: 2602407 },
    { city: 'Brejinho', code: 2602506 },
    { city: 'Brejo da Madre de Deus', code: 2602605 },
    { city: 'Buenos Aires', code: 2602704 },
    { city: 'Buíque', code: 2602803 },
    { city: 'Cabo de Santo Agostinho', code: 2602902 },
    { city: 'Cabrobó', code: 2603009 },
    { city: 'Cachoeirinha', code: 2603108 },
    { city: 'Caetés', code: 2603207 },
    { city: 'Calçado', code: 2603306 },
    { city: 'Calumbi', code: 2603405 },
    { city: 'Camaragibe', code: 2603454 },
    { city: 'Camocim de São Félix', code: 2603504 },
    { city: 'Camutanga', code: 2603603 },
    { city: 'Canhotinho', code: 2603702 },
    { city: 'Capoeiras', code: 2603801 },
    { city: 'Carnaíba', code: 2603900 },
    { city: 'Carnaubeira da Penha', code: 2603926 },
    { city: 'Carpina', code: 2604007 },
    { city: 'Caruaru', code: 2604106 },
    { city: 'Casinhas', code: 2604155 },
    { city: 'Catende', code: 2604205 },
    { city: 'Cedro', code: 2604304 },
    { city: 'Chã de Alegria', code: 2604403 },
    { city: 'Chã Grande', code: 2604502 },
    { city: 'Condado', code: 2604601 },
    { city: 'Correntes', code: 2604700 },
    { city: 'Cortês', code: 2604809 },
    { city: 'Cumaru', code: 2604908 },
    { city: 'Cupira', code: 2605004 },
    { city: 'Custódia', code: 2605103 },
    { city: 'Dormentes', code: 2605152 },
    { city: 'Escada', code: 2605202 },
    { city: 'Exu', code: 2605301 },
    { city: 'Feira Nova', code: 2605400 },
    { city: 'Fernando de Noronha', code: 2605459 },
    { city: 'Ferreiros', code: 2605509 },
    { city: 'Flores', code: 2605608 },
    { city: 'Floresta', code: 2605707 },
    { city: 'Frei Miguelinho', code: 2605806 },
    { city: 'Gameleira', code: 2605905 },
    { city: 'Garanhuns', code: 2606002 },
    { city: 'Glória do Goitá', code: 2606101 },
    { city: 'Goiana', code: 2606200 },
    { city: 'Granito', code: 2606309 },
    { city: 'Gravatá', code: 2606408 },
    { city: 'Iati', code: 2606507 },
    { city: 'Ibimirim', code: 2606606 },
    { city: 'Ibirajuba', code: 2606705 },
    { city: 'Igarassu', code: 2606804 },
    { city: 'Iguaracy', code: 2606903 },
    { city: 'Ilha de Itamaracá', code: 2607604 },
    { city: 'Inajá', code: 2607000 },
    { city: 'Ingazeira', code: 2607109 },
    { city: 'Ipojuca', code: 2607208 },
    { city: 'Ipubi', code: 2607307 },
    { city: 'Itacuruba', code: 2607406 },
    { city: 'Itaíba', code: 2607505 },
    { city: 'Itambé', code: 2607653 },
    { city: 'Itapetim', code: 2607703 },
    { city: 'Itapissuma', code: 2607752 },
    { city: 'Itaquitinga', code: 2607802 },
    { city: 'Jaboatão dos Guararapes', code: 2607901 },
    { city: 'Jaqueira', code: 2607950 },
    { city: 'Jataúba', code: 2608008 },
    { city: 'Jatobá', code: 2608057 },
    { city: 'João Alfredo', code: 2608107 },
    { city: 'Joaquim Nabuco', code: 2608206 },
    { city: 'Jucati', code: 2608255 },
    { city: 'Jupi', code: 2608305 },
    { city: 'Jurema', code: 2608404 },
    { city: 'Lagoa de Itaenga', code: 2608503 },
    { city: 'Lagoa do Carro', code: 2608453 },
    { city: 'Lagoa do Ouro', code: 2608602 },
    { city: 'Lagoa dos Gatos', code: 2608701 },
    { city: 'Lagoa Grande', code: 2608750 },
    { city: 'Lajedo', code: 2608800 },
    { city: 'Limoeiro', code: 2608909 },
    { city: 'Macaparana', code: 2609006 },
    { city: 'Machados', code: 2609105 },
    { city: 'Manari', code: 2609154 },
    { city: 'Maraial', code: 2609204 },
    { city: 'Mirandiba', code: 2609303 },
    { city: 'Moreilândia', code: 2614303 },
    { city: 'Moreno', code: 2609402 },
    { city: 'Nazaré da Mata', code: 2609501 },
    { city: 'Olinda', code: 2609600 },
    { city: 'Orobó', code: 2609709 },
    { city: 'Orocó', code: 2609808 },
    { city: 'Ouricuri', code: 2609907 },
    { city: 'Palmares', code: 2610004 },
    { city: 'Palmeirina', code: 2610103 },
    { city: 'Panelas', code: 2610202 },
    { city: 'Paranatama', code: 2610301 },
    { city: 'Parnamirim', code: 2610400 },
    { city: 'Passira', code: 2610509 },
    { city: 'Paudalho', code: 2610608 },
    { city: 'Paulista', code: 2610707 },
    { city: 'Pedra', code: 2610806 },
    { city: 'Pesqueira', code: 2610905 },
    { city: 'Petrolândia', code: 2611002 },
    { city: 'Petrolina', code: 2611101 },
    { city: 'Poção', code: 2611200 },
    { city: 'Pombos', code: 2611309 },
    { city: 'Primavera', code: 2611408 },
    { city: 'Quipapá', code: 2611507 },
    { city: 'Quixaba', code: 2611533 },
    { city: 'Recife', code: 2611606 },
    { city: 'Riacho das Almas', code: 2611705 },
    { city: 'Ribeirão', code: 2611804 },
    { city: 'Rio Formoso', code: 2611903 },
    { city: 'Sairé', code: 2612000 },
    { city: 'Salgadinho', code: 2612109 },
    { city: 'Salgueiro', code: 2612208 },
    { city: 'Saloá', code: 2612307 },
    { city: 'Sanharó', code: 2612406 },
    { city: 'Santa Cruz', code: 2612455 },
    { city: 'Santa Cruz da Baixa Verde', code: 2612471 },
    { city: 'Santa Cruz do Capibaribe', code: 2612505 },
    { city: 'Santa Filomena', code: 2612554 },
    { city: 'Santa Maria da Boa Vista', code: 2612604 },
    { city: 'Santa Maria do Cambucá', code: 2612703 },
    { city: 'Santa Terezinha', code: 2612802 },
    { city: 'São Benedito do Sul', code: 2612901 },
    { city: 'São Bento do Una', code: 2613008 },
    { city: 'São Caitano', code: 2613107 },
    { city: 'São João', code: 2613206 },
    { city: 'São Joaquim do Monte', code: 2613305 },
    { city: 'São José da Coroa Grande', code: 2613404 },
    { city: 'São José do Belmonte', code: 2613503 },
    { city: 'São José do Egito', code: 2613602 },
    { city: 'São Lourenço da Mata', code: 2613701 },
    { city: 'São Vicente Férrer', code: 2613800 },
    { city: 'Serra Talhada', code: 2613909 },
    { city: 'Serrita', code: 2614006 },
    { city: 'Sertânia', code: 2614105 },
    { city: 'Sirinhaém', code: 2614204 },
    { city: 'Solidão', code: 2614402 },
    { city: 'Surubim', code: 2614501 },
    { city: 'Tabira', code: 2614600 },
    { city: 'Tacaimbó', code: 2614709 },
    { city: 'Tacaratu', code: 2614808 },
    { city: 'Tamandaré', code: 2614857 },
    { city: 'Taquaritinga do Norte', code: 2615003 },
    { city: 'Terezinha', code: 2615102 },
    { city: 'Terra Nova', code: 2615201 },
    { city: 'Timbaúba', code: 2615300 },
    { city: 'Toritama', code: 2615409 },
    { city: 'Tracunhaém', code: 2615508 },
    { city: 'Trindade', code: 2615607 },
    { city: 'Triunfo', code: 2615706 },
    { city: 'Tupanatinga', code: 2615805 },
    { city: 'Tuparetama', code: 2615904 },
    { city: 'Venturosa', code: 2616001 },
    { city: 'Verdejante', code: 2616100 },
    { city: 'Vertente do Lério', code: 2616183 },
    { city: 'Vertentes', code: 2616209 },
    { city: 'Vicência', code: 2616308 },
    { city: 'Vitória de Santo Antão', code: 2616407 },
    { city: 'Xexéu', code: 2616506 },
  ],
  PI: [
    { city: 'Acauã', code: 2200053 },
    { city: 'Agricolândia', code: 2200103 },
    { city: 'Água Branca', code: 2200202 },
    { city: 'Alagoinha do Piauí', code: 2200251 },
    { city: 'Alegrete do Piauí', code: 2200277 },
    { city: 'Alto Longá', code: 2200301 },
    { city: 'Altos', code: 2200400 },
    { city: 'Alvorada do Gurguéia', code: 2200459 },
    { city: 'Amarante', code: 2200509 },
    { city: 'Angical do Piauí', code: 2200608 },
    { city: 'Anísio de Abreu', code: 2200707 },
    { city: 'Antônio Almeida', code: 2200806 },
    { city: 'Aroazes', code: 2200905 },
    { city: 'Aroeiras do Itaim', code: 2200954 },
    { city: 'Arraial', code: 2201002 },
    { city: 'Assunção do Piauí', code: 2201051 },
    { city: 'Avelino Lopes', code: 2201101 },
    { city: 'Baixa Grande do Ribeiro', code: 2201150 },
    { city: 'Barra D\'Alcântara', code: 2201176 },
    { city: 'Barras', code: 2201200 },
    { city: 'Barreiras do Piauí', code: 2201309 },
    { city: 'Barro Duro', code: 2201408 },
    { city: 'Batalha', code: 2201507 },
    { city: 'Bela Vista do Piauí', code: 2201556 },
    { city: 'Belém do Piauí', code: 2201572 },
    { city: 'Beneditinos', code: 2201606 },
    { city: 'Bertolínia', code: 2201705 },
    { city: 'Betânia do Piauí', code: 2201739 },
    { city: 'Boa Hora', code: 2201770 },
    { city: 'Bocaina', code: 2201804 },
    { city: 'Bom Jesus', code: 2201903 },
    { city: 'Bom Princípio do Piauí', code: 2201919 },
    { city: 'Bonfim do Piauí', code: 2201929 },
    { city: 'Boqueirão do Piauí', code: 2201945 },
    { city: 'Brasileira', code: 2201960 },
    { city: 'Brejo do Piauí', code: 2201988 },
    { city: 'Buriti dos Lopes', code: 2202000 },
    { city: 'Buriti dos Montes', code: 2202026 },
    { city: 'Cabeceiras do Piauí', code: 2202059 },
    { city: 'Cajazeiras do Piauí', code: 2202075 },
    { city: 'Cajueiro da Praia', code: 2202083 },
    { city: 'Caldeirão Grande do Piauí', code: 2202091 },
    { city: 'Campinas do Piauí', code: 2202109 },
    { city: 'Campo Alegre do Fidalgo', code: 2202117 },
    { city: 'Campo Grande do Piauí', code: 2202133 },
    { city: 'Campo Largo do Piauí', code: 2202174 },
    { city: 'Campo Maior', code: 2202208 },
    { city: 'Canavieira', code: 2202251 },
    { city: 'Canto do Buriti', code: 2202307 },
    { city: 'Capitão de Campos', code: 2202406 },
    { city: 'Capitão Gervásio Oliveira', code: 2202455 },
    { city: 'Caracol', code: 2202505 },
    { city: 'Caraúbas do Piauí', code: 2202539 },
    { city: 'Caridade do Piauí', code: 2202554 },
    { city: 'Castelo do Piauí', code: 2202604 },
    { city: 'Caxingó', code: 2202653 },
    { city: 'Cocal', code: 2202703 },
    { city: 'Cocal de Telha', code: 2202711 },
    { city: 'Cocal dos Alves', code: 2202729 },
    { city: 'Coivaras', code: 2202737 },
    { city: 'Colônia do Gurguéia', code: 2202752 },
    { city: 'Colônia do Piauí', code: 2202778 },
    { city: 'Conceição do Canindé', code: 2202802 },
    { city: 'Coronel José Dias', code: 2202851 },
    { city: 'Corrente', code: 2202901 },
    { city: 'Cristalândia do Piauí', code: 2203008 },
    { city: 'Cristino Castro', code: 2203107 },
    { city: 'Curimatá', code: 2203206 },
    { city: 'Currais', code: 2203230 },
    { city: 'Curral Novo do Piauí', code: 2203271 },
    { city: 'Curralinhos', code: 2203255 },
    { city: 'Demerval Lobão', code: 2203305 },
    { city: 'Dirceu Arcoverde', code: 2203354 },
    { city: 'Dom Expedito Lopes', code: 2203404 },
    { city: 'Dom Inocêncio', code: 2203453 },
    { city: 'Domingos Mourão', code: 2203420 },
    { city: 'Elesbão Veloso', code: 2203503 },
    { city: 'Eliseu Martins', code: 2203602 },
    { city: 'Esperantina', code: 2203701 },
    { city: 'Fartura do Piauí', code: 2203750 },
    { city: 'Flores do Piauí', code: 2203800 },
    { city: 'Floresta do Piauí', code: 2203859 },
    { city: 'Floriano', code: 2203909 },
    { city: 'Francinópolis', code: 2204006 },
    { city: 'Francisco Ayres', code: 2204105 },
    { city: 'Francisco Macedo', code: 2204154 },
    { city: 'Francisco Santos', code: 2204204 },
    { city: 'Fronteiras', code: 2204303 },
    { city: 'Geminiano', code: 2204352 },
    { city: 'Gilbués', code: 2204402 },
    { city: 'Guadalupe', code: 2204501 },
    { city: 'Guaribas', code: 2204550 },
    { city: 'Hugo Napoleão', code: 2204600 },
    { city: 'Ilha Grande', code: 2204659 },
    { city: 'Inhuma', code: 2204709 },
    { city: 'Ipiranga do Piauí', code: 2204808 },
    { city: 'Isaías Coelho', code: 2204907 },
    { city: 'Itainópolis', code: 2205003 },
    { city: 'Itaueira', code: 2205102 },
    { city: 'Jacobina do Piauí', code: 2205151 },
    { city: 'Jaicós', code: 2205201 },
    { city: 'Jardim do Mulato', code: 2205250 },
    { city: 'Jatobá do Piauí', code: 2205276 },
    { city: 'Jerumenha', code: 2205300 },
    { city: 'João Costa', code: 2205359 },
    { city: 'Joaquim Pires', code: 2205409 },
    { city: 'Joca Marques', code: 2205458 },
    { city: 'José de Freitas', code: 2205508 },
    { city: 'Juazeiro do Piauí', code: 2205516 },
    { city: 'Júlio Borges', code: 2205524 },
    { city: 'Jurema', code: 2205532 },
    { city: 'Lagoa Alegre', code: 2205557 },
    { city: 'Lagoa de São Francisco', code: 2205573 },
    { city: 'Lagoa do Barro do Piauí', code: 2205565 },
    { city: 'Lagoa do Piauí', code: 2205581 },
    { city: 'Lagoa do Sítio', code: 2205599 },
    { city: 'Lagoinha do Piauí', code: 2205540 },
    { city: 'Landri Sales', code: 2205607 },
    { city: 'Luís Correia', code: 2205706 },
    { city: 'Luzilândia', code: 2205805 },
    { city: 'Madeiro', code: 2205854 },
    { city: 'Manoel Emídio', code: 2205904 },
    { city: 'Marcolândia', code: 2205953 },
    { city: 'Marcos Parente', code: 2206001 },
    { city: 'Massapê do Piauí', code: 2206050 },
    { city: 'Matias Olímpio', code: 2206100 },
    { city: 'Miguel Alves', code: 2206209 },
    { city: 'Miguel Leão', code: 2206308 },
    { city: 'Milton Brandão', code: 2206357 },
    { city: 'Monsenhor Gil', code: 2206407 },
    { city: 'Monsenhor Hipólito', code: 2206506 },
    { city: 'Monte Alegre do Piauí', code: 2206605 },
    { city: 'Morro Cabeça no Tempo', code: 2206654 },
    { city: 'Morro do Chapéu do Piauí', code: 2206670 },
    { city: 'Murici dos Portelas', code: 2206696 },
    { city: 'Nazaré do Piauí', code: 2206704 },
    { city: 'Nazária', code: 2206720 },
    { city: 'Nossa Senhora de Nazaré', code: 2206753 },
    { city: 'Nossa Senhora dos Remédios', code: 2206803 },
    { city: 'Nova Santa Rita', code: 2207959 },
    { city: 'Novo Oriente do Piauí', code: 2206902 },
    { city: 'Novo Santo Antônio', code: 2206951 },
    { city: 'Oeiras', code: 2207009 },
    { city: 'Olho D\'Água do Piauí', code: 2207108 },
    { city: 'Padre Marcos', code: 2207207 },
    { city: 'Paes Landim', code: 2207306 },
    { city: 'Pajeú do Piauí', code: 2207355 },
    { city: 'Palmeira do Piauí', code: 2207405 },
    { city: 'Palmeirais', code: 2207504 },
    { city: 'Paquetá', code: 2207553 },
    { city: 'Parnaguá', code: 2207603 },
    { city: 'Parnaíba', code: 2207702 },
    { city: 'Passagem Franca do Piauí', code: 2207751 },
    { city: 'Patos do Piauí', code: 2207777 },
    { city: 'Pau D\'Arco do Piauí', code: 2207793 },
    { city: 'Paulistana', code: 2207801 },
    { city: 'Pavussu', code: 2207850 },
    { city: 'Pedro II', code: 2207900 },
    { city: 'Pedro Laurentino', code: 2207934 },
    { city: 'Picos', code: 2208007 },
    { city: 'Pimenteiras', code: 2208106 },
    { city: 'Pio IX', code: 2208205 },
    { city: 'Piracuruca', code: 2208304 },
    { city: 'Piripiri', code: 2208403 },
    { city: 'Porto', code: 2208502 },
    { city: 'Porto Alegre do Piauí', code: 2208551 },
    { city: 'Prata do Piauí', code: 2208601 },
    { city: 'Queimada Nova', code: 2208650 },
    { city: 'Redenção do Gurguéia', code: 2208700 },
    { city: 'Regeneração', code: 2208809 },
    { city: 'Riacho Frio', code: 2208858 },
    { city: 'Ribeira do Piauí', code: 2208874 },
    { city: 'Ribeiro Gonçalves', code: 2208908 },
    { city: 'Rio Grande do Piauí', code: 2209005 },
    { city: 'Santa Cruz do Piauí', code: 2209104 },
    { city: 'Santa Cruz dos Milagres', code: 2209153 },
    { city: 'Santa Filomena', code: 2209203 },
    { city: 'Santa Luz', code: 2209302 },
    { city: 'Santa Rosa do Piauí', code: 2209377 },
    { city: 'Santana do Piauí', code: 2209351 },
    { city: 'Santo Antônio de Lisboa', code: 2209401 },
    { city: 'Santo Antônio dos Milagres', code: 2209450 },
    { city: 'Santo Inácio do Piauí', code: 2209500 },
    { city: 'São Braz do Piauí', code: 2209559 },
    { city: 'São Félix do Piauí', code: 2209609 },
    { city: 'São Francisco de Assis do Piauí', code: 2209658 },
    { city: 'São Francisco do Piauí', code: 2209708 },
    { city: 'São Gonçalo do Gurguéia', code: 2209757 },
    { city: 'São Gonçalo do Piauí', code: 2209807 },
    { city: 'São João da Canabrava', code: 2209856 },
    { city: 'São João da Fronteira', code: 2209872 },
    { city: 'São João da Serra', code: 2209906 },
    { city: 'São João da Varjota', code: 2209955 },
    { city: 'São João do Arraial', code: 2209971 },
    { city: 'São João do Piauí', code: 2210003 },
    { city: 'São José do Divino', code: 2210052 },
    { city: 'São José do Peixe', code: 2210102 },
    { city: 'São José do Piauí', code: 2210201 },
    { city: 'São Julião', code: 2210300 },
    { city: 'São Lourenço do Piauí', code: 2210359 },
    { city: 'São Luis do Piauí', code: 2210375 },
    { city: 'São Miguel da Baixa Grande', code: 2210383 },
    { city: 'São Miguel do Fidalgo', code: 2210391 },
    { city: 'São Miguel do Tapuio', code: 2210409 },
    { city: 'São Pedro do Piauí', code: 2210508 },
    { city: 'São Raimundo Nonato', code: 2210607 },
    { city: 'Sebastião Barros', code: 2210623 },
    { city: 'Sebastião Leal', code: 2210631 },
    { city: 'Sigefredo Pacheco', code: 2210656 },
    { city: 'Simões', code: 2210706 },
    { city: 'Simplício Mendes', code: 2210805 },
    { city: 'Socorro do Piauí', code: 2210904 },
    { city: 'Sussuapara', code: 2210938 },
    { city: 'Tamboril do Piauí', code: 2210953 },
    { city: 'Tanque do Piauí', code: 2210979 },
    { city: 'Teresina', code: 2211001 },
    { city: 'União', code: 2211100 },
    { city: 'Uruçuí', code: 2211209 },
    { city: 'Valença do Piauí', code: 2211308 },
    { city: 'Várzea Branca', code: 2211357 },
    { city: 'Várzea Grande', code: 2211407 },
    { city: 'Vera Mendes', code: 2211506 },
    { city: 'Vila Nova do Piauí', code: 2211605 },
    { city: 'Wall Ferraz', code: 2211704 },
  ],
  PR: [
    { city: 'Abatiá', code: 4100103 },
    { city: 'Adrianópolis', code: 4100202 },
    { city: 'Agudos do Sul', code: 4100301 },
    { city: 'Almirante Tamandaré', code: 4100400 },
    { city: 'Altamira do Paraná', code: 4100459 },
    { city: 'Alto Paraíso', code: 4128625 },
    { city: 'Alto Paraná', code: 4100608 },
    { city: 'Alto Piquiri', code: 4100707 },
    { city: 'Altônia', code: 4100509 },
    { city: 'Alvorada do Sul', code: 4100806 },
    { city: 'Amaporã', code: 4100905 },
    { city: 'Ampére', code: 4101002 },
    { city: 'Anahy', code: 4101051 },
    { city: 'Andirá', code: 4101101 },
    { city: 'Ângulo', code: 4101150 },
    { city: 'Antonina', code: 4101200 },
    { city: 'Antônio Olinto', code: 4101309 },
    { city: 'Apucarana', code: 4101408 },
    { city: 'Arapongas', code: 4101507 },
    { city: 'Arapoti', code: 4101606 },
    { city: 'Arapuã', code: 4101655 },
    { city: 'Araruna', code: 4101705 },
    { city: 'Araucária', code: 4101804 },
    { city: 'Ariranha do Ivaí', code: 4101853 },
    { city: 'Assaí', code: 4101903 },
    { city: 'Assis Chateaubriand', code: 4102000 },
    { city: 'Astorga', code: 4102109 },
    { city: 'Atalaia', code: 4102208 },
    { city: 'Balsa Nova', code: 4102307 },
    { city: 'Bandeirantes', code: 4102406 },
    { city: 'Barbosa Ferraz', code: 4102505 },
    { city: 'Barra do Jacaré', code: 4102703 },
    { city: 'Barracão', code: 4102604 },
    { city: 'Bela Vista da Caroba', code: 4102752 },
    { city: 'Bela Vista do Paraíso', code: 4102802 },
    { city: 'Bituruna', code: 4102901 },
    { city: 'Boa Esperança', code: 4103008 },
    { city: 'Boa Esperança do Iguaçu', code: 4103024 },
    { city: 'Boa Ventura de São Roque', code: 4103040 },
    { city: 'Boa Vista da Aparecida', code: 4103057 },
    { city: 'Bocaiúva do Sul', code: 4103107 },
    { city: 'Bom Jesus do Sul', code: 4103156 },
    { city: 'Bom Sucesso', code: 4103206 },
    { city: 'Bom Sucesso do Sul', code: 4103222 },
    { city: 'Borrazópolis', code: 4103305 },
    { city: 'Braganey', code: 4103354 },
    { city: 'Brasilândia do Sul', code: 4103370 },
    { city: 'Cafeara', code: 4103404 },
    { city: 'Cafelândia', code: 4103453 },
    { city: 'Cafezal do Sul', code: 4103479 },
    { city: 'Califórnia', code: 4103503 },
    { city: 'Cambará', code: 4103602 },
    { city: 'Cambé', code: 4103701 },
    { city: 'Cambira', code: 4103800 },
    { city: 'Campina da Lagoa', code: 4103909 },
    { city: 'Campina do Simão', code: 4103958 },
    { city: 'Campina Grande do Sul', code: 4104006 },
    { city: 'Campo Bonito', code: 4104055 },
    { city: 'Campo do Tenente', code: 4104105 },
    { city: 'Campo Largo', code: 4104204 },
    { city: 'Campo Magro', code: 4104253 },
    { city: 'Campo Mourão', code: 4104303 },
    { city: 'Cândido de Abreu', code: 4104402 },
    { city: 'Candói', code: 4104428 },
    { city: 'Cantagalo', code: 4104451 },
    { city: 'Capanema', code: 4104501 },
    { city: 'Capitão Leônidas Marques', code: 4104600 },
    { city: 'Carambeí', code: 4104659 },
    { city: 'Carlópolis', code: 4104709 },
    { city: 'Cascavel', code: 4104808 },
    { city: 'Castro', code: 4104907 },
    { city: 'Catanduvas', code: 4105003 },
    { city: 'Centenário do Sul', code: 4105102 },
    { city: 'Cerro Azul', code: 4105201 },
    { city: 'Céu Azul', code: 4105300 },
    { city: 'Chopinzinho', code: 4105409 },
    { city: 'Cianorte', code: 4105508 },
    { city: 'Cidade Gaúcha', code: 4105607 },
    { city: 'Clevelândia', code: 4105706 },
    { city: 'Colombo', code: 4105805 },
    { city: 'Colorado', code: 4105904 },
    { city: 'Congonhinhas', code: 4106001 },
    { city: 'Conselheiro Mairinck', code: 4106100 },
    { city: 'Contenda', code: 4106209 },
    { city: 'Corbélia', code: 4106308 },
    { city: 'Cornélio Procópio', code: 4106407 },
    { city: 'Coronel Domingos Soares', code: 4106456 },
    { city: 'Coronel Vivida', code: 4106506 },
    { city: 'Corumbataí do Sul', code: 4106555 },
    { city: 'Cruz Machado', code: 4106803 },
    { city: 'Cruzeiro do Iguaçu', code: 4106571 },
    { city: 'Cruzeiro do Oeste', code: 4106605 },
    { city: 'Cruzeiro do Sul', code: 4106704 },
    { city: 'Cruzmaltina', code: 4106852 },
    { city: 'Curitiba', code: 4106902 },
    { city: 'Curiúva', code: 4107009 },
    { city: 'Diamante do Norte', code: 4107108 },
    { city: 'Diamante do Sul', code: 4107124 },
    { city: 'Diamante D\'Oeste', code: 4107157 },
    { city: 'Dois Vizinhos', code: 4107207 },
    { city: 'Douradina', code: 4107256 },
    { city: 'Doutor Camargo', code: 4107306 },
    { city: 'Doutor Ulysses', code: 4128633 },
    { city: 'Enéas Marques', code: 4107405 },
    { city: 'Engenheiro Beltrão', code: 4107504 },
    { city: 'Entre Rios do Oeste', code: 4107538 },
    { city: 'Esperança Nova', code: 4107520 },
    { city: 'Espigão Alto do Iguaçu', code: 4107546 },
    { city: 'Farol', code: 4107553 },
    { city: 'Faxinal', code: 4107603 },
    { city: 'Fazenda Rio Grande', code: 4107652 },
    { city: 'Fênix', code: 4107702 },
    { city: 'Fernandes Pinheiro', code: 4107736 },
    { city: 'Figueira', code: 4107751 },
    { city: 'Flor da Serra do Sul', code: 4107850 },
    { city: 'Floraí', code: 4107801 },
    { city: 'Floresta', code: 4107900 },
    { city: 'Florestópolis', code: 4108007 },
    { city: 'Flórida', code: 4108106 },
    { city: 'Formosa do Oeste', code: 4108205 },
    { city: 'Foz do Iguaçu', code: 4108304 },
    { city: 'Foz do Jordão', code: 4108452 },
    { city: 'Francisco Alves', code: 4108320 },
    { city: 'Francisco Beltrão', code: 4108403 },
    { city: 'General Carneiro', code: 4108502 },
    { city: 'Godoy Moreira', code: 4108551 },
    { city: 'Goioerê', code: 4108601 },
    { city: 'Goioxim', code: 4108650 },
    { city: 'Grandes Rios', code: 4108700 },
    { city: 'Guaíra', code: 4108809 },
    { city: 'Guairaçá', code: 4108908 },
    { city: 'Guamiranga', code: 4108957 },
    { city: 'Guapirama', code: 4109005 },
    { city: 'Guaporema', code: 4109104 },
    { city: 'Guaraci', code: 4109203 },
    { city: 'Guaraniaçu', code: 4109302 },
    { city: 'Guarapuava', code: 4109401 },
    { city: 'Guaraqueçaba', code: 4109500 },
    { city: 'Guaratuba', code: 4109609 },
    { city: 'Honório Serpa', code: 4109658 },
    { city: 'Ibaiti', code: 4109708 },
    { city: 'Ibema', code: 4109757 },
    { city: 'Ibiporã', code: 4109807 },
    { city: 'Icaraíma', code: 4109906 },
    { city: 'Iguaraçu', code: 4110003 },
    { city: 'Iguatu', code: 4110052 },
    { city: 'Imbaú', code: 4110078 },
    { city: 'Imbituva', code: 4110102 },
    { city: 'Inácio Martins', code: 4110201 },
    { city: 'Inajá', code: 4110300 },
    { city: 'Indianópolis', code: 4110409 },
    { city: 'Ipiranga', code: 4110508 },
    { city: 'Iporã', code: 4110607 },
    { city: 'Iracema do Oeste', code: 4110656 },
    { city: 'Irati', code: 4110706 },
    { city: 'Iretama', code: 4110805 },
    { city: 'Itaguajé', code: 4110904 },
    { city: 'Itaipulândia', code: 4110953 },
    { city: 'Itambaracá', code: 4111001 },
    { city: 'Itambé', code: 4111100 },
    { city: 'Itapejara d\'Oeste', code: 4111209 },
    { city: 'Itaperuçu', code: 4111258 },
    { city: 'Itaúna do Sul', code: 4111308 },
    { city: 'Ivaí', code: 4111407 },
    { city: 'Ivaiporã', code: 4111506 },
    { city: 'Ivaté', code: 4111555 },
    { city: 'Ivatuba', code: 4111605 },
    { city: 'Jaboti', code: 4111704 },
    { city: 'Jacarezinho', code: 4111803 },
    { city: 'Jaguapitã', code: 4111902 },
    { city: 'Jaguariaíva', code: 4112009 },
    { city: 'Jandaia do Sul', code: 4112108 },
    { city: 'Janiópolis', code: 4112207 },
    { city: 'Japira', code: 4112306 },
    { city: 'Japurá', code: 4112405 },
    { city: 'Jardim Alegre', code: 4112504 },
    { city: 'Jardim Olinda', code: 4112603 },
    { city: 'Jataizinho', code: 4112702 },
    { city: 'Jesuítas', code: 4112751 },
    { city: 'Joaquim Távora', code: 4112801 },
    { city: 'Jundiaí do Sul', code: 4112900 },
    { city: 'Juranda', code: 4112959 },
    { city: 'Jussara', code: 4113007 },
    { city: 'Kaloré', code: 4113106 },
    { city: 'Lapa', code: 4113205 },
    { city: 'Laranjal', code: 4113254 },
    { city: 'Laranjeiras do Sul', code: 4113304 },
    { city: 'Leópolis', code: 4113403 },
    { city: 'Lidianópolis', code: 4113429 },
    { city: 'Lindoeste', code: 4113452 },
    { city: 'Loanda', code: 4113502 },
    { city: 'Lobato', code: 4113601 },
    { city: 'Londrina', code: 4113700 },
    { city: 'Luiziana', code: 4113734 },
    { city: 'Lunardelli', code: 4113759 },
    { city: 'Lupionópolis', code: 4113809 },
    { city: 'Mallet', code: 4113908 },
    { city: 'Mamborê', code: 4114005 },
    { city: 'Mandaguaçu', code: 4114104 },
    { city: 'Mandaguari', code: 4114203 },
    { city: 'Mandirituba', code: 4114302 },
    { city: 'Manfrinópolis', code: 4114351 },
    { city: 'Mangueirinha', code: 4114401 },
    { city: 'Manoel Ribas', code: 4114500 },
    { city: 'Marechal Cândido Rondon', code: 4114609 },
    { city: 'Maria Helena', code: 4114708 },
    { city: 'Marialva', code: 4114807 },
    { city: 'Marilândia do Sul', code: 4114906 },
    { city: 'Marilena', code: 4115002 },
    { city: 'Mariluz', code: 4115101 },
    { city: 'Maringá', code: 4115200 },
    { city: 'Mariópolis', code: 4115309 },
    { city: 'Maripá', code: 4115358 },
    { city: 'Marmeleiro', code: 4115408 },
    { city: 'Marquinho', code: 4115457 },
    { city: 'Marumbi', code: 4115507 },
    { city: 'Matelândia', code: 4115606 },
    { city: 'Matinhos', code: 4115705 },
    { city: 'Mato Rico', code: 4115739 },
    { city: 'Mauá da Serra', code: 4115754 },
    { city: 'Medianeira', code: 4115804 },
    { city: 'Mercedes', code: 4115853 },
    { city: 'Mirador', code: 4115903 },
    { city: 'Miraselva', code: 4116000 },
    { city: 'Missal', code: 4116059 },
    { city: 'Moreira Sales', code: 4116109 },
    { city: 'Morretes', code: 4116208 },
    { city: 'Munhoz de Melo', code: 4116307 },
    { city: 'Nossa Senhora das Graças', code: 4116406 },
    { city: 'Nova Aliança do Ivaí', code: 4116505 },
    { city: 'Nova América da Colina', code: 4116604 },
    { city: 'Nova Aurora', code: 4116703 },
    { city: 'Nova Cantu', code: 4116802 },
    { city: 'Nova Esperança', code: 4116901 },
    { city: 'Nova Esperança do Sudoeste', code: 4116950 },
    { city: 'Nova Fátima', code: 4117008 },
    { city: 'Nova Laranjeiras', code: 4117057 },
    { city: 'Nova Londrina', code: 4117107 },
    { city: 'Nova Olímpia', code: 4117206 },
    { city: 'Nova Prata do Iguaçu', code: 4117255 },
    { city: 'Nova Santa Bárbara', code: 4117214 },
    { city: 'Nova Santa Rosa', code: 4117222 },
    { city: 'Nova Tebas', code: 4117271 },
    { city: 'Novo Itacolomi', code: 4117297 },
    { city: 'Ortigueira', code: 4117305 },
    { city: 'Ourizona', code: 4117404 },
    { city: 'Ouro Verde do Oeste', code: 4117453 },
    { city: 'Paiçandu', code: 4117503 },
    { city: 'Palmas', code: 4117602 },
    { city: 'Palmeira', code: 4117701 },
    { city: 'Palmital', code: 4117800 },
    { city: 'Palotina', code: 4117909 },
    { city: 'Paraíso do Norte', code: 4118006 },
    { city: 'Paranacity', code: 4118105 },
    { city: 'Paranaguá', code: 4118204 },
    { city: 'Paranapoema', code: 4118303 },
    { city: 'Paranavaí', code: 4118402 },
    { city: 'Pato Bragado', code: 4118451 },
    { city: 'Pato Branco', code: 4118501 },
    { city: 'Paula Freitas', code: 4118600 },
    { city: 'Paulo Frontin', code: 4118709 },
    { city: 'Peabiru', code: 4118808 },
    { city: 'Perobal', code: 4118857 },
    { city: 'Pérola', code: 4118907 },
    { city: 'Pérola d\'Oeste', code: 4119004 },
    { city: 'Piên', code: 4119103 },
    { city: 'Pinhais', code: 4119152 },
    { city: 'Pinhal de São Bento', code: 4119251 },
    { city: 'Pinhalão', code: 4119202 },
    { city: 'Pinhão', code: 4119301 },
    { city: 'Piraí do Sul', code: 4119400 },
    { city: 'Piraquara', code: 4119509 },
    { city: 'Pitanga', code: 4119608 },
    { city: 'Pitangueiras', code: 4119657 },
    { city: 'Planaltina do Paraná', code: 4119707 },
    { city: 'Planalto', code: 4119806 },
    { city: 'Ponta Grossa', code: 4119905 },
    { city: 'Pontal do Paraná', code: 4119954 },
    { city: 'Porecatu', code: 4120002 },
    { city: 'Porto Amazonas', code: 4120101 },
    { city: 'Porto Barreiro', code: 4120150 },
    { city: 'Porto Rico', code: 4120200 },
    { city: 'Porto Vitória', code: 4120309 },
    { city: 'Prado Ferreira', code: 4120333 },
    { city: 'Pranchita', code: 4120358 },
    { city: 'Presidente Castelo Branco', code: 4120408 },
    { city: 'Primeiro de Maio', code: 4120507 },
    { city: 'Prudentópolis', code: 4120606 },
    { city: 'Quarto Centenário', code: 4120655 },
    { city: 'Quatiguá', code: 4120705 },
    { city: 'Quatro Barras', code: 4120804 },
    { city: 'Quatro Pontes', code: 4120853 },
    { city: 'Quedas do Iguaçu', code: 4120903 },
    { city: 'Querência do Norte', code: 4121000 },
    { city: 'Quinta do Sol', code: 4121109 },
    { city: 'Quitandinha', code: 4121208 },
    { city: 'Ramilândia', code: 4121257 },
    { city: 'Rancho Alegre', code: 4121307 },
    { city: 'Rancho Alegre D\'Oeste', code: 4121356 },
    { city: 'Realeza', code: 4121406 },
    { city: 'Rebouças', code: 4121505 },
    { city: 'Renascença', code: 4121604 },
    { city: 'Reserva', code: 4121703 },
    { city: 'Reserva do Iguaçu', code: 4121752 },
    { city: 'Ribeirão Claro', code: 4121802 },
    { city: 'Ribeirão do Pinhal', code: 4121901 },
    { city: 'Rio Azul', code: 4122008 },
    { city: 'Rio Bom', code: 4122107 },
    { city: 'Rio Bonito do Iguaçu', code: 4122156 },
    { city: 'Rio Branco do Ivaí', code: 4122172 },
    { city: 'Rio Branco do Sul', code: 4122206 },
    { city: 'Rio Negro', code: 4122305 },
    { city: 'Rolândia', code: 4122404 },
    { city: 'Roncador', code: 4122503 },
    { city: 'Rondon', code: 4122602 },
    { city: 'Rosário do Ivaí', code: 4122651 },
    { city: 'Sabáudia', code: 4122701 },
    { city: 'Salgado Filho', code: 4122800 },
    { city: 'Salto do Itararé', code: 4122909 },
    { city: 'Salto do Lontra', code: 4123006 },
    { city: 'Santa Amélia', code: 4123105 },
    { city: 'Santa Cecília do Pavão', code: 4123204 },
    { city: 'Santa Cruz de Monte Castelo', code: 4123303 },
    { city: 'Santa Fé', code: 4123402 },
    { city: 'Santa Helena', code: 4123501 },
    { city: 'Santa Inês', code: 4123600 },
    { city: 'Santa Isabel do Ivaí', code: 4123709 },
    { city: 'Santa Izabel do Oeste', code: 4123808 },
    { city: 'Santa Lúcia', code: 4123824 },
    { city: 'Santa Maria do Oeste', code: 4123857 },
    { city: 'Santa Mariana', code: 4123907 },
    { city: 'Santa Mônica', code: 4123956 },
    { city: 'Santa Tereza do Oeste', code: 4124020 },
    { city: 'Santa Terezinha de Itaipu', code: 4124053 },
    { city: 'Santana do Itararé', code: 4124004 },
    { city: 'Santo Antônio da Platina', code: 4124103 },
    { city: 'Santo Antônio do Caiuá', code: 4124202 },
    { city: 'Santo Antônio do Paraíso', code: 4124301 },
    { city: 'Santo Antônio do Sudoeste', code: 4124400 },
    { city: 'Santo Inácio', code: 4124509 },
    { city: 'São Carlos do Ivaí', code: 4124608 },
    { city: 'São Jerônimo da Serra', code: 4124707 },
    { city: 'São João', code: 4124806 },
    { city: 'São João do Caiuá', code: 4124905 },
    { city: 'São João do Ivaí', code: 4125001 },
    { city: 'São João do Triunfo', code: 4125100 },
    { city: 'São Jorge do Ivaí', code: 4125308 },
    { city: 'São Jorge do Patrocínio', code: 4125357 },
    { city: 'São Jorge d\'Oeste', code: 4125209 },
    { city: 'São José da Boa Vista', code: 4125407 },
    { city: 'São José das Palmeiras', code: 4125456 },
    { city: 'São José dos Pinhais', code: 4125506 },
    { city: 'São Manoel do Paraná', code: 4125555 },
    { city: 'São Mateus do Sul', code: 4125605 },
    { city: 'São Miguel do Iguaçu', code: 4125704 },
    { city: 'São Pedro do Iguaçu', code: 4125753 },
    { city: 'São Pedro do Ivaí', code: 4125803 },
    { city: 'São Pedro do Paraná', code: 4125902 },
    { city: 'São Sebastião da Amoreira', code: 4126009 },
    { city: 'São Tomé', code: 4126108 },
    { city: 'Sapopema', code: 4126207 },
    { city: 'Sarandi', code: 4126256 },
    { city: 'Saudade do Iguaçu', code: 4126272 },
    { city: 'Sengés', code: 4126306 },
    { city: 'Serranópolis do Iguaçu', code: 4126355 },
    { city: 'Sertaneja', code: 4126405 },
    { city: 'Sertanópolis', code: 4126504 },
    { city: 'Siqueira Campos', code: 4126603 },
    { city: 'Sulina', code: 4126652 },
    { city: 'Tamarana', code: 4126678 },
    { city: 'Tamboara', code: 4126702 },
    { city: 'Tapejara', code: 4126801 },
    { city: 'Tapira', code: 4126900 },
    { city: 'Teixeira Soares', code: 4127007 },
    { city: 'Telêmaco Borba', code: 4127106 },
    { city: 'Terra Boa', code: 4127205 },
    { city: 'Terra Rica', code: 4127304 },
    { city: 'Terra Roxa', code: 4127403 },
    { city: 'Tibagi', code: 4127502 },
    { city: 'Tijucas do Sul', code: 4127601 },
    { city: 'Toledo', code: 4127700 },
    { city: 'Tomazina', code: 4127809 },
    { city: 'Três Barras do Paraná', code: 4127858 },
    { city: 'Tunas do Paraná', code: 4127882 },
    { city: 'Tuneiras do Oeste', code: 4127908 },
    { city: 'Tupãssi', code: 4127957 },
    { city: 'Turvo', code: 4127965 },
    { city: 'Ubiratã', code: 4128005 },
    { city: 'Umuarama', code: 4128104 },
    { city: 'União da Vitória', code: 4128203 },
    { city: 'Uniflor', code: 4128302 },
    { city: 'Uraí', code: 4128401 },
    { city: 'Ventania', code: 4128534 },
    { city: 'Vera Cruz do Oeste', code: 4128559 },
    { city: 'Verê', code: 4128609 },
    { city: 'Virmond', code: 4128658 },
    { city: 'Vitorino', code: 4128708 },
    { city: 'Wenceslau Braz', code: 4128500 },
    { city: 'Xambrê', code: 4128807 },
  ],
  RJ: [
    { city: 'Angra dos Reis', code: 3300100 },
    { city: 'Aperibé', code: 3300159 },
    { city: 'Araruama', code: 3300209 },
    { city: 'Areal', code: 3300225 },
    { city: 'Armação dos Búzios', code: 3300233 },
    { city: 'Arraial do Cabo', code: 3300258 },
    { city: 'Barra do Piraí', code: 3300308 },
    { city: 'Barra Mansa', code: 3300407 },
    { city: 'Belford Roxo', code: 3300456 },
    { city: 'Bom Jardim', code: 3300506 },
    { city: 'Bom Jesus do Itabapoana', code: 3300605 },
    { city: 'Cabo Frio', code: 3300704 },
    { city: 'Cachoeiras de Macacu', code: 3300803 },
    { city: 'Cambuci', code: 3300902 },
    { city: 'Campos dos Goytacazes', code: 3301009 },
    { city: 'Cantagalo', code: 3301108 },
    { city: 'Carapebus', code: 3300936 },
    { city: 'Cardoso Moreira', code: 3301157 },
    { city: 'Carmo', code: 3301207 },
    { city: 'Casimiro de Abreu', code: 3301306 },
    { city: 'Comendador Levy Gasparian', code: 3300951 },
    { city: 'Conceição de Macabu', code: 3301405 },
    { city: 'Cordeiro', code: 3301504 },
    { city: 'Duas Barras', code: 3301603 },
    { city: 'Duque de Caxias', code: 3301702 },
    { city: 'Engenheiro Paulo de Frontin', code: 3301801 },
    { city: 'Guapimirim', code: 3301850 },
    { city: 'Iguaba Grande', code: 3301876 },
    { city: 'Itaboraí', code: 3301900 },
    { city: 'Itaguaí', code: 3302007 },
    { city: 'Italva', code: 3302056 },
    { city: 'Itaocara', code: 3302106 },
    { city: 'Itaperuna', code: 3302205 },
    { city: 'Itatiaia', code: 3302254 },
    { city: 'Japeri', code: 3302270 },
    { city: 'Laje do Muriaé', code: 3302304 },
    { city: 'Macaé', code: 3302403 },
    { city: 'Macuco', code: 3302452 },
    { city: 'Magé', code: 3302502 },
    { city: 'Mangaratiba', code: 3302601 },
    { city: 'Maricá', code: 3302700 },
    { city: 'Mendes', code: 3302809 },
    { city: 'Mesquita', code: 3302858 },
    { city: 'Miguel Pereira', code: 3302908 },
    { city: 'Miracema', code: 3303005 },
    { city: 'Natividade', code: 3303104 },
    { city: 'Nilópolis', code: 3303203 },
    { city: 'Niterói', code: 3303302 },
    { city: 'Nova Friburgo', code: 3303401 },
    { city: 'Nova Iguaçu', code: 3303500 },
    { city: 'Paracambi', code: 3303609 },
    { city: 'Paraíba do Sul', code: 3303708 },
    { city: 'Paraty', code: 3303807 },
    { city: 'Paty do Alferes', code: 3303856 },
    { city: 'Petrópolis', code: 3303906 },
    { city: 'Pinheiral', code: 3303955 },
    { city: 'Piraí', code: 3304003 },
    { city: 'Porciúncula', code: 3304102 },
    { city: 'Porto Real', code: 3304110 },
    { city: 'Quatis', code: 3304128 },
    { city: 'Queimados', code: 3304144 },
    { city: 'Quissamã', code: 3304151 },
    { city: 'Resende', code: 3304201 },
    { city: 'Rio Bonito', code: 3304300 },
    { city: 'Rio Claro', code: 3304409 },
    { city: 'Rio das Flores', code: 3304508 },
    { city: 'Rio das Ostras', code: 3304524 },
    { city: 'Rio de Janeiro', code: 3304557 },
    { city: 'Santa Maria Madalena', code: 3304607 },
    { city: 'Santo Antônio de Pádua', code: 3304706 },
    { city: 'São Fidélis', code: 3304805 },
    { city: 'São Francisco de Itabapoana', code: 3304755 },
    { city: 'São Gonçalo', code: 3304904 },
    { city: 'São João da Barra', code: 3305000 },
    { city: 'São João de Meriti', code: 3305109 },
    { city: 'São José de Ubá', code: 3305133 },
    { city: 'São José do Vale do Rio Preto', code: 3305158 },
    { city: 'São Pedro da Aldeia', code: 3305208 },
    { city: 'São Sebastião do Alto', code: 3305307 },
    { city: 'Sapucaia', code: 3305406 },
    { city: 'Saquarema', code: 3305505 },
    { city: 'Seropédica', code: 3305554 },
    { city: 'Silva Jardim', code: 3305604 },
    { city: 'Sumidouro', code: 3305703 },
    { city: 'Tanguá', code: 3305752 },
    { city: 'Teresópolis', code: 3305802 },
    { city: 'Trajano de Moraes', code: 3305901 },
    { city: 'Três Rios', code: 3306008 },
    { city: 'Valença', code: 3306107 },
    { city: 'Varre-Sai', code: 3306156 },
    { city: 'Vassouras', code: 3306206 },
    { city: 'Volta Redonda', code: 3306305 },
  ],
  RN: [
    { city: 'Acari', code: 2400109 },
    { city: 'Açu', code: 2400208 },
    { city: 'Afonso Bezerra', code: 2400307 },
    { city: 'Água Nova', code: 2400406 },
    { city: 'Alexandria', code: 2400505 },
    { city: 'Almino Afonso', code: 2400604 },
    { city: 'Alto do Rodrigues', code: 2400703 },
    { city: 'Angicos', code: 2400802 },
    { city: 'Antônio Martins', code: 2400901 },
    { city: 'Apodi', code: 2401008 },
    { city: 'Areia Branca', code: 2401107 },
    { city: 'Arês', code: 2401206 },
    { city: 'Augusto Severo', code: 2401305 },
    { city: 'Baía Formosa', code: 2401404 },
    { city: 'Baraúna', code: 2401453 },
    { city: 'Barcelona', code: 2401503 },
    { city: 'Bento Fernandes', code: 2401602 },
    { city: 'Bodó', code: 2401651 },
    { city: 'Bom Jesus', code: 2401701 },
    { city: 'Brejinho', code: 2401800 },
    { city: 'Caiçara do Norte', code: 2401859 },
    { city: 'Caiçara do Rio do Vento', code: 2401909 },
    { city: 'Caicó', code: 2402006 },
    { city: 'Campo Redondo', code: 2402105 },
    { city: 'Canguaretama', code: 2402204 },
    { city: 'Caraúbas', code: 2402303 },
    { city: 'Carnaúba dos Dantas', code: 2402402 },
    { city: 'Carnaubais', code: 2402501 },
    { city: 'Ceará-Mirim', code: 2402600 },
    { city: 'Cerro Corá', code: 2402709 },
    { city: 'Coronel Ezequiel', code: 2402808 },
    { city: 'Coronel João Pessoa', code: 2402907 },
    { city: 'Cruzeta', code: 2403004 },
    { city: 'Currais Novos', code: 2403103 },
    { city: 'Doutor Severiano', code: 2403202 },
    { city: 'Encanto', code: 2403301 },
    { city: 'Equador', code: 2403400 },
    { city: 'Espírito Santo', code: 2403509 },
    { city: 'Extremoz', code: 2403608 },
    { city: 'Felipe Guerra', code: 2403707 },
    { city: 'Fernando Pedroza', code: 2403756 },
    { city: 'Florânia', code: 2403806 },
    { city: 'Francisco Dantas', code: 2403905 },
    { city: 'Frutuoso Gomes', code: 2404002 },
    { city: 'Galinhos', code: 2404101 },
    { city: 'Goianinha', code: 2404200 },
    { city: 'Governador Dix-Sept Rosado', code: 2404309 },
    { city: 'Grossos', code: 2404408 },
    { city: 'Guamaré', code: 2404507 },
    { city: 'Ielmo Marinho', code: 2404606 },
    { city: 'Ipanguaçu', code: 2404705 },
    { city: 'Ipueira', code: 2404804 },
    { city: 'Itajá', code: 2404853 },
    { city: 'Itaú', code: 2404903 },
    { city: 'Jaçanã', code: 2405009 },
    { city: 'Jandaíra', code: 2405108 },
    { city: 'Janduís', code: 2405207 },
    { city: 'Januário Cicco', code: 2405306 },
    { city: 'Japi', code: 2405405 },
    { city: 'Jardim de Angicos', code: 2405504 },
    { city: 'Jardim de Piranhas', code: 2405603 },
    { city: 'Jardim do Seridó', code: 2405702 },
    { city: 'João Câmara', code: 2405801 },
    { city: 'João Dias', code: 2405900 },
    { city: 'José da Penha', code: 2406007 },
    { city: 'Jucurutu', code: 2406106 },
    { city: 'Jundiá', code: 2406155 },
    { city: 'Lagoa d\'Anta', code: 2406205 },
    { city: 'Lagoa de Pedras', code: 2406304 },
    { city: 'Lagoa de Velhos', code: 2406403 },
    { city: 'Lagoa Nova', code: 2406502 },
    { city: 'Lagoa Salgada', code: 2406601 },
    { city: 'Lajes', code: 2406700 },
    { city: 'Lajes Pintadas', code: 2406809 },
    { city: 'Lucrécia', code: 2406908 },
    { city: 'Luís Gomes', code: 2407005 },
    { city: 'Macaíba', code: 2407104 },
    { city: 'Macau', code: 2407203 },
    { city: 'Major Sales', code: 2407252 },
    { city: 'Marcelino Vieira', code: 2407302 },
    { city: 'Martins', code: 2407401 },
    { city: 'Maxaranguape', code: 2407500 },
    { city: 'Messias Targino', code: 2407609 },
    { city: 'Montanhas', code: 2407708 },
    { city: 'Monte Alegre', code: 2407807 },
    { city: 'Monte das Gameleiras', code: 2407906 },
    { city: 'Mossoró', code: 2408003 },
    { city: 'Natal', code: 2408102 },
    { city: 'Nísia Floresta', code: 2408201 },
    { city: 'Nova Cruz', code: 2408300 },
    { city: 'Olho d\'Água do Borges', code: 2408409 },
    { city: 'Ouro Branco', code: 2408508 },
    { city: 'Paraná', code: 2408607 },
    { city: 'Paraú', code: 2408706 },
    { city: 'Parazinho', code: 2408805 },
    { city: 'Parelhas', code: 2408904 },
    { city: 'Parnamirim', code: 2403251 },
    { city: 'Passa e Fica', code: 2409100 },
    { city: 'Passagem', code: 2409209 },
    { city: 'Patu', code: 2409308 },
    { city: 'Pau dos Ferros', code: 2409407 },
    { city: 'Pedra Grande', code: 2409506 },
    { city: 'Pedra Preta', code: 2409605 },
    { city: 'Pedro Avelino', code: 2409704 },
    { city: 'Pedro Velho', code: 2409803 },
    { city: 'Pendências', code: 2409902 },
    { city: 'Pilões', code: 2410009 },
    { city: 'Poço Branco', code: 2410108 },
    { city: 'Portalegre', code: 2410207 },
    { city: 'Porto do Mangue', code: 2410256 },
    { city: 'Pureza', code: 2410405 },
    { city: 'Rafael Fernandes', code: 2410504 },
    { city: 'Rafael Godeiro', code: 2410603 },
    { city: 'Riacho da Cruz', code: 2410702 },
    { city: 'Riacho de Santana', code: 2410801 },
    { city: 'Riachuelo', code: 2410900 },
    { city: 'Rio do Fogo', code: 2408953 },
    { city: 'Rodolfo Fernandes', code: 2411007 },
    { city: 'Ruy Barbosa', code: 2411106 },
    { city: 'Santa Cruz', code: 2411205 },
    { city: 'Santa Maria', code: 2409332 },
    { city: 'Santana do Matos', code: 2411403 },
    { city: 'Santana do Seridó', code: 2411429 },
    { city: 'Santo Antônio', code: 2411502 },
    { city: 'São Bento do Norte', code: 2411601 },
    { city: 'São Bento do Trairí', code: 2411700 },
    { city: 'São Fernando', code: 2411809 },
    { city: 'São Francisco do Oeste', code: 2411908 },
    { city: 'São Gonçalo do Amarante', code: 2412005 },
    { city: 'São João do Sabugi', code: 2412104 },
    { city: 'São José de Mipibu', code: 2412203 },
    { city: 'São José do Campestre', code: 2412302 },
    { city: 'São José do Seridó', code: 2412401 },
    { city: 'São Miguel', code: 2412500 },
    { city: 'São Miguel do Gostoso', code: 2412559 },
    { city: 'São Paulo do Potengi', code: 2412609 },
    { city: 'São Pedro', code: 2412708 },
    { city: 'São Rafael', code: 2412807 },
    { city: 'São Tomé', code: 2412906 },
    { city: 'São Vicente', code: 2413003 },
    { city: 'Senador Elói de Souza', code: 2413102 },
    { city: 'Senador Georgino Avelino', code: 2413201 },
    { city: 'Serra Caiada', code: 2410306 },
    { city: 'Serra de São Bento', code: 2413300 },
    { city: 'Serra do Mel', code: 2413359 },
    { city: 'Serra Negra do Norte', code: 2413409 },
    { city: 'Serrinha', code: 2413508 },
    { city: 'Serrinha dos Pintos', code: 2413557 },
    { city: 'Severiano Melo', code: 2413607 },
    { city: 'Sítio Novo', code: 2413706 },
    { city: 'Taboleiro Grande', code: 2413805 },
    { city: 'Taipu', code: 2413904 },
    { city: 'Tangará', code: 2414001 },
    { city: 'Tenente Ananias', code: 2414100 },
    { city: 'Tenente Laurentino Cruz', code: 2414159 },
    { city: 'Tibau', code: 2411056 },
    { city: 'Tibau do Sul', code: 2414209 },
    { city: 'Timbaúba dos Batistas', code: 2414308 },
    { city: 'Touros', code: 2414407 },
    { city: 'Triunfo Potiguar', code: 2414456 },
    { city: 'Umarizal', code: 2414506 },
    { city: 'Upanema', code: 2414605 },
    { city: 'Várzea', code: 2414704 },
    { city: 'Venha-Ver', code: 2414753 },
    { city: 'Vera Cruz', code: 2414803 },
    { city: 'Viçosa', code: 2414902 },
    { city: 'Vila Flor', code: 2415008 },
  ],
  RO: [
    { city: 'Alta Floresta D\'Oeste', code: 1100015 },
    { city: 'Alto Alegre dos Parecis', code: 1100379 },
    { city: 'Alto Paraíso', code: 1100403 },
    { city: 'Alvorada D\'Oeste', code: 1100346 },
    { city: 'Ariquemes', code: 1100023 },
    { city: 'Buritis', code: 1100452 },
    { city: 'Cabixi', code: 1100031 },
    { city: 'Cacaulândia', code: 1100601 },
    { city: 'Cacoal', code: 1100049 },
    { city: 'Campo Novo de Rondônia', code: 1100700 },
    { city: 'Candeias do Jamari', code: 1100809 },
    { city: 'Castanheiras', code: 1100908 },
    { city: 'Cerejeiras', code: 1100056 },
    { city: 'Chupinguaia', code: 1100924 },
    { city: 'Colorado do Oeste', code: 1100064 },
    { city: 'Corumbiara', code: 1100072 },
    { city: 'Costa Marques', code: 1100080 },
    { city: 'Cujubim', code: 1100940 },
    { city: 'Espigão D\'Oeste', code: 1100098 },
    { city: 'Governador Jorge Teixeira', code: 1101005 },
    { city: 'Guajará-Mirim', code: 1100106 },
    { city: 'Itapuã do Oeste', code: 1101104 },
    { city: 'Jaru', code: 1100114 },
    { city: 'Ji-Paraná', code: 1100122 },
    { city: 'Machadinho D\'Oeste', code: 1100130 },
    { city: 'Ministro Andreazza', code: 1101203 },
    { city: 'Mirante da Serra', code: 1101302 },
    { city: 'Monte Negro', code: 1101401 },
    { city: 'Nova Brasilândia D\'Oeste', code: 1100148 },
    { city: 'Nova Mamoré', code: 1100338 },
    { city: 'Nova União', code: 1101435 },
    { city: 'Novo Horizonte do Oeste', code: 1100502 },
    { city: 'Ouro Preto do Oeste', code: 1100155 },
    { city: 'Parecis', code: 1101450 },
    { city: 'Pimenta Bueno', code: 1100189 },
    { city: 'Pimenteiras do Oeste', code: 1101468 },
    { city: 'Porto Velho', code: 1100205 },
    { city: 'Presidente Médici', code: 1100254 },
    { city: 'Primavera de Rondônia', code: 1101476 },
    { city: 'Rio Crespo', code: 1100262 },
    { city: 'Rolim de Moura', code: 1100288 },
    { city: 'Santa Luzia D\'Oeste', code: 1100296 },
    { city: 'São Felipe D\'Oeste', code: 1101484 },
    { city: 'São Francisco do Guaporé', code: 1101492 },
    { city: 'São Miguel do Guaporé', code: 1100320 },
    { city: 'Seringueiras', code: 1101500 },
    { city: 'Teixeirópolis', code: 1101559 },
    { city: 'Theobroma', code: 1101609 },
    { city: 'Urupá', code: 1101708 },
    { city: 'Vale do Anari', code: 1101757 },
    { city: 'Vale do Paraíso', code: 1101807 },
    { city: 'Vilhena', code: 1100304 },
  ],
  RR: [
    { city: 'Alto Alegre', code: 1400050 },
    { city: 'Amajari', code: 1400027 },
    { city: 'Boa Vista', code: 1400100 },
    { city: 'Bonfim', code: 1400159 },
    { city: 'Cantá', code: 1400175 },
    { city: 'Caracaraí', code: 1400209 },
    { city: 'Caroebe', code: 1400233 },
    { city: 'Iracema', code: 1400282 },
    { city: 'Mucajaí', code: 1400308 },
    { city: 'Normandia', code: 1400407 },
    { city: 'Pacaraima', code: 1400456 },
    { city: 'Rorainópolis', code: 1400472 },
    { city: 'São João da Baliza', code: 1400506 },
    { city: 'São Luiz', code: 1400605 },
    { city: 'Uiramutã', code: 1400704 },
  ],
  RS: [
    { city: 'Aceguá', code: 4300034 },
    { city: 'Água Santa', code: 4300059 },
    { city: 'Agudo', code: 4300109 },
    { city: 'Ajuricaba', code: 4300208 },
    { city: 'Alecrim', code: 4300307 },
    { city: 'Alegrete', code: 4300406 },
    { city: 'Alegria', code: 4300455 },
    { city: 'Almirante Tamandaré do Sul', code: 4300471 },
    { city: 'Alpestre', code: 4300505 },
    { city: 'Alto Alegre', code: 4300554 },
    { city: 'Alto Feliz', code: 4300570 },
    { city: 'Alvorada', code: 4300604 },
    { city: 'Amaral Ferrador', code: 4300638 },
    { city: 'Ametista do Sul', code: 4300646 },
    { city: 'André da Rocha', code: 4300661 },
    { city: 'Anta Gorda', code: 4300703 },
    { city: 'Antônio Prado', code: 4300802 },
    { city: 'Arambaré', code: 4300851 },
    { city: 'Araricá', code: 4300877 },
    { city: 'Aratiba', code: 4300901 },
    { city: 'Arroio do Meio', code: 4301008 },
    { city: 'Arroio do Padre', code: 4301073 },
    { city: 'Arroio do Sal', code: 4301057 },
    { city: 'Arroio do Tigre', code: 4301206 },
    { city: 'Arroio dos Ratos', code: 4301107 },
    { city: 'Arroio Grande', code: 4301305 },
    { city: 'Arvorezinha', code: 4301404 },
    { city: 'Augusto Pestana', code: 4301503 },
    { city: 'Áurea', code: 4301552 },
    { city: 'Bagé', code: 4301602 },
    { city: 'Balneário Pinhal', code: 4301636 },
    { city: 'Barão', code: 4301651 },
    { city: 'Barão de Cotegipe', code: 4301701 },
    { city: 'Barão do Triunfo', code: 4301750 },
    { city: 'Barra do Guarita', code: 4301859 },
    { city: 'Barra do Quaraí', code: 4301875 },
    { city: 'Barra do Ribeiro', code: 4301909 },
    { city: 'Barra do Rio Azul', code: 4301925 },
    { city: 'Barra Funda', code: 4301958 },
    { city: 'Barracão', code: 4301800 },
    { city: 'Barros Cassal', code: 4302006 },
    { city: 'Benjamin Constant do Sul', code: 4302055 },
    { city: 'Bento Gonçalves', code: 4302105 },
    { city: 'Boa Vista das Missões', code: 4302154 },
    { city: 'Boa Vista do Buricá', code: 4302204 },
    { city: 'Boa Vista do Cadeado', code: 4302220 },
    { city: 'Boa Vista do Incra', code: 4302238 },
    { city: 'Boa Vista do Sul', code: 4302253 },
    { city: 'Bom Jesus', code: 4302303 },
    { city: 'Bom Princípio', code: 4302352 },
    { city: 'Bom Progresso', code: 4302378 },
    { city: 'Bom Retiro do Sul', code: 4302402 },
    { city: 'Boqueirão do Leão', code: 4302451 },
    { city: 'Bossoroca', code: 4302501 },
    { city: 'Bozano', code: 4302584 },
    { city: 'Braga', code: 4302600 },
    { city: 'Brochier', code: 4302659 },
    { city: 'Butiá', code: 4302709 },
    { city: 'Caçapava do Sul', code: 4302808 },
    { city: 'Cacequi', code: 4302907 },
    { city: 'Cachoeira do Sul', code: 4303004 },
    { city: 'Cachoeirinha', code: 4303103 },
    { city: 'Cacique Doble', code: 4303202 },
    { city: 'Caibaté', code: 4303301 },
    { city: 'Caiçara', code: 4303400 },
    { city: 'Camaquã', code: 4303509 },
    { city: 'Camargo', code: 4303558 },
    { city: 'Cambará do Sul', code: 4303608 },
    { city: 'Campestre da Serra', code: 4303673 },
    { city: 'Campina das Missões', code: 4303707 },
    { city: 'Campinas do Sul', code: 4303806 },
    { city: 'Campo Bom', code: 4303905 },
    { city: 'Campo Novo', code: 4304002 },
    { city: 'Campos Borges', code: 4304101 },
    { city: 'Candelária', code: 4304200 },
    { city: 'Cândido Godói', code: 4304309 },
    { city: 'Candiota', code: 4304358 },
    { city: 'Canela', code: 4304408 },
    { city: 'Canguçu', code: 4304507 },
    { city: 'Canoas', code: 4304606 },
    { city: 'Canudos do Vale', code: 4304614 },
    { city: 'Capão Bonito do Sul', code: 4304622 },
    { city: 'Capão da Canoa', code: 4304630 },
    { city: 'Capão do Cipó', code: 4304655 },
    { city: 'Capão do Leão', code: 4304663 },
    { city: 'Capela de Santana', code: 4304689 },
    { city: 'Capitão', code: 4304697 },
    { city: 'Capivari do Sul', code: 4304671 },
    { city: 'Caraá', code: 4304713 },
    { city: 'Carazinho', code: 4304705 },
    { city: 'Carlos Barbosa', code: 4304804 },
    { city: 'Carlos Gomes', code: 4304853 },
    { city: 'Casca', code: 4304903 },
    { city: 'Caseiros', code: 4304952 },
    { city: 'Catuípe', code: 4305009 },
    { city: 'Caxias do Sul', code: 4305108 },
    { city: 'Centenário', code: 4305116 },
    { city: 'Cerrito', code: 4305124 },
    { city: 'Cerro Branco', code: 4305132 },
    { city: 'Cerro Grande', code: 4305157 },
    { city: 'Cerro Grande do Sul', code: 4305173 },
    { city: 'Cerro Largo', code: 4305207 },
    { city: 'Chapada', code: 4305306 },
    { city: 'Charqueadas', code: 4305355 },
    { city: 'Charrua', code: 4305371 },
    { city: 'Chiapetta', code: 4305405 },
    { city: 'Chuí', code: 4305439 },
    { city: 'Chuvisca', code: 4305447 },
    { city: 'Cidreira', code: 4305454 },
    { city: 'Ciríaco', code: 4305504 },
    { city: 'Colinas', code: 4305587 },
    { city: 'Colorado', code: 4305603 },
    { city: 'Condor', code: 4305702 },
    { city: 'Constantina', code: 4305801 },
    { city: 'Coqueiro Baixo', code: 4305835 },
    { city: 'Coqueiros do Sul', code: 4305850 },
    { city: 'Coronel Barros', code: 4305871 },
    { city: 'Coronel Bicaco', code: 4305900 },
    { city: 'Coronel Pilar', code: 4305934 },
    { city: 'Cotiporã', code: 4305959 },
    { city: 'Coxilha', code: 4305975 },
    { city: 'Crissiumal', code: 4306007 },
    { city: 'Cristal', code: 4306056 },
    { city: 'Cristal do Sul', code: 4306072 },
    { city: 'Cruz Alta', code: 4306106 },
    { city: 'Cruzaltense', code: 4306130 },
    { city: 'Cruzeiro do Sul', code: 4306205 },
    { city: 'David Canabarro', code: 4306304 },
    { city: 'Derrubadas', code: 4306320 },
    { city: 'Dezesseis de Novembro', code: 4306353 },
    { city: 'Dilermando de Aguiar', code: 4306379 },
    { city: 'Dois Irmãos', code: 4306403 },
    { city: 'Dois Irmãos das Missões', code: 4306429 },
    { city: 'Dois Lajeados', code: 4306452 },
    { city: 'Dom Feliciano', code: 4306502 },
    { city: 'Dom Pedrito', code: 4306601 },
    { city: 'Dom Pedro de Alcântara', code: 4306551 },
    { city: 'Dona Francisca', code: 4306700 },
    { city: 'Doutor Maurício Cardoso', code: 4306734 },
    { city: 'Doutor Ricardo', code: 4306759 },
    { city: 'Eldorado do Sul', code: 4306767 },
    { city: 'Encantado', code: 4306809 },
    { city: 'Encruzilhada do Sul', code: 4306908 },
    { city: 'Engenho Velho', code: 4306924 },
    { city: 'Entre Rios do Sul', code: 4306957 },
    { city: 'Entre-Ijuís', code: 4306932 },
    { city: 'Erebango', code: 4306973 },
    { city: 'Erechim', code: 4307005 },
    { city: 'Ernestina', code: 4307054 },
    { city: 'Erval Grande', code: 4307203 },
    { city: 'Erval Seco', code: 4307302 },
    { city: 'Esmeralda', code: 4307401 },
    { city: 'Esperança do Sul', code: 4307450 },
    { city: 'Espumoso', code: 4307500 },
    { city: 'Estação', code: 4307559 },
    { city: 'Estância Velha', code: 4307609 },
    { city: 'Esteio', code: 4307708 },
    { city: 'Estrela', code: 4307807 },
    { city: 'Estrela Velha', code: 4307815 },
    { city: 'Eugênio de Castro', code: 4307831 },
    { city: 'Fagundes Varela', code: 4307864 },
    { city: 'Farroupilha', code: 4307906 },
    { city: 'Faxinal do Soturno', code: 4308003 },
    { city: 'Faxinalzinho', code: 4308052 },
    { city: 'Fazenda Vilanova', code: 4308078 },
    { city: 'Feliz', code: 4308102 },
    { city: 'Flores da Cunha', code: 4308201 },
    { city: 'Floriano Peixoto', code: 4308250 },
    { city: 'Fontoura Xavier', code: 4308300 },
    { city: 'Formigueiro', code: 4308409 },
    { city: 'Forquetinha', code: 4308433 },
    { city: 'Fortaleza dos Valos', code: 4308458 },
    { city: 'Frederico Westphalen', code: 4308508 },
    { city: 'Garibaldi', code: 4308607 },
    { city: 'Garruchos', code: 4308656 },
    { city: 'Gaurama', code: 4308706 },
    { city: 'General Câmara', code: 4308805 },
    { city: 'Gentil', code: 4308854 },
    { city: 'Getúlio Vargas', code: 4308904 },
    { city: 'Giruá', code: 4309001 },
    { city: 'Glorinha', code: 4309050 },
    { city: 'Gramado', code: 4309100 },
    { city: 'Gramado dos Loureiros', code: 4309126 },
    { city: 'Gramado Xavier', code: 4309159 },
    { city: 'Gravataí', code: 4309209 },
    { city: 'Guabiju', code: 4309258 },
    { city: 'Guaíba', code: 4309308 },
    { city: 'Guaporé', code: 4309407 },
    { city: 'Guarani das Missões', code: 4309506 },
    { city: 'Harmonia', code: 4309555 },
    { city: 'Herval', code: 4307104 },
    { city: 'Herveiras', code: 4309571 },
    { city: 'Horizontina', code: 4309605 },
    { city: 'Hulha Negra', code: 4309654 },
    { city: 'Humaitá', code: 4309704 },
    { city: 'Ibarama', code: 4309753 },
    { city: 'Ibiaçá', code: 4309803 },
    { city: 'Ibiraiaras', code: 4309902 },
    { city: 'Ibirapuitã', code: 4309951 },
    { city: 'Ibirubá', code: 4310009 },
    { city: 'Igrejinha', code: 4310108 },
    { city: 'Ijuí', code: 4310207 },
    { city: 'Ilópolis', code: 4310306 },
    { city: 'Imbé', code: 4310330 },
    { city: 'Imigrante', code: 4310363 },
    { city: 'Independência', code: 4310405 },
    { city: 'Inhacorá', code: 4310413 },
    { city: 'Ipê', code: 4310439 },
    { city: 'Ipiranga do Sul', code: 4310462 },
    { city: 'Iraí', code: 4310504 },
    { city: 'Itaara', code: 4310538 },
    { city: 'Itacurubi', code: 4310553 },
    { city: 'Itapuca', code: 4310579 },
    { city: 'Itaqui', code: 4310603 },
    { city: 'Itati', code: 4310652 },
    { city: 'Itatiba do Sul', code: 4310702 },
    { city: 'Ivorá', code: 4310751 },
    { city: 'Ivoti', code: 4310801 },
    { city: 'Jaboticaba', code: 4310850 },
    { city: 'Jacuizinho', code: 4310876 },
    { city: 'Jacutinga', code: 4310900 },
    { city: 'Jaguarão', code: 4311007 },
    { city: 'Jaguari', code: 4311106 },
    { city: 'Jaquirana', code: 4311122 },
    { city: 'Jari', code: 4311130 },
    { city: 'Jóia', code: 4311155 },
    { city: 'Júlio de Castilhos', code: 4311205 },
    { city: 'Lagoa Bonita do Sul', code: 4311239 },
    { city: 'Lagoa dos Três Cantos', code: 4311270 },
    { city: 'Lagoa Vermelha', code: 4311304 },
    { city: 'Lagoão', code: 4311254 },
    { city: 'Lajeado', code: 4311403 },
    { city: 'Lajeado do Bugre', code: 4311429 },
    { city: 'Lavras do Sul', code: 4311502 },
    { city: 'Liberato Salzano', code: 4311601 },
    { city: 'Lindolfo Collor', code: 4311627 },
    { city: 'Linha Nova', code: 4311643 },
    { city: 'Maçambará', code: 4311718 },
    { city: 'Machadinho', code: 4311700 },
    { city: 'Mampituba', code: 4311734 },
    { city: 'Manoel Viana', code: 4311759 },
    { city: 'Maquiné', code: 4311775 },
    { city: 'Maratá', code: 4311791 },
    { city: 'Marau', code: 4311809 },
    { city: 'Marcelino Ramos', code: 4311908 },
    { city: 'Mariana Pimentel', code: 4311981 },
    { city: 'Mariano Moro', code: 4312005 },
    { city: 'Marques de Souza', code: 4312054 },
    { city: 'Mata', code: 4312104 },
    { city: 'Mato Castelhano', code: 4312138 },
    { city: 'Mato Leitão', code: 4312153 },
    { city: 'Mato Queimado', code: 4312179 },
    { city: 'Maximiliano de Almeida', code: 4312203 },
    { city: 'Minas do Leão', code: 4312252 },
    { city: 'Miraguaí', code: 4312302 },
    { city: 'Montauri', code: 4312351 },
    { city: 'Monte Alegre dos Campos', code: 4312377 },
    { city: 'Monte Belo do Sul', code: 4312385 },
    { city: 'Montenegro', code: 4312401 },
    { city: 'Mormaço', code: 4312427 },
    { city: 'Morrinhos do Sul', code: 4312443 },
    { city: 'Morro Redondo', code: 4312450 },
    { city: 'Morro Reuter', code: 4312476 },
    { city: 'Mostardas', code: 4312500 },
    { city: 'Muçum', code: 4312609 },
    { city: 'Muitos Capões', code: 4312617 },
    { city: 'Muliterno', code: 4312625 },
    { city: 'Não-Me-Toque', code: 4312658 },
    { city: 'Nicolau Vergueiro', code: 4312674 },
    { city: 'Nonoai', code: 4312708 },
    { city: 'Nova Alvorada', code: 4312757 },
    { city: 'Nova Araçá', code: 4312807 },
    { city: 'Nova Bassano', code: 4312906 },
    { city: 'Nova Boa Vista', code: 4312955 },
    { city: 'Nova Bréscia', code: 4313003 },
    { city: 'Nova Candelária', code: 4313011 },
    { city: 'Nova Esperança do Sul', code: 4313037 },
    { city: 'Nova Hartz', code: 4313060 },
    { city: 'Nova Pádua', code: 4313086 },
    { city: 'Nova Palma', code: 4313102 },
    { city: 'Nova Petrópolis', code: 4313201 },
    { city: 'Nova Prata', code: 4313300 },
    { city: 'Nova Ramada', code: 4313334 },
    { city: 'Nova Roma do Sul', code: 4313359 },
    { city: 'Nova Santa Rita', code: 4313375 },
    { city: 'Novo Barreiro', code: 4313490 },
    { city: 'Novo Cabrais', code: 4313391 },
    { city: 'Novo Hamburgo', code: 4313409 },
    { city: 'Novo Machado', code: 4313425 },
    { city: 'Novo Tiradentes', code: 4313441 },
    { city: 'Novo Xingu', code: 4313466 },
    { city: 'Osório', code: 4313508 },
    { city: 'Paim Filho', code: 4313607 },
    { city: 'Palmares do Sul', code: 4313656 },
    { city: 'Palmeira das Missões', code: 4313706 },
    { city: 'Palmitinho', code: 4313805 },
    { city: 'Panambi', code: 4313904 },
    { city: 'Pantano Grande', code: 4313953 },
    { city: 'Paraí', code: 4314001 },
    { city: 'Paraíso do Sul', code: 4314027 },
    { city: 'Pareci Novo', code: 4314035 },
    { city: 'Parobé', code: 4314050 },
    { city: 'Passa Sete', code: 4314068 },
    { city: 'Passo do Sobrado', code: 4314076 },
    { city: 'Passo Fundo', code: 4314100 },
    { city: 'Paulo Bento', code: 4314134 },
    { city: 'Paverama', code: 4314159 },
    { city: 'Pedras Altas', code: 4314175 },
    { city: 'Pedro Osório', code: 4314209 },
    { city: 'Pejuçara', code: 4314308 },
    { city: 'Pelotas', code: 4314407 },
    { city: 'Picada Café', code: 4314423 },
    { city: 'Pinhal', code: 4314456 },
    { city: 'Pinhal da Serra', code: 4314464 },
    { city: 'Pinhal Grande', code: 4314472 },
    { city: 'Pinheirinho do Vale', code: 4314498 },
    { city: 'Pinheiro Machado', code: 4314506 },
    { city: 'Pinto Bandeira', code: 4314548 },
    { city: 'Pirapó', code: 4314555 },
    { city: 'Piratini', code: 4314605 },
    { city: 'Planalto', code: 4314704 },
    { city: 'Poço das Antas', code: 4314753 },
    { city: 'Pontão', code: 4314779 },
    { city: 'Ponte Preta', code: 4314787 },
    { city: 'Portão', code: 4314803 },
    { city: 'Porto Alegre', code: 4314902 },
    { city: 'Porto Lucena', code: 4315008 },
    { city: 'Porto Mauá', code: 4315057 },
    { city: 'Porto Vera Cruz', code: 4315073 },
    { city: 'Porto Xavier', code: 4315107 },
    { city: 'Pouso Novo', code: 4315131 },
    { city: 'Presidente Lucena', code: 4315149 },
    { city: 'Progresso', code: 4315156 },
    { city: 'Protásio Alves', code: 4315172 },
    { city: 'Putinga', code: 4315206 },
    { city: 'Quaraí', code: 4315305 },
    { city: 'Quatro Irmãos', code: 4315313 },
    { city: 'Quevedos', code: 4315321 },
    { city: 'Quinze de Novembro', code: 4315354 },
    { city: 'Redentora', code: 4315404 },
    { city: 'Relvado', code: 4315453 },
    { city: 'Restinga Sêca', code: 4315503 },
    { city: 'Rio dos Índios', code: 4315552 },
    { city: 'Rio Grande', code: 4315602 },
    { city: 'Rio Pardo', code: 4315701 },
    { city: 'Riozinho', code: 4315750 },
    { city: 'Roca Sales', code: 4315800 },
    { city: 'Rodeio Bonito', code: 4315909 },
    { city: 'Rolador', code: 4315958 },
    { city: 'Rolante', code: 4316006 },
    { city: 'Ronda Alta', code: 4316105 },
    { city: 'Rondinha', code: 4316204 },
    { city: 'Roque Gonzales', code: 4316303 },
    { city: 'Rosário do Sul', code: 4316402 },
    { city: 'Sagrada Família', code: 4316428 },
    { city: 'Saldanha Marinho', code: 4316436 },
    { city: 'Salto do Jacuí', code: 4316451 },
    { city: 'Salvador das Missões', code: 4316477 },
    { city: 'Salvador do Sul', code: 4316501 },
    { city: 'Sananduva', code: 4316600 },
    { city: 'Santa Bárbara do Sul', code: 4316709 },
    { city: 'Santa Cecília do Sul', code: 4316733 },
    { city: 'Santa Clara do Sul', code: 4316758 },
    { city: 'Santa Cruz do Sul', code: 4316808 },
    { city: 'Santa Margarida do Sul', code: 4316972 },
    { city: 'Santa Maria', code: 4316907 },
    { city: 'Santa Maria do Herval', code: 4316956 },
    { city: 'Santa Rosa', code: 4317202 },
    { city: 'Santa Tereza', code: 4317251 },
    { city: 'Santa Vitória do Palmar', code: 4317301 },
    { city: 'Santana da Boa Vista', code: 4317004 },
    { city: 'Sant\'Ana do Livramento', code: 4317103 },
    { city: 'Santiago', code: 4317400 },
    { city: 'Santo Ângelo', code: 4317509 },
    { city: 'Santo Antônio da Patrulha', code: 4317608 },
    { city: 'Santo Antônio das Missões', code: 4317707 },
    { city: 'Santo Antônio do Palma', code: 4317558 },
    { city: 'Santo Antônio do Planalto', code: 4317756 },
    { city: 'Santo Augusto', code: 4317806 },
    { city: 'Santo Cristo', code: 4317905 },
    { city: 'Santo Expedito do Sul', code: 4317954 },
    { city: 'São Borja', code: 4318002 },
    { city: 'São Domingos do Sul', code: 4318051 },
    { city: 'São Francisco de Assis', code: 4318101 },
    { city: 'São Francisco de Paula', code: 4318200 },
    { city: 'São Gabriel', code: 4318309 },
    { city: 'São Jerônimo', code: 4318408 },
    { city: 'São João da Urtiga', code: 4318424 },
    { city: 'São João do Polêsine', code: 4318432 },
    { city: 'São Jorge', code: 4318440 },
    { city: 'São José das Missões', code: 4318457 },
    { city: 'São José do Herval', code: 4318465 },
    { city: 'São José do Hortêncio', code: 4318481 },
    { city: 'São José do Inhacorá', code: 4318499 },
    { city: 'São José do Norte', code: 4318507 },
    { city: 'São José do Ouro', code: 4318606 },
    { city: 'São José do Sul', code: 4318614 },
    { city: 'São José dos Ausentes', code: 4318622 },
    { city: 'São Leopoldo', code: 4318705 },
    { city: 'São Lourenço do Sul', code: 4318804 },
    { city: 'São Luiz Gonzaga', code: 4318903 },
    { city: 'São Marcos', code: 4319000 },
    { city: 'São Martinho', code: 4319109 },
    { city: 'São Martinho da Serra', code: 4319125 },
    { city: 'São Miguel das Missões', code: 4319158 },
    { city: 'São Nicolau', code: 4319208 },
    { city: 'São Paulo das Missões', code: 4319307 },
    { city: 'São Pedro da Serra', code: 4319356 },
    { city: 'São Pedro das Missões', code: 4319364 },
    { city: 'São Pedro do Butiá', code: 4319372 },
    { city: 'São Pedro do Sul', code: 4319406 },
    { city: 'São Sebastião do Caí', code: 4319505 },
    { city: 'São Sepé', code: 4319604 },
    { city: 'São Valentim', code: 4319703 },
    { city: 'São Valentim do Sul', code: 4319711 },
    { city: 'São Valério do Sul', code: 4319737 },
    { city: 'São Vendelino', code: 4319752 },
    { city: 'São Vicente do Sul', code: 4319802 },
    { city: 'Sapiranga', code: 4319901 },
    { city: 'Sapucaia do Sul', code: 4320008 },
    { city: 'Sarandi', code: 4320107 },
    { city: 'Seberi', code: 4320206 },
    { city: 'Sede Nova', code: 4320230 },
    { city: 'Segredo', code: 4320263 },
    { city: 'Selbach', code: 4320305 },
    { city: 'Senador Salgado Filho', code: 4320321 },
    { city: 'Sentinela do Sul', code: 4320354 },
    { city: 'Serafina Corrêa', code: 4320404 },
    { city: 'Sério', code: 4320453 },
    { city: 'Sertão', code: 4320503 },
    { city: 'Sertão Santana', code: 4320552 },
    { city: 'Sete de Setembro', code: 4320578 },
    { city: 'Severiano de Almeida', code: 4320602 },
    { city: 'Silveira Martins', code: 4320651 },
    { city: 'Sinimbu', code: 4320677 },
    { city: 'Sobradinho', code: 4320701 },
    { city: 'Soledade', code: 4320800 },
    { city: 'Tabaí', code: 4320859 },
    { city: 'Tapejara', code: 4320909 },
    { city: 'Tapera', code: 4321006 },
    { city: 'Tapes', code: 4321105 },
    { city: 'Taquara', code: 4321204 },
    { city: 'Taquari', code: 4321303 },
    { city: 'Taquaruçu do Sul', code: 4321329 },
    { city: 'Tavares', code: 4321352 },
    { city: 'Tenente Portela', code: 4321402 },
    { city: 'Terra de Areia', code: 4321436 },
    { city: 'Teutônia', code: 4321451 },
    { city: 'Tio Hugo', code: 4321469 },
    { city: 'Tiradentes do Sul', code: 4321477 },
    { city: 'Toropi', code: 4321493 },
    { city: 'Torres', code: 4321501 },
    { city: 'Tramandaí', code: 4321600 },
    { city: 'Travesseiro', code: 4321626 },
    { city: 'Três Arroios', code: 4321634 },
    { city: 'Três Cachoeiras', code: 4321667 },
    { city: 'Três Coroas', code: 4321709 },
    { city: 'Três de Maio', code: 4321808 },
    { city: 'Três Forquilhas', code: 4321832 },
    { city: 'Três Palmeiras', code: 4321857 },
    { city: 'Três Passos', code: 4321907 },
    { city: 'Trindade do Sul', code: 4321956 },
    { city: 'Triunfo', code: 4322004 },
    { city: 'Tucunduva', code: 4322103 },
    { city: 'Tunas', code: 4322152 },
    { city: 'Tupanci do Sul', code: 4322186 },
    { city: 'Tupanciretã', code: 4322202 },
    { city: 'Tupandi', code: 4322251 },
    { city: 'Tuparendi', code: 4322301 },
    { city: 'Turuçu', code: 4322327 },
    { city: 'Ubiretama', code: 4322343 },
    { city: 'União da Serra', code: 4322350 },
    { city: 'Unistalda', code: 4322376 },
    { city: 'Uruguaiana', code: 4322400 },
    { city: 'Vacaria', code: 4322509 },
    { city: 'Vale do Sol', code: 4322533 },
    { city: 'Vale Real', code: 4322541 },
    { city: 'Vale Verde', code: 4322525 },
    { city: 'Vanini', code: 4322558 },
    { city: 'Venâncio Aires', code: 4322608 },
    { city: 'Vera Cruz', code: 4322707 },
    { city: 'Veranópolis', code: 4322806 },
    { city: 'Vespasiano Corrêa', code: 4322855 },
    { city: 'Viadutos', code: 4322905 },
    { city: 'Viamão', code: 4323002 },
    { city: 'Vicente Dutra', code: 4323101 },
    { city: 'Victor Graeff', code: 4323200 },
    { city: 'Vila Flores', code: 4323309 },
    { city: 'Vila Lângaro', code: 4323358 },
    { city: 'Vila Maria', code: 4323408 },
    { city: 'Vila Nova do Sul', code: 4323457 },
    { city: 'Vista Alegre', code: 4323507 },
    { city: 'Vista Alegre do Prata', code: 4323606 },
    { city: 'Vista Gaúcha', code: 4323705 },
    { city: 'Vitória das Missões', code: 4323754 },
    { city: 'Westfália', code: 4323770 },
    { city: 'Xangri-lá', code: 4323804 },
  ],
  SC: [
    { city: 'Abdon Batista', code: 4200051 },
    { city: 'Abelardo Luz', code: 4200101 },
    { city: 'Agrolândia', code: 4200200 },
    { city: 'Agronômica', code: 4200309 },
    { city: 'Água Doce', code: 4200408 },
    { city: 'Águas de Chapecó', code: 4200507 },
    { city: 'Águas Frias', code: 4200556 },
    { city: 'Águas Mornas', code: 4200606 },
    { city: 'Alfredo Wagner', code: 4200705 },
    { city: 'Alto Bela Vista', code: 4200754 },
    { city: 'Anchieta', code: 4200804 },
    { city: 'Angelina', code: 4200903 },
    { city: 'Anita Garibaldi', code: 4201000 },
    { city: 'Anitápolis', code: 4201109 },
    { city: 'Antônio Carlos', code: 4201208 },
    { city: 'Apiúna', code: 4201257 },
    { city: 'Arabutã', code: 4201273 },
    { city: 'Araquari', code: 4201307 },
    { city: 'Araranguá', code: 4201406 },
    { city: 'Armazém', code: 4201505 },
    { city: 'Arroio Trinta', code: 4201604 },
    { city: 'Arvoredo', code: 4201653 },
    { city: 'Ascurra', code: 4201703 },
    { city: 'Atalanta', code: 4201802 },
    { city: 'Aurora', code: 4201901 },
    { city: 'Balneário Arroio do Silva', code: 4201950 },
    { city: 'Balneário Barra do Sul', code: 4202057 },
    { city: 'Balneário Camboriú', code: 4202008 },
    { city: 'Balneário Gaivota', code: 4202073 },
    { city: 'Balneário Piçarras', code: 4212809 },
    { city: 'Balneário Rincão', code: 4220000 },
    { city: 'Bandeirante', code: 4202081 },
    { city: 'Barra Bonita', code: 4202099 },
    { city: 'Barra Velha', code: 4202107 },
    { city: 'Bela Vista do Toldo', code: 4202131 },
    { city: 'Belmonte', code: 4202156 },
    { city: 'Benedito Novo', code: 4202206 },
    { city: 'Biguaçu', code: 4202305 },
    { city: 'Blumenau', code: 4202404 },
    { city: 'Bocaina do Sul', code: 4202438 },
    { city: 'Bom Jardim da Serra', code: 4202503 },
    { city: 'Bom Jesus', code: 4202537 },
    { city: 'Bom Jesus do Oeste', code: 4202578 },
    { city: 'Bom Retiro', code: 4202602 },
    { city: 'Bombinhas', code: 4202453 },
    { city: 'Botuverá', code: 4202701 },
    { city: 'Braço do Norte', code: 4202800 },
    { city: 'Braço do Trombudo', code: 4202859 },
    { city: 'Brunópolis', code: 4202875 },
    { city: 'Brusque', code: 4202909 },
    { city: 'Caçador', code: 4203006 },
    { city: 'Caibi', code: 4203105 },
    { city: 'Calmon', code: 4203154 },
    { city: 'Camboriú', code: 4203204 },
    { city: 'Campo Alegre', code: 4203303 },
    { city: 'Campo Belo do Sul', code: 4203402 },
    { city: 'Campo Erê', code: 4203501 },
    { city: 'Campos Novos', code: 4203600 },
    { city: 'Canelinha', code: 4203709 },
    { city: 'Canoinhas', code: 4203808 },
    { city: 'Capão Alto', code: 4203253 },
    { city: 'Capinzal', code: 4203907 },
    { city: 'Capivari de Baixo', code: 4203956 },
    { city: 'Catanduvas', code: 4204004 },
    { city: 'Caxambu do Sul', code: 4204103 },
    { city: 'Celso Ramos', code: 4204152 },
    { city: 'Cerro Negro', code: 4204178 },
    { city: 'Chapadão do Lageado', code: 4204194 },
    { city: 'Chapecó', code: 4204202 },
    { city: 'Cocal do Sul', code: 4204251 },
    { city: 'Concórdia', code: 4204301 },
    { city: 'Cordilheira Alta', code: 4204350 },
    { city: 'Coronel Freitas', code: 4204400 },
    { city: 'Coronel Martins', code: 4204459 },
    { city: 'Correia Pinto', code: 4204558 },
    { city: 'Corupá', code: 4204509 },
    { city: 'Criciúma', code: 4204608 },
    { city: 'Cunha Porã', code: 4204707 },
    { city: 'Cunhataí', code: 4204756 },
    { city: 'Curitibanos', code: 4204806 },
    { city: 'Descanso', code: 4204905 },
    { city: 'Dionísio Cerqueira', code: 4205001 },
    { city: 'Dona Emma', code: 4205100 },
    { city: 'Doutor Pedrinho', code: 4205159 },
    { city: 'Entre Rios', code: 4205175 },
    { city: 'Ermo', code: 4205191 },
    { city: 'Erval Velho', code: 4205209 },
    { city: 'Faxinal dos Guedes', code: 4205308 },
    { city: 'Flor do Sertão', code: 4205357 },
    { city: 'Florianópolis', code: 4205407 },
    { city: 'Formosa do Sul', code: 4205431 },
    { city: 'Forquilhinha', code: 4205456 },
    { city: 'Fraiburgo', code: 4205506 },
    { city: 'Frei Rogério', code: 4205555 },
    { city: 'Galvão', code: 4205605 },
    { city: 'Garopaba', code: 4205704 },
    { city: 'Garuva', code: 4205803 },
    { city: 'Gaspar', code: 4205902 },
    { city: 'Governador Celso Ramos', code: 4206009 },
    { city: 'Grão Pará', code: 4206108 },
    { city: 'Gravatal', code: 4206207 },
    { city: 'Guabiruba', code: 4206306 },
    { city: 'Guaraciaba', code: 4206405 },
    { city: 'Guaramirim', code: 4206504 },
    { city: 'Guarujá do Sul', code: 4206603 },
    { city: 'Guatambú', code: 4206652 },
    { city: 'Herval d\'Oeste', code: 4206702 },
    { city: 'Ibiam', code: 4206751 },
    { city: 'Ibicaré', code: 4206801 },
    { city: 'Ibirama', code: 4206900 },
    { city: 'Içara', code: 4207007 },
    { city: 'Ilhota', code: 4207106 },
    { city: 'Imaruí', code: 4207205 },
    { city: 'Imbituba', code: 4207304 },
    { city: 'Imbuia', code: 4207403 },
    { city: 'Indaial', code: 4207502 },
    { city: 'Iomerê', code: 4207577 },
    { city: 'Ipira', code: 4207601 },
    { city: 'Iporã do Oeste', code: 4207650 },
    { city: 'Ipuaçu', code: 4207684 },
    { city: 'Ipumirim', code: 4207700 },
    { city: 'Iraceminha', code: 4207759 },
    { city: 'Irani', code: 4207809 },
    { city: 'Irati', code: 4207858 },
    { city: 'Irineópolis', code: 4207908 },
    { city: 'Itá', code: 4208005 },
    { city: 'Itaiópolis', code: 4208104 },
    { city: 'Itajaí', code: 4208203 },
    { city: 'Itapema', code: 4208302 },
    { city: 'Itapiranga', code: 4208401 },
    { city: 'Itapoá', code: 4208450 },
    { city: 'Ituporanga', code: 4208500 },
    { city: 'Jaborá', code: 4208609 },
    { city: 'Jacinto Machado', code: 4208708 },
    { city: 'Jaguaruna', code: 4208807 },
    { city: 'Jaraguá do Sul', code: 4208906 },
    { city: 'Jardinópolis', code: 4208955 },
    { city: 'Joaçaba', code: 4209003 },
    { city: 'Joinville', code: 4209102 },
    { city: 'José Boiteux', code: 4209151 },
    { city: 'Jupiá', code: 4209177 },
    { city: 'Lacerdópolis', code: 4209201 },
    { city: 'Lages', code: 4209300 },
    { city: 'Laguna', code: 4209409 },
    { city: 'Lajeado Grande', code: 4209458 },
    { city: 'Laurentino', code: 4209508 },
    { city: 'Lauro Müller', code: 4209607 },
    { city: 'Lebon Régis', code: 4209706 },
    { city: 'Leoberto Leal', code: 4209805 },
    { city: 'Lindóia do Sul', code: 4209854 },
    { city: 'Lontras', code: 4209904 },
    { city: 'Luiz Alves', code: 4210001 },
    { city: 'Luzerna', code: 4210035 },
    { city: 'Macieira', code: 4210050 },
    { city: 'Mafra', code: 4210100 },
    { city: 'Major Gercino', code: 4210209 },
    { city: 'Major Vieira', code: 4210308 },
    { city: 'Maracajá', code: 4210407 },
    { city: 'Maravilha', code: 4210506 },
    { city: 'Marema', code: 4210555 },
    { city: 'Massaranduba', code: 4210605 },
    { city: 'Matos Costa', code: 4210704 },
    { city: 'Meleiro', code: 4210803 },
    { city: 'Mirim Doce', code: 4210852 },
    { city: 'Modelo', code: 4210902 },
    { city: 'Mondaí', code: 4211009 },
    { city: 'Monte Carlo', code: 4211058 },
    { city: 'Monte Castelo', code: 4211108 },
    { city: 'Morro da Fumaça', code: 4211207 },
    { city: 'Morro Grande', code: 4211256 },
    { city: 'Navegantes', code: 4211306 },
    { city: 'Nova Erechim', code: 4211405 },
    { city: 'Nova Itaberaba', code: 4211454 },
    { city: 'Nova Trento', code: 4211504 },
    { city: 'Nova Veneza', code: 4211603 },
    { city: 'Novo Horizonte', code: 4211652 },
    { city: 'Orleans', code: 4211702 },
    { city: 'Otacílio Costa', code: 4211751 },
    { city: 'Ouro', code: 4211801 },
    { city: 'Ouro Verde', code: 4211850 },
    { city: 'Paial', code: 4211876 },
    { city: 'Painel', code: 4211892 },
    { city: 'Palhoça', code: 4211900 },
    { city: 'Palma Sola', code: 4212007 },
    { city: 'Palmeira', code: 4212056 },
    { city: 'Palmitos', code: 4212106 },
    { city: 'Papanduva', code: 4212205 },
    { city: 'Paraíso', code: 4212239 },
    { city: 'Passo de Torres', code: 4212254 },
    { city: 'Passos Maia', code: 4212270 },
    { city: 'Paulo Lopes', code: 4212304 },
    { city: 'Pedras Grandes', code: 4212403 },
    { city: 'Penha', code: 4212502 },
    { city: 'Peritiba', code: 4212601 },
    { city: 'Pescaria Brava', code: 4212650 },
    { city: 'Petrolândia', code: 4212700 },
    { city: 'Pinhalzinho', code: 4212908 },
    { city: 'Pinheiro Preto', code: 4213005 },
    { city: 'Piratuba', code: 4213104 },
    { city: 'Planalto Alegre', code: 4213153 },
    { city: 'Pomerode', code: 4213203 },
    { city: 'Ponte Alta', code: 4213302 },
    { city: 'Ponte Alta do Norte', code: 4213351 },
    { city: 'Ponte Serrada', code: 4213401 },
    { city: 'Porto Belo', code: 4213500 },
    { city: 'Porto União', code: 4213609 },
    { city: 'Pouso Redondo', code: 4213708 },
    { city: 'Praia Grande', code: 4213807 },
    { city: 'Presidente Castello Branco', code: 4213906 },
    { city: 'Presidente Getúlio', code: 4214003 },
    { city: 'Presidente Nereu', code: 4214102 },
    { city: 'Princesa', code: 4214151 },
    { city: 'Quilombo', code: 4214201 },
    { city: 'Rancho Queimado', code: 4214300 },
    { city: 'Rio das Antas', code: 4214409 },
    { city: 'Rio do Campo', code: 4214508 },
    { city: 'Rio do Oeste', code: 4214607 },
    { city: 'Rio do Sul', code: 4214805 },
    { city: 'Rio dos Cedros', code: 4214706 },
    { city: 'Rio Fortuna', code: 4214904 },
    { city: 'Rio Negrinho', code: 4215000 },
    { city: 'Rio Rufino', code: 4215059 },
    { city: 'Riqueza', code: 4215075 },
    { city: 'Rodeio', code: 4215109 },
    { city: 'Romelândia', code: 4215208 },
    { city: 'Salete', code: 4215307 },
    { city: 'Saltinho', code: 4215356 },
    { city: 'Salto Veloso', code: 4215406 },
    { city: 'Sangão', code: 4215455 },
    { city: 'Santa Cecília', code: 4215505 },
    { city: 'Santa Helena', code: 4215554 },
    { city: 'Santa Rosa de Lima', code: 4215604 },
    { city: 'Santa Rosa do Sul', code: 4215653 },
    { city: 'Santa Terezinha', code: 4215679 },
    { city: 'Santa Terezinha do Progresso', code: 4215687 },
    { city: 'Santiago do Sul', code: 4215695 },
    { city: 'Santo Amaro da Imperatriz', code: 4215703 },
    { city: 'São Bento do Sul', code: 4215802 },
    { city: 'São Bernardino', code: 4215752 },
    { city: 'São Bonifácio', code: 4215901 },
    { city: 'São Carlos', code: 4216008 },
    { city: 'São Cristóvão do Sul', code: 4216057 },
    { city: 'São Domingos', code: 4216107 },
    { city: 'São Francisco do Sul', code: 4216206 },
    { city: 'São João Batista', code: 4216305 },
    { city: 'São João do Itaperiú', code: 4216354 },
    { city: 'São João do Oeste', code: 4216255 },
    { city: 'São João do Sul', code: 4216404 },
    { city: 'São Joaquim', code: 4216503 },
    { city: 'São José', code: 4216602 },
    { city: 'São José do Cedro', code: 4216701 },
    { city: 'São José do Cerrito', code: 4216800 },
    { city: 'São Lourenço do Oeste', code: 4216909 },
    { city: 'São Ludgero', code: 4217006 },
    { city: 'São Martinho', code: 4217105 },
    { city: 'São Miguel da Boa Vista', code: 4217154 },
    { city: 'São Miguel do Oeste', code: 4217204 },
    { city: 'São Pedro de Alcântara', code: 4217253 },
    { city: 'Saudades', code: 4217303 },
    { city: 'Schroeder', code: 4217402 },
    { city: 'Seara', code: 4217501 },
    { city: 'Serra Alta', code: 4217550 },
    { city: 'Siderópolis', code: 4217600 },
    { city: 'Sombrio', code: 4217709 },
    { city: 'Sul Brasil', code: 4217758 },
    { city: 'Taió', code: 4217808 },
    { city: 'Tangará', code: 4217907 },
    { city: 'Tigrinhos', code: 4217956 },
    { city: 'Tijucas', code: 4218004 },
    { city: 'Timbé do Sul', code: 4218103 },
    { city: 'Timbó', code: 4218202 },
    { city: 'Timbó Grande', code: 4218251 },
    { city: 'Três Barras', code: 4218301 },
    { city: 'Treviso', code: 4218350 },
    { city: 'Treze de Maio', code: 4218400 },
    { city: 'Treze Tílias', code: 4218509 },
    { city: 'Trombudo Central', code: 4218608 },
    { city: 'Tubarão', code: 4218707 },
    { city: 'Tunápolis', code: 4218756 },
    { city: 'Turvo', code: 4218806 },
    { city: 'União do Oeste', code: 4218855 },
    { city: 'Urubici', code: 4218905 },
    { city: 'Urupema', code: 4218954 },
    { city: 'Urussanga', code: 4219002 },
    { city: 'Vargeão', code: 4219101 },
    { city: 'Vargem', code: 4219150 },
    { city: 'Vargem Bonita', code: 4219176 },
    { city: 'Vidal Ramos', code: 4219200 },
    { city: 'Videira', code: 4219309 },
    { city: 'Vitor Meireles', code: 4219358 },
    { city: 'Witmarsum', code: 4219408 },
    { city: 'Xanxerê', code: 4219507 },
    { city: 'Xavantina', code: 4219606 },
    { city: 'Xaxim', code: 4219705 },
    { city: 'Zortéa', code: 4219853 },
  ],
  SE: [
    { city: 'Amparo de São Francisco', code: 2800100 },
    { city: 'Aquidabã', code: 2800209 },
    { city: 'Aracaju', code: 2800308 },
    { city: 'Arauá', code: 2800407 },
    { city: 'Areia Branca', code: 2800506 },
    { city: 'Barra dos Coqueiros', code: 2800605 },
    { city: 'Boquim', code: 2800670 },
    { city: 'Brejo Grande', code: 2800704 },
    { city: 'Campo do Brito', code: 2801009 },
    { city: 'Canhoba', code: 2801108 },
    { city: 'Canindé de São Francisco', code: 2801207 },
    { city: 'Capela', code: 2801306 },
    { city: 'Carira', code: 2801405 },
    { city: 'Carmópolis', code: 2801504 },
    { city: 'Cedro de São João', code: 2801603 },
    { city: 'Cristinápolis', code: 2801702 },
    { city: 'Cumbe', code: 2801900 },
    { city: 'Divina Pastora', code: 2802007 },
    { city: 'Estância', code: 2802106 },
    { city: 'Feira Nova', code: 2802205 },
    { city: 'Frei Paulo', code: 2802304 },
    { city: 'Gararu', code: 2802403 },
    { city: 'General Maynard', code: 2802502 },
    { city: 'Gracho Cardoso', code: 2802601 },
    { city: 'Ilha das Flores', code: 2802700 },
    { city: 'Indiaroba', code: 2802809 },
    { city: 'Itabaiana', code: 2802908 },
    { city: 'Itabaianinha', code: 2803005 },
    { city: 'Itabi', code: 2803104 },
    { city: 'Itaporanga d\'Ajuda', code: 2803203 },
    { city: 'Japaratuba', code: 2803302 },
    { city: 'Japoatã', code: 2803401 },
    { city: 'Lagarto', code: 2803500 },
    { city: 'Laranjeiras', code: 2803609 },
    { city: 'Macambira', code: 2803708 },
    { city: 'Malhada dos Bois', code: 2803807 },
    { city: 'Malhador', code: 2803906 },
    { city: 'Maruim', code: 2804003 },
    { city: 'Moita Bonita', code: 2804102 },
    { city: 'Monte Alegre de Sergipe', code: 2804201 },
    { city: 'Muribeca', code: 2804300 },
    { city: 'Neópolis', code: 2804409 },
    { city: 'Nossa Senhora Aparecida', code: 2804458 },
    { city: 'Nossa Senhora da Glória', code: 2804508 },
    { city: 'Nossa Senhora das Dores', code: 2804607 },
    { city: 'Nossa Senhora de Lourdes', code: 2804706 },
    { city: 'Nossa Senhora do Socorro', code: 2804805 },
    { city: 'Pacatuba', code: 2804904 },
    { city: 'Pedra Mole', code: 2805000 },
    { city: 'Pedrinhas', code: 2805109 },
    { city: 'Pinhão', code: 2805208 },
    { city: 'Pirambu', code: 2805307 },
    { city: 'Poço Redondo', code: 2805406 },
    { city: 'Poço Verde', code: 2805505 },
    { city: 'Porto da Folha', code: 2805604 },
    { city: 'Propriá', code: 2805703 },
    { city: 'Riachão do Dantas', code: 2805802 },
    { city: 'Riachuelo', code: 2805901 },
    { city: 'Ribeirópolis', code: 2806008 },
    { city: 'Rosário do Catete', code: 2806107 },
    { city: 'Salgado', code: 2806206 },
    { city: 'Santa Luzia do Itanhy', code: 2806305 },
    { city: 'Santa Rosa de Lima', code: 2806503 },
    { city: 'Santana do São Francisco', code: 2806404 },
    { city: 'Santo Amaro das Brotas', code: 2806602 },
    { city: 'São Cristóvão', code: 2806701 },
    { city: 'São Domingos', code: 2806800 },
    { city: 'São Francisco', code: 2806909 },
    { city: 'São Miguel do Aleixo', code: 2807006 },
    { city: 'Simão Dias', code: 2807105 },
    { city: 'Siriri', code: 2807204 },
    { city: 'Telha', code: 2807303 },
    { city: 'Tobias Barreto', code: 2807402 },
    { city: 'Tomar do Geru', code: 2807501 },
    { city: 'Umbaúba', code: 2807600 },
  ],
  SP: [
    { city: 'Adamantina', code: 3500105 },
    { city: 'Adolfo', code: 3500204 },
    { city: 'Aguaí', code: 3500303 },
    { city: 'Águas da Prata', code: 3500402 },
    { city: 'Águas de Lindóia', code: 3500501 },
    { city: 'Águas de Santa Bárbara', code: 3500550 },
    { city: 'Águas de São Pedro', code: 3500600 },
    { city: 'Agudos', code: 3500709 },
    { city: 'Alambari', code: 3500758 },
    { city: 'Alfredo Marcondes', code: 3500808 },
    { city: '-', code: 3500907 },
    { city: 'Altinópolis', code: 3501004 },
    { city: 'Alto Alegre', code: 3501103 },
    { city: 'Alumínio', code: 3501152 },
    { city: 'Álvares Florence', code: 3501202 },
    { city: 'Álvares Machado', code: 3501301 },
    { city: 'Álvaro de Carvalho', code: 3501400 },
    { city: 'Alvinlândia', code: 3501509 },
    { city: 'Americana', code: 3501608 },
    { city: 'Américo Brasiliense', code: 3501707 },
    { city: 'Américo de Campos', code: 3501806 },
    { city: 'Amparo', code: 3501905 },
    { city: 'Analândia', code: 3502002 },
    { city: 'Andradina', code: 3502101 },
    { city: 'Angatuba', code: 3502200 },
    { city: 'Anhembi', code: 3502309 },
    { city: 'Anhumas', code: 3502408 },
    { city: 'Aparecida', code: 3502507 },
    { city: 'Aparecida d\'Oeste', code: 3502606 },
    { city: 'Apiaí', code: 3502705 },
    { city: 'Araçariguama', code: 3502754 },
    { city: 'Araçatuba', code: 3502804 },
    { city: 'Araçoiaba da Serra', code: 3502903 },
    { city: 'Aramina', code: 3503000 },
    { city: 'Arandu', code: 3503109 },
    { city: 'Arapeí', code: 3503158 },
    { city: 'Araraquara', code: 3503208 },
    { city: 'Araras', code: 3503307 },
    { city: 'Arco-Íris', code: 3503356 },
    { city: 'Arealva', code: 3503406 },
    { city: 'Areias', code: 3503505 },
    { city: 'Areiópolis', code: 3503604 },
    { city: 'Ariranha', code: 3503703 },
    { city: 'Artur Nogueira', code: 3503802 },
    { city: 'Arujá', code: 3503901 },
    { city: 'Aspásia', code: 3503950 },
    { city: 'Assis', code: 3504008 },
    { city: 'Atibaia', code: 3504107 },
    { city: 'Auriflama', code: 3504206 },
    { city: 'Avaí', code: 3504305 },
    { city: 'Avanhandava', code: 3504404 },
    { city: 'Avaré', code: 3504503 },
    { city: 'Bady Bassitt', code: 3504602 },
    { city: 'Balbinos', code: 3504701 },
    { city: 'Bálsamo', code: 3504800 },
    { city: 'Bananal', code: 3504909 },
    { city: 'Barão de Antonina', code: 3505005 },
    { city: 'Barbosa', code: 3505104 },
    { city: 'Bariri', code: 3505203 },
    { city: 'Barra Bonita', code: 3505302 },
    { city: 'Barra do Chapéu', code: 3505351 },
    { city: 'Barra do Turvo', code: 3505401 },
    { city: 'Barretos', code: 3505500 },
    { city: 'Barrinha', code: 3505609 },
    { city: 'Barueri', code: 3505708 },
    { city: 'Bastos', code: 3505807 },
    { city: 'Batatais', code: 3505906 },
    { city: 'Bauru', code: 3506003 },
    { city: 'Bebedouro', code: 3506102 },
    { city: 'Bento de Abreu', code: 3506201 },
    { city: 'Bernardino de Campos', code: 3506300 },
    { city: 'Bertioga', code: 3506359 },
    { city: 'Bilac', code: 3506409 },
    { city: 'Birigui', code: 3506508 },
    { city: 'Biritiba Mirim', code: 3506607 },
    { city: 'Boa Esperança do Sul', code: 3506706 },
    { city: 'Bocaina', code: 3506805 },
    { city: 'Bofete', code: 3506904 },
    { city: 'Boituva', code: 3507001 },
    { city: 'Bom Jesus dos Perdões', code: 3507100 },
    { city: 'Bom Sucesso de Itararé', code: 3507159 },
    { city: 'Borá', code: 3507209 },
    { city: 'Boracéia', code: 3507308 },
    { city: 'Borborema', code: 3507407 },
    { city: 'Borebi', code: 3507456 },
    { city: 'Botucatu', code: 3507506 },
    { city: 'Bragança Paulista', code: 3507605 },
    { city: 'Braúna', code: 3507704 },
    { city: 'Brejo Alegre', code: 3507753 },
    { city: 'Brodowski', code: 3507803 },
    { city: 'Brotas', code: 3507902 },
    { city: 'Buri', code: 3508009 },
    { city: 'Buritama', code: 3508108 },
    { city: 'Buritizal', code: 3508207 },
    { city: 'Cabrália Paulista', code: 3508306 },
    { city: 'Cabreúva', code: 3508405 },
    { city: 'Caçapava', code: 3508504 },
    { city: 'Cachoeira Paulista', code: 3508603 },
    { city: 'Caconde', code: 3508702 },
    { city: 'Cafelândia', code: 3508801 },
    { city: 'Caiabu', code: 3508900 },
    { city: 'Caieiras', code: 3509007 },
    { city: 'Caiuá', code: 3509106 },
    { city: 'Cajamar', code: 3509205 },
    { city: 'Cajati', code: 3509254 },
    { city: 'Cajobi', code: 3509304 },
    { city: 'Cajuru', code: 3509403 },
    { city: 'Campina do Monte Alegre', code: 3509452 },
    { city: 'Campinas', code: 3509502 },
    { city: 'Campo Limpo Paulista', code: 3509601 },
    { city: 'Campos do Jordão', code: 3509700 },
    { city: 'Campos Novos Paulista', code: 3509809 },
    { city: 'Cananéia', code: 3509908 },
    { city: 'Canas', code: 3509957 },
    { city: 'Cândido Mota', code: 3510005 },
    { city: 'Cândido Rodrigues', code: 3510104 },
    { city: 'Canitar', code: 3510153 },
    { city: 'Capão Bonito', code: 3510203 },
    { city: 'Capela do Alto', code: 3510302 },
    { city: 'Capivari', code: 3510401 },
    { city: 'Caraguatatuba', code: 3510500 },
    { city: 'Carapicuíba', code: 3510609 },
    { city: 'Cardoso', code: 3510708 },
    { city: 'Casa Branca', code: 3510807 },
    { city: 'Cássia dos Coqueiros', code: 3510906 },
    { city: 'Castilho', code: 3511003 },
    { city: 'Catanduva', code: 3511102 },
    { city: 'Catiguá', code: 3511201 },
    { city: 'Cedral', code: 3511300 },
    { city: 'Cerqueira César', code: 3511409 },
    { city: 'Cerquilho', code: 3511508 },
    { city: 'Cesário Lange', code: 3511607 },
    { city: 'Charqueada', code: 3511706 },
    { city: 'Chavantes', code: 3557204 },
    { city: 'Clementina', code: 3511904 },
    { city: 'Colina', code: 3512001 },
    { city: 'Colômbia', code: 3512100 },
    { city: 'Conchal', code: 3512209 },
    { city: 'Conchas', code: 3512308 },
    { city: 'Cordeirópolis', code: 3512407 },
    { city: 'Coroados', code: 3512506 },
    { city: 'Coronel Macedo', code: 3512605 },
    { city: 'Corumbataí', code: 3512704 },
    { city: 'Cosmópolis', code: 3512803 },
    { city: 'Cosmorama', code: 3512902 },
    { city: 'Cotia', code: 3513009 },
    { city: 'Cravinhos', code: 3513108 },
    { city: 'Cristais Paulista', code: 3513207 },
    { city: 'Cruzália', code: 3513306 },
    { city: 'Cruzeiro', code: 3513405 },
    { city: 'Cubatão', code: 3513504 },
    { city: 'Cunha', code: 3513603 },
    { city: 'Descalvado', code: 3513702 },
    { city: 'Diadema', code: 3513801 },
    { city: 'Dirce Reis', code: 3513850 },
    { city: 'Divinolândia', code: 3513900 },
    { city: 'Dobrada', code: 3514007 },
    { city: 'Dois Córregos', code: 3514106 },
    { city: 'Dolcinópolis', code: 3514205 },
    { city: 'Dourado', code: 3514304 },
    { city: 'Dracena', code: 3514403 },
    { city: 'Duartina', code: 3514502 },
    { city: 'Dumont', code: 3514601 },
    { city: 'Echaporã', code: 3514700 },
    { city: 'Eldorado', code: 3514809 },
    { city: 'Elias Fausto', code: 3514908 },
    { city: 'Elisiário', code: 3514924 },
    { city: 'Embaúba', code: 3514957 },
    { city: 'Embu das Artes', code: 3515004 },
    { city: 'Embu-Guaçu', code: 3515103 },
    { city: 'Emilianópolis', code: 3515129 },
    { city: 'Engenheiro Coelho', code: 3515152 },
    { city: 'Espírito Santo do Pinhal', code: 3515186 },
    { city: 'Espírito Santo do Turvo', code: 3515194 },
    { city: 'Estiva Gerbi', code: 3557303 },
    { city: 'Estrela do Norte', code: 3515301 },
    { city: 'Estrela d\'Oeste', code: 3515202 },
    { city: 'Euclides da Cunha Paulista', code: 3515350 },
    { city: 'Fartura', code: 3515400 },
    { city: 'Fernando Prestes', code: 3515608 },
    { city: 'Fernandópolis', code: 3515509 },
    { city: 'Fernão', code: 3515657 },
    { city: 'Ferraz de Vasconcelos', code: 3515707 },
    { city: 'Flora Rica', code: 3515806 },
    { city: 'Floreal', code: 3515905 },
    { city: 'Flórida Paulista', code: 3516002 },
    { city: 'Florínea', code: 3516101 },
    { city: 'Franca', code: 3516200 },
    { city: 'Francisco Morato', code: 3516309 },
    { city: 'Franco da Rocha', code: 3516408 },
    { city: 'Gabriel Monteiro', code: 3516507 },
    { city: 'Gália', code: 3516606 },
    { city: 'Garça', code: 3516705 },
    { city: 'Gastão Vidigal', code: 3516804 },
    { city: 'Gavião Peixoto', code: 3516853 },
    { city: 'General Salgado', code: 3516903 },
    { city: 'Getulina', code: 3517000 },
    { city: 'Glicério', code: 3517109 },
    { city: 'Guaiçara', code: 3517208 },
    { city: 'Guaimbê', code: 3517307 },
    { city: 'Guaíra', code: 3517406 },
    { city: 'Guapiaçu', code: 3517505 },
    { city: 'Guapiara', code: 3517604 },
    { city: 'Guará', code: 3517703 },
    { city: 'Guaraçaí', code: 3517802 },
    { city: 'Guaraci', code: 3517901 },
    { city: 'Guarani d\'Oeste', code: 3518008 },
    { city: 'Guarantã', code: 3518107 },
    { city: 'Guararapes', code: 3518206 },
    { city: 'Guararema', code: 3518305 },
    { city: 'Guaratinguetá', code: 3518404 },
    { city: 'Guareí', code: 3518503 },
    { city: 'Guariba', code: 3518602 },
    { city: 'Guarujá', code: 3518701 },
    { city: 'Guarulhos', code: 3518800 },
    { city: 'Guatapará', code: 3518859 },
    { city: 'Guzolândia', code: 3518909 },
    { city: 'Herculândia', code: 3519006 },
    { city: 'Holambra', code: 3519055 },
    { city: 'Hortolândia', code: 3519071 },
    { city: 'Iacanga', code: 3519105 },
    { city: 'Iacri', code: 3519204 },
    { city: 'Iaras', code: 3519253 },
    { city: 'Ibaté', code: 3519303 },
    { city: 'Ibirá', code: 3519402 },
    { city: 'Ibirarema', code: 3519501 },
    { city: 'Ibitinga', code: 3519600 },
    { city: 'Ibiúna', code: 3519709 },
    { city: 'Icém', code: 3519808 },
    { city: 'Iepê', code: 3519907 },
    { city: 'Igaraçu do Tietê', code: 3520004 },
    { city: 'Igarapava', code: 3520103 },
    { city: 'Igaratá', code: 3520202 },
    { city: 'Iguape', code: 3520301 },
    { city: 'Ilha Comprida', code: 3520426 },
    { city: 'Ilha Solteira', code: 3520442 },
    { city: 'Ilhabela', code: 3520400 },
    { city: 'Indaiatuba', code: 3520509 },
    { city: 'Indiana', code: 3520608 },
    { city: 'Indiaporã', code: 3520707 },
    { city: 'Inúbia Paulista', code: 3520806 },
    { city: 'Ipaussu', code: 3520905 },
    { city: 'Iperó', code: 3521002 },
    { city: 'Ipeúna', code: 3521101 },
    { city: 'Ipiguá', code: 3521150 },
    { city: 'Iporanga', code: 3521200 },
    { city: 'Ipuã', code: 3521309 },
    { city: 'Iracemápolis', code: 3521408 },
    { city: 'Irapuã', code: 3521507 },
    { city: 'Irapuru', code: 3521606 },
    { city: 'Itaberá', code: 3521705 },
    { city: 'Itaí', code: 3521804 },
    { city: 'Itajobi', code: 3521903 },
    { city: 'Itaju', code: 3522000 },
    { city: 'Itanhaém', code: 3522109 },
    { city: 'Itaoca', code: 3522158 },
    { city: 'Itapecerica da Serra', code: 3522208 },
    { city: 'Itapetininga', code: 3522307 },
    { city: 'Itapeva', code: 3522406 },
    { city: 'Itapevi', code: 3522505 },
    { city: 'Itapira', code: 3522604 },
    { city: 'Itapirapuã Paulista', code: 3522653 },
    { city: 'Itápolis', code: 3522703 },
    { city: 'Itaporanga', code: 3522802 },
    { city: 'Itapuí', code: 3522901 },
    { city: 'Itapura', code: 3523008 },
    { city: 'Itaquaquecetuba', code: 3523107 },
    { city: 'Itararé', code: 3523206 },
    { city: 'Itariri', code: 3523305 },
    { city: 'Itatiba', code: 3523404 },
    { city: 'Itatinga', code: 3523503 },
    { city: 'Itirapina', code: 3523602 },
    { city: 'Itirapuã', code: 3523701 },
    { city: 'Itobi', code: 3523800 },
    { city: 'Itu', code: 3523909 },
    { city: 'Itupeva', code: 3524006 },
    { city: 'Ituverava', code: 3524105 },
    { city: 'Jaborandi', code: 3524204 },
    { city: 'Jaboticabal', code: 3524303 },
    { city: 'Jacareí', code: 3524402 },
    { city: 'Jaci', code: 3524501 },
    { city: 'Jacupiranga', code: 3524600 },
    { city: 'Jaguariúna', code: 3524709 },
    { city: 'Jales', code: 3524808 },
    { city: 'Jambeiro', code: 3524907 },
    { city: 'Jandira', code: 3525003 },
    { city: 'Jardinópolis', code: 3525102 },
    { city: 'Jarinu', code: 3525201 },
    { city: 'Jaú', code: 3525300 },
    { city: 'Jeriquara', code: 3525409 },
    { city: 'Joanópolis', code: 3525508 },
    { city: 'João Ramalho', code: 3525607 },
    { city: 'José Bonifácio', code: 3525706 },
    { city: 'Júlio Mesquita', code: 3525805 },
    { city: 'Jumirim', code: 3525854 },
    { city: 'Jundiaí', code: 3525904 },
    { city: 'Junqueirópolis', code: 3526001 },
    { city: 'Juquiá', code: 3526100 },
    { city: 'Juquitiba', code: 3526209 },
    { city: 'Lagoinha', code: 3526308 },
    { city: 'Laranjal Paulista', code: 3526407 },
    { city: 'Lavínia', code: 3526506 },
    { city: 'Lavrinhas', code: 3526605 },
    { city: 'Leme', code: 3526704 },
    { city: 'Lençóis Paulista', code: 3526803 },
    { city: 'Limeira', code: 3526902 },
    { city: 'Lindóia', code: 3527009 },
    { city: 'Lins', code: 3527108 },
    { city: 'Lorena', code: 3527207 },
    { city: 'Lourdes', code: 3527256 },
    { city: 'Louveira', code: 3527306 },
    { city: 'Lucélia', code: 3527405 },
    { city: 'Lucianópolis', code: 3527504 },
    { city: 'Luís Antônio', code: 3527603 },
    { city: 'Luiziânia', code: 3527702 },
    { city: 'Lupércio', code: 3527801 },
    { city: 'Lutécia', code: 3527900 },
    { city: 'Macatuba', code: 3528007 },
    { city: 'Macaubal', code: 3528106 },
    { city: 'Macedônia', code: 3528205 },
    { city: 'Magda', code: 3528304 },
    { city: 'Mairinque', code: 3528403 },
    { city: 'Mairiporã', code: 3528502 },
    { city: 'Manduri', code: 3528601 },
    { city: 'Marabá Paulista', code: 3528700 },
    { city: 'Maracaí', code: 3528809 },
    { city: 'Marapoama', code: 3528858 },
    { city: 'Mariápolis', code: 3528908 },
    { city: 'Marília', code: 3529005 },
    { city: 'Marinópolis', code: 3529104 },
    { city: 'Martinópolis', code: 3529203 },
    { city: 'Matão', code: 3529302 },
    { city: 'Mauá', code: 3529401 },
    { city: 'Mendonça', code: 3529500 },
    { city: 'Meridiano', code: 3529609 },
    { city: 'Mesópolis', code: 3529658 },
    { city: 'Miguelópolis', code: 3529708 },
    { city: 'Mineiros do Tietê', code: 3529807 },
    { city: 'Mira Estrela', code: 3530003 },
    { city: 'Miracatu', code: 3529906 },
    { city: 'Mirandópolis', code: 3530102 },
    { city: 'Mirante do Paranapanema', code: 3530201 },
    { city: 'Mirassol', code: 3530300 },
    { city: 'Mirassolândia', code: 3530409 },
    { city: 'Mococa', code: 3530508 },
    { city: 'Mogi das Cruzes', code: 3530607 },
    { city: 'Mogi Guaçu', code: 3530706 },
    { city: 'Mogi Mirim', code: 3530805 },
    { city: 'Mombuca', code: 3530904 },
    { city: 'Monções', code: 3531001 },
    { city: 'Mongaguá', code: 3531100 },
    { city: 'Monte Alegre do Sul', code: 3531209 },
    { city: 'Monte Alto', code: 3531308 },
    { city: 'Monte Aprazível', code: 3531407 },
    { city: 'Monte Azul Paulista', code: 3531506 },
    { city: 'Monte Castelo', code: 3531605 },
    { city: 'Monte Mor', code: 3531803 },
    { city: 'Monteiro Lobato', code: 3531704 },
    { city: 'Morro Agudo', code: 3531902 },
    { city: 'Morungaba', code: 3532009 },
    { city: 'Motuca', code: 3532058 },
    { city: 'Murutinga do Sul', code: 3532108 },
    { city: 'Nantes', code: 3532157 },
    { city: 'Narandiba', code: 3532207 },
    { city: 'Natividade da Serra', code: 3532306 },
    { city: 'Nazaré Paulista', code: 3532405 },
    { city: 'Neves Paulista', code: 3532504 },
    { city: 'Nhandeara', code: 3532603 },
    { city: 'Nipoã', code: 3532702 },
    { city: 'Nova Aliança', code: 3532801 },
    { city: 'Nova Campina', code: 3532827 },
    { city: 'Nova Canaã Paulista', code: 3532843 },
    { city: 'Nova Castilho', code: 3532868 },
    { city: 'Nova Europa', code: 3532900 },
    { city: 'Nova Granada', code: 3533007 },
    { city: 'Nova Guataporanga', code: 3533106 },
    { city: 'Nova Independência', code: 3533205 },
    { city: 'Nova Luzitânia', code: 3533304 },
    { city: 'Nova Odessa', code: 3533403 },
    { city: 'Novais', code: 3533254 },
    { city: 'Novo Horizonte', code: 3533502 },
    { city: 'Nuporanga', code: 3533601 },
    { city: 'Ocauçu', code: 3533700 },
    { city: 'Óleo', code: 3533809 },
    { city: 'Olímpia', code: 3533908 },
    { city: 'Onda Verde', code: 3534005 },
    { city: 'Oriente', code: 3534104 },
    { city: 'Orindiúva', code: 3534203 },
    { city: 'Orlândia', code: 3534302 },
    { city: 'Osasco', code: 3534401 },
    { city: 'Oscar Bressane', code: 3534500 },
    { city: 'Osvaldo Cruz', code: 3534609 },
    { city: 'Ourinhos', code: 3534708 },
    { city: 'Ouro Verde', code: 3534807 },
    { city: 'Ouroeste', code: 3534757 },
    { city: 'Pacaembu', code: 3534906 },
    { city: 'Palestina', code: 3535002 },
    { city: 'Palmares Paulista', code: 3535101 },
    { city: 'Palmeira d\'Oeste', code: 3535200 },
    { city: 'Palmital', code: 3535309 },
    { city: 'Panorama', code: 3535408 },
    { city: 'Paraguaçu Paulista', code: 3535507 },
    { city: 'Paraibuna', code: 3535606 },
    { city: 'Paraíso', code: 3535705 },
    { city: 'Paranapanema', code: 3535804 },
    { city: 'Paranapuã', code: 3535903 },
    { city: 'Parapuã', code: 3536000 },
    { city: 'Pardinho', code: 3536109 },
    { city: 'Pariquera-Açu', code: 3536208 },
    { city: 'Parisi', code: 3536257 },
    { city: 'Patrocínio Paulista', code: 3536307 },
    { city: 'Paulicéia', code: 3536406 },
    { city: 'Paulínia', code: 3536505 },
    { city: 'Paulistânia', code: 3536570 },
    { city: 'Paulo de Faria', code: 3536604 },
    { city: 'Pederneiras', code: 3536703 },
    { city: 'Pedra Bela', code: 3536802 },
    { city: 'Pedranópolis', code: 3536901 },
    { city: 'Pedregulho', code: 3537008 },
    { city: 'Pedreira', code: 3537107 },
    { city: 'Pedrinhas Paulista', code: 3537156 },
    { city: 'Pedro de Toledo', code: 3537206 },
    { city: 'Penápolis', code: 3537305 },
    { city: 'Pereira Barreto', code: 3537404 },
    { city: 'Pereiras', code: 3537503 },
    { city: 'Peruíbe', code: 3537602 },
    { city: 'Piacatu', code: 3537701 },
    { city: 'Piedade', code: 3537800 },
    { city: 'Pilar do Sul', code: 3537909 },
    { city: 'Pindamonhangaba', code: 3538006 },
    { city: 'Pindorama', code: 3538105 },
    { city: 'Pinhalzinho', code: 3538204 },
    { city: 'Piquerobi', code: 3538303 },
    { city: 'Piquete', code: 3538501 },
    { city: 'Piracaia', code: 3538600 },
    { city: 'Piracicaba', code: 3538709 },
    { city: 'Piraju', code: 3538808 },
    { city: 'Pirajuí', code: 3538907 },
    { city: 'Pirangi', code: 3539004 },
    { city: 'Pirapora do Bom Jesus', code: 3539103 },
    { city: 'Pirapozinho', code: 3539202 },
    { city: 'Pirassununga', code: 3539301 },
    { city: 'Piratininga', code: 3539400 },
    { city: 'Pitangueiras', code: 3539509 },
    { city: 'Planalto', code: 3539608 },
    { city: 'Platina', code: 3539707 },
    { city: 'Poá', code: 3539806 },
    { city: 'Poloni', code: 3539905 },
    { city: 'Pompéia', code: 3540002 },
    { city: 'Pongaí', code: 3540101 },
    { city: 'Pontal', code: 3540200 },
    { city: 'Pontalinda', code: 3540259 },
    { city: 'Pontes Gestal', code: 3540309 },
    { city: 'Populina', code: 3540408 },
    { city: 'Porangaba', code: 3540507 },
    { city: 'Porto Feliz', code: 3540606 },
    { city: 'Porto Ferreira', code: 3540705 },
    { city: 'Potim', code: 3540754 },
    { city: 'Potirendaba', code: 3540804 },
    { city: 'Pracinha', code: 3540853 },
    { city: 'Pradópolis', code: 3540903 },
    { city: 'Praia Grande', code: 3541000 },
    { city: 'Pratânia', code: 3541059 },
    { city: 'Presidente Alves', code: 3541109 },
    { city: 'Presidente Bernardes', code: 3541208 },
    { city: 'Presidente Epitácio', code: 3541307 },
    { city: 'Presidente Prudente', code: 3541406 },
    { city: 'Presidente Venceslau', code: 3541505 },
    { city: 'Promissão', code: 3541604 },
    { city: 'Quadra', code: 3541653 },
    { city: 'Quatá', code: 3541703 },
    { city: 'Queiroz', code: 3541802 },
    { city: 'Queluz', code: 3541901 },
    { city: 'Quintana', code: 3542008 },
    { city: 'Rafard', code: 3542107 },
    { city: 'Rancharia', code: 3542206 },
    { city: 'Redenção da Serra', code: 3542305 },
    { city: 'Regente Feijó', code: 3542404 },
    { city: 'Reginópolis', code: 3542503 },
    { city: 'Registro', code: 3542602 },
    { city: 'Restinga', code: 3542701 },
    { city: 'Ribeira', code: 3542800 },
    { city: 'Ribeirão Bonito', code: 3542909 },
    { city: 'Ribeirão Branco', code: 3543006 },
    { city: 'Ribeirão Corrente', code: 3543105 },
    { city: 'Ribeirão do Sul', code: 3543204 },
    { city: 'Ribeirão dos Índios', code: 3543238 },
    { city: 'Ribeirão Grande', code: 3543253 },
    { city: 'Ribeirão Pires', code: 3543303 },
    { city: 'Ribeirão Preto', code: 3543402 },
    { city: 'Rifaina', code: 3543600 },
    { city: 'Rincão', code: 3543709 },
    { city: 'Rinópolis', code: 3543808 },
    { city: 'Rio Claro', code: 3543907 },
    { city: 'Rio das Pedras', code: 3544004 },
    { city: 'Rio Grande da Serra', code: 3544103 },
    { city: 'Riolândia', code: 3544202 },
    { city: 'Riversul', code: 3543501 },
    { city: 'Rosana', code: 3544251 },
    { city: 'Roseira', code: 3544301 },
    { city: 'Rubiácea', code: 3544400 },
    { city: 'Rubinéia', code: 3544509 },
    { city: 'Sabino', code: 3544608 },
    { city: 'Sagres', code: 3544707 },
    { city: 'Sales', code: 3544806 },
    { city: 'Sales Oliveira', code: 3544905 },
    { city: 'Salesópolis', code: 3545001 },
    { city: 'Salmourão', code: 3545100 },
    { city: 'Saltinho', code: 3545159 },
    { city: 'Salto', code: 3545209 },
    { city: 'Salto de Pirapora', code: 3545308 },
    { city: 'Salto Grande', code: 3545407 },
    { city: 'Sandovalina', code: 3545506 },
    { city: 'Santa Adélia', code: 3545605 },
    { city: 'Santa Albertina', code: 3545704 },
    { city: 'Santa Bárbara d\'Oeste', code: 3545803 },
    { city: 'Santa Branca', code: 3546009 },
    { city: 'Santa Clara d\'Oeste', code: 3546108 },
    { city: 'Santa Cruz da Conceição', code: 3546207 },
    { city: 'Santa Cruz da Esperança', code: 3546256 },
    { city: 'Santa Cruz das Palmeiras', code: 3546306 },
    { city: 'Santa Cruz do Rio Pardo', code: 3546405 },
    { city: 'Santa Ernestina', code: 3546504 },
    { city: 'Santa Fé do Sul', code: 3546603 },
    { city: 'Santa Gertrudes', code: 3546702 },
    { city: 'Santa Isabel', code: 3546801 },
    { city: 'Santa Lúcia', code: 3546900 },
    { city: 'Santa Maria da Serra', code: 3547007 },
    { city: 'Santa Mercedes', code: 3547106 },
    { city: 'Santa Rita do Passa Quatro', code: 3547502 },
    { city: 'Santa Rita d\'Oeste', code: 3547403 },
    { city: 'Santa Rosa de Viterbo', code: 3547601 },
    { city: 'Santa Salete', code: 3547650 },
    { city: 'Santana da Ponte Pensa', code: 3547205 },
    { city: 'Santana de Parnaíba', code: 3547304 },
    { city: 'Santo Anastácio', code: 3547700 },
    { city: 'Santo André', code: 3547809 },
    { city: 'Santo Antônio da Alegria', code: 3547908 },
    { city: 'Santo Antônio de Posse', code: 3548005 },
    { city: 'Santo Antônio do Aracanguá', code: 3548054 },
    { city: 'Santo Antônio do Jardim', code: 3548104 },
    { city: 'Santo Antônio do Pinhal', code: 3548203 },
    { city: 'Santo Expedito', code: 3548302 },
    { city: 'Santópolis do Aguapeí', code: 3548401 },
    { city: 'Santos', code: 3548500 },
    { city: 'São Bento do Sapucaí', code: 3548609 },
    { city: 'São Bernardo do Campo', code: 3548708 },
    { city: 'São Caetano do Sul', code: 3548807 },
    { city: 'São Carlos', code: 3548906 },
    { city: 'São Francisco', code: 3549003 },
    { city: 'São João da Boa Vista', code: 3549102 },
    { city: 'São João das Duas Pontes', code: 3549201 },
    { city: 'São João de Iracema', code: 3549250 },
    { city: 'São João do Pau d\'Alho', code: 3549300 },
    { city: 'São Joaquim da Barra', code: 3549409 },
    { city: 'São José da Bela Vista', code: 3549508 },
    { city: 'São José do Barreiro', code: 3549607 },
    { city: 'São José do Rio Pardo', code: 3549706 },
    { city: 'São José do Rio Preto', code: 3549805 },
    { city: 'São José dos Campos', code: 3549904 },
    { city: 'São Lourenço da Serra', code: 3549953 },
    { city: 'São Luiz do Paraitinga', code: 3550001 },
    { city: 'São Manuel', code: 3550100 },
    { city: 'São Miguel Arcanjo', code: 3550209 },
    { city: 'São Paulo', code: 3550308 },
    { city: 'São Pedro', code: 3550407 },
    { city: 'São Pedro do Turvo', code: 3550506 },
    { city: 'São Roque', code: 3550605 },
    { city: 'São Sebastião', code: 3550704 },
    { city: 'São Sebastião da Grama', code: 3550803 },
    { city: 'São Simão', code: 3550902 },
    { city: 'São Vicente', code: 3551009 },
    { city: 'Sarapuí', code: 3551108 },
    { city: 'Sarutaiá', code: 3551207 },
    { city: 'Sebastianópolis do Sul', code: 3551306 },
    { city: 'Serra Azul', code: 3551405 },
    { city: 'Serra Negra', code: 3551603 },
    { city: 'Serrana', code: 3551504 },
    { city: 'Sertãozinho', code: 3551702 },
    { city: 'Sete Barras', code: 3551801 },
    { city: 'Severínia', code: 3551900 },
    { city: 'Silveiras', code: 3552007 },
    { city: 'Socorro', code: 3552106 },
    { city: 'Sorocaba', code: 3552205 },
    { city: 'Sud Mennucci', code: 3552304 },
    { city: 'Sumaré', code: 3552403 },
    { city: 'Suzanápolis', code: 3552551 },
    { city: 'Suzano', code: 3552502 },
    { city: 'Tabapuã', code: 3552601 },
    { city: 'Tabatinga', code: 3552700 },
    { city: 'Taboão da Serra', code: 3552809 },
    { city: 'Taciba', code: 3552908 },
    { city: 'Taguaí', code: 3553005 },
    { city: 'Taiaçu', code: 3553104 },
    { city: 'Taiúva', code: 3553203 },
    { city: 'Tambaú', code: 3553302 },
    { city: 'Tanabi', code: 3553401 },
    { city: 'Tapiraí', code: 3553500 },
    { city: 'Tapiratiba', code: 3553609 },
    { city: 'Taquaral', code: 3553658 },
    { city: 'Taquaritinga', code: 3553708 },
    { city: 'Taquarituba', code: 3553807 },
    { city: 'Taquarivaí', code: 3553856 },
    { city: 'Tarabai', code: 3553906 },
    { city: 'Tarumã', code: 3553955 },
    { city: 'Tatuí', code: 3554003 },
    { city: 'Taubaté', code: 3554102 },
    { city: 'Tejupá', code: 3554201 },
    { city: 'Teodoro Sampaio', code: 3554300 },
    { city: 'Terra Roxa', code: 3554409 },
    { city: 'Tietê', code: 3554508 },
    { city: 'Timburi', code: 3554607 },
    { city: 'Torre de Pedra', code: 3554656 },
    { city: 'Torrinha', code: 3554706 },
    { city: 'Trabiju', code: 3554755 },
    { city: 'Tremembé', code: 3554805 },
    { city: 'Três Fronteiras', code: 3554904 },
    { city: 'Tuiuti', code: 3554953 },
    { city: 'Tupã', code: 3555000 },
    { city: 'Tupi Paulista', code: 3555109 },
    { city: 'Turiúba', code: 3555208 },
    { city: 'Turmalina', code: 3555307 },
    { city: 'Ubarana', code: 3555356 },
    { city: 'Ubatuba', code: 3555406 },
    { city: 'Ubirajara', code: 3555505 },
    { city: 'Uchoa', code: 3555604 },
    { city: 'União Paulista', code: 3555703 },
    { city: 'Urânia', code: 3555802 },
    { city: 'Uru', code: 3555901 },
    { city: 'Urupês', code: 3556008 },
    { city: 'Valentim Gentil', code: 3556107 },
    { city: 'Valinhos', code: 3556206 },
    { city: 'Valparaíso', code: 3556305 },
    { city: 'Vargem', code: 3556354 },
    { city: 'Vargem Grande do Sul', code: 3556404 },
    { city: 'Vargem Grande Paulista', code: 3556453 },
    { city: 'Várzea Paulista', code: 3556503 },
    { city: 'Vera Cruz', code: 3556602 },
    { city: 'Vinhedo', code: 3556701 },
    { city: 'Viradouro', code: 3556800 },
    { city: 'Vista Alegre do Alto', code: 3556909 },
    { city: 'Vitória Brasil', code: 3556958 },
    { city: 'Votorantim', code: 3557006 },
    { city: 'Votuporanga', code: 3557105 },
    { city: 'Zacarias', code: 3557154 },
  ],
  TO: [
    { city: 'Abreulândia', code: 1700251 },
    { city: 'Aguiarnópolis', code: 1700301 },
    { city: 'Aliança do Tocantins', code: 1700350 },
    { city: 'Almas', code: 1700400 },
    { city: 'Alvorada', code: 1700707 },
    { city: 'Ananás', code: 1701002 },
    { city: 'Angico', code: 1701051 },
    { city: 'Aparecida do Rio Negro', code: 1701101 },
    { city: 'Aragominas', code: 1701309 },
    { city: 'Araguacema', code: 1701903 },
    { city: 'Araguaçu', code: 1702000 },
    { city: 'Araguaína', code: 1702109 },
    { city: 'Araguanã', code: 1702158 },
    { city: 'Araguatins', code: 1702208 },
    { city: 'Arapoema', code: 1702307 },
    { city: 'Arraias', code: 1702406 },
    { city: 'Augustinópolis', code: 1702554 },
    { city: 'Aurora do Tocantins', code: 1702703 },
    { city: 'Axixá do Tocantins', code: 1702901 },
    { city: 'Babaçulândia', code: 1703008 },
    { city: 'Bandeirantes do Tocantins', code: 1703057 },
    { city: 'Barra do Ouro', code: 1703073 },
    { city: 'Barrolândia', code: 1703107 },
    { city: 'Bernardo Sayão', code: 1703206 },
    { city: 'Bom Jesus do Tocantins', code: 1703305 },
    { city: 'Brasilândia do Tocantins', code: 1703602 },
    { city: 'Brejinho de Nazaré', code: 1703701 },
    { city: 'Buriti do Tocantins', code: 1703800 },
    { city: 'Cachoeirinha', code: 1703826 },
    { city: 'Campos Lindos', code: 1703842 },
    { city: 'Cariri do Tocantins', code: 1703867 },
    { city: 'Carmolândia', code: 1703883 },
    { city: 'Carrasco Bonito', code: 1703891 },
    { city: 'Caseara', code: 1703909 },
    { city: 'Centenário', code: 1704105 },
    { city: 'Chapada da Natividade', code: 1705102 },
    { city: 'Chapada de Areia', code: 1704600 },
    { city: 'Colinas do Tocantins', code: 1705508 },
    { city: 'Colméia', code: 1716703 },
    { city: 'Combinado', code: 1705557 },
    { city: 'Conceição do Tocantins', code: 1705607 },
    { city: 'Couto Magalhães', code: 1706001 },
    { city: 'Cristalândia', code: 1706100 },
    { city: 'Crixás do Tocantins', code: 1706258 },
    { city: 'Darcinópolis', code: 1706506 },
    { city: 'Dianópolis', code: 1707009 },
    { city: 'Divinópolis do Tocantins', code: 1707108 },
    { city: 'Dois Irmãos do Tocantins', code: 1707207 },
    { city: 'Dueré', code: 1707306 },
    { city: 'Esperantina', code: 1707405 },
    { city: 'Fátima', code: 1707553 },
    { city: 'Figueirópolis', code: 1707652 },
    { city: 'Filadélfia', code: 1707702 },
    { city: 'Formoso do Araguaia', code: 1708205 },
    { city: 'Fortaleza do Tabocão', code: 1708254 },
    { city: 'Goianorte', code: 1708304 },
    { city: 'Goiatins', code: 1709005 },
    { city: 'Guaraí', code: 1709302 },
    { city: 'Gurupi', code: 1709500 },
    { city: 'Ipueiras', code: 1709807 },
    { city: 'Itacajá', code: 1710508 },
    { city: 'Itaguatins', code: 1710706 },
    { city: 'Itapiratins', code: 1710904 },
    { city: 'Itaporã do Tocantins', code: 1711100 },
    { city: 'Jaú do Tocantins', code: 1711506 },
    { city: 'Juarina', code: 1711803 },
    { city: 'Lagoa da Confusão', code: 1711902 },
    { city: 'Lagoa do Tocantins', code: 1711951 },
    { city: 'Lajeado', code: 1712009 },
    { city: 'Lavandeira', code: 1712157 },
    { city: 'Lizarda', code: 1712405 },
    { city: 'Luzinópolis', code: 1712454 },
    { city: 'Marianópolis do Tocantins', code: 1712504 },
    { city: 'Mateiros', code: 1712702 },
    { city: 'Maurilândia do Tocantins', code: 1712801 },
    { city: 'Miracema do Tocantins', code: 1713205 },
    { city: 'Miranorte', code: 1713304 },
    { city: 'Monte do Carmo', code: 1713601 },
    { city: 'Monte Santo do Tocantins', code: 1713700 },
    { city: 'Muricilândia', code: 1713957 },
    { city: 'Natividade', code: 1714203 },
    { city: 'Nazaré', code: 1714302 },
    { city: 'Nova Olinda', code: 1714880 },
    { city: 'Nova Rosalândia', code: 1715002 },
    { city: 'Novo Acordo', code: 1715101 },
    { city: 'Novo Alegre', code: 1715150 },
    { city: 'Novo Jardim', code: 1715259 },
    { city: 'Oliveira de Fátima', code: 1715507 },
    { city: 'Palmas', code: 1721000 },
    { city: 'Palmeirante', code: 1715705 },
    { city: 'Palmeiras do Tocantins', code: 1713809 },
    { city: 'Palmeirópolis', code: 1715754 },
    { city: 'Paraíso do Tocantins', code: 1716109 },
    { city: 'Paranã', code: 1716208 },
    { city: 'Pau D\'Arco', code: 1716307 },
    { city: 'Pedro Afonso', code: 1716505 },
    { city: 'Peixe', code: 1716604 },
    { city: 'Pequizeiro', code: 1716653 },
    { city: 'Pindorama do Tocantins', code: 1717008 },
    { city: 'Piraquê', code: 1717206 },
    { city: 'Pium', code: 1717503 },
    { city: 'Ponte Alta do Bom Jesus', code: 1717800 },
    { city: 'Ponte Alta do Tocantins', code: 1717909 },
    { city: 'Porto Alegre do Tocantins', code: 1718006 },
    { city: 'Porto Nacional', code: 1718204 },
    { city: 'Praia Norte', code: 1718303 },
    { city: 'Presidente Kennedy', code: 1718402 },
    { city: 'Pugmil', code: 1718451 },
    { city: 'Recursolândia', code: 1718501 },
    { city: 'Riachinho', code: 1718550 },
    { city: 'Rio da Conceição', code: 1718659 },
    { city: 'Rio dos Bois', code: 1718709 },
    { city: 'Rio Sono', code: 1718758 },
    { city: 'Sampaio', code: 1718808 },
    { city: 'Sandolândia', code: 1718840 },
    { city: 'Santa Fé do Araguaia', code: 1718865 },
    { city: 'Santa Maria do Tocantins', code: 1718881 },
    { city: 'Santa Rita do Tocantins', code: 1718899 },
    { city: 'Santa Rosa do Tocantins', code: 1718907 },
    { city: 'Santa Tereza do Tocantins', code: 1719004 },
    { city: 'Santa Terezinha do Tocantins', code: 1720002 },
    { city: 'São Bento do Tocantins', code: 1720101 },
    { city: 'São Félix do Tocantins', code: 1720150 },
    { city: 'São Miguel do Tocantins', code: 1720200 },
    { city: 'São Salvador do Tocantins', code: 1720259 },
    { city: 'São Sebastião do Tocantins', code: 1720309 },
    { city: 'São Valério', code: 1720499 },
    { city: 'Silvanópolis', code: 1720655 },
    { city: 'Sítio Novo do Tocantins', code: 1720804 },
    { city: 'Sucupira', code: 1720853 },
    { city: 'Taguatinga', code: 1720903 },
    { city: 'Taipas do Tocantins', code: 1720937 },
    { city: 'Talismã', code: 1720978 },
    { city: 'Tocantínia', code: 1721109 },
    { city: 'Tocantinópolis', code: 1721208 },
    { city: 'Tupirama', code: 1721257 },
    { city: 'Tupiratins', code: 1721307 },
    { city: 'Wanderlândia', code: 1722081 },
    { city: 'Xambioá', code: 1722107 },
  ]
};

export default citiesByUF;