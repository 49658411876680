import { actions } from 'rdx';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Bull } from 'types';
import segmentFromBullOrBreed from '../segmentFromBullOrBreed';
import segmentTranslator from '../segmentTranslator';
import string from '../string';

const fromBull = (
  navigateFn: NavigateFunction, 
  dispatch: Dispatch
) => (bull: Bull) => {
  dispatch(actions.scope.changeBull_crv_code(bull.CRVcode.toUpperCase()));
  navigateFn(`\
    /segment/\
    ${segmentTranslator(segmentFromBullOrBreed(bull), 'pathlike')}/\
    ${string.printableToPathlike(bull.breed)}/\
    ${string.printableToPathlike(bull.CRVcode.toUpperCase())}\
  `.replace(/(\s|\t)*/g, ''));
};

export default fromBull;