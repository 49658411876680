import { withTheme } from 'theme';

const tableSectionHeader = withTheme(() => ({
  width: 'calc(100% - 6px)',
  textAlign: 'center',
  background: '#111',
  color: 'white',
  paddingY: '8px'
}));

const tableCellHeader = withTheme(() => ({
  padding: '4px',
  marginLeft: '6px',
  width: 'max-content',
  whiteSpace: 'nowrap',
  background: '#5D5551',
  color: 'white',
  border: '4px solid white'
}));

const tableContainer = withTheme(() => ({
  width: 'max-content',
  display: 'inline-table',
  marginRight: '12px'
}));

const tableLineType = withTheme(() => ({
  background: '#B74D6D',
  width: '80px',
  height: 'auto',
  padding: '4px',
  color: 'white',
  border: '4px solid white'
}));

const tableCellValue = withTheme(() => ({
  padding: '4px',
  background: '#BCB4B1',
  color: 'black',
  border: '3px solid white'
}));

const styles = {
  tableSectionHeader, 
  tableCellHeader,
  tableContainer,
  tableLineType,
  tableCellValue
};

export default styles;