import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import utils from 'utils';
import Logo from '../Logo';
import styles from './Footer.styles';

const Footer: FC = () => {
  return (
    <Stack
      {...utils.stack.flex.col('center', 'center') }
      sx={ styles.containerDoContainer }
    >
      <Stack
        { ...utils.stack.flex.row('space-between', 'center') }
        spacing={ 4 }
        sx={ styles.container }
      >
        <Logo />
        <Stack { ...utils.stack.flex.col('flex-start', 'flex-start') } sx={ styles.infoStack }>
          <Typography variant='h3' sx={{  paddingBottom: '20px' }}>
          Contato
          </Typography>
          <Typography variant='body2' sx={{ fontSize: '11px' }}>Telefone: (16) 3797-1500</Typography>
          <Typography variant='body2' sx={{ fontSize: '11px' }}>Email SAC: sac@crv4all.com.br </Typography>
          <Typography variant='body2' sx={{ fontSize: '11px' }}>Localização: R. Gen. Augusto Soares dos Santos, 100, Bloco C Parque Industrial Lagoinha, Ribeirão Preto - SP, 14095-240 </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;