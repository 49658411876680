import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ButtonWithIcon, Img, Page, Space } from 'components';
import { SelectedBull } from 'components/Catalog';
import { getBlob, ref } from 'firebase/storage';
import { objectSome } from 'object-fn-ts/lib';
import { actions, Store } from 'rdx';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { catalog as catalogService, storage } from 'services';
import { Bull } from 'types';
import { useImmer } from 'use-immer';
import utils from 'utils';
import styles from './Catalog.styles';
import { BsArrowDownUp } from 'react-icons/bs';
import pako from 'pako';
import { PDFDocument } from 'pdf-lib';
import { Line } from 'rc-progress';
import React from 'react';

function base64ToArrayBuffer(base64: string) {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export type CatalogState = {
  cover: number | null;
  farmName: string;
  clientName: string;
  consultantName: string;
  consultantNumber: string;
};

const covers = [
  'Capa Corte Zebu',
  'Capa Corte Europeu',
  'Capa Leite Zebu',
  'Capa Leite Europeu',
  'Capa Institucional',
];
const originalCovers = [
  'CORTE_ZEBU',
  'CORTE_EUROPEU',
  'LEITE_ZEBU',
  'LEITE_EUROPEU',
  'NEUTRA',
  '',
];

const coverPath: any = {
  'Capa Corte Zebu': 'corte_zebu',
  'Capa Corte Europeu': 'corte_europeu',
  'Capa Leite Zebu': 'leite_zebu',
  'Capa Leite Europeu': 'leite_europeu',
  'Capa Institucional': 'neutra',
};

const Catalog: FC = () => {
  const { items, scope, catalog } = useSelector((store: Store) => store);
  const dispatch = useDispatch();
  const [ state, setState ] = useImmer<CatalogState>(catalog.state);
  const navigate = useNavigate();
  const gtMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [ loading, setLoading ] = useState(false);
  const [ count, setCount ] = useState(0);
  const [ modalOpen, setModalOpen ]=useState({
    open:false,
    cover:null as unknown as  number
  });
  const [ isAscending, setIsAscending ] = useState(true);
  const [ searchParams ] = useSearchParams();
  const [ selectedBulls, setSelectedBulls ] = useState<number[]>(items.selectedBulls);
  const [ downloadStarted, setDownloadStarted ] = useState(false);

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  let queries = {} as any;

  useEffect(() => {
    if (
      searchParams.get('generate') !== null ||
      searchParams.get('share') !== null
    ) {
      const params = searchParams.get('generate') || searchParams.get('share');
      const uint8array = Uint8Array.from(
        Buffer.from(params?.replace(/ /g, '+') || '', 'base64')
      );
      const stringQuery = pako.inflate(uint8array, { to: 'string' });
      queries = JSON.parse(stringQuery);

      if (searchParams.get('share') !== null) {
        const objForString = {
          bulls: queries.bulls,
          client: {
            name: encodeURI(queries.client?.name),
            farm: encodeURI(queries.client?.farm),
          },
          consultant: {
            name: encodeURI(queries.consultant?.name),
            phone: queries.consultant?.phone,
            email: queries.consultant.email?.replace('@', '%40'),
          },
          cover: queries?.cover,
          name: encodeURI(queries?.name),
        };
        const string = JSON.stringify(objForString);

        console.log(
          'Redirecionando para o app'
        );

        window.location.replace('crv-app://catalog/' + string);
      }
    }

    if (Object.keys(queries).length > 0) {
      if (queries.bulls) {
        const bulls = queries.bulls;

        for (const bull of bulls) {
          const bullIndex = items.bulls.findIndex((b) => b.CRVcode === bull);

          if (bullIndex >= 0) {
            dispatch(
              actions.items['selectBull'](
                items.bulls.findIndex((b) => b.CRVcode === bull)
              )
            );
          }
        }
      }

      setState({
        ...state,
        cover: queries.cover,
        farmName: queries.client.farm,
        clientName: queries.client.name,
        consultantName: queries.consultant.name,
        consultantNumber: queries.consultant.phone,
      });
    }
  }, [
    queries?.cover,
    queries?.consultant?.name,
    queries?.consultant?.phone,
    queries?.client?.name,
    queries?.client?.farm,
    queries?.bulls,
    items.bulls,
  ]);

  useEffect(() => {
    setSelectedBulls(items.selectedBulls);
  }, [ items.selectedBulls ]);

  useEffect(() => {
    return () => {
      dispatch(actions.catalog.setState(state));


      if (
        !downloadStarted &&
        searchParams.get('generate') !== null &&
        items.selectedBulls &&
        items.bulls.length > 0 
      ) {
        onClickDownloadBtn('pt');

        navigate('/catalog', {
          replace: true,
        });
      }
    };
  }, [ state, items.selectedBulls ]);

  const mainBtnDisabled = items.selectedBulls.length <= 0;

  const onClickDownloadBtn = useCallback(async (language: string) => {
    handleClose();

    if (language == null || language == '' || language == 'pt') {
      language = '';
    } else {
      language = '_' + language;
    }

    setDownloadStarted(true);
    const pdfToMerge = await PDFDocument.create();
    const blobFromBull = (bull: Bull) => {
      const segment = utils
        .segmentTranslator(utils.segmentFromBullOrBreed(bull), 'pathlike')
        .replaceAll('-', '_');
       
      const subpath = `${segment}/pdfs/digital/${bull.CRVcode.toUpperCase()}${language}.pdf`;
      return getBlob(ref(storage, subpath));
    };

    setLoading(true);

    const [ coverResult ] = await utils.handlePromise(
      catalogService.create({
        consultant: state.consultantName,
        consultantPhone: state.consultantNumber,
        CRVcodes: [],
        cover: originalCovers[state.cover ?? 5],
        customer: state.clientName,
        farm: state.farmName,
      })
    );
    let coverArrayBuffer: ArrayBuffer | null = null;
    if (coverResult && state.cover !== null && state.cover !== 5) {
      const {
        data: { catalog: coverBase64 },
      } = coverResult;
      coverArrayBuffer = base64ToArrayBuffer(coverBase64);
    }

    if (coverArrayBuffer) {
      const intermidiatePage = await PDFDocument.load(coverArrayBuffer);
      const page = (
        await pdfToMerge.copyPages(
          intermidiatePage,
          intermidiatePage.getPageIndices()
        )
      )[0];
      pdfToMerge.addPage(page);
    }

    for (const n of items.selectedBulls) {
      setCount((n) => n + 1);
      try {
        const blob = await blobFromBull(items.bulls[n]);
        const intermidiatePage = await PDFDocument.load(
          await blob.arrayBuffer()
        );
        const page = (
          await pdfToMerge.copyPages(
            intermidiatePage,
            intermidiatePage.getPageIndices()
          )
        )[0];
        pdfToMerge.addPage(page);
      } catch (err) {
        console.log({ err });
      }
    }

    const merged = await pdfToMerge.save();
    const url = URL.createObjectURL(
      new Blob([ merged.buffer ], { type: 'application/pdf' })
    );

    const a = document.createElement('a');
    a.href = url;
    a.download = 'Catálogo';
    a.click();

    setLoading(false);
    setCount(0);
    URL.revokeObjectURL(url);
    document.removeChild(a);
    setDownloadStarted(false);
  }, [ state, items.selectedBulls, items.bulls ]);

  const percentage = Math.ceil(
    (count / (items.selectedBulls.length || 1)) * 100
  );

  const FORM_STARTED = !!(state.clientName||state.consultantName||state.consultantNumber||state.farmName);

 

  return (
    <Page>
      <Stack
        {...utils.stack.flex.col('center', 'flex-start')}
        sx={{ width: '100%', maxWidth: '1280px' }}
      >
        <Stack
          {...utils.stack.flex.col('flex-start', 'center')}
          spacing={3}
          sx={styles.container}
        >
          <Typography>Faça o passo a passo para gerar o catálogo</Typography>
          <Typography variant="h3">
            {'1º Passo: '}
            <Typography component="span">Escolha a capa do catálogo (Não é obrigatório)</Typography>
          </Typography>
        </Stack>

        <Grid container spacing={gtMd ? 3 : 1} sx={styles.coversWrapper}>
          {covers.map((e, i) => (
            <Grid item key={e} xs={gtMd ? 4 : 6}>
              <Stack
                {...utils.stack.flex.col('center', 'flex-start')}
                sx={styles.getCoverItemContainer(state.cover ?? -1, i)}
                onClick={() =>{
                  if (FORM_STARTED && !!state.cover && i===state.cover) {
                    setModalOpen({
                      open:true,
                      cover:i
                    });
                   
                    return;
                  }
                  setState((draft) => {
                    draft.cover = draft.cover === i ? null : i;
                  });
                }
                }
              >
               
                {i !== 5 ? (
                  <Img
                    url={`https://firebasestorage.googleapis.com/v0/b/crv-lagoa.appspot.com/o/assets%2Fcapa_web%2F${coverPath[e]}.jpg?alt=media`}
                    sx={styles.imgContainer}
                  />
                ) : (
                  <Box
                    sx={{ ...styles.imgContainer, background: 'white' } as any}
                  />
                )}
                <Stack
                  {...utils.stack.flex.col('center', 'center')}
                  sx={{ width: '100%', minHeight: '50px' }}
                >
                  <Typography
                    sx={(theme) => ({
                      paddingY: theme.spacing(gtMd ? 2 : 0.5),
                    })}
                  >
                    {e}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>

        <Typography variant="h3" sx={{ width: '90%' }}>
          {'2º Passo: '}
          <Typography component="span">
            Preencha os dados do cliente e consultor (Não é obrigatório)
          </Typography>
          
        </Typography>
        {state.cover===null && (
          <Stack sx={{ width:'90%' }} marginTop={1}>
            <Stack flexDirection={'row'}  alignItems="center" bgcolor={'#E4E0DE'} paddingX={1} borderRadius={1} width={'60%'}>
              <img
                src={
                  '/icons/warning.svg'
                }
              />    
              <Typography component="span"  fontSize={10} marginLeft={1}>
        Selecione uma capa para que os dados preenchidos apareçam no catálogo
              </Typography></Stack>
          </Stack>
        )}
        

        <Space size={3} />

        <Stack
          {...utils.stack.flex.col('center', 'center')}
          spacing={1}
          sx={{ width: '90%', maxWidth: '900px' }}
        >
          <TextField
            fullWidth
            label="Nome da fazenda"
            disabled={state.cover === null || state.cover === 5}
            value={state.farmName}
            onChange={(e) =>
              setState((draft) => {
                draft.farmName = e.target.value;
              })
            }
            helperText={' '}
          />
          <TextField
            fullWidth
            label="Nome do cliente"
            value={state.clientName}
            disabled={state.cover === null || state.cover === 5}
            onChange={(e) =>
              setState((draft) => {
                draft.clientName = e.target.value;
              })
            }
            helperText={' '}
          />
          <TextField
            fullWidth
            label="Nome do consultor"
            value={state.consultantName}
            disabled={state.cover === null || state.cover === 5}
            onChange={(e) =>
              setState((draft) => {
                draft.consultantName = e.target.value;
              })
            }
            helperText={' '}
          />
          <TextField
            fullWidth
            label="Telefone do consultor"
            value={state.consultantNumber}
            disabled={state.cover === null || state.cover === 5}
            onChange={(e) =>
              setState((draft) => {
                draft.consultantNumber = e.target.value;
              })
            }
            helperText={' '} 
          />
        </Stack>

        <Space size={3} />

        <Typography variant="h3" sx={{ width: '90%' }}>
          {'3º Passo: '}
          <Typography component="span">
            Verifique a lista de touros selecionados
          </Typography>
        </Typography>

        <Space size={gtMd ? 7 : 3} />
        <Stack
          {...utils.stack.flex.col('flex-end', 'center')}
          sx={{ width: '90%', maxWidth: '900px' }}
        >
          <Button
            variant="text"
            sx={{ color: '#000', fontWeight: '600' }}
            onClick={() => {
              if (items.selectedBulls.length === 0) {
                return;
              }
          
              const sortedBulls = selectedBulls.slice().sort((a, b) => {
                const bullAName = items?.bulls[a]?.warName !== '-' ? items?.bulls[a]?.warName : items?.bulls[a]?.name;
                const bullBName = items?.bulls[b]?.warName !== '-' ? items?.bulls[b]?.warName : items?.bulls[b]?.name;
          
                if (!bullAName || !bullBName) return 0;
          
                const compareResult = bullAName.localeCompare(bullBName as string, 'pt-BR', { sensitivity: 'base' });
          
                return isAscending ? compareResult : -compareResult;
              });
          
              dispatch(actions.items.silentChangeSelectedBulls(sortedBulls));
          
              setSelectedBulls(sortedBulls);
          
              setIsAscending(!isAscending);
            }}
          >
            {`Ordenar por ${isAscending ? 'A-Z' : 'Z-A'}`} <BsArrowDownUp style={{ marginLeft: '10px' }}/>
          </Button>
        </Stack>
        <Space size={gtMd ? 2 : 1} />
        <Stack
          {...utils.stack.flex.col('center', 'flex-start')}
          spacing={2}
          sx={{ width: '90%' }}
        >
          {items.bulls.length !== 0
            ? selectedBulls.map((bullIndex) => (
              <SelectedBull
                key={`selected-${bullIndex}`}
                bull={items.bulls[bullIndex]}
                index={bullIndex}
              />
            ))
            : null}
        </Stack>
        <Space size={gtMd ? 3 : 1} />
        <Stack
          {...utils.stack.flex.col('flex-end', 'center')}
          sx={{ width: '90%', maxWidth: '900px' }}
        >
          <Button
            variant="text"
            onClick={() => {
              items.selectedBulls.forEach((bindex) =>
                dispatch(actions.items.unselectBull(bindex))
              );
            }}
          >
            Remover todos
          </Button>
        </Stack>

        <Space size={gtMd ? 7 : 3} />

        <ButtonWithIcon
          iconURL="/icons/sm-add.svg"
          onClick={() => {
            navigate(
              `/segment/${utils.segmentTranslator(scope.segment, 'pathlike')}` +
                (scope.breed !== null
                  ? `/${utils.string.printableToPathlike(scope.breed)}`
                  : '')
            );
          }}
        >
          Adicionar touro
        </ButtonWithIcon>

        <Space size={3} />

        <ButtonWithIcon
          iconURL="/icons/cloud-download-white.svg"
          size="l"
          disabled={loading || mainBtnDisabled}
          onClick={handleClick}
          loading={loading}
        >
          Baixar catálogo personalizado
        </ButtonWithIcon>
        {loading && (
          <Stack {...utils.stack.flex.col('center', 'center')} spacing={2}>
            <Typography> Gerando catálogo {percentage}% </Typography>
            <Line
              percent={percentage}
              strokeWidth={4}
              strokeColor="#004aaa"
              strokeLinecap="round"
            />
          </Stack>
        )}

        <Menu
          id="lock-menu"
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }} 
          anchorEl={anchorEl}
          open={open}      
          onClose={handleClose}
          PaperProps={{  
            style: {  
              width: 327,  
            },  
          }}
        >
          <MenuItem
            onClick={() => onClickDownloadBtn('pt')}
          >
            Português
          </MenuItem>
          <MenuItem
            key={1}
            onClick={() => onClickDownloadBtn('en')}
          >
            English
          </MenuItem>
          <MenuItem
            key={1}
            onClick={() => onClickDownloadBtn('es')}
          >
            Español
          </MenuItem>
        </Menu>

        <Space size={1} />

        {items.selectedBulls.length <= 0 ? (
          <Typography sx={(theme) => ({ color: theme.palette.error.main })}>
            Selecione ao menos um touro
          </Typography>
        ) : (
          <Container
            sx={{
              height: '25px',
            }}
          />
        )}

        <Space size={3} />
        <Dialog
          open={modalOpen.open}
          onClose={()=>setModalOpen({
            open:false,
            cover:null as unknown as number
          })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          
          <DialogTitle id="alert-dialog-title" textAlign={'center'} sx={{ paddingY:4 }}>
            <Stack justifyContent={'center'}>
              <img style={{ width:44, alignSelf:'center', marginBottom:38 }}
                src={
                  '/icons/cover-warning.svg'
                }
              />   
            Você está desmarcando a capa

            </Stack>
          </DialogTitle>
          <DialogContent >
            <DialogContentText color="black" textAlign="center">
            Ao desmarcar, os dados que foram preenchidos ficarão desabilitados e não aparecerão quando baixar o catálogo
            </DialogContentText>
            <DialogContentText color="black" textAlign="center" marginTop={1}>
            Para habilitá-los selecione novamente uma capa
            </DialogContentText>
            <DialogContentText color="black" textAlign="center" marginTop={1}>
            Deseja continuar mesmo assim?
            </DialogContentText>
          </DialogContent>
          <DialogActions >
            <Stack justifyContent={'space-around'} flexDirection="row" flex={1} marginBottom={4}>
              
              <Button onClick={()=>{
                setState((draft) => {
                  draft.cover = modalOpen.cover;
                });
                setModalOpen({
                  open:false,
                  cover:null as unknown as number
                });
              }} variant="outlined" sx={{ borderRadius:'2px' }}>Cancelar</Button>
              <Button 
                onClick={()=>{
                  setState((draft) => {
                    draft.cover = null;
                  });
                  setModalOpen({
                    open:false,
                    cover:null as unknown as number
                  });}}
                autoFocus sx={{ borderRadius:'2px' }}>
            Sim, quero desmarcar
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Stack>
    </Page>
  );
};

export default Catalog;
