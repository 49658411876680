import { Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { BreedCard } from 'components/common';
import { Store } from 'rdx';
import { FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import utils from 'utils';
import styles from './BreedHighlight.styles';

const BreedHighlight: FC = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { breeds, highlightBreeds } = useSelector((store: Store) => store.items);
  const gtMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Stack { ...utils.stack.flex.col('center', 'flex-start') } sx={ utils.sx.container }>
      <Stack
        { ...utils.stack.flex.col('flex-start', 'center') }
        sx={ utils.sx.fw }
      >
        { 
          highlightBreeds.length > 0 &&
          <Typography variant='h2' sx={ utils.sx.fw }>
            Raças em destaque
          </Typography>
        }
      </Stack>
      <Grid
        container
        { ...utils.stack.flex.row('flex-start', 'flex-start') }
        rowSpacing={ 4 }
        columnSpacing={ 4 }
        sx={ styles.breedsContainer }
      >
        {
          highlightBreeds.map((n, i) => {
            const breed = breeds[n];
            return (
              <Grid
                justifyContent='center'
                item
                xs={ 12 }
                md={ 6 }
                lg={ 4 }
                key={`${breed.name}${i}`}
              >
                <BreedCard
                  breed={ breed }
                  onClick={ utils.location.fromBreed(navigate, dispatch) }
                />
              </Grid>
            );
          })
        }
      </Grid>
    </Stack>
  );
};

export default BreedHighlight;