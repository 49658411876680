import stack from './stack';
import sx from './sx';
import string from './string';
import segmentTranslator from './segmentTranslator';
import collectionToArray from './collectionToArray';
import fromDictionary from './fromDictionary';
import conditionalRenderingArray from './conditionalRenderingArray';
import arrayReplace from './arrayReplace';
import handlePromise from './handlePromise';
import location from './location';
import bullLinkToDownload from './bullLinkToDownload';
import segmentFromBullOrBreed from './segmentFromBullOrBreed';
import segmentIconFromBullOrBreed from './getIconFromSegment';
import compareWithNullable from './compareWithNillable';
import isAllSelected from './isAllSelected';
import safeHas from './safeHas';
import * as proofBuilders from './proof';
export { default as stack } from './stack';
export { default as sx } from './sx';

const utils = {
  stack,
  sx,
  string,
  location,
  proofBuilders,
  segmentTranslator,
  collectionToArray,
  fromDictionary,
  arrayReplace,
  handlePromise,
  conditionalRenderingArray,
  bullLinkToDownload,
  segmentFromBullOrBreed,
  segmentIconFromBullOrBreed,
  compareWithNullable,
  isAllSelected,
  safeHas
};

export default utils;