import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import { Space } from 'components';
import { BullResultCard } from 'components/BullSearch';
import { actions, Store } from 'rdx';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Bull } from 'types';
import utils from 'utils';

const BullList: FC = () => {

  const { scope, items } = useSelector((store: Store) => store);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSingleSelection = (v: boolean, bull: Bull | number) => {
    const index = typeof bull === 'number'
      ? bull
      : items.bulls.findIndex(b => b.CRVcode === bull.CRVcode);
    dispatch(actions.items[v ? 'selectBull' : 'unselectBull'](index));
    if (!v) {
      dispatch(actions.items.removeAllSelected({
        type: 'breed',
        key: scope.breed || ''
      }));
    }
  };

  const onSelectAll = useCallback((v: boolean) => {
    items.scopedBulls.forEach(index => handleSingleSelection(v, index));
    dispatch(actions.items[v ? 'pushAllSelected' : 'removeAllSelected']({
      type: 'breed',
      key: scope.breed || ''
    }));
  }, [ handleSingleSelection ]);

  const currentBreed = items.breeds.find(b => b.name.toLowerCase() === scope.breed?.toLowerCase());

  return (
    <Stack
      { ...utils.stack.flex.col('flex-start', 'center') }
      sx={ utils.sx.fw }
      spacing={ 2 }
    >
      {
        currentBreed &&
        <FormControl
          fullWidth
          sx={{ maxWidth: '570px' }}
        >
          <InputLabel id='rating-label'> Avaliação </InputLabel>
          <Select
            labelId='rating-label'
            defaultValue='all'
            value={ scope.rating ?? 'all' }
            label='Avaliação'
            onChange={(e) => dispatch(actions.scope.changeRating(e.target.value))}
          >
            {
              currentBreed.filters.length === 0 &&
              <MenuItem disabled value=''> Nenhum avaliação encontrada </MenuItem>
            }
            {
              currentBreed.filters.length > 0 &&
              <MenuItem value='all'> Todas as avaliações </MenuItem>
            }
            {
              currentBreed.filters.map(p => (
                <MenuItem key={p.name} value={p.name}>{ p.name }</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      }
      <Stack
        { ...utils.stack.flex.row('flex-start', 'center') }
        sx={ utils.sx.fw }
        spacing={ 1 }
      >
        <Checkbox
          checked={ utils.isAllSelected(items.allSelected, scope, 'breed') }
          sx={(theme) => ({ color: theme.palette.primary.main, padding: '0px' })}
          onChange={(_, v) => onSelectAll(v)}
        />
        <Typography> Selecionar todos </Typography>
      </Stack>
      <Space size={ 1 } key='breed-page-space-1' />
      <Stack
        { ...utils.stack.flex.col('flex-start', 'flex-start') }
        sx={{ width: '100%' }}
        spacing={ 2 }
      >
        {
          items.scopedBulls.map((n) => {
            const bull = items.bulls[n];
            return (
              <>
                {/* <p>{n}</p> */}
                <BullResultCard
                  bull={ bull }
                  key={ bull.name }
                  onClick={() => navigate(utils.string.printableToPathlike(bull.CRVcode.toUpperCase()))}
                  onChange={(v) => handleSingleSelection(v, bull)}
                />
              </>
            );
          })
        }
      </Stack>
    </Stack>
  );
};

export default BullList;