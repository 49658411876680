import { withTheme } from 'theme';

const container = withTheme((theme) => ({
  minWidth: '160px',
  background: theme.palette.primary.main,
  borderRadius: '2px',
  paddingY: theme.spacing(1.5),
  paddingX: theme.spacing(2)
}));

const styles = {
  container
};

export default styles;