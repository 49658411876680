import { Theme, ThemeOptions } from '@mui/material';

export const getButtonOverrides = (theme: Theme): ThemeOptions['components'] => {
  return {
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          borderRadius: '2px',
          textTransform: 'capitalize',
        },
        outlined: {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          borderRadius: '4px'
        }
      }
    }
  };
};