import { withTheme } from 'theme';

const breedsContainer = withTheme((theme) => ({
  paddingY: theme.spacing(3)
}));

const styles = {
  breedsContainer,
};

export default styles;