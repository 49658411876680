import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CatalogState } from 'pages/Catalog/Catalog';

type Catalog = {
  state: CatalogState
}

const initialState: Catalog = {
  state: {
    cover: null,
    clientName: '',
    consultantName: '',
    consultantNumber: '',
    farmName: ''
  }
};

const catalog = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<CatalogState>) => {
      state.state = action.payload;
    },
    resetState: (state) => {
      state = initialState;
    }
  }
});

export const catalogActions = catalog.actions;
export default catalog.reducer;