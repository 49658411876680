import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Modal = {
  secImgBull: {
    open: boolean,
    url: string,
    subtitle: string | null
  }
}

const initialState: Modal = {
  secImgBull: {
    open: false,
    url: '/imgs/default.png',
    subtitle: null
  }
};

const modal = createSlice({
  name: 'scope',
  initialState,
  reducers: {
    setSIBOpen: (state, action: PayloadAction<boolean>) => {
      state.secImgBull.open = action.payload;
    },
    setSIB_URL: (state, action: PayloadAction<string | null | undefined>) => {
      state.secImgBull.url = action.payload || '/imgs/default.png';
    },
    setSIBSubtitle: (state, action: PayloadAction<string | null >) => {
      state.secImgBull.subtitle = action.payload;
    }
  }
});

export const modalActions = modal.actions;
export default modal.reducer;