import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import utils from 'utils';

type Props = {
  name: string,
  val: string | number
}

const IndexElement: FC<Props> = ({ name: key, val }) => {
  return (
    <Stack
      {...utils.stack.flex.col('center', 'center') }
      spacing={ 1 }
      sx={{
        width: '70px',
        minWidth: '70px',
        height: '70px',
        padding: '4px',
        background: '#EAE8E5',
        borderRadius: '3px',
        marginBottom: '24px !important',
        marginRight: '24px !important'
      }}
    >
      <Typography variant='body2'>{ key }</Typography>
      <Typography variant='body2'>{ val as any }</Typography>
    </Stack>
  );
};

export default IndexElement;