import { withTheme } from 'theme';
import utils from 'utils';

const container = withTheme((theme) => ({
  ...utils.sx.container(theme),
  paddingY: theme.spacing(2),
  flexWrap: 'wrap',
}));

const containerDoContainer = withTheme((theme) => ({
  width: '100%',
  ...utils.sx.flex.col('center', 'center'),
  maxWidth: theme.breakpoints.values.lg
}));

const homeIcon = withTheme(() => ({
  width: '24px',
  cursor: 'pointer',
  transform: 'translateY(5px)'
}));

const styles = {
  container,
  homeIcon,
  containerDoContainer
};

export default styles;