import { withTheme } from 'theme';
import utils from 'utils';

const scales = {
  s: 1,
  m: 1.3,
  l: 1.7
};

const getContainer = (size: 's' | 'm' | 'l' = 's', gtMd = false) => withTheme(() => ({
  ...utils.sx.flex.col('center', 'center'),
  width: `${130 * (gtMd ? 1.4 : 1) * scales[size]}px`,
  height: `${50 * (gtMd ? 1.4 : 1) * scales[size]}px`,
  minWidth: `${130 * (gtMd ? 1.4 : 1) * scales[size]}px`,
  minHeight: `${50 * (gtMd ? 1.4 : 1) * scales[size]}px`,
  overflow: 'hidden',
  'img': {
    width: '120%',
    marginLeft: '10px',
    translate: '-10px 0px',
  }
}));

const styles = {
  getContainer
};

export default styles;