import { Box } from '@mui/material';
import { FC } from 'react';
import utils from 'utils';
import ParentLine from './ParentLine';
import { Info } from './Pedigree.types';

type Props = {
  group: {
    m: Info,
    f: Info,
  }
  omitLeftBorder?: boolean
  sm?: boolean
}

const ParentGroup: FC<Props> = ({ group, omitLeftBorder = false, sm = false }) => {
  return (
    <Box sx={{
      ...utils.sx.flex.col('center', 'center'),
      width: '200px',
      height: sm ? '150px' : '300px',
      borderLeft: omitLeftBorder ? 'none' : '2px solid black',
      '& > div': {
        borderBottom: '2px solid black'
      },
      '& > div:last-child': {
        borderBottom: 'none'
      },
    }}>
      <ParentLine position='top' info={ group.m } type='m' />
      <ParentLine position='bottom' info={ group.f } type='f' />
    </Box>  
  );
};

export default ParentGroup;