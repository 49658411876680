import { withTheme } from 'theme';
import utils from 'utils';

const containerDoContainer = withTheme((theme) => ({
  width: '100%',
  borderTop: `1px solid ${theme.palette.primary.light}`,
}));

const container = withTheme((theme) => ({
  ...utils.sx.container(theme),
  paddingY: theme.spacing(3),
}));

const infoStack = withTheme(() => ({
  maxWidth: 'min(50%, 400px)',
  wordWrap: 'break-word',
  overflowWrap: 'anywhere'
}));

const styles = {
  container,
  infoStack,
  containerDoContainer
};

export default styles;