import { Stack, Box } from '@mui/material';
import { FC, useRef, useState, useEffect } from 'react';
import utils from 'utils';
import styles from './LZ.styles';

type CommonProofLZ = {
  [key: string]: {
    name: string,
    type: string[],
    values: string[]
  }[]
}

type Props = {
  data: CommonProofLZ['key']
  comp?: {
    BetaCaseina?: string
  }
}

const LZView: FC<Props> = ({ data, comp = {} }) => {
  const allTypes = data.reduce((acc: string[], e) => {
    const semi = [];
    for (const t of e.type) {
      if (acc.includes(t)) return acc;
      semi.push(t);
    }
    return [ ...acc, ...semi ];
  }, []);

  const tableSection = data.filter(e => e.type.length > 0);
  const constantSection = data.filter(e => e.type.length === 0);

  const baseTableRef = useRef<HTMLDivElement | undefined >(undefined);
  const [ fullCellStyle, setFullCellStyle ] = useState<any>({});

  useEffect(() => {
    setFullCellStyle(styles.getCellFull(baseTableRef.current?.offsetHeight || 28));
    setTimeout(() =>
      setFullCellStyle(styles.getCellFull(baseTableRef.current?.offsetHeight || 28)),
    500);
  }, [ baseTableRef ]);

  return (
    <Stack
      { ...utils.stack.flex.row('flex-start', 'flex-start')}
      sx={{ width: '100%', overflowX: 'auto' }}
    >
      <Stack
        { ...utils.stack.flex.row('flex-start', 'flex-start') }
        spacing={ 1 }
        sx={ styles.tableContainer }
        ref={ baseTableRef }
      >
        <Stack
          { ...utils.stack.flex.col('flex-start', 'flex-start') }
          spacing={ 1 }
          sx={ styles.tableColumn }
        >
          <Box sx={ styles.tableCell }></Box>
          {
            allTypes.map(t => (
              <Box sx={ styles.tableUnitType }>{ t }</Box>
            ))
          }
        </Stack>
        {
          tableSection.map(e => (
            <Stack
              { ...utils.stack.flex.col('flex-start', 'flex-start') }
              spacing={ 1 }
              sx={ styles.tableColumn }
            >
              <Box sx={ styles.tableHeader }>{ e.name.replaceAll('\n', '') }</Box>
              {
                Array(allTypes.length).fill('-').map((f, i) => e.values[i] || f)
                  .map(v => (
                    <Box sx={ styles.tableCell }>{v}</Box>
                  ))
              }
            </Stack>
          ))
        }
        {
          constantSection.map(e => (
            <Stack
              { ...utils.stack.flex.col('flex-start', 'flex-start') }
              spacing={ 1 }
              sx={ styles.tableColumn }
            >
              <Box sx={ styles.tableHeader }>{ e.name }</Box>
              <Stack
                { ...utils.stack.flex.col('center', 'center') }
                sx={ fullCellStyle }
              > { e.values[0] || '-' } </Stack>
            </Stack>
          ))
        }
        
      </Stack>
    </Stack>
  );
};

export default LZView;