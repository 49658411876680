import { Box, IconButton, TextField } from '@mui/material';
import { FC, useEffect, useRef } from 'react';
import Img from '../Img';
import styles from './MainTextSearchInput.styles';
import { actions, Store } from 'rdx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import utils from 'utils';

type Props = {
  autoFocus?: boolean;
};

const normStr = utils.string.normalize;

const MainTextSearchInput: FC<Props> = ({ autoFocus }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items, scope } = useSelector((store: Store) => store);

  const debouncedFiltering = useRef(
    debounce((value: string) => {
      dispatch(actions.items.setLoading(true));
      if (value === '') {
        dispatch(actions.items.changeFilteredList(items.scopedBulls));
        setTimeout(() => dispatch(actions.items.setLoading(false)), 500);
        return;
      }
      const indexes = (
        scope.segment === ''
          ? items.bulls
          : items.scopedBulls.map((n) => items.bulls[n])
      )
        .filter(
          (bull) =>
            normStr(bull?.warName || bull.name).includes(normStr(value)) ||
            normStr(bull.registry).includes(normStr(value)) ||
            normStr(bull.CRVcode).includes(normStr(value))
        )
        .map((bull) =>
          items.bulls.findIndex((b) =>
            typeof b.CRVcode === 'string' &&
            b.CRVcode !== '-' &&
            b.CRVcode !== ''
              ? b.CRVcode === bull.CRVcode
              : (b?.warName || b.name) === (bull?.warName || bull.name)
          )
        );
      dispatch(actions.items.changeFilteredList(indexes));
      setTimeout(() => dispatch(actions.items.setLoading(false)), 500);
    }, 500)
  );

  useEffect(() => {
    dispatch(actions.items.setLoading(true));
    debouncedFiltering.current(scope.filterValue);
  }, []);

  const handleChange = (value: string) => {
    if (value !== '' && !window.location.pathname.includes('/bull-search')) {
      navigate('bull-search');
    }
    debouncedFiltering.current(value);
    dispatch(actions.scope.changeFilterValue(value));
  };

  const boldPlaceholder = (
    <span
      style={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        marginLeft: '60px',
        color: 'rgba(0, 0, 0, 0.54)',
        pointerEvents: 'none'
      }}
    >
      Digite o <b>nome</b>, <b>RGD</b> ou <b>Código CRV</b>
    </span>
  );

  return (
    <Box sx={styles.container}>
      <TextField
        fullWidth
        autoFocus={autoFocus}
        placeholder=" "
        value={scope.filterValue}
        onChange={(e) => handleChange(e.target.value)}
        InputProps={{
          startAdornment: (
            <IconButton>
              <Img url="/icons/search.svg" />
            </IconButton>
          ),
          ...(scope.filterValue !== ''
            ? {
              endAdornment: (
                <IconButton
                  onClick={() => {
                    dispatch(actions.scope.changeFilterValue(''));
                    debouncedFiltering.current('');
                  }}
                >
                  <Img url="/icons/close.svg" />
                </IconButton>
              ),
            }
            : {}),
        }}
      />
      {scope.filterValue !== '' ? null : boldPlaceholder}
    </Box>
  );
};

export default MainTextSearchInput;
