const namePool = [
  {
    short: 'zc',
    pathlike: 'corte-zebu',
    snake: 'corte_zebu',
    printable: 'Corte Zebu'
  },
  {
    short: 'zl',
    pathlike: 'leite-zebu',
    snake: 'leite_zebu',
    printable: 'Leite Zebu'
  },
  {
    short: 'ec',
    pathlike: 'corte-europeu',
    snake: 'corte_europeu',
    printable: 'Corte Europeu'
  },
  {
    short: 'el',
    pathlike: 'leite-europeu',
    snake: 'leite_europeu',
    printable: 'Leite Europeu'
  }
];


const segmentTranslator = (s: string, to: 'printable' | 'pathlike' | 'short' | 'snake') => {
  return namePool.find(e => Object.values(e).includes(s))?.[to] ?? s;
};

export default segmentTranslator;