import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

type FeedbackProps = {
  timeout: number,
  visible: boolean,
  text: string | string[],
  timeoutvar: NodeJS.Timeout | null,  
  type?: 'error' | 'ok'
}

const defaultFeedbackProps: FeedbackProps = {
  timeout: 3000,
  visible: false,
  text: '',
  timeoutvar: null,
};

type FeedbackStore = { [key: string]: FeedbackProps } 

const initialState = {
  bottomAlert: defaultFeedbackProps,
};

const createSetTimeout = (key: string) =>
<T extends FeedbackStore>(state: T, action: PayloadAction<number>) => {
  state[key].timeout = action.payload;
};

const createSetText = (key: string) =>
<T extends FeedbackStore>(state: T, action: PayloadAction<string | string[]>) => {
  state[key].text = action.payload;
};

const createHide = (key: string) =>
<T extends FeedbackStore>(state: T) => {
  state[key].visible = false;
  state[key].type = undefined;
};

const createSetType = (key: string) =>
<T extends FeedbackStore>(state: T, action: PayloadAction<'error' | 'ok' | undefined>) => {
  state[key].type = action.payload;
};

const createShow = (key: string) =>
<T extends FeedbackStore>(state: T, action: PayloadAction<Dispatch>) => {
  state[key].visible = true;
  if (state[key].timeoutvar === null) {
    state[key].timeoutvar = setTimeout(() => {
      action.payload({
        type: `feedback/hide${key.charAt(0).toUpperCase()}${key.slice(1)}`,
        payload: undefined
      });
    }, state[key].timeout);
  } else {
    const t = state[key].timeoutvar as NodeJS.Timeout;
    clearTimeout(t);
    state[key].timeoutvar = setTimeout(() => {
      action.payload({
        type: `feedback/hide${key.charAt(0).toUpperCase()}${key.slice(1)}`,
        payload: undefined
      });
    }, state[key].timeout);
  }  
};


const createReducers = (): {
  [K in keyof typeof initialState as `set${Capitalize<K>}Timeout`]: ReturnType<typeof createSetTimeout>
} & {
  [K in keyof typeof initialState as `set${Capitalize<K>}Text`]: ReturnType<typeof createSetText>
} & {
  [K in keyof typeof initialState as `show${Capitalize<K>}`]: ReturnType<typeof createShow>
} & {
  [K in keyof typeof initialState as `hide${Capitalize<K>}`]: ReturnType<typeof createHide>
} & {
  [K in keyof typeof initialState as `set${Capitalize<K>}IconType`]: ReturnType<typeof createSetType>
} => {
  const keys = Object.keys(initialState);
  const reducersEntries = keys.map((key) => [
    [ `set${`${key.charAt(0).toUpperCase()}${key.slice(1)}`}Timeout`, createSetTimeout(key) ],
    [ `set${`${key.charAt(0).toUpperCase()}${key.slice(1)}`}Text`, createSetText(key) ],
    [ `show${`${key.charAt(0).toUpperCase()}${key.slice(1)}`}`, createShow(key) ],
    [ `hide${`${key.charAt(0).toUpperCase()}${key.slice(1)}`}`, createHide(key) ],
    [ `set${`${key.charAt(0).toUpperCase()}${key.slice(1)}IconType`}`, createSetType(key) ],
  ]).flat();
  return Object.fromEntries(reducersEntries);
};


const feedback = createSlice({
  name: 'feedback',
  initialState,
  reducers: createReducers()
});

export const feedbackActions = feedback.actions;
export default feedback.reducer;