import { Box } from '@mui/material';
import { FC } from 'react';
import { withTheme } from 'theme';

const Hr: FC = () => {
  return (
    <Box sx={
      withTheme((theme) => ({
        width: '100%',
        background: '#99BDDE',
        height: '1px'
      }))
    }></Box>
  );
};

export default Hr;