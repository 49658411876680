import { Stack } from '@mui/material';
import { ButtonWithIcon, Hr, Space } from 'components';
import { BreedHighlight, SegmentSelector } from 'components/Main';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import utils from 'utils';

type Props = {
  gtMd?: boolean
}

const Initial: FC<Props> = ({ gtMd = false }) => {

  const navigate = useNavigate();

  return (
    <Stack
      { ...utils.stack.flex.col('center', 'flex-start') }
      sx={ utils.sx.fw }
    >
      <Stack
        { ...utils.stack.flex.col('center', 'flex-start') }
        key='segment-wrapper'
        sx={ utils.sx.fw }
      >
        {
          !gtMd &&
          <Hr />
        }
        <SegmentSelector />
        <Hr />
      </Stack>
      <Space size={ 5 } />
      <ButtonWithIcon
        iconURL='/icons/search-people.svg'
        size='l'
        key='btn-with-icon'
        onClick={() => navigate('/consultant-search')}
      >
        Encontre o seu consultor
      </ButtonWithIcon>
      <Space size={ 5 } />
      <BreedHighlight /> 
    </Stack>
  );
};

export default Initial;