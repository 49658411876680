import { withTheme } from 'theme';

const container = withTheme((theme) => ({
  width: '90%',
  paddingY: theme.spacing(2),
  maxWidth: '800px'
}));

const stateInputSx = withTheme((theme) => ({
  width: '33%',
  maxWidth: '200px',
  minWidth: '33%',
  [theme.breakpoints.up('sm')]: {
    minWidth: '110px',
    width: '20%',
    maxWidth: '140px'
  }
}));

const styles = {
  container,
  stateInputSx
};

export default styles;