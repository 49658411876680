import { withTheme } from 'theme';
import utils from 'utils';

export const scales = {
  s: 1,
  m: 1.26,
  l: 2.1
};

const getContainer = (
  size: 's' | 'm' | 'l', 
  variant: 'contained' | 'outlined', 
  disabled = false
) => withTheme((theme) => ({
  ...utils.sx.flex.row('space-evenly', 'center'),
  width: `${120 * scales[size]}px`,
  minHeight: '50px',
  background: disabled 
    ? 'gray'
    : variant === 'contained'
      ? theme.palette.primary.main
      : 'transparent',
  paddingX: theme.spacing(1),
  paddingY: theme.spacing(1.5),
  borderRadius: '2px',
  border: variant === 'contained' ? 'transparent' : `1px solid ${theme.palette.primary.main}`,
  color: variant === 'contained' ? 'white' : theme.palette.primary.main,
  cursor: 'pointer',
  '&:hover':{
    background: disabled
      ? 'gray'
      : variant === 'contained'
        ? theme.palette.primary.light
        : '#DCEAF6'
  },
  [theme.breakpoints.up('md')]: {
    width: `${120 * 1.3 * scales[size]}px`
  }
}));

const styles = {
  getContainer
};

export default styles;