import { withTheme } from 'theme';

const container = withTheme(() => ({
  width: '100%'
}));

const titleWrapper = withTheme((theme) => ({
  width: '100%',
  padding: theme.spacing(1),
  background: '#CEE0F1',
  borderRadius: '4px 4px 0 0',
  marginBottom: '-2px',
  maxWidth: '600px'
}));

const styles = {
  container,
  titleWrapper
};

export default styles;