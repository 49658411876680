import { withTheme } from 'theme';

const tableContainer = withTheme(() => ({
  width: 'auto',
  minWidth: '400px',
  minHeight: '80px'
}));

const tableCell = withTheme(() => ({
  width: '100%',
  whiteSpace: 'nowrap',
  padding: '2px',
  borderRadius: '4px',
  background: '#ccc',
  minWidth: '70px',
  minHeight: '28px',
  textAlign: 'center'
}));

const getCellFull = (h: number) => withTheme(() => ({
  ...tableCell,
  minHeight: `${h - 28 - 8}px`
}));

const tableHeader = withTheme(() => ({
  width: 'max-content',
  whiteSpace: 'nowrap',
  padding: '2px',
  borderRadius: '4px',
  background: '#999',
  color: 'white',
  minWidth: '110px',
  minHeight: '22px',
  textAlign: 'center',
  overflowX: 'auto'
}));

const tableUnitType = withTheme((theme) => ({
  ...tableCell,
  background: theme.palette.primary.main,
  color: 'white'
}));

const tableColumn = withTheme(() => ({
  width: 'auto',
  minWidth: '70px'
}));

export default {
  tableContainer,
  tableCell,
  tableHeader,
  tableColumn,
  tableUnitType,
  getCellFull
};