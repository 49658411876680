import { FC } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { BullSearch, Catalog, ConsultantSearch, Main } from 'pages';
import { MainLayout2 } from 'layouts';

const segmentBaseNames = [
  'leite-zebu',
  'leite-europeu',
  'corte-zebu',
  'corte-europeu'
];

const segmentChildrenRoutes: RouteObject[] = segmentBaseNames.map(route => {
  const variations = [ '', '/:breed', '/:breed/:bull' ];
  return variations.map(variation => ({
    path: `${route}${variation}`,
    element: <Main />
  }));
}).flat();

const segmentChildrenRoutesWithBullSearch: RouteObject[] = segmentBaseNames.map(route => {
  const variations = [ '/bull-search', '/:breed/bull-search', '/:breed/:bull/bull-search' ];
  return variations.map(variation => ({
    path: `${route}${variation}`,
    element: <BullSearch />
  }));
}).flat();


const Routes: FC = () => useRoutes([
  {
    path: '/',
    element: <MainLayout2 />,
    children: [
      {
        path: '',
        element: <Main />
      },
      {
        path: '/segment',
        children: [
          {
            path: '',
            element: <Navigate to='/' replace />
          },
          ...segmentChildrenRoutes,
          ...segmentChildrenRoutesWithBullSearch
        ]
      },
      {
        path: '/consultant-search',
        element: <ConsultantSearch />
      },
      {
        path: '/bull-search',
        element: <BullSearch />
      },
      {
        path: '/catalog',
        element: <Catalog />
      }
    ]
  }
]);
export default Routes;