import { AxiosResponse } from 'axios';
import http from 'services/http';
import { Breed, Bull } from 'types';

type GetAllResponse = {
  bulls: Bull[],
  breedsProofs: Breed[]
}
const getAll = async () => http.get<any, AxiosResponse<GetAllResponse>>('/bull/find');

const bulls = {
  getAll
};

export default bulls;