import { Stack, Typography } from '@mui/material';
import { ButtonWithIcon, Img } from 'components/common';
import { actions } from 'rdx';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Bull } from 'types';
import utils from 'utils';
import styles from './SelectedBull.styles';

type Props = {
  bull: Bull,
  index: number
}

const SelectedBull: FC<Props> = ({ bull, index }) => {
  
  const dispatch = useDispatch();

  return (
    <Stack
      { ...utils.stack.flex.row('space-between', 'center') }
      sx={ styles.container }
    >
      <Stack
        { ...utils.stack.flex.row('flex-start', 'center') }
        spacing={ 2 }
      >
        <Img
          url={ utils.segmentIconFromBullOrBreed(bull) }
        />
        <Typography>{ utils.segmentFromBullOrBreed(bull).includes('z') ? 'Zebu' : 'Europeu' }</Typography>
        <Typography translate="no">{ bull?.warName !== '-' ? bull?.warName : bull.name }</Typography>
      </Stack>
      <ButtonWithIcon
        iconURL='/icons/sm-rm.svg'
        size='s'
        variant='outlined'
        onClick={() => dispatch(actions.items.unselectBull(index))}
      >
        Remover
      </ButtonWithIcon>
    </Stack>
  );
};

export default SelectedBull;