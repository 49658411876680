import { objectDeepEntries, objectDepth, objectFilter, objectFromDeepEntries, objectIs } from 'object-fn-ts/lib';
import { CommonProofCZ, CommonProofLZ } from 'types';
import { CommonProofLZTable } from 'types/proof';

type ProofBuilder = (proofObject: any, selectedProof: string | number | symbol) => any

export const buildEC: ProofBuilder = (proofObject, selectedProof) => {
  const data: any[] = (proofObject[selectedProof] as any).table;
  if (data.every(e => objectIs(e) && objectDepth(e) === 1 && Object.keys(e).length === 1)) {
    const objectData = data.reduce((acm: any, e: any) => {
      return {
        ...acm,
        [Object.keys(e)[0]]: Object.values(e)[0]
      };
    }, {});
    return objectData;
  } else {
    return data;
  }
};

export const buildEL: ProofBuilder = (proofObject, selectedProof) => {
  return objectFromDeepEntries(
    objectDeepEntries(proofObject[selectedProof])
      .map(([ key, val ]) => [
        key.replace(/ \d+$/, ''),
        typeof val === 'object'
          ? Array.isArray(val)
            ? val.map(([ key2, val2 ]) => [ key2.replace(/ \d+$/, ''), val2 ])
            : val
          : val
      ])
  );
};

export const buildZC: ProofBuilder = (proofObject, selectedProof) => {
  const raw = proofObject[selectedProof] as CommonProofCZ;
  const filteredTable = { ...raw, table: raw.table.filter(e => e.headers.length > 0) };
  const treatedKeys = objectFromDeepEntries(objectDeepEntries(filteredTable)
    .map(([ key, val ]: [string, any]) => [
      key.replace(/ \d+$/g, ''),
      Array.isArray(val) && val.every(c => Array.isArray(c) && c.length === 2)
        ? val.map(([ key2, val2 ]) => [ key2.replace(/ \d+$/g, ''), val2 ])
        : val
    ])) as CommonProofCZ;
  const tableKeys = treatedKeys.table.map(e => e.name.toLowerCase());
  const duplicatedRm = objectFilter(
    treatedKeys,
    (key) => !tableKeys.includes(`${key}`.toLowerCase())
  ) as CommonProofCZ;
  return duplicatedRm;
};

export const buildZL: ProofBuilder = (proofObject, selectedProof) => {
  const data: CommonProofLZTable = proofObject[selectedProof];
  const filteredData = data.table.filter(
    (e: any) => typeof e === 'object' &&
                'headers' in e &&
                !([ 'Touro', 'Raça', 'Código' ].includes(e.headers))
  );
  return filteredData; 
};