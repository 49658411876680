import { Box, Stack, Typography } from '@mui/material';
import { Img } from 'components/common';
import { FC } from 'react';
import utils from 'utils';
import { Info } from './Pedigree.types';

type Props = {
  info: Info,
  sm?: boolean,
  position: 'top' | 'bottom'
  type: 'm' | 'f'
}

const ParentLine: FC<Props> = ({ info, sm = false, position, type }) => {
  return (
    <Box
      sx={{
        ...utils.sx.flex.col('flex-end', position === 'top' ? 'flex-end' : 'flex-start'),
        width: '100%',
        height: sm ? '75px' : '135px'
      }}
    >
      <Stack { ...utils.stack.flex.row('center', 'flex-start') }>
        <Stack { ...utils.stack.flex.col('flex-end', 'center')} sx={{ padding: '4px', textAlign: 'end' }}>
          <Typography variant={type === 'm' ? 'h3' : 'body1' } translate="no">{ info.name }</Typography>
          { info.code &&
            <Typography variant='body2'>{ info.code }</Typography>
          }
        </Stack>
        <Box sx={{
          paddingY: '4px'
        }}>
          <Img sx={{ width: '24px', height: '24px' }} url={`/icons/${type}.svg`} />
        </Box>
      </Stack>
    </Box>
  );
};

export default ParentLine;