import { withTheme } from 'theme';
import utils from 'utils';

const container = withTheme((theme) => ({
  ...utils.sx.container(theme),
  paddingTop: '6px',
  paddingBottom: '20px',
  [theme.breakpoints.down('md')]: {    
    maxWidth: '400px',
  }
}));

const textLayer = withTheme((theme) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(3),
  ...utils.sx.fw
}));

const btnsContainer = withTheme((theme) => ({
  width: '100%',
  maxWidth: 'none',
  [theme.breakpoints.up('md')]: {
    maxWidth: '330px'
  }
}));

const styles = {
  container,
  textLayer,
  btnsContainer
};

export default styles;