import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import { Bull } from 'types';
import utils from 'utils';
import Fork from './Fork';
import ParentGroup from './ParentGroup';
import { Info } from './Pedigree.types';

type Props = {
  bull: Bull
}

const commonKeys = [
  'Pai',
  'Mãe',
  'Avô Paterno',
  'Avó Paterno',
  'Avô Materno',
  'Avó Materno'
] as const;

type CommonKeys = typeof commonKeys[number]

const extract = (x: any) => {
  const gc = (x: any): Info =>
    typeof x === 'string'
      ? { name: x }
      : typeof x === 'object'
        ? { name: x?.name || 'S/R', code: x?.code }
        : { name: 'S/R' };
  return Array.isArray(x) ? gc(x[0]) : gc(x);
};

const Pedigree: FC<Props> = ({ bull }) => {

  const segment = utils.segmentFromBullOrBreed(bull);
  let p, m, pp, mp, pm, mm, ppp, mpp, pmp, mmp, ppm, mpm, pmm, mmm;
  let hasThirdLayer = segment === 'zc';

  if (bull.pedigree instanceof Array && bull.pedigree.length === 1) hasThirdLayer = true;
  if (bull.pedigree instanceof Array && bull.pedigree.length > 1) {

    const importantMembers = (bull.pedigree as any[])
      .filter((o: any) => Object.keys(o).some((k) => commonKeys.includes(k as CommonKeys)));

    const parentsMap = importantMembers.reduce((acm: any, curr) => {
      const [ [ p, m ] ] = Object.entries(curr);
      return { ...acm, [p]: m };
    }, {}) as {[K in CommonKeys]: any};
    // const [ _p, _m, _pp, _mp, _pm, _mm ] = bull.pedigree.map(e => Object.values(e));
    p = extract(parentsMap['Pai']);
    m = extract(parentsMap['Mãe']);
    pp = extract(parentsMap['Avô Paterno']);
    mp = extract(parentsMap['Avó Paterno']);
    pm = extract(parentsMap['Avô Materno']);
    mm = extract(parentsMap['Avó Materno']);
  } else {
    const { Pai: _p, Mae: _m } = bull.pedigree[0] as any;
    const { pai: _pp, mae: _mp } = _p;
    const { pai: _pm, mae: _mm } = _m;
    p = extract(_p);
    m = extract(_m);
    pp = extract(_pp);
    mp = extract(_mp);
    pm = extract(_pm);
    mm = extract(_mm);

    const { pai: _ppp, mae: _mpp } = _pp;
    const { pai: _pmp, mae: _mmp } = _mp;
    const { pai: _ppm, mae: _mpm } = _pm;
    const { pai: _pmm, mae: _mmm } = _mm;
    ppp = extract(_ppp);
    mpp = extract(_mpp);
    pmp = extract(_pmp);
    mmp = extract(_mmp);
    ppm = extract(_ppm);
    mpm = extract(_mpm);
    pmm = extract(_pmm);
    mmm = extract(_mmm);
  }

  return (
    <Box sx={{
      ...utils.sx.flex.row('flex-end', 'center'),
      width: hasThirdLayer ? '700px' : '400px',
      height: hasThirdLayer ? '800px' : '550px',    
      minWidth: 'min-content',
    }}>
      {
        hasThirdLayer && ppp && mpp && pmp && mmp && ppm && mpm && pmm && mmm &&
        <Box sx={t => ({
          ...utils.sx.flex.col('flex-end', 'center'),
          width: '200px',
          height: '800px',
          [t.breakpoints.down('md')]: {            
            marginLeft: '100px'
          },
          [t.breakpoints.down('sm')]: {            
            marginLeft: '200px'
          }
        })}>
          <Box sx={{
            ...utils.sx.flex.col('flex-end', 'space-between'),
            width: '200px',
            height: '300px',
          }}>
            <ParentGroup group={{ m: ppp, f: mpp }} omitLeftBorder sm/>
            <ParentGroup group={{ m: pmp, f: mmp }} omitLeftBorder sm/>
          </Box>
          <Box sx={{
            ...utils.sx.flex.col('flex-end', 'space-between'),
            width: '200px',
            height: '300px',
          }}>
            <ParentGroup group={{ m: ppm, f: mpm }} omitLeftBorder sm/>
            <ParentGroup group={{ m: pmm, f: mmm }} omitLeftBorder sm/>
          </Box>
        </Box>
      }
      <Fork
        g1={{ m: p, f: m }}
        g2a={{ m: pp, f: mp }}
        g2b={{ m: pm, f: mm }}
        isNode={ hasThirdLayer }
      />
    </Box>
  );
};

export default Pedigree;