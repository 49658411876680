import { withTheme } from 'theme';

const container = withTheme(() => ({
  width: '90%',
  marginTop: '30px'
}));

const coversWrapper = withTheme(() => ({
  width: '85%',  
  marginY: '30px',
}));

const getCoverItemContainer = (cover: number, i: number) =>
  withTheme(() => ({
    width: '100%',
    maxWidth: '360px',    
    minHeight: '225px',
    padding: '12px',
    background: cover === i ? '#A8C8E5' : '#EAF2FA',
    borderRadius: '2px',
    border: '2px solid transparent',
    ':hover': {
      border: '2px solid #154775',
      cursor: 'pointer'
    }
  }));

const imgContainer = withTheme((theme) => ({
  width: '100%',  
  aspectRatio: '25/27',
  minWidth: '118px',
  borderRadius: '2px',
  [theme.breakpoints.up('md')]: {
    minWidth: '250px',
  }
}));

const styles = {
  container,
  coversWrapper,
  getCoverItemContainer,
  imgContainer
};

export default styles;