import { Stack, Typography } from '@mui/material';
import { Space } from 'components/common';
import { FC } from 'react';
import { Bull } from 'types';
import utils from 'utils';

type Props = {
  bull: Bull
}

const TextInfo: FC<Props> = ({ bull }) => {
  return (
    <Stack
      { ...utils.stack.flex.col('flex-start', 'flex-start') }
      sx={ utils.sx.fw }
    >
      <Typography variant='h3'>
        { 'Nome completo: ' }
        <Typography component='span'>{ bull?.name || 'S/R' }</Typography>
      </Typography>
      <Typography variant='h3'>
        { 'Registro: ' }
        <Typography component='span'>{ bull?.registry || 'S/R' }</Typography>
      </Typography>
      <Typography variant='h3'>
        { 'Código CRV: ' }
        <Typography component='span'>{ bull?.CRVcode || 'S/R' }</Typography>
      </Typography>
      <Typography variant='h3'>
        { 'Nascimento: ' }
        <Typography component='span'>{ utils.string.tsToBrDate(bull.birthDate) || 'S/R' }</Typography>
      </Typography>
      <Typography variant='h3'>
        { 'Criador: ' }
        <Typography component='span'>{ bull?.creator || 'S/R' }</Typography>
      </Typography>
      <Typography variant='h3'>
        { 'Proprietário: ' }
        <Typography component='span'>{ bull?.owner || 'S/R' }</Typography>
      </Typography>
      <Space size={ 3 } />
      {
        'lineage' in bull &&
        !!bull.lineage &&
        <Typography variant='h3'>
          { 'Linhagem: ' }
          <Typography component='span'>{ bull?.lineage || 'S/R' }</Typography>
        </Typography>
      }
      <Space size={ 3 } />
      <Typography>
        { bull.comment1 !== '-' ? bull.comment1 : ''}
      </Typography>
      <Typography>
        { bull.comment2 !== '-' ? bull.comment2 : '' }
      </Typography>
      <Typography>
        { bull.comment3 !== '-' ? bull.comment3 : ''}
      </Typography>
      <Typography>
        { bull.comment4 !== '-' ? bull.comment4 : ''}
      </Typography>
      <Typography>
        { bull.comment5 !== '-' ? bull.comment5 : '' }
      </Typography>
    </Stack>
  );
};

export default TextInfo;