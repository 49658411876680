import { Box, CircularProgress, Typography } from '@mui/material';
import { FC, MouseEventHandler } from 'react';
import Icon from '../Img';
import styles, { scales } from './ButtonWithIcon.styles';

type Props = {
  size?: 's' | 'm' | 'l',
  variant?: 'contained' | 'outlined',
  iconURL: string
  children: string,
  onClick?: MouseEventHandler | undefined;
  disabled?: boolean,
  loading?: boolean
}

const ButtonWithIcon: FC<Props> = ({
  size = 'm',
  variant =  'contained',
  iconURL,
  onClick,
  children,
  disabled = false,
  loading = false
}) => {
  return (
    <Box sx={ styles.getContainer(size, variant, disabled) } onClick={ !disabled ? onClick : undefined }>
      { !loading && <Icon url={ iconURL } /> }
      { !loading && <Typography>{ children }</Typography> }
      { loading && <CircularProgress size={ 11 * scales[size] } sx={{ color: 'white' }}/> }
    </Box>
  );
};

export default ButtonWithIcon;