import { Box, Stack, Typography } from '@mui/material';
import { Img } from 'components/common';
import { FC } from 'react';
import { Bull } from 'types';
import utils from 'utils';

type Props = {
  bull: Bull
}

const Measurements: FC<Props> = ({ bull }) => {
  return (
    <Stack
      { ...utils.stack.flex.col('center', 'center') } 
      sx={{ width: '100%' }}
    >
      <Box sx={{ position: 'relative', '& p': {
        position: 'absolute',
        fontWeight: 'bold'
      } }}>
        <Img url='/imgs/medida.png' sx={{ width: '340px' }} />
        <Typography
          variant='body2'
          sx={{  left: '76px', top: '90px' }}
        >{ bull.rumpWidth }</Typography>
        <Typography
          variant='body2'
          sx={{ left: '100px', top: '42px',  }}
        >{ bull.rumpHeight }</Typography>
        <Typography
          variant='body2'
          sx={{ left: '148px', top: '80px' }}
        >{ bull.croupLength }</Typography>
        <Typography
          variant='body2'
          sx={{ left: '164px', top: '126px' }}
        >{ bull.CE }</Typography>
        <Typography
          variant='body2'
          sx={{ left: '208px', top: '126px' }}
        >{ bull.thoracicPerimeter }</Typography>
        <Typography
          variant='body2'
          sx={{ left: '241px', top: '60px' }}
        >{ bull.chestDepth }</Typography>
        <Typography
          variant='body2'
          sx={{ left: '300px', top: '98px' }}
        >{ bull.bodyLength }</Typography>
        <Typography
          variant='body2'
          sx={{ left: '8px', top: '178px' }}
        >{ `${bull.weight} kg aos ${bull.ageInMeasure}` }</Typography>
      </Box>
    </Stack>
  );
};

export default Measurements;