import { withTheme } from 'theme';

const container = withTheme((theme) => ({
  width: '100%',
  minHeight: '100px',
  padding: theme.spacing(1),
  background: '#EAF2FA',
  borderRadius: '2px',
  maxWidth: '600px'
}));

const styles = {
  container
};

export default styles;