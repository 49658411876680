import { withTheme } from 'theme';
import utils from 'utils';

const container = withTheme((theme) => ({
  ...utils.sx.flex.col('flex-start', 'flex-start'),
  ...utils.sx.container(theme),
  paddingY: '40px',
  position: 'relative'
}));

const styles = {
  container
};

export default styles;