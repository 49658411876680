import { withTheme } from 'theme';
import utils from 'utils';

const container = withTheme(() => ({
  ...utils.sx.flex.col('center', 'center'),
  width: '100vw',
  height: '100vh',
  background: '#f4f4f4',
  position: 'fixed',
  top: '0px',
  left: '0px',
  zIndex: 999
}));

const styles = {
  container
};

export default styles;