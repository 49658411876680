import { CircularProgress, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { Page, Space } from 'components';
import { ConsultantCard, Form, Manager, SACCard } from 'components/ConsultantSearch';
import { FC, useEffect, useState } from 'react';
import { consultant } from 'services';
import { Consultant } from 'types';
import utils from 'utils';

const ConsultantSearch: FC = () => {
  
  const gtSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [ state, setState ] = useState({
    uf: '',
    city: ''
  });
  const [ loading, setLoading ] = useState(false);
  const infoSelected = state.uf !== '' && (state.city !== '' && state.city !== '-1');

  const [ results, setResults ] = useState<Consultant[]>([]);

  useEffect(() => {
    (async () => {
      if (state.city !== '' && state.uf !== '') {
        setLoading(true);
        const [ result, error ] = await utils.handlePromise(
          consultant.search(state.uf, state.city)
        );
        setLoading(false);
        if (result) {
          setResults(result.data);
        }
      } else {
        setResults([]);
      }
    })();
  }, [ state ]);

  return (
    <Page title='Busca de consultores' disableMinHeight>
      <Stack
        { ...utils.stack.flex.col(gtSm ? 'center' : 'flex-start', 'flex-start') }
        spacing={ gtSm ? 2 : 0 }
        sx={{ width: '90%', paddingBottom: '64px' }}
      >
        <Space size={ 2 } />
        <Typography sx={{ width: '90%' }}>
            Encontre o consultor mais próximo de você
        </Typography>
        <Form onChange={(uf, city) => setState({ uf, city })} />
        <Space size={ 2 } />
        {
          infoSelected &&
          results.length > 0 &&
          <Typography variant='h2' sx={{ width: '100%', maxWidth: '600px' }}>
            Consultores de negócio
          </Typography>
        }
        <Space size={ 1 } />
        <Stack
          { ...utils.stack.flex.col(infoSelected && !gtSm ? 'flex-start' : 'center', 'center') }
          spacing={ 2 }
          sx={{ width: '100%', ...(!infoSelected ? { minHeight: '50vh' } : {}) }}
        >
          {
            !infoSelected &&
            <Typography variant='h2'>
              Selecione o estado e a cidade para visualizar os consultores
            </Typography>
          }
          {
            infoSelected &&
            !loading &&
            results.length > 0 &&
            results.filter(c => !c.manager).map((consultant, i)=> (
              <ConsultantCard
                key={ `${consultant.name}_${i}` }
                consultant={ consultant }
              />
            ))
          }
          {
            infoSelected &&
            !loading &&
            results.length === 0 &&
            <Typography> Nenhum resultado encontrado </Typography>
          }
          {
            loading &&
            <CircularProgress size={ 32 } color='primary' />
          }
        </Stack>
        <Space size={ 3 } />
        {
          results.filter(c => c.manager).map(m => <Manager manager={ m } />)
        }
        <Space size={ 3 } />
        <SACCard />
      </Stack>
    </Page>
  );
};

export default ConsultantSearch;