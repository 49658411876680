import { withTheme } from 'theme';
import utils from 'utils';

const getContainer = (visible: boolean) => withTheme(() => ({
  width: '90%',
  minHeight: '100px',
  maxWidth: '450px',
  borderRadius: '5px',
  background: 'white',
  boxShadow: '0px 3px 9px rgba(0, 0, 0, .5)',
  position: 'fixed',
  bottom: visible ? '-10px' : '-100%',
  padding: '6px',
  paddingRight: '42px',
  transition: '1s ease'
}));

const closeBtn = withTheme(() => ({
  ...utils.sx.flex.col('center', 'center'),
  width: '42px',
  height: '42px',
  minWidth: '42px',
  minHeight: '42px',
  padding: '6px',
  position: 'absolute',
  top: '4px',
  right: '4px'
}));

const getLeftIcon = (visible: boolean) => withTheme(() => ({
  width: '42px',
  height: '42px',
  minWidth: '42px',
  minHeight: '42px',
  display: visible ? 'block' : 'none'
}));

const styles = {
  getContainer,
  closeBtn,
  getLeftIcon
};

export default styles;