import printableToPathlike  from './printableToPathlike';
import pathlikeToPrintable  from './pathlikeToPrintable';
import capitalize from './capitalize';
import tsToBrDate from './tsToBrDate';
import normalize from './normalize';

const string = {
  printableToPathlike,
  pathlikeToPrintable,
  capitalize,
  tsToBrDate,
  normalize
};

export default string;