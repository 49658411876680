const arrayReplace = <T extends string | number | symbol>(arr: T[], searched: T, replacement: T): T[] => {
  const index = arr.findIndex(e => e === searched);
  if (index !== -1) {
    return [
      ...arr.slice(0, index),
      replacement,
      ...arr.slice(index + 1, arr.length)
    ];
  }
  return arr;
};

export default arrayReplace;