import { Box } from '@mui/material';
import { FC } from 'react';
import utils from 'utils';
import ParentGroup from './ParentGroup';
import { Info } from './Pedigree.types';

type Props = {
  isNode?: boolean,
  g1: {
    m: Info,
    f: Info
  },
  g2a: {
    m: Info,
    f: Info,
  },
  g2b: {
    m: Info,
    f: Info,
  }
}

const Fork: FC<Props> = ({ g1, g2a, g2b, isNode = false }) => {
  return (
    <Box sx={{
      ...utils.sx.flex.row('flex-end', 'center'),
      width: '400px',
      height: isNode ? '450px' : '550px',
      marginLeft: isNode ? '0px' : '50px'
    }}>
      <Box sx={{
        ...utils.sx.flex.col('center', 'space-between'),
        width: '200px',
        height: isNode ? '450px' : '598px',
      }}>
        <ParentGroup group={ g2a } omitLeftBorder={ !isNode } sm={ isNode } />
        <ParentGroup group={ g2b } omitLeftBorder={ !isNode } sm={ isNode } />
      </Box>
      <ParentGroup group={ g1 } />       
    </Box>
  );
};

export default Fork;