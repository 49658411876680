import { Box, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import styles from './Page.styles';

type Props = {
  children?: ReactNode,
  title?: string,
  titleType?: 'partial' | 'total',
  sx?: SxProps,
  disableMinHeight?: boolean
}

const Page: FC<Props> = ({
  title,
  titleType = 'partial',
  sx = {},
  children,
  disableMinHeight = false
}) => {

  const treatedTitle = title
    ? titleType === 'total'
      ? title
      : `CRV${' | ' + title}`
    : 'CRV';

  return (
    <Box sx={{ ...styles.getContainer(disableMinHeight), ...sx } as SxProps}>
      <Helmet>
        <title>
          {
            treatedTitle
          }
        </title>
      </Helmet>
      { children }
    </Box>
  );
};

export default Page;