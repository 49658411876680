import { Stack, Theme, useMediaQuery } from '@mui/material';
import { MainTextSearchInput, Page, Space } from 'components';
import { BullDetails } from 'components/Main';
import { Store } from 'rdx';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import Breeds from './Breeds';
import BullList from './BullList';
import Initial from './Initial';

const Main: FC = () => {
  
  const { scope, items } = useSelector((store: Store) => store);

  const currentBull = items.bulls.find(b => utils.compareWithNullable(b.CRVcode, scope.bull));
  const gtMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  
  return (
    <Page disableMinHeight={ scope.segment !== '' && items.scopedBulls.length === 0 }>
      <Stack
        { ...utils.stack.flex.col('center', 'flex-start') }
        sx={ utils.sx.fw }
      >        
        {
          scope.bull === null &&
          <MainTextSearchInput />
        }
        {/* no-scope */}
        {
          scope.segment === '' &&
          <Initial gtMd={ gtMd } />
        }

        <Stack
          { ...utils.stack.flex.col('center', 'flex-start') }
          spacing={ 3 }
          sx={ utils.sx.container }
        >
          {/* segment-page */}
          {
            scope.segment !== '' &&
            scope.breed === null &&
            scope.bull === null &&
            <Breeds />
          }
          {/* breed-page */}
          {
            scope.segment !== '' &&
            scope.breed !== null &&
            scope.bull === null &&
            <BullList />
          }
          {/* bull-page */}
          {
            scope.segment !== '' &&
            scope.bull !== null &&
            currentBull &&
            <BullDetails bull={ currentBull } />
          }
        </Stack>        
        <Space size={ 3 } />
      </Stack>
    </Page>
  );
};

export default Main;