import { Box } from '@mui/material';
import { FC } from 'react';
import { withTheme } from 'theme';

type Props = {
  size?: number,
  direction?: 'h' | 'v'
}

const Space: FC<Props> = ({ size = 1, direction = 'h' }) => {
  return (
    <Box sx={
      withTheme((theme) => ({
        ...(direction === 'h' ? { height: theme.spacing(size) } : { width: theme.spacing(size) })
      }))
    }>

    </Box>
  );
};

export default Space;