import { withTheme } from 'theme';
import utils from 'utils';

const container = withTheme((theme) => ({
  width: '100%',
  aspectRatio: '3/2',
  cursor: 'pointer',
}));

const imgWrapper = withTheme(() => ({
  ...utils.sx.flex.col('center', 'center'),
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  aspectRatio: '3/2', 
}));

const header = withTheme((theme) => ({
  background: '#EAF2FA',
  width: '100%',
  padding: theme.spacing(1),
  borderRadius: '3px 3px 0px 0px',
  border: `1px solid ${theme.palette.primary.light}`,
  borderBottomColor: 'transparent'
}));

const img = withTheme(() => ({
  width: '100%',
  borderRadius: '0px 0px 3px 3px',
  aspectRatio: '3/2'
}));

const name = withTheme(() => ({
  color: 'white',
  position: 'absolute',
  top: '16px',
  left: '16px'
}));

const styles = {
  container,
  header,
  img,
  imgWrapper,
  name
};

export default styles;