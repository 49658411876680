import { Breed, Bull, Segment } from 'types';

const segmentFromBullOrBreed = (target: Bull | Breed): Segment => {
  const { type, segment } = target;
  if (type === '1' && segment === '1') return 'ec';
  else if (type === '1' && segment === '2') return 'el';
  else if (type === '2' && segment === '1') return 'zc';
  else if (type === '2' && segment === '2') return 'zl';
  else return '';
};

export default segmentFromBullOrBreed;