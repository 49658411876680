import { AxiosResponse } from 'axios';
import http from 'services/http';
import { Consultant } from 'types';

const search = (state: string, city: string) =>
  http.get<any, AxiosResponse<Consultant[]>>(`/user/consultant/find/${state}/${city}`);

const consultant = {
  search
};

export default consultant;