import { Table, TableContainer, TableHead, TableRow, TableCell, Stack, TableBody, Box, Typography } from '@mui/material';
import { FC } from 'react';
import styles from '../TableView/TableView.styles';
import utils from 'utils';

type Props = {
  data: {[key: string]: string},
  title?: string
}
const ObjectView: FC<Props> = ({ data, title }) => {

  
  return (
    <Stack
      {...utils.stack.flex.row('flex-start', 'flex-start')}
      sx={{ width: '100%' }}
    >
      <TableContainer sx={{ minWidth: '450px', width: 'auto', maxWidth: '100%' }}>
        {
          title &&
          <Box sx={{ width: '100%', background: '#333', color: 'white', padding: '6px', border: '3px solid white' }}>
            <Typography>{ title }</Typography>
          </Box>
        }
        <Table sx={{ minWidth: '200px' }}>
          <TableHead>
            <TableRow>
              {
                Object.keys(data).map((header) => (
                  <TableCell
                    sx={ styles.tableCellHeader }
                    align='center'
                  >
                    { typeof header === 'object' ? '[Object]' : header.replace(/ \d+$/g, '') }
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                '&:last-child th': { border: 0 }
              }}
            >
              {
                Object.values(data).map((val) => (
                  <TableCell
                    sx={ styles.tableCellValue }
                    align='right'
                  >
                    { typeof val === 'object' ? '[Object]' : val }
                  </TableCell>                
                ))
              }
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default ObjectView;