import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { Consultant } from 'types';
import utils from 'utils';
import ConsultantCard from '../ConsultantCard';
import styles from './Manager.styles';

type Props = {
  manager: Consultant
}

const Manager: FC<Props> = ({ manager }) => {

  const gtSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <Stack { ...utils.stack.flex.col(gtSm ? 'center' : 'flex-start', 'flex-start') } sx={ styles.container }>
      <Stack { ...utils.stack.flex.row('flex-start', 'center') } sx={ styles.titleWrapper }>
        <Typography variant='h3'> Gerente de negócios </Typography>
      </Stack>
      <ConsultantCard consultant={ manager } />
    </Stack>
  );
};

export default Manager;