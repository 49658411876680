import { withTheme } from 'theme';

const container = withTheme((theme) => ({
  width: '100%',
  padding: theme.spacing(1),
  background: '#EAF2FA',
  borderRadius: '2px',
  maxWidth: '600px'
}));

const infoWrapper = withTheme((theme) => ({
  width: '100%',
}));

const styles = {
  container,
  infoWrapper
};

export default styles;