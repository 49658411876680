import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import utils from 'utils';
import styles from './SACCard.styles';

const SACCard: FC = () => {
  return (
    <Stack
      { ...utils.stack.flex.col('flex-start', 'center') }
      spacing={ .25 }
      sx={ styles.container }
    >
      <Typography variant='h3'> Contato SAC </Typography>
      <Typography> Contato 01: (16) 3797-1500 </Typography>
      <Typography> Contato 02: (16) 99223-6558 </Typography>
      <Typography> Email: sac@crv4all.com.br </Typography>
    </Stack>
  );
};

export default SACCard;