import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { MainTextSearchInput, Page } from 'components';
import { BullResultCard } from 'components/BullSearch';
import { actions, Store } from 'rdx';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import utils from 'utils';

const BullSearch: FC = () => {

  const { items } = useSelector((store: Store) => store);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  return (
    <Page title='Buscar touro'>
      <Box sx={{ ...utils.sx.flex.col('center', 'flex-start'), width: '100%', maxWidth: '1280px' }}>
        <Stack
          { ...utils.stack.flex.col('center', 'flex-start') }
          spacing={ 5 }
          sx={{ width: '100%', paddingBottom: '64px' }}
        >
          <MainTextSearchInput autoFocus />
          {
            items.loading &&
            <Stack { ...utils.stack.flex.col('center', 'center') } sx={ utils.sx.fw }>
              <CircularProgress size={ 32 } />
            </Stack>
          }
          <Stack
            { ...utils.stack.flex.col('center', 'flex-start') }
            spacing={ 3 }
            sx={ utils.sx.container }
          >
            {
              !items.loading &&
              items.filteredBulls
                .map(index => {
                  const bull = items.bulls[index];
                  return  (
                    <BullResultCard
                      key={ bull.name }
                      bull={ bull }
                      onChange={v => dispatch(actions.items[`${v ? '' : 'un'}selectBull`](index))}
                      onClick={() => utils.location.fromBull(navigate, dispatch)(bull)}
                    />
                  );
                })
            }
          </Stack>
          {
            !items.loading &&
            items.filteredBulls.length === 0 &&
            <Typography> Nenhum resultado encontrado </Typography>
          }     
        </Stack>
      </Box>
    </Page>
  );
};

export default BullSearch;