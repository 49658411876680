import { actions } from 'rdx';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Breed } from 'types';
import segmentFromBullOrBreed from 'utils/segmentFromBullOrBreed';
import segmentTranslator from 'utils/segmentTranslator';
import string from 'utils/string';

const fromBreed = (
  navigateFn: NavigateFunction, 
  dispatch: Dispatch
) => (breed: Breed) => {
  dispatch(actions.scope.changeBreed(breed.name));
  navigateFn(`\
    /segment/\
    ${segmentTranslator(segmentFromBullOrBreed(breed), 'pathlike')}/\
    ${string.printableToPathlike(breed.name)}\
  `.replace(/(\s|\t)*/g, ''));
};


export default fromBreed;