import { AxiosResponse } from 'axios';
import http from 'services/http';

type CreationInput = {
  CRVcodes: {
    code: string,
    type: string,
    segment: string
  }[],
  cover: string,
  farm: string,
  customer: string,
  consultant: string,
  consultantPhone: string
}
type CreationOutput = {
  catalog: string
}
const create = (input: CreationInput) => {
  return http.post<any, AxiosResponse<CreationOutput>>('/bull/customizable/catalog', input);
};

const catalog = {
  create
};

export default catalog;