/* eslint-disable @typescript-eslint/no-empty-function */
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Buffer = require('buffer/').Buffer;
window.Buffer = Buffer;
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux'; 
import Routes from './routes';
import theme from './theme';
import store from 'rdx';
import { useEffect } from 'react';

import { initializeApp } from 'firebase/app';
import { getBlob, getStorage, ref, } from 'firebase/storage';
import PDFMerger from 'pdf-merger-js/browser';


// // Initialize Firebase

// function bytesFromBlob(blob: Blob) {
//   return new Promise<string | ArrayBuffer | null>((res, rej) => {
//     const fr = new FileReader();
//     fr.onload = () => {
//       res(fr.result);
//     };
//     fr.onerror = rej;
//     fr.readAsArrayBuffer(blob);
//   });
// }

// {
//   (async () => {
//     const merger = new PDFMerger();
//     const gsref = ref(storage, 'corte_europeu/05120CV.pdf');
//     const gsref2 = ref(storage, 'corte_zebu/7852.pdf');
//     const blob = await getBlob(gsref);
//     const blob2 = await getBlob(gsref2);
//     const file1 = await bytesFromBlob(blob);
//     const file2 = await bytesFromBlob(blob2);
//     if (file1 && file2) {
//       await merger.add(file1);
//       await merger.add(file2);
//       const blob = await merger.saveAsBlob();
//       const url = URL.createObjectURL(blob);
//       window.open(url, '_blank');
//     }
//   })();
// }

function App() {

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      console.info = function() { };
      console.error = function() { };
      console.warn = function() { };
      console.log = function() { };
    }
  }, []);

  return (
    <HelmetProvider>
      <Provider store={ store }>
        <ThemeProvider theme={ theme }>
          <Routes />          
        </ThemeProvider>
      </Provider>      
    </HelmetProvider>
  );
}

export default App;
