import { Box, SxProps, Theme, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import Img from '../Img';
import styles from './Logo.styles';

type Props = {
  sx?: SxProps,
  size?: 's' | 'm' | 'l',
  onClick?: () => void
}

const Logo: FC<Props> = ({ size = 's', onClick, sx }) => {

  const gtMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Box sx={{ ...styles.getContainer(size, gtMd), ...sx } as SxProps}>
      <Img
        url='/imgs/main-logo.svg'
        onClick={ onClick }
      />
    </Box>
  );
};

export default Logo;