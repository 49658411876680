import { SxProps } from '@mui/material';
import { FC, useState } from 'react';

type Props = {
  url: string,
  alt?: string,
  sx?: SxProps,
  onClick?: () => void,
  fallbackImg?: string,
  rmIfFail?: boolean
}

const Img: FC<Props> = ({
  url,
  alt = 'image',
  sx,
  onClick,
  fallbackImg,
  rmIfFail
}) => {

  const [ failed, setFailed ] = useState(false);

  return (
    <>
      {
        !failed &&
        <img
          style={ sx as any }
          src={ url }
          alt={ alt }
          onClick={ onClick }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            if (rmIfFail) setFailed(true);
            currentTarget.src = fallbackImg ?? '';
          }}
        />
      }
    </>
  );
};

export default Img;