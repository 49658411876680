import { AllSelected } from 'rdx/items/items.store';
import { Scope } from 'rdx/scope/scope.store';

export default function isAllSelected(arr: AllSelected[], scope: Scope, type: 'segment' | 'breed') {
  if (type === 'segment') {
    return arr.findIndex(e => e.type === 'segment' && e.key === scope.segment) !== -1;
  } else {
    return arr.findIndex(e =>
      (e.type === 'breed' && e.key === scope.breed) ||
      (e.type === 'segment' && e.key === scope.segment)
    ) !== -1;
  }
}