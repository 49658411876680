import axios from 'axios';
import { NOMEM } from 'dns';

const apiLink = process.env.REACT_APP_API || 'http://localhost:8080';

const client = axios.create({
  baseURL: apiLink,
});

export default client;
