import { withTheme } from 'theme';

const container = withTheme((theme) => ({
  width: '100%',
  paddingY: theme.spacing(3)
}));

const mainImgWrapper = withTheme((theme) => ({
  width: '100%',
  background: '#EAF2FA',
}));

const leftSubContainer = withTheme((theme) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: '50%'
  }
}));

const styles = {
  container,
  mainImgWrapper,
  leftSubContainer
};

export default styles;