import { createTheme, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { getOverrides } from './overrides';
import { palette } from './palette';

const theme = createTheme({
  palette,
  breakpoints: {
    values: {
      xs: 300,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1460
    }  
  }
});

theme.components = {
  ...getOverrides(theme),
};

export const withTheme = (fn: (theme: Theme) => SxProps) => fn(theme);

export const updateThemeComponents = (t: Theme) => t.components = {
  ...getOverrides(t),
};

export default theme;