import { Box, Stack, Typography } from '@mui/material';
import { Store } from 'rdx';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import utils from 'utils';
import Img from '../Img';
import styles from './Feedback.styles';

type Props = {
  text?: string | string[],
  onClose?: () => void,
  type?: 'error' | 'ok'
}

const Feedback: FC<Props> = ({
  text = '',
  type,
  onClose
}) => {

  const feedback = useSelector((store: Store) => store.feedback);

  return (
    <Stack
      { ...utils.stack.flex.row('center', 'center') }
      spacing={ 3 }
      sx={ styles.getContainer(feedback.bottomAlert.visible) }
    >
      <Box sx={ styles.closeBtn } onClick={ onClose }>
        <Img url='/icons/close.svg' sx={{ width: '20px' }} />
      </Box>
      <Img
        url={ '/icons/ok.svg' }
        sx={ styles.getLeftIcon(feedback.bottomAlert.type === 'ok' ) }
      />
      <Img
        url={ '/icons/err.svg' }
        sx={ styles.getLeftIcon(feedback.bottomAlert.type === 'error') }
      />
      <Stack
        { ...utils.stack.flex.col('flex-start', 'center') }
        spacing={ 1.2 }
      >
        {
          (typeof text === 'string' ? [ text ] : text)
            .map((p, i) => (
              <Typography key={ `p${i}` } variant='body2'>{ p }</Typography>
            ))
        }
      </Stack>
    </Stack>
  );
};

export default Feedback;