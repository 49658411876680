import { useState } from 'react';
import { Box, Link, Stack, Typography } from '@mui/material';
import { forwardRef } from 'react';
import theme from 'theme';
import { Breed } from 'types';
import utils from 'utils';
import segmentFromBullOrBreed from 'utils/segmentFromBullOrBreed';
import segmentTranslator from 'utils/segmentTranslator';
import string from 'utils/string';
import Img from '../Img';
import styles from './BreedCard.styles';

type Props = {
  breed: Breed
  onClick?: (breed: Breed) => void
}

const BreedCard = forwardRef<unknown, Props>(({ onClick, breed }, ref) => {
  const [ border, setBorder ] = useState(false);
  return (
    <Link
      { ...utils.stack.flex.col('center', 'flex-start') }
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(breed);
      }}
      href={ `/segment/${segmentTranslator(segmentFromBullOrBreed(breed), 'pathlike')}/${string.printableToPathlike(breed.name).replace(/(\s|\t)*/g, '')}` }
      sx={{ ...styles.container, textDecoration: 'none', color: '#000000' }}
      onMouseEnter={(e) => {
        e.preventDefault();
        setBorder(true);
      }}
      onMouseLeave={(e) => {
        e.preventDefault();
        setBorder(false);
      }}
      ref={ ref }
    >
      <Stack
        { ...utils.stack.flex.row('flex-start', 'center') }
        spacing={ 1 }
        sx={{ ...styles.header, border: `${border ? '2px solid #154775' : `1px solid ${theme.palette.primary.light}` }`, padding: `${border ? 'calc(8px - 1px)' : '8px' }` }}
      > 
        <Img
          url={ utils.segmentIconFromBullOrBreed(breed) }
          sx={{ width: '20px' }}
        />
        <Typography variant='h3'>
          { utils.segmentTranslator(utils.segmentFromBullOrBreed(breed), 'printable') }
        </Typography>
      </Stack>
      <Box sx={{ ...styles.imgWrapper, border: `${border ? '2px solid #154775' : `1px solid ${theme.palette.primary.light}` }` }}>
        <Img
          url={ breed.picture }
          sx={ styles.img }
          fallbackImg='/imgs/default.png'
        />
        <img
          style={{
            width: '80%',
            position: 'absolute',
            top: '0px',
            left: '-1px'
          }}
          src="/imgs/blue-bg.svg"
        />
        <Typography variant='h2' sx={ styles.name }>{ breed.name }</Typography>
      </Box>
    </Link>
  );
});

export default BreedCard;