import { Box, Stack, Typography } from '@mui/material';
import { objectFilter } from 'object-fn-ts/lib';
import utils from 'utils';

const { safeHas } = utils;

type Props = {
  selectedProofData: any,
  color: string
  objName?: string
}

function tryCatch(fn: () => JSX.Element): JSX.Element | null {
  try {
    const result = fn();
    return result;
  } catch (err) {
    console.log({ err });
    return null;
  }
}

function TableCenterChart({ selectedProofData, color, objName = 'EFICIÊNCIA' }: Props) {
  const objNameCapitalized = objName.charAt(0).toUpperCase() + objName.slice(1).toLowerCase();

  

  return tryCatch(() => (
    <Stack
      { ...utils.stack.flex.col('center', 'flex-start') }
      sx={{ marginBottom: '36px' }}
    >
      {
        safeHas(objName, selectedProofData) &&
        Object.entries(objectFilter(selectedProofData?.[`${objName}`], (key) => key !== `${objNameCapitalized}`))
          .map(([ key, val ], i, arr) => {            
            const treatedVal = (+((val.value || '0') as unknown as string).replaceAll(',', '.') || 0) as number;
            const absTreatedVal = Math.abs(treatedVal);
            const max = absTreatedVal > 500
              ? 1000 
              : absTreatedVal > 250
                ? 500
                : absTreatedVal > 100
                  ? 250
                  : absTreatedVal > 50
                    ? 100
                    : absTreatedVal > 10
                      ? 50
                      : 10;

            const baseMin = ((Number(val.max)-Number(val.min))/4)+Number(val.min) ;
            const baseMax = (3*(Number(val.max)-Number(val.base))/4)+Number(val.min);
            const centerMax = ((Number(val.max)-Number(val.base))/2)+Number(val.min);

            const centerBaseMin = ((val.base-val.min)/2) + val.min;
            const centerBaseMax = ((val.max-val.base)/2) + val.base;


            const unit = Math.floor(max / 2);


            const steps = val.middle ? [ val.min, centerBaseMin, val.base, centerBaseMax, val.max ] : [ val.min, baseMin,centerMax, baseMax,val.max ];                  

            const rawPercentage = Math.max(absTreatedVal / val.max * 100, 0) / 2;
            const percentageVal = Math.abs(Math.min(rawPercentage, 50));
            const positive = treatedVal >= 0;
            const remaining = treatedVal > val.max || treatedVal < val.min ;


            const leftRawPercentage = Math.max(((treatedVal-val.min)*100)/(val.max-val.min), 0);
            const leftPercentageVal = Math.min(leftRawPercentage, 100);
            const positiveLeft = leftRawPercentage > 100;
            const negativeLeft = leftRawPercentage <= 0;  

            return (<>
              {
                val.middle ? (
                  <Stack
                    { ...utils.stack.flex.row('space-between', 'center') }
                    sx={{
                      width: '100%',
                      borderBottom: `1px solid ${i === arr.length - 1 ? 'transparent' : '#333'}`,
                      minHeight: '32px',
                      paddingY: '8px',
                    }}
                    spacing={3}
                    key={key}
                  >
                    <Stack
                      { ...utils.stack.flex.row('space-between', 'center') }
                      sx={ utils.sx.fw } 
                      spacing={ 2 }
                    >
                      <Typography>{ key }</Typography>
                      <Typography>{ treatedVal }</Typography>
                    </Stack>
                    <Box sx={{
                      width: '45%',
                      minWidth: '45%',
                      ...utils.sx.flex.row('center', 'center'),
                      position: 'relative'
                    }}>
                      <Box sx={{
                        width: `${percentageVal.toFixed(2) || '1'}%`,
                        background: color,
                        height: '12px',
                        transform: `translateX(${positive ? '' : '-'}50%)`
                      }}>
                        {
                          remaining &&
                      <Box sx={{
                        width: '0px',
                        height: '0px',
                        borderTop: '6px solid transparent',
                        borderBottom: '6px solid transparent',
                        ...(
                          positive
                            ? { borderLeft: `6px solid ${color}`, right: 0 }
                            : { borderRight: `6px solid ${color}`, left: 0 }
                        ),
                        position: 'absolute',
                        transform: `translateX(${positive ? '' : '-'}100%)`,
                        
                      }}></Box>
                        }
                      </Box>
                      <Box sx={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                      }}>
                        {
                          steps.map((n, i) => (
                            <Box sx={{
                              position: 'absolute',
                              width: '2px',
                              height: '17px',
                              background: '#999',
                              marginLeft: `${25 * i}%`,
                              '&::after': {
                                width: 'min-content',
                                content: `"${n}"`,
                                display: 'block',
                                color: '#999',
                                fontSize: '12px',
                                transform: 'translate(-50%, 75%)'                   
                              }
                            }}></Box>
                          ))
                        }
                      </Box>
                  
                    </Box>
                  </Stack>
                ):(
                  <Stack
                    { ...utils.stack.flex.row('space-between', 'center') }
                    sx={{
                      width: '100%',
                      borderBottom: `1px solid ${i === arr.length - 1 ? 'transparent' : '#333'}`,
                      minHeight: '32px',
                      paddingY: '8px',
                    }}
                    spacing={3}
                    key={key}
                  >
                    <Stack
                      { ...utils.stack.flex.row('space-between', 'center') }
                      sx={ utils.sx.fw } 
                      spacing={ 2 }
                    >
                      <Typography>{ key }</Typography>
                      <Typography>{ treatedVal }</Typography>
                    </Stack>
                    <Box sx={{
                      width: '45%',
                      minWidth: '45%',
                      ...utils.sx.flex.row('flex-start', 'center'),
                      position: 'relative'
                    }}>
                      <Box sx={{
                        width: `${leftPercentageVal.toFixed(2)}%`,
                        height: '12px',
                        background: color,
                        position: 'absolute',
                        left: 0
                      }}>
                        {
                          (positiveLeft || negativeLeft) &&
                    <Box sx={{
                      width: '0px',
                      height: '0px',
                      borderTop: '6px solid transparent',
                      borderBottom: '6px solid transparent',
                      ...(
                        positiveLeft
                          ? { borderLeft: `6px solid ${color}`, right: 0 }
                          : { borderRight: `6px solid ${color}`, left: 0 }
                      ),
                      position: 'absolute',
                      transform: `translateX(${positiveLeft ? '' : '-'}100%)`,
                      
                    }}></Box>
                        }
                      </Box>
                      {
                        steps.map((n, i) => (
                          <Box key={i} sx={{
                            position: 'absolute',
                            width: '2px',
                            height: '17px',
                            background: '#999',
                            marginLeft: `${25 * i}%`,
                            '&::after': {
                              width: 'min-content',
                              content: `"${n}"`,
                              display: 'block',
                              color: '#999',
                              fontSize: '12px',
                              transform: 'translate(-50%, 75%)'                   
                            }
                          }}></Box>
                        ))
                      }
                    </Box>
                  </Stack>
                )
              }
              
            </>
            );
          })
      }
    </Stack>
  ));
}

export default TableCenterChart;