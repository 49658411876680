import { Theme, ThemeOptions } from '@mui/material';

export const getTypographyOverrides = (theme: Theme): ThemeOptions['components'] => {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          '::-webkit-scrollbar': {
            height: '.5rem'
          },
          '::-webkit-scrollbar-track': {
            background: '#ccc',
            borderRadius: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '6px'
          }
        },
        h1: {
          fontSize: '24px',
          fontWeight: 'bold',
          [theme.breakpoints.up('md')]: {
            fontSize: '28px'
          }
        },
        h2: {
          fontSize: '18px',
          fontWeight: 'bold',
          [theme.breakpoints.up('md')]: {
            fontSize: '20px'
          }
        },
        h3: {
          fontSize: '14px',
          fontWeight: 'bold',
          [theme.breakpoints.up('md')]: {
            fontSize: '18px'
          }
        },
        body1: {
          fontSize: '14px',
          fontWeight: 'normal',
          [theme.breakpoints.up('md')]: {
            fontSize: '17px'
          }
        },
        body2: {
          fontSize: '11px',
          fontWeight: 'normal',
          [theme.breakpoints.up('md')]: {
            fontSize: '13px'
          }
        }        
      }
    }
  };
};