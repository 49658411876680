import { withTheme } from 'theme';

const getContainer = (clickable: boolean) => withTheme((theme) => ({
  paddingX: theme.spacing(2),
  paddingY: theme.spacing(1.5),
  background: clickable
    ? theme.palette.secondary.dark
    : theme.palette.secondary.light,
  borderRadius: '2px',
  cursor: 'pointer'
}));

const getText = (clickable: boolean) => withTheme((theme) => ({
  fontWeight: '500',
  color: clickable
    ? theme.palette.text.secondary
    : theme.palette.text.primary
}));

const img = withTheme(() => ({
  width: '16px'
}));

const badge = withTheme(() => ({
  '& .MuiBadge-badge': {
    border: '2px solid #fff',
    background: '#FF9600',
    color: '#fff',
    minWidth: '24px',
    minHeight: '24px',
    borderRadius: '50%'
  }
}));

const styles = {
  getContainer,
  getText,
  img,
  badge
};

export default styles;