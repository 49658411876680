import { Box, Stack, Typography } from '@mui/material';
import { actions } from 'rdx';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LocationTrackerItem, Segment } from 'types';
import utils from 'utils';
import Img from '../Img';
import styles from './LocationTracker.styles';

type Props = {
  trackedLocation: LocationTrackerItem[]
}

const LocationTracker: FC<Props> = ({ trackedLocation }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateTo = (trackedLocal: LocationTrackerItem) => () => {
    dispatch(actions.scope.changeFilterValue(''));
    dispatch(actions.scope.changeSegment(trackedLocal.segment));
    navigate(trackedLocal.pathname);
  };

  return (
    trackedLocation.length > 0
      ? (
        <Box sx={ styles.containerDoContainer }>
          <Stack
            { ...utils.stack.flex.row('flex-start', 'center') }
            spacing={ 2 }
            sx={ styles.container }
          >
            <Img
              url='/icons/home.svg'
              sx={ styles.homeIcon }
              onClick={ navigateTo({ pathname: '/', segment: '', printable: '' }) }
            />
            {
              trackedLocation.map((trackedLocal, index, arr)=> (
                <Stack
                  key={ index }
                  {...utils.stack.flex.row('center', 'center') }
                  sx={{
                    paddingTop: '10px'
                  }}
                  spacing={ 2 }
                >
                  <Img url='/icons/chevron-right.svg' />
                  <Typography
                    translate="no"
                    sx={{
                      cursor: index === arr.length - 1 ? 'default' : 'pointer',
                      whiteSpace: 'nowrap',
                      fontWeight: index !== arr.length - 1 ? 'normal' : 'bold',
                      borderBottom: '1px solid transparent',
                      ':hover': {
                        borderBottomColor: index === arr.length - 1 ? 'transparent' : '#333'
                      }
                    }}
                    onClick={ index !== arr.length - 1 ? navigateTo(trackedLocal) : undefined }
                  >
                    { trackedLocal.printable }
                  </Typography>
                </Stack>
              ))
            }
          </Stack>
        </Box>
      )
      : (
        <></>
      )
  );
};

export default LocationTracker;