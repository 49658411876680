import { configureStore } from '@reduxjs/toolkit';
import scope, { scopeActions } from './scope';
import items, { itemsActions } from './items';
import catalog, { catalogActions } from './catalog';
import feedback, { feedbackActions } from './feedback';
import modal, { modalActions } from './modal/modal.store';

const store = configureStore({
  reducer: {
    scope,
    items,
    catalog,
    feedback,
    modal
  },
  middleware: (getDefaultMiddlewares) => getDefaultMiddlewares({
    serializableCheck: false
  })
});

export type Store = ReturnType<typeof store.getState>
export const actions = {
  scope: scopeActions,
  items: itemsActions,
  catalog: catalogActions,
  feedback: feedbackActions,
  modal: modalActions
};
export default store;