import { Box } from '@mui/material';
import { actions } from 'rdx';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CatalogButton from '../CatalogButton';
import Logo from '../Logo';
import styles from './Header.styles';

const Header: FC = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box sx={ styles.container }>
      <Box sx={ styles.contentContainer }>
        <Logo
          onClick={() => {
            dispatch(actions.scope.changeSegment(''));
            dispatch(actions.scope.changeBull_crv_code(null));
            dispatch(actions.scope.changeBreed(null));
            navigate('/');
          }}
          sx={{ cursor: 'pointer' }}
        />
        <CatalogButton />
      </Box>
    </Box>
  );
};

export default Header;