import { withTheme } from 'theme';
import utils from 'utils';

const getContainer = (mhDisabled = false) => withTheme(() => ({
  ...utils.sx.flex.col('center', 'flex-start'),
  width: '100%',
  maxWidth: 'none',
  height: 'auto',
  minHeight: mhDisabled ? 'none' : '100vh'
}));

const styles = {
  getContainer
};

export default styles;