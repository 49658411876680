import { withTheme } from 'theme';

const container = withTheme(() => ({
  width: '100%',
  background: '#EAF2FA',
  maxWidth: '900px',
  padding: '12px'
}));

const styles = {
  container
};

export default styles;