import { withTheme } from 'theme';

const container = withTheme(() => ({
  width: '100%',
  background: '#EAF2FA',
  borderRadius: '2px'
}));

const btnWrapper = withTheme((theme) => ({
  width: '33%',
  maxWidth: '256px',
  paddingY: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingRight: theme.spacing(6)
  }
}));

const pictureWrapper = withTheme((theme) => ({
  width: '33%',
  minWidth: '120px',
  maxWidth: '160px',
  cursor: 'pointer',
  [theme.breakpoints.up('sm')]: {
    minWidth: '170px',
    maxWidth: '260px',
  }
}));

const RGDWrapper = withTheme(() => ({
  width: '100%',
  background: '#75A3CD'
}));

const infoWrapper = withTheme((theme) => ({
  width: '100%',
  padding: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(4)
  }
}));

const styles = {
  container,
  btnWrapper,
  pictureWrapper,
  RGDWrapper,
  infoWrapper
};

export default styles;