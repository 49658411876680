import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCbOS5r4LCD-GvRMy91PF4eShky-G_aJto',
  authDomain: 'crv-lagoa.firebaseapp.com',
  projectId: 'crv-lagoa',
  storageBucket: 'crv-lagoa.appspot.com',
  messagingSenderId: '454572947289',
  appId: '1:454572947289:web:b4fd277becc7d10185be48',
  measurementId: 'G-80H9Y6G3ZF'
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CssBaseline />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
